import {
  getPhiRedactionStatusStudiesStudyIdSubjectsSubjectIdTimepointSubmissionsTimepointSubmissionIdPhiRedactionStatusGet,
  type GetPhiRedactionStatusStudiesStudyIdSubjectsSubjectIdTimepointSubmissionsTimepointSubmissionIdPhiRedactionStatusGetData,
  GetPhiRedactionStatusStudiesStudyIdSubjectsSubjectIdTimepointSubmissionsTimepointSubmissionIdPhiRedactionStatusGetResponse,
  HTTPValidationError,
} from '@common/config/api/client'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export const useGetPHIRedactionStatus = (
  params: GetPhiRedactionStatusStudiesStudyIdSubjectsSubjectIdTimepointSubmissionsTimepointSubmissionIdPhiRedactionStatusGetData['path'],
  query: GetPhiRedactionStatusStudiesStudyIdSubjectsSubjectIdTimepointSubmissionsTimepointSubmissionIdPhiRedactionStatusGetData['query'],
): UseQueryResult<
  GetPhiRedactionStatusStudiesStudyIdSubjectsSubjectIdTimepointSubmissionsTimepointSubmissionIdPhiRedactionStatusGetResponse,
  AxiosError<HTTPValidationError>
> => {
  return useQuery({
    queryKey: ['PHIRedactionStatus'],
    queryFn: async () => {
      const response =
        await getPhiRedactionStatusStudiesStudyIdSubjectsSubjectIdTimepointSubmissionsTimepointSubmissionIdPhiRedactionStatusGet(
          {
            path: params,
            query,
            throwOnError: true,
          },
        )
      return response.data
    },
    enabled: !!query.container_submission_ids.length,
    refetchInterval: (data) => {
      if (!data) return 3000

      const allCompleted = data.state.data?.containers.every((container) => container.phi_status !== 'in_progress')

      return allCompleted ? false : 3000
    },
  })
}
