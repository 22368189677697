import { Order } from '@common/components/Table/table.types'
import { ListStudy, Status } from '@common/config/api/client'

export const getComparator = (order: Order, orderBy: keyof ListStudy) => {
  return order === 'desc'
    ? (a: ListStudy, b: ListStudy) => {
        const aValue = a[orderBy] ?? 0
        const bValue = b[orderBy] ?? 0
        return bValue < aValue ? -1 : bValue > aValue ? 1 : 0
      }
    : (a: ListStudy, b: ListStudy) => {
        const aValue = a[orderBy] ?? 0
        const bValue = b[orderBy] ?? 0
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0
      }
}

export const formatStatusText = (status: Status) => {
  switch (status) {
    case 'STUDY_SETUP':
      return 'Study Setup'
    case 'DRAFT':
      return 'Draft'
    case 'UAT':
      return 'Uat'
    case 'LIVE':
      return 'Live'
    case 'COMPLETED':
      return 'Completed'
    case 'ARCHIVED':
      return 'Archived'
  }
}

export const mapStatusText = (status: string) => {
  switch (status) {
    case 'Study Setup':
      return 'STUDY_SETUP'
    case 'Draft':
      return 'DRAFT'
    case 'Uat':
      return 'UAT'
    case 'Live':
      return 'LIVE'
    case 'Completed':
      return 'COMPLETED'
    case 'Archived':
      return 'ARCHIVED'
    default:
      return 'ALL'
  }
}
