import { ReadTaskSummaryData } from '@common/config/api/client'

export const COLUMN_WIDTH = 290
export const CHECKBOX_WIDTH = 52
export const MIN_VISIBLE_TIMEPOINTS = 5
export const LAST_TIMEPOINT_LEFT_OFFSET = COLUMN_WIDTH * MIN_VISIBLE_TIMEPOINTS
export const TABLE_WIDTH = (COLUMN_WIDTH + 1) * MIN_VISIBLE_TIMEPOINTS + CHECKBOX_WIDTH

export const READ_TASK_SUMMARY_MOCK_DATA: ReadTaskSummaryData = {
  read_task_summary_metadata: {
    ecrf_definition: {
      pages: [
        {
          name: 'page1',
          elements: [
            {
              name: 'question1',
              type: 'boolean',
              title: 'Are there any other sites (other than lymph node or bone) of metastases present?',
            },
            {
              name: 'question2',
              type: 'boolean',
              title: 'Is this image set evaluable?',
            },
            {
              name: 'question3',
              type: 'boolean',
              title: 'Are there any measurable non-regional lymph node metastases present?',
            },
            {
              name: 'question4',
              type: 'boolean',
              title: 'Eligibility / stratification based on information provided above?',
              labelTrue: 'Eligible',
              labelFalse: 'Not eligible',
            },
          ],
        },
      ],
    },
    timepoint_name: 'timepoint_8',
    read_task_type: 'ADJUDICATION',
    task_pseudo_id: 123,
  },

  timepoints: [
    {
      timepoint_eform_definition: {
        title: 'Test',
        pages: [
          {
            name: 'page1',
            title: 'Vivamus dolor',
            elements: [
              {
                type: 'radiogroup',
                name: 'question1',
                title: 'Nam tristique',
                choices: [
                  {
                    value: 'Item 1',
                    text: 'Ut enim nibh',
                  },
                  {
                    value: 'Item 2',
                    text: 'Praesent et ante mauris',
                  },
                  {
                    value: 'Item 3',
                    text: 'Vestibulum',
                  },
                ],
              },
              {
                type: 'radiogroup',
                name: 'question2',
                title: 'Duis vulputate nec risus',
                choices: [
                  {
                    value: 'Item 1',
                    text: 'Fusce urna leo',
                  },
                  {
                    value: 'Item 2',
                    text: 'Nam ut leo',
                  },
                  {
                    value: 'Item 3',
                    text: 'Nunc tincidunt',
                  },
                  {
                    value: 'Item 4',
                    text: 'Ut lacus nunc',
                  },
                ],
              },
            ],
          },
          {
            name: 'page2',
            title: 'Praesent et ante mauris\r',
            elements: [
              {
                type: 'radiogroup',
                name: 'question4',
                title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit? ',
                choices: [
                  {
                    value: 'Item 1',
                    text: 'Nunc',
                  },
                  {
                    value: 'Item 2',
                    text: 'Aliquam',
                  },
                ],
              },
            ],
          },
        ],
      },
      timepoint_eform_submission: {
        question1: 'Item 1',
        question2: 'Item 2',
        question4: 'Item 2',
      },
      response_criteria: 'response_criteria',
      timepoint_name: 'timepoint_1',
      reader_submissions: [
        {
          assigned_reader_id: 'assigned_reader_id',
          ecrf_form_submission: {
            newLesion: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
              },
            ],
            non_target_lesion_assessment: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
              },
            ],
            target_lesion_measurements: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
                long_axis: 'long axis',
                short_axis: 'short axis',
                unit: 'unit',
              },
            ],
            answers: '{"question1":false,"question2":true,"question3":true,"question4":false}',
            comment: 'comment',
            overall_response: {
              from_baseline: 'from baseline',
              from_nadir: 'from nadir',
              new_lesions: 'new lesions',
              non_target_lesion_response: 'non target lesion response',
              overall_response: 'Disease',
              sod: 'sod',
              target_lesion_response: 'target lesion response',
              adjudication_comment: 'adjudication comment',
              adjudication_justification: 'adjudication justification',
            },
          },
        },
        {
          assigned_reader_id: 'assigned_reader_id',
          ecrf_form_submission: {
            newLesion: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
              },
            ],
            non_target_lesion_assessment: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
              },
            ],
            target_lesion_measurements: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
                long_axis: 'long axis',
                short_axis: 'short axis',
                unit: 'unit',
              },
            ],
            answers: '{"question1":false,"question2":true,"question3":true,"question4":true}',
            comment: 'comment',
            overall_response: {
              from_baseline: 'from baseline',
              from_nadir: 'from nadir',
              new_lesions: 'new lesions',
              non_target_lesion_response: 'non target lesion response',
              overall_response: 'Progressive Disease',
              sod: 'sod',
              target_lesion_response: 'target lesion response',
            },
          },
        },
      ],
    },
    {
      timepoint_eform_definition: {
        title: 'Test',
        pages: [
          {
            name: 'page1',
            title: 'dolor dolordolor',
            elements: [
              {
                type: 'radiogroup',
                name: 'question1',
                title: 'Nam tristique',
                choices: [
                  {
                    value: 'Item 1',
                    text: 'Ut enim nibh',
                  },
                  {
                    value: 'Item 2',
                    text: 'Praesent et ante mauris',
                  },
                  {
                    value: 'Item 3',
                    text: 'Vestibulum',
                  },
                ],
              },
              {
                type: 'radiogroup',
                name: 'question2',
                title: 'Duis vulputate nec risus',
                choices: [
                  {
                    value: 'Item 1',
                    text: 'Fusce urna leo',
                  },
                  {
                    value: 'Item 2',
                    text: 'Nam ut leo',
                  },
                  {
                    value: 'Item 3',
                    text: 'Nunc tincidunt',
                  },
                  {
                    value: 'Item 4',
                    text: 'Ut lacus nunc',
                  },
                ],
              },
            ],
          },
          {
            name: 'page2',
            title: 'Praesent et ante mauris\r',
            elements: [
              {
                type: 'radiogroup',
                name: 'question4',
                title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit? ',
                choices: [
                  {
                    value: 'Item 1',
                    text: 'Nunc',
                  },
                  {
                    value: 'Item 2',
                    text: 'Aliquam',
                  },
                ],
              },
            ],
          },
        ],
      },
      timepoint_eform_submission: {
        question1: 'Item 2',
        question2: 'Item 1',
        question4: 'Item 2',
      },
      response_criteria: 'response_criteria',
      timepoint_name: 'timepoint_2',
      reader_submissions: [
        {
          assigned_reader_id: 'assigned_reader_id',
          ecrf_form_submission: {
            newLesion: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
              },
            ],
            non_target_lesion_assessment: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
              },
            ],
            target_lesion_measurements: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
                long_axis: 'long axis',
                short_axis: 'short axis',
                unit: 'unit',
              },
            ],
            answers: '{"question1":false,"question2":true,"question3":true,"question4":true}',
            comment: 'comment',
            overall_response: {
              from_baseline: 'from baseline',
              from_nadir: 'from nadir',
              new_lesions: 'new lesions',
              non_target_lesion_response: 'non target lesion response',
              overall_response: 'Progressive Disease',
              sod: 'sod',
              target_lesion_response: 'target lesion response',
            },
          },
        },
        {
          assigned_reader_id: 'assigned_reader_id',
          ecrf_form_submission: {
            newLesion: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
              },
            ],
            non_target_lesion_assessment: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
              },
            ],
            target_lesion_measurements: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
                long_axis: 'long axis',
                short_axis: 'short axis',
                unit: 'unit',
              },
            ],
            answers: '{"question1":false,"question2":true,"question3":true,"question4":true}',
            comment: 'comment',
            overall_response: {
              from_baseline: 'from baseline',
              from_nadir: 'from nadir',
              new_lesions: 'new lesions',
              non_target_lesion_response: 'non target lesion response',
              overall_response: 'Progressive Disease',
              sod: 'sod',
              target_lesion_response: 'target lesion response',
            },
          },
        },
      ],
    },
    {
      response_criteria: 'response_criteria',
      timepoint_name: 'timepoint_3',
      reader_submissions: [
        {
          assigned_reader_id: 'assigned_reader_id',
          ecrf_form_submission: {
            newLesion: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
              },
            ],
            non_target_lesion_assessment: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
              },
            ],
            target_lesion_measurements: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
                long_axis: 'long axis',
                short_axis: 'short axis',
                unit: 'unit',
              },
            ],
            answers: '{"question1":false,"question2":true,"question3":true,"question4":true}',
            comment: 'comment',
            overall_response: {
              from_baseline: 'from baseline',
              from_nadir: 'from nadir',
              new_lesions: 'new lesions',
              non_target_lesion_response: 'non target lesion response',
              overall_response: 'Progressive Disease',
              sod: 'sod',
              target_lesion_response: 'target lesion response',
            },
          },
        },
        {
          assigned_reader_id: 'assigned_reader_id',
          ecrf_form_submission: {
            newLesion: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
              },
            ],
            non_target_lesion_assessment: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
              },
            ],
            target_lesion_measurements: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
                long_axis: 'long axis',
                short_axis: 'short axis',
                unit: 'unit',
              },
            ],
            answers: '{"question1":false,"question2":true,"question3":true,"question4":true}',
            comment: 'comment',
            overall_response: {
              from_baseline: 'from baseline',
              from_nadir: 'from nadir',
              new_lesions: 'new lesions',
              non_target_lesion_response: 'non target lesion response',
              overall_response: 'Progressive Disease',
              sod: 'sod',
              target_lesion_response: 'target lesion response',
            },
          },
        },
      ],
    },
    {
      response_criteria: 'response_criteria',
      timepoint_name: 'timepoint_4',
      reader_submissions: [
        {
          assigned_reader_id: 'assigned_reader_id',
          ecrf_form_submission: {
            newLesion: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
              },
            ],
            non_target_lesion_assessment: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
              },
            ],
            target_lesion_measurements: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
                long_axis: 'long axis',
                short_axis: 'short axis',
                unit: 'unit',
              },
            ],
            answers: '{"question1":false,"question2":true,"question3":true,"question4":true}',
            comment: 'comment',
            overall_response: {
              from_baseline: 'from baseline',
              from_nadir: 'from nadir',
              new_lesions: 'new lesions',
              non_target_lesion_response: 'non target lesion response',
              overall_response: 'Progressive Disease',
              sod: 'sod',
              target_lesion_response: 'target lesion response',
            },
          },
        },
        {
          assigned_reader_id: 'assigned_reader_id',
          ecrf_form_submission: {
            newLesion: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
              },
            ],
            non_target_lesion_assessment: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
              },
            ],
            target_lesion_measurements: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
                long_axis: 'long axis',
                short_axis: 'short axis',
                unit: 'unit',
              },
            ],
            answers: '{"question1":false,"question2":true,"question3":true,"question4":true}',
            comment: 'comment',
            overall_response: {
              from_baseline: 'from baseline',
              from_nadir: 'from nadir',
              new_lesions: 'new lesions',
              non_target_lesion_response: 'non target lesion response',
              overall_response: 'Progressive Disease',
              sod: 'sod',
              target_lesion_response: 'target lesion response',
            },
          },
        },
      ],
    },
    {
      response_criteria: 'response_criteria',
      timepoint_name: 'timepoint_5',
      reader_submissions: [
        {
          assigned_reader_id: 'assigned_reader_id',
          ecrf_form_submission: {
            newLesion: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
              },
            ],
            non_target_lesion_assessment: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
              },
            ],
            target_lesion_measurements: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
                long_axis: 'long axis',
                short_axis: 'short axis',
                unit: 'unit',
              },
            ],
            answers: '{"question1":false,"question2":true,"question3":true,"question4":true}',
            comment: 'comment',
            overall_response: {
              from_baseline: 'from baseline',
              from_nadir: 'from nadir',
              new_lesions: 'new lesions',
              non_target_lesion_response: 'non target lesion response',
              overall_response: 'Progressive Disease',
              sod: 'sod',
              target_lesion_response: 'target lesion response',
            },
          },
        },
        {
          assigned_reader_id: 'assigned_reader_id',
          ecrf_form_submission: {
            newLesion: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
              },
            ],
            non_target_lesion_assessment: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
              },
            ],
            target_lesion_measurements: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
                long_axis: 'long axis',
                short_axis: 'short axis',
                unit: 'unit',
              },
            ],
            answers: '{"question1":false,"question2":true,"question3":true,"question4":true}',
            comment: 'comment',
            overall_response: {
              from_baseline: 'from baseline',
              from_nadir: 'from nadir',
              new_lesions: 'new lesions',
              non_target_lesion_response: 'non target lesion response',
              overall_response: 'Progressive Disease',
              sod: 'sod',
              target_lesion_response: 'target lesion response',
            },
          },
        },
      ],
    },
    {
      response_criteria: 'response_criteria',
      timepoint_name: 'timepoint_6',
      reader_submissions: [
        {
          assigned_reader_id: 'assigned_reader_id',
          ecrf_form_submission: {
            newLesion: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
              },
            ],
            non_target_lesion_assessment: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
              },
            ],
            target_lesion_measurements: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
                long_axis: 'long axis',
                short_axis: 'short axis',
                unit: 'unit',
              },
            ],
            answers: '{"question1":false,"question2":true,"question3":true,"question4":true}',
            comment: 'comment',
            overall_response: {
              from_baseline: 'from baseline',
              from_nadir: 'from nadir',
              new_lesions: 'new lesions',
              non_target_lesion_response: 'non target lesion response',
              overall_response: 'Progressive Disease',
              sod: 'sod',
              target_lesion_response: 'target lesion response',
            },
          },
        },
        {
          assigned_reader_id: 'assigned_reader_id',
          ecrf_form_submission: {
            newLesion: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
              },
            ],
            non_target_lesion_assessment: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
              },
            ],
            target_lesion_measurements: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
                long_axis: 'long axis',
                short_axis: 'short axis',
                unit: 'unit',
              },
            ],
            answers: '{"question1":false,"question2":true,"question3":true,"question4":true}',
            comment: 'comment',
            overall_response: {
              from_baseline: 'from baseline',
              from_nadir: 'from nadir',
              new_lesions: 'new lesions',
              non_target_lesion_response: 'non target lesion response',
              overall_response: 'Progressive Disease',
              sod: 'sod',
              target_lesion_response: 'target lesion response',
            },
          },
        },
      ],
    },
    {
      response_criteria: 'response_criteria',
      timepoint_name: 'timepoint_7',
      reader_submissions: [
        {
          assigned_reader_id: 'assigned_reader_id',
          ecrf_form_submission: {
            newLesion: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
              },
            ],
            non_target_lesion_assessment: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
              },
            ],
            target_lesion_measurements: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
                long_axis: 'long axis',
                short_axis: 'short axis',
                unit: 'unit',
              },
            ],
            answers: '{"question1":false,"question2":true,"question3":true,"question4":true}',
            comment: 'comment',
            overall_response: {
              from_baseline: 'from baseline',
              from_nadir: 'from nadir',
              new_lesions: 'new lesions',
              non_target_lesion_response: 'non target lesion response',
              overall_response: 'Progressive Disease',
              sod: 'sod',
              target_lesion_response: 'target lesion response',
            },
          },
        },
        {
          assigned_reader_id: 'assigned_reader_id',
          ecrf_form_submission: {
            newLesion: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
              },
            ],
            non_target_lesion_assessment: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
              },
            ],
            target_lesion_measurements: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
                long_axis: 'long axis',
                short_axis: 'short axis',
                unit: 'unit',
              },
            ],
            answers: '{"question1":false,"question2":true,"question3":true,"question4":true}',
            comment: 'comment',
            overall_response: {
              from_baseline: 'from baseline',
              from_nadir: 'from nadir',
              new_lesions: 'new lesions',
              non_target_lesion_response: 'non target lesion response',
              overall_response: 'Progressive Disease',
              sod: 'sod',
              target_lesion_response: 'target lesion response',
            },
          },
        },
      ],
    },
    {
      response_criteria: 'response_criteria',
      timepoint_name: 'timepoint_8',
      reader_submissions: [
        {
          assigned_reader_id: 'assigned_reader_id_1',
          ecrf_form_submission: {
            newLesion: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
              },
            ],
            non_target_lesion_assessment: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
              },
            ],
            target_lesion_measurements: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
                long_axis: 'long axis',
                short_axis: 'short axis',
                unit: 'unit',
              },
            ],
            answers: '{"question1":false,"question2":true,"question3":true,"question4":true}',
            comment: 'comment',
            overall_response: {
              from_baseline: 'from baseline',
              from_nadir: 'from nadir',
              new_lesions: 'new lesions',
              non_target_lesion_response: 'non target lesion response',
              overall_response: 'Progressive Disease',
              sod: 'sod',
              target_lesion_response: 'target lesion response',
            },
          },
        },
        {
          assigned_reader_id: 'assigned_reader_id_2',
          ecrf_form_submission: {
            newLesion: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
              },
            ],
            non_target_lesion_assessment: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
              },
            ],
            target_lesion_measurements: [
              {
                lesion_id: 'lesion_id',
                location: 'location',
                state: 'state',
                long_axis: 'long axis',
                short_axis: 'short axis',
                unit: 'unit',
              },
            ],
            answers: '{"question1":false,"question2":true,"question3":true,"question4":true}',
            comment: 'comment',
            overall_response: {
              from_baseline: 'from baseline',
              from_nadir: 'from nadir',
              new_lesions: 'new lesions',
              non_target_lesion_response: 'non target lesion response',
              overall_response: 'Progressive Disease',
              sod: 'sod',
              target_lesion_response: 'target lesion response',
            },
          },
        },
      ],
    },
  ],
}
