import { Stack, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

export default function ReviewDicomTagsTableHeader() {
  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{
            borderBottom: '1px solid #DCE3E5',
          }}
        >
          Tag ID and name
        </TableCell>
        <TableCell
          sx={{
            borderBottom: '1px solid #DCE3E5',
          }}
        >
          Status
        </TableCell>
        <TableCell
          sx={{
            borderBottom: '1px solid #DCE3E5',
          }}
        >
          New value
        </TableCell>
        <TableCell
          sx={{
            borderBottom: '1px solid #DCE3E5',
          }}
        >
          Instances
        </TableCell>
        <TableCell
          sx={{
            borderBottom: '1px solid #DCE3E5',
          }}
        >
          <Stack direction="row" alignItems="center" spacing={0.5}>
            Apply to all
            <Tooltip title="Enable the toggle to apply the new value to the selected DICOM tag across all series" arrow>
              <InfoOutlinedIcon sx={{ fontSize: 16, color: 'text.secondary' }} />
            </Tooltip>
          </Stack>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}
