import { useState, MouseEvent } from 'react'

import { StudyUserReadSchema } from '@common/config/api/client'
import { useNavigate, useParams } from 'react-router-dom'

export default function useUserTable(users: StudyUserReadSchema[], onDeleteUser: (userId: string) => void) {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null)
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null)
  const { studyId } = useParams<{ studyId?: string }>()
  const navigate = useNavigate()

  const handleOpenMenu = (event: MouseEvent<HTMLElement>, userId: string) => {
    event.stopPropagation()
    setMenuAnchorEl(event.currentTarget)
    setSelectedUserId(userId)
  }

  const handleCloseMenu = () => {
    setMenuAnchorEl(null)
    setSelectedUserId(null)
  }

  const handleEditUser = () => {
    const selectedUser = users.find((user) => user.user_id === selectedUserId)
    if (selectedUserId && selectedUser) {
      navigate(`/studies/${studyId}/account-management/${selectedUser.company_id}/${selectedUserId}?edit=true`)
      handleCloseMenu()
    }
  }

  const handleDeleteUser = () => {
    const selectedUser = users.find((user) => user.user_id === selectedUserId)
    if (selectedUser && selectedUserId) {
      onDeleteUser(selectedUserId)
      handleCloseMenu()
    }
  }

  function transformUserType(type: string): string {
    return type
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')
  }

  function getUserTypeRoutes(studyUsersMetadata: { user_types: Array<Array<string>> } | undefined) {
    return (studyUsersMetadata?.user_types ?? [])
      .map((user_type) => user_type[0])
      .filter((value, index, self) => self.indexOf(value) === index)
      .map((type) => ({
        path: `#${type.toLowerCase()}`,
        label: transformUserType(type),
        id: type.toLowerCase(),
      }))
  }

  return {
    menuAnchorEl,
    handleOpenMenu,
    handleCloseMenu,
    handleEditUser,
    handleDeleteUser,
    getUserTypeRoutes,
    setSelectedUserId,
  }
}
