import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Fragment, useState } from 'react'
import { PopupState } from 'material-ui-popup-state/hooks'
import { useForm } from 'react-hook-form'
import { TextField } from '@common/components/Form/TextField'
import { DeleteOutlineRounded } from '@mui/icons-material'
import { useDeleteSeriesMetadataMutation } from '@features/qc_task_details_view/hooks/useDeleteSeriesMetadataMutation'
import { TimepointContainerSubmissionExamMetadataResponse } from '@common/config/api/client'
import { useParams } from 'react-router-dom'
import { IconButton } from '@mui/material'
import { SIZING } from '@common/theme/sizing'
import { useDeleteExamMetadataMutation } from '@features/qc_task_details_view/hooks/useDeleteExamMetadataMutation'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  borderRadius: 2,
  bgcolor: 'background.default',
  boxShadow: 8,
  padding: 1,
}

export default function DeleteModal({
  container_submission_id,
  exam,
  popupState,
  selectedIds,
}: {
  container_submission_id?: string
  exam?: TimepointContainerSubmissionExamMetadataResponse
  popupState?: PopupState
  selectedIds?: string[]
}) {
  const [open, setOpen] = useState(false)
  const { qcTaskId, studyId } = useParams()
  const { control } = useForm({ mode: 'onChange', defaultValues: { reason: '' } })
  const handleClose = () => {
    setOpen(false)
    popupState?.close()
  }
  const { mutateAsync: deleteSeriesMetadata, isPending: seriesPending } = useDeleteSeriesMetadataMutation()
  const { mutateAsync: deleteExamMetadata, isPending: examPending } = useDeleteExamMetadataMutation()

  const handledDeleteStudy = async () => {
    if (!exam || !container_submission_id) return

    if (!popupState) {
      const series_metadata_ids =
        selectedIds?.join(',') ?? exam.series.map((series) => series.series_metadata_id).join(',')

      deleteSeriesMetadata({
        path: {
          qc_task_id: qcTaskId!,
        },
        query: {
          exam_metadata_id: exam.exam_metadata_id!,
          study_id: studyId!,
          series_metadata_ids: series_metadata_ids,
          timepoint_container_submission_id: container_submission_id,
        },
      }).finally(() => handleClose())
    } else {
      deleteExamMetadata({
        path: {
          qc_task_id: qcTaskId!,
        },

        query: {
          exam_metadata_id: exam.exam_metadata_id!,
          study_id: studyId!,
          timepoint_container_submission_id: container_submission_id,
        },
      }).finally(() => handleClose())
    }
  }

  return (
    <>
      <Box
        onClick={() => setOpen(true)}
        sx={{
          display: 'flex',
          width: '100%',
          gap: 1.5,
          alignItems: 'center',
        }}
        data-testid="delete-task-button"
        tabIndex={0}
      >
        {popupState ? (
          <Fragment>
            <DeleteOutlineRounded
              sx={{
                color: 'text.secondary',
              }}
            />
            <Typography>Delete</Typography>
          </Fragment>
        ) : (
          <IconButton>
            <DeleteOutlineRounded
              sx={{
                color: 'text.secondary',
                width: SIZING.iconWidth,
                height: SIZING.iconWidth,
              }}
            />
          </IconButton>
        )}
      </Box>
      <Modal open={open} onClose={handleClose} data-testid="delete-modal">
        <Box sx={modalStyle}>
          <Box
            sx={{
              display: 'flex',
              padding: 2,
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: 0.5,
              alignSelf: 'stretch',
              width: 600,
            }}
          >
            {popupState ? (
              <Fragment>
                <Typography variant="h6" color="text.primary" id="delete-modal-title">
                  Delete study?
                </Typography>
                <Typography variant="body1" color="text.secondary" id="delete-modal-description">
                  You will fully remove the study from the task with no option to restore it.
                </Typography>
              </Fragment>
            ) : (
              <Fragment>
                <Typography variant="h6" color="text.primary" id="delete-modal-title">
                  Delete series?
                </Typography>
                <Typography variant="body1" color="text.secondary" id="delete-modal-description">
                  You will fully remove the series from the task with no option to restore it.
                </Typography>
              </Fragment>
            )}
          </Box>
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              alignSelf: 'stretch',
              padding: 2,
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              name="reason"
              control={control}
              id="outlined-multiline-static"
              label="Provide a reason"
              multiline
              rows={6}
              fullWidth
              onKeyDown={(e) => e.stopPropagation()}
              data-testid="reason-textfield"
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              alignSelf: 'stretch',
              padding: 2,
              gap: 1,
            }}
          >
            <Button
              variant="contained"
              sx={{
                bgcolor: 'secondary.main',
                color: 'secondary.contrastText',
              }}
              onClick={handleClose}
              loading={seriesPending || examPending}
              data-testid="cancel-delete-task-button"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                bgcolor: 'primary.main',
                color: 'primary.contrastText',
              }}
              onClick={() => handledDeleteStudy()}
              loading={seriesPending || examPending}
              data-testid="confirm-delete-task-button"
            >
              {popupState ? 'Delete study' : 'Delete series'}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}
