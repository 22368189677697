import { Button, Divider, Menu, MenuItem, MenuList, Stack, Typography } from '@mui/material'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { Fragment } from 'react/jsx-runtime'
import {
  DescriptionOutlined,
  FileDownloadRounded,
  KeyboardArrowDownRounded,
  RemoveRedEyeRounded,
} from '@mui/icons-material'
import { SPACING } from '@common/theme/spacing'

export default function EFormPopupMenuList() {
  return (
    <PopupState variant="popover">
      {(popupState) => (
        <Fragment>
          <Button
            variant="outlined"
            sx={{
              paddingX: 0,
              paddingY: 0,
              color: 'text.primary',
              borderColor: 'divider',
            }}
            {...bindTrigger(popupState)}
          >
            <Stack direction="row" alignItems="center">
              <Stack
                direction="row"
                alignItems="center"
                spacing={SPACING.minSpacing}
                paddingX={SPACING.popupMenuSpacingX}
                paddingY={SPACING.PopupMenuSpacingY}
              >
                <DescriptionOutlined />
                <Typography variant="button">eForm</Typography>
              </Stack>
              <Divider orientation="vertical" flexItem />
              <Stack
                direction="row"
                alignItems="center"
                paddingX={SPACING.minSpacing}
                paddingY={SPACING.PopupMenuSpacingY}
              >
                <KeyboardArrowDownRounded />
              </Stack>
            </Stack>
          </Button>
          <Menu
            {...bindMenu(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            data-testid="popup-menu-list"
          >
            <MenuList
              sx={{
                width: 220,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                paddingY: 0.5,
                outline: 'none',
              }}
            >
              <MenuItem
                sx={{
                  height: 36,
                  width: '100%',
                  paddingY: 0.5,
                }}
                data-testid="popup-menu-list-item-reopen"
              >
                <Stack direction="row" spacing={SPACING.popupMenuIconSpacing} alignItems="center">
                  <RemoveRedEyeRounded
                    sx={{
                      color: 'text.secondary',
                    }}
                  />
                  <Typography>Preview</Typography>
                </Stack>
              </MenuItem>
              <MenuItem
                sx={{
                  height: 36,
                  width: '100%',
                  paddingY: 0.5,
                }}
                data-testid="popup-menu-list-item-reset"
              >
                <Stack direction="row" spacing={SPACING.popupMenuIconSpacing} alignItems="center">
                  <FileDownloadRounded
                    sx={{
                      color: 'text.secondary',
                    }}
                  />
                  <Typography>Download</Typography>
                </Stack>
              </MenuItem>
            </MenuList>
          </Menu>
        </Fragment>
      )}
    </PopupState>
  )
}
