import { ReactNode, useState } from 'react'
import { Table, TableBody, TableCell, TableRow, Typography, useTheme } from '@mui/material'
import DefaultSummaryTableHeader from './DefaultSummaryTableHeader'
import ResponseTableRow from './ResponseTableRow'
import { ReadTaskSummaryData } from '@common/config/api/client'
import { TableScrollSize } from '@features/reader_summary/pages/summary.types'
import ExpandableSection from './ExpandableSection'

interface RowDef {
  label: string
  getValue: (tp: ReadTaskSummaryData['timepoints'][number]) => ReactNode
}

export default function ExpandableResponseTableRow({
  timepoints,
  tableScrollSize,
}: {
  timepoints: ReadTaskSummaryData['timepoints']
  tableScrollSize: TableScrollSize
}) {
  const [expanded, setExpanded] = useState(false)
  const theme = useTheme()

  const rows: RowDef[] = [
    {
      label: 'SOD',
      getValue: (tp) => tp.reader_submissions[0].ecrf_form_submission?.overall_response?.sod ?? '',
    },
    {
      label: 'From baseline',
      getValue: (tp) => tp.reader_submissions[0].ecrf_form_submission?.overall_response?.from_baseline ?? '',
    },
    {
      label: 'From nadir',
      getValue: (tp) => tp.reader_submissions[0].ecrf_form_submission?.overall_response?.from_nadir ?? '',
    },
    {
      label: 'Target lesion response',
      getValue: (tp) => tp.reader_submissions[0].ecrf_form_submission?.overall_response?.target_lesion_response ?? '',
    },
    {
      label: 'Non-target lesion response',
      getValue: (tp) =>
        tp.reader_submissions[0].ecrf_form_submission?.overall_response?.non_target_lesion_response ?? '',
    },
    {
      label: 'New lesions',
      getValue: (tp) => tp.reader_submissions[0].ecrf_form_submission?.overall_response?.new_lesions ?? '',
    },
  ]

  return (
    <>
      <ResponseTableRow
        expanded={expanded}
        setExpanded={setExpanded}
        timepoints={timepoints}
        tableScrollSize={tableScrollSize}
      />
      <TableRow>
        <TableCell sx={{ p: 0 }} colSpan={tableScrollSize.minVisibleTimepoints + 2} data-testid="collapse-cell">
          <ExpandableSection expanded={expanded} disableAnimation>
            <Table
              data-testid="expanded-table"
              sx={{
                '& .MuiTableCell-root': { py: 0.75 },
                borderCollapse: 'separate',
              }}
            >
              <DefaultSummaryTableHeader isHidden timepoints={timepoints} tableScrollSize={tableScrollSize} />
              <TableBody>
                {rows.map((rowDef, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    sx={{
                      bgcolor: rowIndex % 2 === 0 ? 'paper.main' : 'background.paper',
                    }}
                  >
                    <TableCell
                      sx={{
                        width: tableScrollSize.columnWidth,
                        minWidth: tableScrollSize.columnWidth,
                        position: 'sticky',
                        left: 0,
                        background: 'inherit',
                        zIndex: 2,
                        paddingLeft: 3,
                        borderBottom: `1px solid ${theme.palette.divider}`,
                      }}
                    >
                      <Typography variant="body2" sx={{ color: 'text.secondary', paddingLeft: 4 }}>
                        {rowDef.label}
                      </Typography>
                    </TableCell>

                    {Array.from({ length: tableScrollSize.emptyCellsCount }).map((_, i) => (
                      <TableCell
                        key={`empty-${i}`}
                        sx={{
                          width: tableScrollSize.columnWidth,
                          minWidth: tableScrollSize.columnWidth,
                          borderBottom: `1px solid ${theme.palette.divider}`,
                        }}
                      />
                    ))}

                    {timepoints.map((timepoint, tpIndex) => {
                      let stickyStyles = {}
                      if (tpIndex === 0) {
                        stickyStyles = {
                          position: 'sticky',
                          left: tableScrollSize.firstTimepointLeftOffset,
                          background: 'inherit',
                          zIndex: 2,
                        }
                      }

                      if (tpIndex === timepoints.length - 1) {
                        stickyStyles = {
                          position: 'sticky',
                          right: tableScrollSize.checkboxWidth,
                          background: 'inherit',
                          zIndex: 2,
                          borderLeft: `1px solid ${theme.palette.primary.main}`,
                        }
                      }
                      const isNadir =
                        timepoint.reader_submissions[0].ecrf_form_submission?.overall_response?.from_nadir === 'Nadir'
                      const isFirstTimepoint = tpIndex === 0
                      const isFirstThreeColumns = rowIndex < 3

                      return (
                        <TableCell
                          key={`tp-${tpIndex}-row-${rowIndex}`}
                          sx={{
                            width: tableScrollSize.columnWidth,
                            minWidth: tableScrollSize.columnWidth,
                            borderBottom: `1px solid ${theme.palette.divider}`,
                            borderLeft: `1px solid ${theme.palette.divider}`,
                            ...stickyStyles,
                          }}
                        >
                          <Typography
                            variant={(isNadir || isFirstTimepoint) && isFirstThreeColumns ? 'button' : 'body2'}
                            sx={{ color: 'text.primary' }}
                          >
                            {rowDef.getValue(timepoint)}
                          </Typography>
                        </TableCell>
                      )
                    })}

                    <TableCell
                      padding="checkbox"
                      sx={{
                        width: tableScrollSize.checkboxWidth,
                        minWidth: tableScrollSize.checkboxWidth,
                        position: 'sticky',
                        right: 0,
                        background: 'inherit',
                        zIndex: 3,
                        borderBottom: `1px solid ${theme.palette.divider}`,
                        borderLeft: `1px solid ${theme.palette.primary.main}`,
                      }}
                    />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ExpandableSection>
        </TableCell>
      </TableRow>
    </>
  )
}
