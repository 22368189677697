const OHIF_BASE_URL = `${import.meta.env.VITE_OHIF_WEB_URL}`
export const OHIF_READER_MODE = `${OHIF_BASE_URL}/blinded`

// jwtToken and studyInstanceUID are temporarily hardcoded, to be removed after
// OHIF keycloak integration and development of backend endpoints for DICOM JSON
export const composeOhifReaderLink = (task_id: string, study_id: string, jwtToken: string) => {
  return `${OHIF_READER_MODE}?studyId=${study_id}&taskId=${task_id}&token=${jwtToken}`
}

export const lauchDicomViewerForQC = (
  qcTaskId?: string,
  studyId?: string,
  seriesInstanceId?: string,
  jwtToken?: string,
) => {
  if (!qcTaskId || !studyId || !seriesInstanceId || !jwtToken) {
    console.error('Invalid parameters')
    return
  }
  const url = `${OHIF_BASE_URL}/qc?qcTaskId=${qcTaskId}&studyId=${studyId}&initialSeriesInstanceUID=${seriesInstanceId}&token=${jwtToken}`
  window.open(url, '_blank', 'noopener,noreferrer')
}

export const lauchDicomViewerForSite = (
  subjectId?: string,
  studyId?: string,
  timepointSubmissionId?: string,
  containerSubmissionId?: string,
  seriesInstanceId?: string,
  jwtToken?: string,
) => {
  if (!subjectId || !studyId || !timepointSubmissionId || !containerSubmissionId || !seriesInstanceId || !jwtToken) {
    console.error('Invalid parameters')
    return
  }

  const url = `${OHIF_BASE_URL}/preview?studyId=${studyId}&subjectId=${subjectId}&timepointSubmissionId=${timepointSubmissionId}&containerSubmissionId=${containerSubmissionId}&initialSeriesInstanceUID=${seriesInstanceId}&token=${jwtToken}`
  window.open(url, '_blank', 'noopener,noreferrer')
}
