import { getStudyUsersMetadataStudiesUsersMetadataGet } from '@common/config/api/client'
import { useQuery } from '@tanstack/react-query'
import queryKeyFactory from '../queryKeyFactory'

export default function useStudyUsersMetadata() {
  return useQuery({
    queryKey: queryKeyFactory.studyUsersMetadata(),
    queryFn: async () => {
      const response = await getStudyUsersMetadataStudiesUsersMetadataGet()
      return response.data ?? { user_types: [] }
    },
  })
}
