import {
  Permission,
  StudyPermission,
  SitePermission,
  SubjectPermission,
  ReadTaskPermission,
  QCTaskPermission,
  QueryPermission,
  StudyUserManagementPermission,
  StudyReportPermission,
} from '@auth/permissionsEnum'
import { RouteConfig, ROUTES } from 'src/routes'

type TabItem = {
  route: RouteConfig
  permissions?: Permission[]
}
export const tabItems: TabItem[] = [
  {
    route: ROUTES.STUDY_DETAILS,
    permissions: [StudyPermission.VIEW_USER_STUDY],
  },
  {
    route: ROUTES.SITES,
    permissions: [SitePermission.ALL],
  },
  {
    route: ROUTES.SUBJECTS,
    permissions: [SubjectPermission.ALL, SubjectPermission.LIST, SubjectPermission.LIST_FOR_OWN_SITE],
  },
  {
    route: ROUTES.READER_TASKS,
    permissions: [
      ReadTaskPermission.VIEW_ASSIGNED_TASKS,
      ReadTaskPermission.VIEW_COMPLETED_TASKS,
      ReadTaskPermission.VIEW_SKIPPED_TASKS,
      ReadTaskPermission.VIEW_TASK_POOL,
      ReadTaskPermission.ALL,
    ],
  },
  {
    route: ROUTES.QC_TASKS,
    permissions: [
      QCTaskPermission.VIEW_ASSIGNED_TASKS,
      QCTaskPermission.VIEW_COMPLETED_TASKS,
      QCTaskPermission.VIEW_TASK_POOL,
      QCTaskPermission.MANAGE_TASKS,
    ],
  },
  {
    route: ROUTES.QUERIES,
    permissions: [QueryPermission.ALL],
  },
  {
    route: ROUTES.ACCOUNT_MANAGEMENT,
    permissions: [StudyUserManagementPermission.ALL],
  },
  {
    route: ROUTES.STUDY_REPORTS,
    permissions: [StudyReportPermission.ALL],
  },
]
