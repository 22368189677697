import { IconButton, Menu, MenuItem, MenuList, Stack, Typography } from '@mui/material'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { Fragment } from 'react/jsx-runtime'
import { FileDownloadRounded, MoreVertRounded } from '@mui/icons-material'
import { TimepointContainerSubmissionExamMetadata } from '@common/config/api/client'
import { SPACING } from '@common/theme/spacing'
import { useState } from 'react'
import SeriesModal from '@features/qc_task_details_view/pages/modals/SeriesModal'

export default function ExamDetailPopupMenuList({
  container_submission_id,
  exam,
}: {
  container_submission_id?: string
  exam?: TimepointContainerSubmissionExamMetadata
}) {
  const [loading, setLoading] = useState(false)

  const handledDownloadExamMetadata = async () => {
    if (!container_submission_id) return
    setLoading(true)

    setLoading(false)
  }

  return (
    <PopupState variant="popover">
      {(popupState) => (
        <Fragment>
          <IconButton {...bindTrigger(popupState)}>
            <MoreVertRounded
              sx={{
                color: 'text.secondary',
                width: 20,
                height: 20,
              }}
            />
          </IconButton>
          <Menu
            {...bindMenu(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            data-testid="popup-menu-list"
          >
            <MenuList
              sx={{
                width: 220,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                paddingY: SPACING.examDetailPopupMenuListSpacing,
                outline: 'none',
              }}
            >
              <MenuItem
                sx={{
                  height: 36,
                  width: '100%',
                  paddingY: SPACING.examDetailPopupMenuListSpacing,
                }}
                data-testid="popup-menu-list-item-reset"
              >
                <SeriesModal container_submission_id={container_submission_id} exam={exam} popupState={popupState} />
              </MenuItem>
              <MenuItem
                sx={{
                  height: 36,
                  width: '100%',
                  paddingY: SPACING.examDetailPopupMenuListSpacing,
                }}
                onClick={() => {
                  handledDownloadExamMetadata()
                  popupState.close()
                }}
                disabled={loading}
                data-testid="popup-menu-list-item-reset"
              >
                <Stack direction="row" spacing={SPACING.popupMenuIconSpacing} alignItems="center">
                  <FileDownloadRounded
                    sx={{
                      color: 'text.secondary',
                    }}
                  />
                  <Typography>Download</Typography>
                </Stack>
              </MenuItem>
            </MenuList>
          </Menu>
        </Fragment>
      )}
    </PopupState>
  )
}
