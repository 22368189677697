import {
  CompaniesMetadataSchema,
  CompanyTypeFilter,
  getCompaniesMetadataCompaniesMetadataGet,
} from '@common/config/api/client'
import { useQuery } from '@tanstack/react-query'
import queryKeyFactory from '@features/company_management/queryKeyFactory'
import { CompanyType } from '../types'
import { Option } from '@common/components/Form/Dropdown/Dropdown'

export interface TransformedCompanyMetadata {
  companyTypeFilter: CompanyType[]
  companyTypes: Option[]
}

function transformCompanyMetadata(data: CompaniesMetadataSchema): TransformedCompanyMetadata {
  return {
    companyTypeFilter: data.company_type_filter.map(([value, label]) => ({
      id: value as CompanyTypeFilter,
      name: value,
      label: label,
    })),
    companyTypes: data.company_types.map(([value, label]) => ({
      label,
      value,
    })),
  }
}

export default function useCompanyMetadata() {
  return useQuery({
    queryKey: queryKeyFactory.companyMetadata(),
    queryFn: async () => {
      const response = await getCompaniesMetadataCompaniesMetadataGet()
      return response.data ?? { companies: [], company_types: [], company_type_filter: [] }
    },
    select: transformCompanyMetadata,
  })
}
