import { useMutation } from '@tanstack/react-query'
import {
  updateCurrentUserProfileAuthProfilePatch,
  UpdateCurrentUserProfileAuthProfilePatchResponse,
  HTTPValidationError,
  UpdateProfileSchema,
} from '@common/config/api/client'
import { AxiosError } from 'axios'

interface Params {
  userData: UpdateProfileSchema
}

/**
 * Hook for updating the authenticated user's profile
 *
 * @returns A mutation for updating profile information
 */
export default function useUpdateProfile() {
  return useMutation<UpdateCurrentUserProfileAuthProfilePatchResponse, AxiosError<HTTPValidationError>, Params>({
    mutationFn: async ({ userData }) => {
      const response = await updateCurrentUserProfileAuthProfilePatch({
        body: {
          name: userData.name,
        },
        throwOnError: true,
      })
      return response.data
    },
  })
}
