import { useQuery } from '@tanstack/react-query'
import { getQcTaskMetadataQcTasksQcTaskIdMetadataGet, QcTaskMetadataSchema } from '@common/config/api/client'
import { queryKeyFactory } from './queryKeyFactory'

export type GetQcTaskMetadataParams = {
  qc_task_id: string
  study_id: string
}

export const useGetQcTaskSubmissionMetadata = (params: GetQcTaskMetadataParams) => {
  return useQuery<QcTaskMetadataSchema, Error, QcTaskMetadataSchema>({
    queryKey: queryKeyFactory.qcTaskMetadata(params),
    queryFn: async () => {
      const response = await getQcTaskMetadataQcTasksQcTaskIdMetadataGet({
        path: {
          qc_task_id: params.qc_task_id,
        },
        query: {
          study_id: params.study_id,
        },
        throwOnError: true,
      })
      return response.data
    },
    enabled: !!params.qc_task_id && !!params.study_id,
  })
}
