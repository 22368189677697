import { Badge, IconButton, Stack, Table, TableCell, TableRow, Typography, useTheme } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { ReceiptLongRounded } from '@mui/icons-material'
import { ReadTaskSummaryData } from '@common/config/api/client'
import { TableScrollSize } from '@features/reader_summary/pages/summary.types'

export default function ResponseTableRow({
  expanded,
  timepoints,
  tableScrollSize,
  setExpanded,
}: {
  expanded: boolean
  timepoints: ReadTaskSummaryData['timepoints']
  tableScrollSize: TableScrollSize
  setExpanded: (expanded: boolean) => void
}) {
  const theme = useTheme()

  const areAnyAdjudicationDifferent = timepoints.some(
    (tp) =>
      tp.reader_submissions[0].ecrf_form_submission?.overall_response?.overall_response !==
        tp.reader_submissions[1].ecrf_form_submission?.overall_response?.overall_response ||
      tp.reader_submissions[0].ecrf_form_submission?.overall_response?.target_lesion_response !==
        tp.reader_submissions[1].ecrf_form_submission?.overall_response?.target_lesion_response ||
      tp.reader_submissions[0].ecrf_form_submission?.overall_response?.non_target_lesion_response !==
        tp.reader_submissions[1].ecrf_form_submission?.overall_response?.non_target_lesion_response ||
      tp.reader_submissions[0].ecrf_form_submission?.overall_response?.new_lesions !==
        tp.reader_submissions[1].ecrf_form_submission?.overall_response?.new_lesions,
  )

  return (
    <TableRow
      data-testid="subject-table-row"
      hover
      sx={{
        '&:hover': {
          '& > td': {
            backgroundColor: (theme) => theme.palette.action.hover,
          },
        },
        bgcolor: 'background.paper',
      }}
    >
      <TableCell
        data-testid="pseudo-id-cell"
        sx={{
          width: tableScrollSize.columnWidth,
          minWidth: tableScrollSize.columnWidth,
          position: 'sticky',
          paddingLeft: 3,
          left: 0,
          background: 'inherit',
          zIndex: 3,
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          sx={{
            color: 'text.primary',
          }}
        >
          <ReceiptLongRounded />
          <Typography variant="button">Response</Typography>
        </Stack>
      </TableCell>
      {Array.from({ length: tableScrollSize.emptyCellsCount }).map((_, i) => (
        <TableCell
          key={`empty-${i}`}
          sx={{
            width: tableScrollSize.columnWidth,
            minWidth: tableScrollSize.columnWidth,
            borderBottom: `1px solid ${theme.palette.divider}`,
          }}
        />
      ))}

      {timepoints.map((timepoint, i) => {
        let stickyStyles = {}

        if (i === 0) {
          stickyStyles = {
            position: 'sticky',
            left: tableScrollSize.firstTimepointLeftOffset,
            bgcolor: 'inherit',
            zIndex: 2,
          }
        }

        if (i === timepoints.length - 1) {
          stickyStyles = {
            position: 'sticky',
            right: tableScrollSize.checkboxWidth,
            bgcolor: 'inherit',
            zIndex: 2,
            borderLeft: `1px solid ${theme.palette.primary.main}`,
          }
        }
        const isAdjudicationDifferent =
          timepoint.reader_submissions[0].ecrf_form_submission?.overall_response?.overall_response !==
          timepoint.reader_submissions[1].ecrf_form_submission?.overall_response?.overall_response
        return (
          <TableCell
            key={`target-lesions-length-${i}`}
            sx={{
              padding: 0,
              width: tableScrollSize.columnWidth,
              minWidth: tableScrollSize.columnWidth,
              borderBottom: `1px solid ${theme.palette.divider}`,
              borderLeft: `1px solid ${theme.palette.divider}`,
              ...stickyStyles,
            }}
          >
            <Table
              sx={{
                padding: 0,
                border: isAdjudicationDifferent ? `2px solid ${theme.palette.error.main}` : 'none',
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    maxWidth: tableScrollSize.columnWidth / 2,
                    width: tableScrollSize.columnWidth / 2,
                  }}
                >
                  <Typography variant="button" sx={{ color: 'text.primary' }} align="left">
                    {timepoint.reader_submissions[0].ecrf_form_submission?.overall_response?.overall_response}
                  </Typography>
                </TableCell>

                <TableCell
                  align="right"
                  sx={{
                    borderLeft: `1px solid ${theme.palette.divider}`,
                    width: tableScrollSize.columnWidth / 2,
                    maxWidth: tableScrollSize.columnWidth / 2,
                  }}
                >
                  <Typography variant="button" sx={{ color: 'text.primary' }} align="right">
                    {timepoint.reader_submissions[1].ecrf_form_submission?.overall_response?.overall_response}
                  </Typography>
                </TableCell>
              </TableRow>
            </Table>
          </TableCell>
        )
      })}

      <TableCell
        padding="checkbox"
        data-testid="expand-cell"
        sx={{
          width: tableScrollSize.checkboxWidth,
          minWidth: tableScrollSize.checkboxWidth,
          position: 'sticky',
          right: 0,
          background: 'inherit',
          zIndex: 3,
          borderBottom: `1px solid ${theme.palette.divider}`,
          borderLeft: `1px solid ${theme.palette.primary.main}`,
        }}
      >
        <Badge color="error" variant="dot" invisible={!areAnyAdjudicationDifferent}>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation()
              setExpanded(!expanded)
            }}
            data-testid="expand-button"
          >
            {expanded ? (
              <KeyboardArrowUpIcon data-testid="icon-expanded" />
            ) : (
              <KeyboardArrowDownIcon data-testid="icon-collapsed" />
            )}
          </IconButton>
        </Badge>
      </TableCell>
    </TableRow>
  )
}
