import { Divider, Stack } from '@mui/material'
import OnHoldModal from '../modals/OnHoldModal'
import DeleteModal from '../modals/DeleteModal'
import { StudyUserRoleAssociation } from '@common/config/api/client'
import PopupAssignMenuList from '../PopupAssignMenuList'

export default function ReaderTaskListBulkActionsTaskPool({
  taskIds,
  study_id,
  assigneeList,
}: {
  taskIds: string[]
  study_id: string
  assigneeList: StudyUserRoleAssociation[]
}) {
  return (
    <Stack direction={'row'} spacing={2} paddingX={2}>
      <Divider orientation={'vertical'} flexItem />
      <PopupAssignMenuList taskIds={taskIds} assigneeList={assigneeList} isBulkAssign />
      <OnHoldModal taskIds={taskIds} study_id={study_id} />
      <DeleteModal taskIds={taskIds} study_id={study_id} />
    </Stack>
  )
}
