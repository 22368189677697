import React from 'react'
import { Stack, Typography, ButtonGroup, Button, SxProps, Theme } from '@mui/material'
import { SPACING } from '@common/theme/spacing'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'

export interface PageHeaderProps {
  title: string
  buttonsVariant?: 'text' | 'contained' | 'outlined'
  primaryAction?: (event: React.MouseEvent<HTMLElement>) => void
  secondaryAction?: (event: React.MouseEvent<HTMLElement>) => void
  icon?: string
  sx?: SxProps<Theme>
}

export function PageHeader({ title, buttonsVariant, primaryAction, secondaryAction, icon, sx }: PageHeaderProps) {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={sx}>
      <Typography variant="h4">{title}</Typography>
      {primaryAction && (
        <ButtonGroup variant={buttonsVariant}>
          <Button onClick={primaryAction}>Add new</Button>
          {icon === 'arrow' && secondaryAction && (
            <Button sx={{ px: SPACING.spacingMd }} onClick={secondaryAction}>
              <KeyboardArrowDownRoundedIcon />
            </Button>
          )}
        </ButtonGroup>
      )}
    </Stack>
  )
}
