import { useGetStudyAdherenceConfig } from '@features/subject/hooks/useGetStudyAdherenceConfig.ts'
import { useGetStudyAdherenceMetadata } from '@features/subject/hooks/useGetStudyAdherenceMetadata.ts'
import { useMemo } from 'react'
import {
  ListStudyAdherenceConfigsStudiesStudyIdAdherenceConfigGetResponse,
  StudyAdherenceConfigMetadataResponse,
} from '@common/config/api/client'
import { Study, CheckItem, Operator } from '@features/subject/subject.types'

function checkOperator(tagValue: string, ruleOperator: Operator, ruleValue: string): boolean {
  switch (ruleOperator) {
    case 'mandatory':
      return !!tagValue

    case 'contains':
      return tagValue.toLowerCase().includes(ruleValue.toLowerCase())

    case 'does_not_contain':
      return !tagValue.toLowerCase().includes(ruleValue.toLowerCase())

    case 'eq':
      return tagValue === ruleValue

    case 'gt':
      return parseFloat(tagValue) > parseFloat(ruleValue)

    case 'gte':
      return parseFloat(tagValue) >= parseFloat(ruleValue)

    case 'lt':
      return parseFloat(tagValue) < parseFloat(ruleValue)

    case 'lte':
      return parseFloat(tagValue) <= parseFloat(ruleValue)

    case 'between': {
      const [minRaw, maxRaw] = ruleValue.split('-')
      const min = parseFloat(minRaw.replace(/[^\d.]/g, ''))
      const max = parseFloat(maxRaw.replace(/[^\d.]/g, ''))
      const val = parseFloat(tagValue.replace(/[^\d.]/g, ''))
      return val >= min && val <= max
    }

    default:
      return true
  }
}

type SeriesResult = {
  isSeriesValid: boolean
  checks: CheckItem[]
}

type CheckResult = {
  [studyName: string]: {
    isStudyValid: boolean
    series: {
      [seriesId: string]: SeriesResult
    }
  }
}

export const useCheckTagsValidity = (studyId: string, dicomFiles?: Array<Study>) => {
  const { data: config, isPending: configLoading } = useGetStudyAdherenceConfig(studyId)
  const { data: metadata, isPending: metadataLoading } = useGetStudyAdherenceMetadata(studyId)

  const checkValidity = (
    config: ListStudyAdherenceConfigsStudiesStudyIdAdherenceConfigGetResponse,
    dicomFiles: Array<Study>,
    metadata: StudyAdherenceConfigMetadataResponse,
  ) => {
    const result: CheckResult = {}

    dicomFiles.forEach((study) => {
      result[study.name] = {
        isStudyValid: true,
        series: {},
      }

      study.series.forEach((series) => {
        let isSeriesValid = true
        const checks: CheckItem[] = []

        const matchingConfigs = config.filter((c) => c.imaging_modality?.name === series.modality)

        if (matchingConfigs.length === 0) {
          result[study.name].series[series.seriesNumber] = {
            isSeriesValid,
            checks,
          }
          return
        }

        matchingConfigs.forEach((cfg) => {
          cfg.rules.forEach((rule) => {
            const { tags, operator, value, is_soft_rule } = rule

            if (tags.length === 0) {
              return
            }

            tags.forEach((ruleTag) => {
              const foundTag = series.tagData.find((td) => td.tagId === ruleTag.tag_id)

              if (!foundTag) {
                checks.push({
                  tagId: ruleTag.tag_id,
                  tagName: ruleTag.tag_name,
                  rule: operator as Operator,
                  ruleLabel: metadata.operators.find((x) => x[0] === operator)?.[1] || '',
                  targetValue: value as string,
                  actualValue: 'N/A',
                  status: 'failed',
                })

                if (!is_soft_rule) {
                  isSeriesValid = false
                }
              } else {
                const passed = checkOperator(foundTag.value, operator as Operator, value as string)
                const status = passed ? 'passed' : 'failed'
                checks.push({
                  tagId: foundTag.tagId,
                  tagName: foundTag.tagName,
                  rule: operator as Operator,
                  ruleLabel: metadata.operators.find((x) => x[0] === operator)?.[1] || '',
                  targetValue: value as string,
                  actualValue: foundTag.value,
                  status,
                })
                if (status === 'failed' && !is_soft_rule) {
                  isSeriesValid = false
                }
              }
            })
          })
        })

        // At least 1 series is invalid - whole study is invalid
        if (!isSeriesValid) {
          result[study.name].isStudyValid = false
        }

        result[study.name].series[series.seriesNumber] = {
          isSeriesValid,
          checks,
        }
      })
    })

    return result
  }

  return {
    result: useMemo(() => {
      if (!configLoading && !metadataLoading && config && dicomFiles && metadata) {
        return checkValidity(config, dicomFiles, metadata)
      } else {
        return null
      }
    }, [config, metadata, dicomFiles, configLoading, metadataLoading]),
  }
}
