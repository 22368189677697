import {
  readTaskEsignReadTasksEsignPost,
  ReadTaskEsignReadTasksEsignPostResponse,
  ReadTaskEsignReadTasksEsignPostData,
  HTTPValidationError,
} from '@common/config/api/client'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { queryClient } from '@common/config/api/queryClient.ts'

export const useReadTaskESignMutation = () => {
  return useMutation<
    ReadTaskEsignReadTasksEsignPostResponse,
    AxiosError<HTTPValidationError>,
    ReadTaskEsignReadTasksEsignPostData
  >({
    mutationFn: async (query) => {
      const response = await readTaskEsignReadTasksEsignPost({
        body: query.body,
        query: query.query,
        throwOnError: true,
      })
      return response.data
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['SummaryReadTasks'] })
    },
  })
}
