import { useQuery } from '@tanstack/react-query'
import {
  getReportsStudiesStudyIdReportsReportTypeGet,
  GetReportsStudiesStudyIdReportsReportTypeGetResponse,
  HTTPValidationError,
  FilterReportTypeSchema,
} from '@common/config/api/client'
import { AxiosError } from 'axios'
import queryKeyFactory from '../queryKeyFactory'

export default function useGetReports(studyId: string, reportType: FilterReportTypeSchema) {
  return useQuery<GetReportsStudiesStudyIdReportsReportTypeGetResponse, AxiosError<HTTPValidationError>>({
    queryKey: queryKeyFactory.reports(studyId, reportType),
    queryFn: async () => {
      const response = await getReportsStudiesStudyIdReportsReportTypeGet({
        path: {
          study_id: studyId,
          report_type: reportType,
        },
        throwOnError: true,
      })

      return response.data
    },
    enabled: !!studyId && !!reportType,
  })
}
