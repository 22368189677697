import { useMutation } from '@tanstack/react-query'
import { removeUserUsersUserIdDelete } from '@common/config/api/client'

interface DeleteUserParams {
  userId: string
}

export default function useDeleteUser() {
  return useMutation({
    mutationFn: ({ userId }: DeleteUserParams) =>
      removeUserUsersUserIdDelete({
        path: {
          user_id: userId,
        },
        throwOnError: true,
      }),
  })
}
