import { useEffect, useState } from 'react'
import { TimepointContainerSubmissionExamMetadataResponse } from '@common/config/api/client'
import { RemoveRedEyeRounded } from '@mui/icons-material'
import { Checkbox, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import SeriesTableHeader from './SeriesTableHeader'
import SeriesTableBulk from './SeriesTableBulk'
import { SPACING } from '@common/theme/spacing'
import { SIZING } from '@common/theme/sizing'
import DeleteModal from '../modals/DeleteModal'
import { lauchDicomViewerForQC, lauchDicomViewerForSite } from '@common/utils/ohifUtils'
import { useParams } from 'react-router-dom'
import { useExtendedAuth } from '@auth/hooks/useExtendedAuth'

export default function SeriesTable({
  container_submission_id,
  exam,
}: {
  container_submission_id?: string
  exam?: TimepointContainerSubmissionExamMetadataResponse
}) {
  const [selected, setSelected] = useState<number[]>([])
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const { qcTaskId, studyId, subjectId, timepointSubmissionId } = useParams()
  const { user } = useExtendedAuth()

  const isSelected = (index: number) => selected.indexOf(index) !== -1

  const handleSelectOne = (index: number) => {
    const selectedIndex = selected.indexOf(index)
    let newSelected: number[] = []
    let newSelectedIds: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, index)
      newSelectedIds = newSelectedIds.concat(selectedIds, exam?.series[index].series_metadata_id ?? '')
    } else {
      newSelected = selected.filter((i) => i !== index)
      newSelectedIds = selectedIds.filter((i) => i !== exam?.series[index].series_metadata_id)
    }

    setSelected(newSelected)
    setSelectedIds(newSelectedIds)
  }

  const handleSelectAll = () => {
    if (!exam?.series) return
    if (selected.length < exam.series.length) {
      setSelected(exam.series.map((_, idx) => idx))
      setSelectedIds(exam.series.map((series) => series.series_metadata_id!))
    } else {
      setSelected([])
      setSelectedIds([])
    }
  }

  const allSeries = exam?.series ?? []

  useEffect(() => {
    setSelected([])
    setSelectedIds([])
  }, [exam])

  return (
    <TableContainer
      sx={{
        borderRadius: SPACING.borderRadiusXl,
      }}
    >
      <Stack spacing={SPACING.minSpacing}>
        <SeriesTableBulk container_submission_id={container_submission_id} exam={exam} selectedIds={selectedIds} />
        <Table size="small">
          <SeriesTableHeader selected={selected} allSeries={allSeries} handleSelectAll={handleSelectAll} />
          <TableBody>
            {allSeries.map((series, index) => {
              const checked = isSelected(index)
              return (
                <TableRow key={index}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={checked}
                      onChange={() => handleSelectOne(index)}
                      sx={{
                        color: 'text.secondary',
                      }}
                    />
                  </TableCell>
                  <TableCell>{series.description}</TableCell>
                  <TableCell>{series.series_number}</TableCell>
                  <TableCell>{series.acquisition_date}</TableCell>
                  <TableCell>{series.total_instances}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() =>
                        qcTaskId
                          ? lauchDicomViewerForQC(qcTaskId, studyId, series.series_uid || undefined, user?.access_token)
                          : lauchDicomViewerForSite(
                              subjectId,
                              studyId,
                              timepointSubmissionId,
                              container_submission_id,
                              series.series_uid || undefined,
                              user?.access_token,
                            )
                      }
                    >
                      <RemoveRedEyeRounded
                        sx={{
                          color: 'text.secondary',
                          width: SIZING.iconWidth,
                          height: SIZING.iconWidth,
                        }}
                      />
                    </IconButton>
                  </TableCell>
                  <TableCell align="right">
                    {series?.series_metadata_id && (
                      <DeleteModal
                        container_submission_id={container_submission_id}
                        exam={exam}
                        selectedIds={[series.series_metadata_id]}
                      />
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Stack>
    </TableContainer>
  )
}
