import { useState } from 'react'
import { Table, TableBody, TableCell, TableRow, Typography, useTheme } from '@mui/material'
import DefaultAdjudicationTableHeader from './DefaultAdjudicationTableHeader'
import CommentTableRow from './CommentTableRow'
import { ReadTaskSummaryData } from '@common/config/api/client'
import { TableScrollSize } from '@features/reader_summary/pages/summary.types'
import ExpandableSection from '../summary/ExpandableSection'

export default function ExpandableCommentTableRow({
  timepoints,
  tableScrollSize,
}: {
  timepoints: ReadTaskSummaryData['timepoints']
  tableScrollSize: TableScrollSize
}) {
  const [expanded, setExpanded] = useState(false)
  const theme = useTheme()

  return (
    <>
      <CommentTableRow
        expanded={expanded}
        setExpanded={setExpanded}
        timepoints={timepoints}
        tableScrollSize={tableScrollSize}
      />
      <TableRow>
        <TableCell sx={{ p: 0 }} colSpan={tableScrollSize.minVisibleTimepoints + 2} data-testid="collapse-cell">
          <ExpandableSection expanded={expanded} disableAnimation>
            <Table
              data-testid="expanded-table"
              sx={{
                borderCollapse: 'separate',
              }}
            >
              <DefaultAdjudicationTableHeader isHidden timepoints={timepoints} tableScrollSize={tableScrollSize} />
              <TableBody>
                <TableRow
                  sx={{
                    bgcolor: 'paper.main',
                  }}
                >
                  <TableCell
                    sx={{
                      width: tableScrollSize.columnWidth,
                      minWidth: tableScrollSize.columnWidth,
                      position: 'sticky',
                      left: 0,
                      background: 'inherit',
                      zIndex: 3,
                      borderBottom: `1px solid ${theme.palette.divider}`,
                    }}
                  />
                  {Array.from({ length: tableScrollSize.emptyCellsCount }).map((_, i) => (
                    <TableCell
                      key={`empty-${i}`}
                      sx={{
                        width: tableScrollSize.columnWidth,
                        minWidth: tableScrollSize.columnWidth,
                        borderBottom: `1px solid ${theme.palette.divider}`,
                      }}
                    />
                  ))}

                  {timepoints.map((timepoint, index) => {
                    let stickyStyles = {}
                    if (index === 0) {
                      stickyStyles = {
                        position: 'sticky',
                        left: tableScrollSize.firstTimepointLeftOffset,
                        background: 'inherit',
                        zIndex: 2,
                      }
                    }
                    if (index === timepoints.length - 1) {
                      stickyStyles = {
                        position: 'sticky',
                        right: tableScrollSize.checkboxWidth,
                        background: 'inherit',
                        zIndex: 2,
                        borderLeft: `1px solid ${theme.palette.primary.main}`,
                        borderBottom: `1px solid ${theme.palette.primary.main}`,
                      }
                    }

                    const comment1 = timepoint.reader_submissions[0].ecrf_form_submission?.comment
                    const comment2 = timepoint.reader_submissions[1].ecrf_form_submission?.comment

                    return (
                      <TableCell
                        key={`comment-${index}`}
                        sx={{
                          padding: 0,
                          width: tableScrollSize.columnWidth,
                          minWidth: tableScrollSize.columnWidth,
                          borderBottom: `1px solid ${theme.palette.divider}`,
                          borderLeft: `1px solid ${theme.palette.divider}`,
                          ...stickyStyles,
                        }}
                      >
                        {(comment1 || comment2) && (
                          <Table sx={{ padding: 0 }}>
                            <TableRow>
                              <TableCell
                                align="left"
                                sx={{
                                  maxWidth: tableScrollSize.columnWidth / 2,
                                  width: tableScrollSize.columnWidth / 2,
                                }}
                              >
                                {comment1 && (
                                  <Typography variant="body2" sx={{ color: 'text.primary' }}>
                                    {comment1}
                                  </Typography>
                                )}
                              </TableCell>
                              <TableCell
                                align="right"
                                sx={{
                                  borderLeft: `1px solid ${theme.palette.divider}`,
                                  width: tableScrollSize.columnWidth / 2,
                                  maxWidth: tableScrollSize.columnWidth / 2,
                                }}
                              >
                                {comment2 && (
                                  <Typography variant="body2" sx={{ color: 'text.primary' }}>
                                    {comment2}
                                  </Typography>
                                )}
                              </TableCell>
                            </TableRow>
                          </Table>
                        )}
                      </TableCell>
                    )
                  })}
                  <TableCell
                    padding="checkbox"
                    sx={{
                      width: tableScrollSize.checkboxWidth,
                      minWidth: tableScrollSize.checkboxWidth,
                      position: 'sticky',
                      right: 0,
                      background: 'inherit',
                      zIndex: 3,
                      borderBottom: `1px solid ${theme.palette.divider}`,
                      borderLeft: `1px solid ${theme.palette.primary.main}`,
                    }}
                  />
                </TableRow>
              </TableBody>
            </Table>
          </ExpandableSection>
        </TableCell>
      </TableRow>
    </>
  )
}
