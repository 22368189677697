import { Stack, Grid2 as Grid } from '@mui/material'
import { Breadcrumb } from '@common/components/Breadcrumb'
import { SERIES_LABEL_MANAGEMENT_BREADCRUMB_ITEMS } from '@features/series_label_management/constants'
import SeriesLabelManagementTable from './SeriesLabelManagementTable'
import SeriesLabelAddMenu from './SeriesLabelAddMenu'

import SeriesLabelManagementTableSkeleton from './SeriesLabelManagementTableSkeleton'
import {
  useSeriesLabelModalityCategoryNavigation,
  useGetSeriesLabels,
  useSeriesLabelManagementHandlers,
} from '@features/series_label_management/hooks'
import CreateOrUpdateSeriesLabelDialog, { SeriesLabelFormValues } from './CreateOrUpdateSeriesLabelDialog'
import { useGetSeriesLabelsMetadata } from '../hooks/useSeriesLabelsQueries'
import { SPACING } from '@common/theme/spacing'
import { SIZING } from '@common/theme/sizing'
import { PageHeader } from '@common/components/PageHeader'

export default function SeriesLabelManagement() {
  const {
    page,
    isSeriesLabelDialogVisible,
    editingSeriesLabelId,
    addMenuAnchor,
    seriesLabelsParams,
    setSeriesLabelsParams,
    editingSeriesLabel,
    currentImagingModalityCategory,
    handleOpenSeriesLabelMenu,
    handleCloseSeriesLabelMenu,
    handleImagingModalitySelect,
    handleSaveSeriesLabel,
    handlePageChange,
    handleRowsPerPageChange,
    handleSearch,
    handleRequestSort,
    handleAddClick,
    handleEditClick,
    handleDuplicateSeriesLabel,
    handleSaveEditedSeriesLabel,
    handleDeleteSeriesLabel,
    seteditingSeriesLabelId,
    setIsSeriesLabelDialogVisible,
    handleImagingModalityCategoryChange,
    handleBulkDeleteSeriesLabels,
    isEditingSeriesLabelPending,
    selectedImagingModality,
  } = useSeriesLabelManagementHandlers()

  const { data: seriesLabelMetadata, isLoading: isMetadataLoading } = useGetSeriesLabelsMetadata()

  const { data: seriesLabels, isPending: isSeriesLabelsPending } = useGetSeriesLabels(seriesLabelsParams)

  useSeriesLabelModalityCategoryNavigation({
    imagingModalityCategories: seriesLabelMetadata?.imaging_modality_categories,
    seriesLabelsParams,
    setSeriesLabelsParams,
  })

  const isLoading = isSeriesLabelsPending || isMetadataLoading

  const shouldShowSeriesLabelDialog =
    isSeriesLabelDialogVisible && (!editingSeriesLabelId || !isEditingSeriesLabelPending)

  return (
    <>
      <Breadcrumb
        items={SERIES_LABEL_MANAGEMENT_BREADCRUMB_ITEMS}
        sx={{ marginBottom: SPACING.breadcrumbsMarginBottom }}
      />
      <Grid container>
        <Grid size={SIZING.gridSizeXxl}>
          <Stack spacing={SPACING.sectionsSpacing}>
            <Stack>
              <PageHeader
                title="Series Label management"
                buttonsVariant="contained"
                primaryAction={handleOpenSeriesLabelMenu}
                icon="arrow"
                secondaryAction={handleOpenSeriesLabelMenu}
              />
            </Stack>
            {isLoading ? (
              <SeriesLabelManagementTableSkeleton />
            ) : (
              <SeriesLabelManagementTable
                imagingModalityCategories={seriesLabelMetadata?.imaging_modality_categories}
                seriesLabels={seriesLabels}
                currentImagingModalityCategory={currentImagingModalityCategory}
                handlePageChange={(event, newPage) => handlePageChange(seriesLabels, event, newPage)}
                onRowsPerPageChange={handleRowsPerPageChange}
                onSearch={handleSearch}
                searchValue={seriesLabelsParams.search ?? ''}
                page={page}
                orderBy={seriesLabelsParams.order_by_field}
                order={seriesLabelsParams.asc_order ? 'asc' : 'desc'}
                onRequestSort={handleRequestSort}
                onAddClick={handleAddClick}
                onEditClick={handleEditClick}
                onDeleteSeriesLabel={handleDeleteSeriesLabel}
                onDuplicateSeriesLabel={handleDuplicateSeriesLabel}
                onImagingModalityCategoryChange={handleImagingModalityCategoryChange}
                onBulkDeleteSeriesLabels={handleBulkDeleteSeriesLabels}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
      <SeriesLabelAddMenu
        anchorEl={addMenuAnchor}
        onClose={handleCloseSeriesLabelMenu}
        imagingModalities={seriesLabelMetadata?.imaging_modalities}
        onImagingModalitySelect={handleImagingModalitySelect}
      />
      {shouldShowSeriesLabelDialog && (
        <CreateOrUpdateSeriesLabelDialog
          onClose={() => {
            setIsSeriesLabelDialogVisible(false)
            seteditingSeriesLabelId(null)
          }}
          title={editingSeriesLabelId ? 'Edit Series Label' : 'New Series Label'}
          onSave={editingSeriesLabelId ? handleSaveEditedSeriesLabel : handleSaveSeriesLabel}
          data={
            editingSeriesLabelId
              ? ({
                  ...editingSeriesLabel,
                  imaging_modality_id: editingSeriesLabel?.imaging_modality?.id,
                } as unknown as SeriesLabelFormValues)
              : {
                  name: '',
                  imaging_modality_id: selectedImagingModality?.value ?? '',
                  allow_qc_to_duplicate: false,
                }
          }
        />
      )}
    </>
  )
}
