import { Button, Menu, MenuItem, MenuList, Stack, Typography } from '@mui/material'
import PopupState, { bindMenu } from 'material-ui-popup-state'
import { Fragment } from 'react/jsx-runtime'
import { useState } from 'react'
import { CheckRounded } from '@mui/icons-material'
import { UsePatchTaskMutation } from '@features/reader_task_list_iam_view/pages/hooks/UsePatchTaskMutation'

export default function PriorityPopupMenuList({
  priority,
  task_id,
  study_id,
}: {
  priority: number
  task_id: string
  study_id: string
}) {
  const [selectedPriority, setSelectedPriority] = useState<number>(priority)
  const maxPriority = 9

  const { mutateAsync: priorityTask } = UsePatchTaskMutation()

  const handlePriorityTask = async (priority: number) => {
    await priorityTask({
      query: { study_id: study_id, read_task_ids: [task_id] },
      body: {
        priority: priority,
      },
    }).then(() => {
      setSelectedPriority(priority)
    })
  }
  return (
    <PopupState variant="popover">
      {(popupState) => (
        <Fragment>
          <Button
            variant="text"
            sx={{ padding: 0, width: 112, borderRadius: 0, justifyContent: 'flex-start' }}
            onClick={popupState.open}
          >
            <Typography
              sx={{
                color: 'text.primary',
              }}
            >
              {selectedPriority}
            </Typography>
          </Button>
          <Menu
            {...bindMenu(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            data-testid="popup-menu-list"
          >
            <MenuList
              sx={{
                width: 112,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                paddingY: 0.5,
                outline: 'none',
              }}
            >
              {Array.from({ length: maxPriority }, (_, i) => i + 1).map((p) => (
                <MenuItem
                  sx={{
                    width: '100%',
                  }}
                  key={p}
                  onClick={() => {
                    handlePriorityTask(p)
                    popupState.close()
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    alignSelf={'stretch'}
                    width={'100%'}
                  >
                    <Typography>{p}</Typography>
                    {selectedPriority === p && (
                      <CheckRounded
                        sx={{
                          color: 'primary.main',
                        }}
                      />
                    )}
                  </Stack>
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Fragment>
      )}
    </PopupState>
  )
}
