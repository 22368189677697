import { z } from 'zod'

const readTaskSubmissionSchema = z
  .object({
    adjudication_agreed: z.boolean().optional().default(false),
    adjudication_selected_read_task_id: z
      .string()
      .min(1, 'You must select a Reader or specify that you do not agree with either Reader below')
      .optional(),
    adjudication_justification_id: z.string().min(1, 'Please select a reason'),
    justification_comment: z.string().optional(),
    comment: z.string().optional(),
  })
  .refine((data) => {
    if (
      data.adjudication_agreed &&
      (!data.adjudication_selected_read_task_id || data.adjudication_selected_read_task_id.length < 1)
    ) {
      return false
    }
    return true
  })
  .refine(
    (data) => {
      if (data.adjudication_agreed && (!data.justification_comment || data.justification_comment.length < 1)) {
        return false
      }
      return true
    },
    {
      message: 'Provide a justification for your decision',
      path: ['justification_comment'],
    },
  )
  .refine(
    (data) => {
      if (!data.adjudication_agreed && (!data.comment || data.comment.length < 1)) {
        return false
      }
      return true
    },
    {
      message: 'Provide a reason for your disagreement',
      path: ['comment'],
    },
  )

const contextSchema = z.object({
  type: z.literal('reader_task_submission'),
  read_task_submission: readTaskSubmissionSchema,
})

export const readerTaskSubmissionSchema = z.object({
  username: z.string().min(1, 'Username is required'),
  password: z.string().min(1, 'Password is required'),
  context: contextSchema,
})

export type ReaderTaskSubmissionFormValues = z.infer<typeof readerTaskSubmissionSchema>
