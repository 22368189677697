import { Outlet, useParams } from 'react-router-dom'
import { Stack } from '@mui/material'
import { useGetStudyById } from '@study_setup/hooks/useStudyQueries'
import StudyDetailsHeader from '@common/components/StudyDetailsHeader'
import { BaseLayout } from '@common/components/layouts/BaseLayout'

import { CombinedPermissionsProvider } from '@auth/hooks/useCombinedPermissions'
import { StudyDetailsTabs } from '@features/study_details/components'
import { SPACING } from '@common/theme/spacing'

export default function StudyDetailsLayout() {
  const { studyId } = useParams<{ studyId: string }>()
  const { data: studyData } = useGetStudyById(studyId!)

  return (
    <CombinedPermissionsProvider studyId={studyId!}>
      <BaseLayout>
        {studyData && <StudyDetailsHeader study={studyData} />}
        <StudyDetailsTabs />
        <Stack spacing={SPACING.spacingLg} sx={{ paddingTop: SPACING.spacingLg }}>
          <Outlet />
        </Stack>
      </BaseLayout>
    </CombinedPermissionsProvider>
  )
}
