/**
 * Table component for displaying and managing companies with sorting, filtering,
 * and action menu functionality
 */
import { MouseEvent } from 'react'
import { TableBody, TableCell, TableRow, Paper, Checkbox, IconButton, Link, Box } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { CompanyData, CompanyType } from '@features/company_management/types'
import EnhancedTable from '@common/components/Table/EnhancedTable'
import { Order, HeadCell } from '@common/components/Table/table.types'
import CompanyActionMenu from './CompanyActionMenu'
import CompanyManagementTableToolbar from './CompanyManagementTableToolbar'
import {
  TransformedCompanyMetadata,
  TransformedCompanies,
  useCompanyManagementTableHandlers,
} from '@features/company_management/hooks'
import EnhancedNavTabs from '@common/components/Tab/EnhancedNavTabs'
import { NavTabsVariant } from '@common/components/Tab/tabs.types'
import { SPACING } from '@common/theme/spacing'

interface Props {
  companyMetadata?: TransformedCompanyMetadata
  page: number
  companies: TransformedCompanies | undefined
  currentCompanyType: CompanyType | null
  handlePageChange: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onSearch: (value: string) => void
  searchValue?: string
  order: Order
  orderBy: string
  onRequestSort: (event: MouseEvent<unknown>, property: keyof CompanyData) => void
  onAddClick: (companyType: CompanyType) => void
  onEditClick: (companyId: string) => void
  onDeleteCompany: (companyId: string) => void
  onBulkDeleteCompanies: (companyIds: Array<string>) => void
  onCompanyTypeChange: (companyType: CompanyType | null) => void
}

const getHeadCells = (): readonly HeadCell<CompanyData>[] => {
  const baseCells: HeadCell<CompanyData>[] = [
    { id: 'name', label: 'Company name', disablePadding: false },
    { id: 'type', label: 'Company type', disablePadding: false },
    { id: 'number_of_users', label: 'Number of users', disablePadding: false },
    { id: 'created_by', label: 'Created by', disablePadding: false },
  ]

  return [
    ...baseCells,
    { id: 'editCompany', label: '', disablePadding: false },
    { id: 'actions', label: '', disablePadding: false },
  ]
}

export default function CompanyManagementTable({
  companyMetadata,
  companies,
  currentCompanyType,
  onSearch,
  searchValue,
  onRowsPerPageChange,
  handlePageChange,
  page,
  order,
  orderBy,
  onRequestSort,
  onAddClick,
  onEditClick,
  onDeleteCompany,
  onBulkDeleteCompanies,
  onCompanyTypeChange,
}: Readonly<Props>) {
  const {
    selected,
    menuAnchorEl,
    handleEditClick,
    handleSelectAllClick,
    handleClick,
    handleOpenMenu,
    handleCloseMenu,
    handleDeleteCompany,
    handleBulkDeleteCompanies,
  } = useCompanyManagementTableHandlers({
    onEditClick,
    onDeleteCompany,
    onBulkDeleteCompanies,
    companies,
  })

  const headCells = getHeadCells()

  const companyTypeRoutes =
    companyMetadata?.companyTypeFilter?.map((companyType) => ({
      path: `#${companyType.name}`,
      label: companyType.label,
      id: companyType.id,
    })) ?? []

  return (
    <Box component="section">
      <CompanyManagementTableToolbar
        companyTypes={companyMetadata?.companyTypeFilter}
        onSearch={onSearch}
        searchValue={searchValue}
        onAddClick={onAddClick}
        currentCompanyType={currentCompanyType}
        onCompanyTypeChange={onCompanyTypeChange}
      />
      <Paper sx={{ p: SPACING.paperInnerPadding }}>
        <EnhancedNavTabs routes={companyTypeRoutes} variant={NavTabsVariant.SEGMENTED_CONTROLS} />
        <EnhancedTable<CompanyData>
          rows={companies?.items ?? []}
          headCells={headCells}
          order={order}
          orderBy={orderBy as keyof CompanyData}
          selected={selected}
          page={page}
          next_cursor={companies?.pagination.nextCursor}
          previous_cursor={companies?.pagination.previousCursor}
          rowsPerPage={companies?.pagination.limit ?? 50}
          rowsPerPageOptions={[10, 25, 50]}
          onRequestSort={onRequestSort}
          onSelectAllClick={handleSelectAllClick}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={onRowsPerPageChange}
          onBulkDelete={handleBulkDeleteCompanies}
        >
          <TableBody>
            {companies?.items.map((row) => {
              const isItemSelected = selected.indexOf(row.id) !== -1

              return (
                <TableRow
                  hover
                  onClick={(event) => handleClick(event, row.id)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox color="primary" checked={isItemSelected} />
                  </TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.type}</TableCell>
                  <TableCell>{row.usersNbr ?? '-'}</TableCell>
                  <TableCell>{row.createdByUser ?? '-'}</TableCell>
                  <TableCell align="right">
                    <Link onClick={(e) => handleEditClick(e, row.id)}>Edit company</Link>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton size="small" onClick={(e) => handleOpenMenu(e, row.id)}>
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </EnhancedTable>
        <CompanyActionMenu anchorEl={menuAnchorEl} onClose={handleCloseMenu} onDelete={handleDeleteCompany} />
      </Paper>
    </Box>
  )
}
