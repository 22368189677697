import { Control, Controller, FieldValues, Path, PathValue } from 'react-hook-form'
import { TextField, TextFieldProps } from '@mui/material'

interface FormTextFieldProps<T extends FieldValues, P extends Path<T>>
  extends Omit<TextFieldProps, 'name' | 'defaultValue'> {
  name: P
  control: Control<T>
  defaultValue?: PathValue<T, P>
  onCustomChange?: (value: any) => void // eslint-disable-line @typescript-eslint/no-explicit-any
}

export function InputField<T extends FieldValues, P extends Path<T>>({
  name,
  control,
  defaultValue,
  onCustomChange,
  ...props
}: FormTextFieldProps<T, P>) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          {...props}
          error={!!fieldState.error}
          helperText={fieldState.error?.message || props.helperText}
          onChange={(e) => {
            let val: unknown = e.target.value
            if (props.type === 'number' && val !== '') {
              val = Number(val)
            }
            field.onChange(val)
            if (onCustomChange) {
              onCustomChange(val)
            }
          }}
        />
      )}
    />
  )
}
