import { CharacterType } from '@study_setup/SubjectConfiguration/types'

export const convertCharacterTypeArrayToString = (types: CharacterType[]): CharacterType => {
  if (types.includes(CharacterType.ALPHA) && types.includes(CharacterType.NUMERICAL)) {
    return CharacterType.ALPHANUMERIC
  }
  return types[0]
}

export const convertCharacterTypeStringToArray = (type: CharacterType): CharacterType[] => {
  if (type === CharacterType.ALPHANUMERIC) {
    return [CharacterType.ALPHA, CharacterType.NUMERICAL]
  }
  return [type]
}
