import { useQuery } from '@tanstack/react-query'
import {
  detailsStudiesStudyIdDetailsGet,
  DetailsStudiesStudyIdDetailsGetResponse,
  HTTPValidationError,
} from '@common/config/api/client'
import { AxiosError } from 'axios'
import queryKeyFactory from '../queryKeyFactory'

export default function useStudyDetails(studyId: string) {
  return useQuery<DetailsStudiesStudyIdDetailsGetResponse, AxiosError<HTTPValidationError>>({
    queryKey: queryKeyFactory.studyDetails(studyId),
    queryFn: async () => {
      if (!studyId) {
        throw new Error('studyId is required')
      }

      const response = await detailsStudiesStudyIdDetailsGet({
        path: { study_id: studyId },
        throwOnError: true,
      })

      return response.data
    },
    enabled: !!studyId,
    retry: false,
  })
}
