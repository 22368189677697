import { Stack, Table, TableBody, TableCell, TableRow, Typography, useTheme } from '@mui/material'
import { useState } from 'react'
import NewLesionsTableRow from './NewLesionsTableRow'
import { ReadTaskSummaryData } from '@common/config/api/client'
import DefaultSummaryTableHeader from './DefaultSummaryTableHeader'
import { TableScrollSize } from '@features/reader_summary/pages/summary.types'
import ExpandableSection from './ExpandableSection'

export default function ExpandableNewLesionsTableRow({
  timepoints,
  tableScrollSize,
}: {
  timepoints: ReadTaskSummaryData['timepoints']
  tableScrollSize: TableScrollSize
}) {
  const [expanded, setExpanded] = useState(false)
  const theme = useTheme()

  const maxNewTargetLesionsNumber = timepoints.reduce((max, timepoint) => {
    const count = timepoint.reader_submissions[0].ecrf_form_submission?.newLesion?.length ?? 0
    return Math.max(max, count)
  }, 0)

  return (
    <>
      <NewLesionsTableRow
        expanded={expanded}
        setExpanded={setExpanded}
        timepoints={timepoints}
        tableScrollSize={tableScrollSize}
      />
      <TableRow>
        <TableCell sx={{ p: 0 }} colSpan={tableScrollSize.minVisibleTimepoints + 2} data-testid="collapse-cell">
          <ExpandableSection expanded={expanded} disableAnimation>
            <Table
              data-testid="expanded-table"
              sx={{
                '& .MuiTableCell-root': { py: 0.75 },
                borderCollapse: 'separate',
              }}
            >
              <DefaultSummaryTableHeader isHidden timepoints={timepoints} tableScrollSize={tableScrollSize} />
              <TableBody>
                {Array.from({ length: maxNewTargetLesionsNumber }).map((_, lesionIndex) => {
                  const rowCells = Array.from({ length: tableScrollSize.minVisibleTimepoints }).map((_, colIndex) => {
                    if (colIndex < tableScrollSize.emptyCellsCount) {
                      return (
                        <TableCell
                          key={`empty-${colIndex}`}
                          sx={{
                            width: tableScrollSize.columnWidth,
                            minWidth: tableScrollSize.columnWidth,
                            borderBottom: `1px solid ${theme.palette.divider}`,
                          }}
                        />
                      )
                    }
                    const tpIndex = colIndex - tableScrollSize.emptyCellsCount
                    const timepoint = timepoints[tpIndex]
                    const measurement =
                      timepoint &&
                      timepoint.reader_submissions[0] &&
                      timepoint.reader_submissions[0].ecrf_form_submission &&
                      timepoint.reader_submissions[0].ecrf_form_submission?.newLesion?.[lesionIndex]
                    let stickyStyles = {}
                    if (colIndex === 0) {
                      stickyStyles = {
                        position: 'sticky',
                        left: tableScrollSize.firstTimepointLeftOffset,
                        background: 'inherit',
                        zIndex: 2,
                      }
                    }

                    if (colIndex === tableScrollSize.minVisibleTimepoints - 1) {
                      stickyStyles = {
                        position: 'sticky',
                        right: tableScrollSize.checkboxWidth,
                        background: 'inherit',
                        zIndex: 2,
                        borderLeft: `1px solid ${theme.palette.primary.main}`,
                      }
                    }

                    return (
                      <TableCell
                        key={`tp-${tpIndex}-new-lesion-${lesionIndex}`}
                        sx={{
                          width: tableScrollSize.columnWidth,
                          minWidth: tableScrollSize.columnWidth,
                          borderBottom: `1px solid ${theme.palette.divider}`,
                          borderLeft: `1px solid ${theme.palette.divider}`,
                          ...stickyStyles,
                        }}
                      >
                        {measurement && (
                          <Stack spacing={1} direction={'row'}>
                            <Typography variant="body2" sx={{ color: 'text.primary' }}>
                              {lesionIndex + 1}
                            </Typography>
                            <Stack>
                              <Typography variant="body2" sx={{ color: 'text.primary' }}>
                                {measurement.location}
                              </Typography>
                              <Typography variant="button" sx={{ color: 'text.primary' }}>
                                {measurement.state}
                              </Typography>
                            </Stack>
                          </Stack>
                        )}
                      </TableCell>
                    )
                  })

                  return (
                    <TableRow
                      key={`new-lesion-row-${lesionIndex}`}
                      sx={{
                        bgcolor: lesionIndex % 2 === 0 ? 'paper.main' : 'background.paper',
                      }}
                    >
                      <TableCell
                        sx={{
                          width: tableScrollSize.columnWidth,
                          minWidth: tableScrollSize.columnWidth,
                          position: 'sticky',
                          left: 0,
                          background: 'inherit',
                          zIndex: 3,
                          borderBottom: `1px solid ${theme.palette.divider}`,
                        }}
                      />
                      {rowCells}
                      <TableCell
                        padding="checkbox"
                        sx={{
                          width: tableScrollSize.checkboxWidth,
                          minWidth: tableScrollSize.checkboxWidth,
                          position: 'sticky',
                          right: 0,
                          background: 'inherit',
                          zIndex: 3,
                          borderBottom: `1px solid ${theme.palette.divider}`,
                          borderLeft: `1px solid ${theme.palette.primary.main}`,
                        }}
                      />
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </ExpandableSection>
        </TableCell>
      </TableRow>
    </>
  )
}
