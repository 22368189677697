import { useMutation, UseMutationResult } from '@tanstack/react-query'
import {
  deleteCompaniesCompaniesDelete,
  DeleteCompaniesCompaniesDeleteResponse,
  HTTPValidationError,
} from '@common/config/api/client'
import { AxiosError } from 'axios'

export interface bulkDeleteCompaniesPayload {
  company_ids: string
}
/**
 * Hook for bulk deleting companies
 * @returns UseMutationResult for deleting multiple companies
 */
export default function useDeleteCompanies(): UseMutationResult<
  DeleteCompaniesCompaniesDeleteResponse,
  AxiosError<HTTPValidationError>,
  bulkDeleteCompaniesPayload
> {
  return useMutation<
    DeleteCompaniesCompaniesDeleteResponse,
    AxiosError<HTTPValidationError>,
    bulkDeleteCompaniesPayload
  >({
    mutationFn: async ({ company_ids }) => {
      const response = await deleteCompaniesCompaniesDelete({
        query: { company_ids },
        throwOnError: true,
      })
      return response.data
    },
  })
}
