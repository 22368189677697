import { Key, MouseEvent } from 'react'
import { TableHead, TableRow, TableCell, Checkbox, TableSortLabel, Box, Typography } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { EnhancedTableHeadProps } from './table.types'
import { SIZING } from '@common/theme/sizing'
import { SPACING } from '@common/theme/spacing'

const EnhancedTableHead = <T,>({
  headCells,
  numSelected,
  onSelectAllClick,
  expandable,
  order,
  orderBy,
  rowCount,
  onRequestSort,
  sx,
}: EnhancedTableHeadProps<T>) => {
  const createSortHandler = (property: keyof T) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }
  return (
    <TableHead data-testid="table-head">
      <TableRow>
        {numSelected !== undefined && onSelectAllClick !== undefined ? (
          <TableCell
            padding="checkbox"
            sx={{
              width: SIZING.tableCellWidth,
              minWidth: SIZING.tableCellWidth,
              ...sx,
            }}
          >
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all tasks',
              }}
            />
          </TableCell>
        ) : (
          expandable && (
            <TableCell
              padding="checkbox"
              sx={{
                width: SIZING.tableCellWidth,
                minWidth: SIZING.tableCellWidth,
                zIndex: 20,
                left: 0,
                ...sx,
              }}
            />
          )
        )}

        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id as Key}
            sortDirection={orderBy === headCell.id ? order : false}
            data-testid="head-table-cell"
            sx={{
              width: headCell.width ? headCell.width : 'auto',
              minWidth: headCell.minWidth ? headCell.minWidth : headCell.width ? headCell.width : 'auto',
              zIndex: headCell.stickyColumn ? 20 : 10,
              left: headCell.stickyColumn && headCell.width ? 48 + index * headCell.width : 'auto',
              color: headCell.roundCorner ? 'text.primary' : 'none',
              bgcolor: headCell.roundCorner ? 'paper.main' : 'none',
              padding: headCell.roundCorner ? SPACING.spacingMd : SPACING.spacingLg,
              '&:first-of-type': {
                borderTopLeftRadius: headCell.roundCorner ? '16px' : '0px',
              },
              '&:last-of-type': {
                borderTopRightRadius: headCell.roundCorner ? '16px' : '0px',
              },
              ...sx,
            }}
          >
            {headCell.disableSort ? (
              <Typography
                noWrap
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontWeight: headCell.boldText ? '700' : '400',
                  fontSize: headCell.boldText ? '14px' : '16px',
                }}
              >
                {' '}
                {headCell.label}{' '}
              </Typography>
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography
                  noWrap
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontWeight: headCell.boldText ? '700' : '400',
                    fontSize: headCell.boldText ? '14px' : '16px',
                  }}
                >
                  {headCell.label}
                </Typography>
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default EnhancedTableHead
