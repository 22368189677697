import { InputAdornment, TextField, TextFieldProps } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { SearchContext } from '@common/context/SearchContext'

export function Search(props: TextFieldProps) {
  return (
    <SearchContext.Consumer>
      {(context) =>
        context && (
          <TextField
            variant="outlined"
            size="small"
            onChange={(e) => context.setValue(e.target.value)}
            value={context.value}
            label="Search"
            sx={{
              boxShadow: 1,
            }}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              },
            }}
            {...props}
          />
        )
      }
    </SearchContext.Consumer>
  )
}
