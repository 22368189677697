import { SxProps, Theme } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'

export default function MoveIcon({ sx }: { sx?: SxProps<Theme> }) {
  return (
    <SvgIcon sx={sx}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <mask id="mask0_2915_38265" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
          <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_2915_38265)">
          <path
            d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V17C3 16.7167 3.09583 16.4792 3.2875 16.2875C3.47917 16.0958 3.71667 16 4 16C4.28333 16 4.52083 16.0958 4.7125 16.2875C4.90417 16.4792 5 16.7167 5 17V19H19V7H5V9C5 9.28333 4.90417 9.52083 4.7125 9.7125C4.52083 9.90417 4.28333 10 4 10C3.71667 10 3.47917 9.90417 3.2875 9.7125C3.09583 9.52083 3 9.28333 3 9V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM12.175 14H4C3.71667 14 3.47917 13.9042 3.2875 13.7125C3.09583 13.5208 3 13.2833 3 13C3 12.7167 3.09583 12.4792 3.2875 12.2875C3.47917 12.0958 3.71667 12 4 12H12.175L10.8 10.6C10.6167 10.4167 10.5208 10.1875 10.5125 9.9125C10.5042 9.6375 10.6 9.4 10.8 9.2C10.9833 9.01667 11.2167 8.925 11.5 8.925C11.7833 8.925 12.0167 9.01667 12.2 9.2L15.3 12.3C15.5 12.5 15.6 12.7333 15.6 13C15.6 13.2667 15.5 13.5 15.3 13.7L12.2 16.8C12.0167 16.9833 11.7875 17.0792 11.5125 17.0875C11.2375 17.0958 11 17 10.8 16.8C10.6167 16.6167 10.525 16.3833 10.525 16.1C10.525 15.8167 10.6167 15.5833 10.8 15.4L12.175 14Z"
            fill="#546E7A"
          />
        </g>
      </svg>
    </SvgIcon>
  )
}
