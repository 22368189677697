import { Alert, AlertTitle, Badge, Box, Button, IconButton, Stack, Typography } from '@mui/material'
import dayjs from 'dayjs'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import dcmjs from 'dcmjs'
import GroupedFilesBlock from '@features/subject/components/GroupedFilesBlock'
import SeriesRow from '@features/subject/components/SeriesRow'
import NonDICOMRow from '@features/subject/components/NonDICOMRow'
import DicomTagsDialog from '@features/subject/components/DicomTagsDialog'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded'
import { Meta, UppyFile } from '@uppy/core'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useCheckTagsValidity } from '@features/subject/hooks/useCheckTagsValidity.ts'
import CriteriaCheckDialog from '@features/subject/components/CriteriaCheckDialog'

import {
  CheckItem,
  CheckStepProps,
  ContainerParam,
  ContainerType,
  DicomDataSet,
  DictionaryEntry,
  NonDicomFile,
  Study,
  TagsProps,
} from '@features/subject/subject.types'
import { lauchDicomViewerForSite } from '@common/utils/ohifUtils'
import { useExtendedAuth } from '@auth/hooks/useExtendedAuth'

const EXTENSION_TYPE_MAP: Record<string, string> = {
  pdf: 'PDF',
  jpg: 'JPEG_JPG',
  png: 'PNG',
  bmp: 'BMP',
  tiff: 'TIFF',
  nifti: 'NIFTI',
  mov: 'VIDEO_MOV',
  mp4: 'VIDEO_MP4',
  doc: 'DOC',
}

const getFileNameWithoutExtension = (filename?: string): string => {
  if (!filename) {
    return ''
  }

  const lastDotIndex = filename.lastIndexOf('.')
  if (lastDotIndex === -1) {
    // No extension found, return the whole name
    return filename
  }

  // Return the name without the extension
  return filename.substring(0, lastDotIndex)
}

const getFileTypeAllowed = (data: Array<ContainerParam>, type: ContainerType): string[] => {
  const container = data.find((item) => item.type === type)
  return container ? container.fileTypesAllowed : []
}

const getModalityAllowed = (data: Array<ContainerParam>, type: ContainerType): string[] => {
  const container = data.find((item) => item.type === type)
  return container ? container.modalityAllowed : []
}

const checkFileTypeError = (allowedFileTypes: string[], files: NonDicomFile[]): boolean => {
  return files.some((file) => {
    const fileExtension = file.name?.split('.').pop()?.toUpperCase()
    return fileExtension ? !allowedFileTypes.includes(fileExtension) : true
  })
}

const checkModalityError = (allowedModalities: string[], files: Study[] | Study): boolean => {
  if (Array.isArray(files)) {
    // Handle the case where files is an array of Study objects
    return files.some((file) => {
      return file.series.some((series) => {
        return !allowedModalities.includes(series.modality)
      })
    })
  } else {
    // Handle the case where files is a single Study object
    return files.series.some((series) => {
      return !allowedModalities.includes(series.modality)
    })
  }
}

const checkModalityMissing = (
  containerParam: Array<ContainerParam>,
  dicomData: Study[],
  nonDicomData: NonDicomFile[],
): { DICOM: boolean; NON_DICOM: boolean } => {
  return {
    DICOM:
      dicomData.length > 0 && containerParam.some((item) => item.type === 'DICOM' && item.noFilesSubmitted === true),
    NON_DICOM:
      nonDicomData.length > 0 &&
      containerParam.some((item) => item.type === 'NON_DICOM' && item.noFilesSubmitted === true),
  }
}

export default function CheckStep({ uppy, containerParam, onCheckStepComplete }: CheckStepProps) {
  const files = uppy.getFiles()
  const [dicomData, setDicomData] = useState<Study[]>([])
  const [nonDicomData, setNonDicomData] = useState<NonDicomFile[]>([])
  const { studyId, subjectId, timepointSubmissionId } = useParams<{
    studyId: string
    subjectId: string
    timepointSubmissionId: string
  }>()
  const { user } = useExtendedAuth()

  const [dicomTagsDialogState, setDicomTagsDialogState] = useState({
    open: false,
    seriesId: 0,
  })
  const [adherenceErrorsAlertVisible, setAdherenceErrorsAlertVisible] = useState(true)

  const [checkCriteriaDialogState, setCheckCriteriaDialogState] = useState<{
    open: boolean
    checks: CheckItem[]
  }>({ open: false, checks: [] })
  const { result: checkAdherenceResult } = useCheckTagsValidity(studyId!, dicomData)
  const nonDicomNonConvert = nonDicomData.filter((x) => !x.convert)
  const nonDicomConvert = nonDicomData.filter((x) => x.convert)

  const handleCriteriaDialogOpen = (seriesId: number, studyId: string) => {
    setCheckCriteriaDialogState({
      open: true,
      checks: checkAdherenceResult?.[studyId]?.series[seriesId].checks || [],
    })
  }

  const handleDicomTagsDialogOpen = (seriesId: number) => {
    setDicomTagsDialogState({ open: true, seriesId })
  }

  const handleDicomTagsDialogClose = () => {
    setDicomTagsDialogState({ ...dicomTagsDialogState, open: false })
  }

  const getTagsData = (dataSet: DicomDataSet): TagsProps[] => {
    const tags: TagsProps[] = []
    const { dictionary } = dcmjs.data.DicomMetaDictionary

    const nameLookup: Record<string, DictionaryEntry> = Object.values(
      dictionary as Record<string, DictionaryEntry>,
    ).reduce(
      (lookup, entry) => {
        lookup[entry.name] = entry
        return lookup
      },
      {} as Record<string, DictionaryEntry>,
    )

    for (const [key, value] of Object.entries(dataSet)) {
      tags.push({
        tagId: nameLookup[key]?.tag || 'Unknown Tag',
        tagName: key,
        value: String(value),
      })
    }

    return tags
  }

  const setCommentForFailedStudy = (studyId: string, comment: string) => {
    const files = uppy.getFiles()

    files.forEach((file) => {
      if (file.meta.study_uid === studyId) {
        uppy.setFileMeta(file.id, { ...file.meta, comment })
      }
    })
  }

  const groupFilesByStudyId = async (files: UppyFile<Meta, Record<string, never>>[]) => {
    const dicomFiles: Record<string, Study> = {}
    const nonDicomFiles: NonDicomFile[] = []

    for (const file of files) {
      try {
        const arrayBuffer = await file.data.arrayBuffer()
        const byteArray = new Uint8Array(arrayBuffer)
        const isDicom = String.fromCharCode(...byteArray.slice(128, 132)) === 'DICM'

        if (!isDicom) {
          const container = containerParam.find((x) =>
            x.containerFileTypes.includes(EXTENSION_TYPE_MAP[file.extension]),
          )

          nonDicomFiles.push({
            id: file.id,
            basename: getFileNameWithoutExtension(file.name),
            name: file.name,
            acquisitionDate: file.meta.acquisitionDate as string,
            convert: container?.convertToDicom || false,
          })

          uppy.setFileMeta(file.id, {
            ...file.meta,
            modality: container?.convertToDicom ? 'OT' : undefined,
            container_submission_id: container?.containerSubmissionId,
          })
          continue
        }

        const dicomData = dcmjs.data.DicomMessage.readFile(arrayBuffer)
        const dataSet = dcmjs.data.DicomMetaDictionary.naturalizeDataset(dicomData.dict)

        const studyId = dataSet.StudyInstanceUID || 'Unknown Study'
        const studyDescription = dataSet.StudyDescription || 'Unknown Study'
        const seriesInstanceUID = dataSet.SeriesInstanceUID || 'Unknown SeriesInstanceUID'
        const sopInstanceUID = dataSet.SOPInstanceUID || null
        const modality = dataSet.Modality || 'Unknown Modality'
        const seriesId = dataSet.SeriesNumber || 'Unknown SeriesNumber'
        const description = dataSet.SeriesDescription || 'Unknown Description'
        const acquisitionDate = dataSet.AcquisitionDate || null
        const studyDate = dataSet.StudyDate || null

        if (!dicomFiles[studyId]) {
          dicomFiles[studyId] = {
            name: studyId,
            studyDescription,
            date: studyDate ? dayjs(studyDate, 'YYYYMMDD').format('DD/MM/YYYY') : '-',
            series: [],
          }
        }

        const series = dicomFiles[studyId].series.find((s) => s.seriesInstanceUID === seriesInstanceUID)

        if (!series) {
          dicomFiles[studyId].series.push({
            fileIds: [file.id],
            tagData: getTagsData(dataSet),
            description,
            tooltipText: description || 'Series',
            acquisitionDate: acquisitionDate ? dayjs(acquisitionDate, 'YYYYMMDD').format('DD/MM/YYYY') : '-',
            seriesNumber: seriesId,
            instances: [sopInstanceUID],
            modality,
            seriesInstanceUID,
          })

          uppy.setFileMeta(file.id, {
            ...file.meta,
            study_uid: studyId,
            studyDescription,
            seriesInstanceUID,
            totalInstances: 1,
            seriesDescription: description,
            container_submission_id: containerParam.find((x) => x.modality === modality)?.containerSubmissionId,
            modality,
            seriesId,
            acquisitionDate,
          })
        } else {
          series.fileIds.push(file.id)

          if (series.instances.some((instance) => instance === sopInstanceUID)) {
            // This feature will be handled in future
          } else {
            series.instances.push(sopInstanceUID)

            uppy.setFileMeta(file.id, {
              ...file.meta,
              study_uid: studyId,
              studyDescription,
              seriesInstanceUID,
              totalInstances: series.instances.length,
              seriesDescription: description,
              container_submission_id: containerParam.find((x) => x.modality === modality)?.containerSubmissionId,
              modality,
              seriesId,
              acquisitionDate,
            })
          }
        }
      } catch (error) {
        console.error(`Error processing file ${file.name}:`, error)
      }
    }

    return { dicomFiles: Object.values(dicomFiles), nonDicomFiles }
  }

  const handleCloseCriteriaCheckDialog = () => {
    setCheckCriteriaDialogState({ open: false, checks: [] })
  }

  useEffect(() => {
    const fetchAndGroupFiles = async () => {
      const { dicomFiles, nonDicomFiles } = await groupFilesByStudyId(files)
      setDicomData(dicomFiles)
      setNonDicomData(nonDicomFiles)
    }
    fetchAndGroupFiles()
  }, [])

  const renderStudyActions = (
    subjectId?: string,
    studyId?: string,
    timepointSubmissionId?: string,
    containerSubmissionId?: string,
    seriesInstanceId?: string,
  ) => {
    return (
      <Box display="flex" gap={1}>
        <IconButton
          onClick={() =>
            lauchDicomViewerForSite(
              subjectId,
              studyId,
              timepointSubmissionId,
              containerSubmissionId,
              seriesInstanceId,
              user?.access_token,
            )
          }
        >
          <RemoveRedEyeRoundedIcon sx={{ color: 'text.primary' }} />
        </IconButton>
        <IconButton>
          <DeleteOutlineOutlinedIcon sx={{ color: 'text.primary' }} />
        </IconButton>
      </Box>
    )
  }

  const renderNonDicomActions = () => {
    return (
      <IconButton>
        <DeleteOutlineOutlinedIcon sx={{ color: 'text.primary' }} />
      </IconButton>
    )
  }

  const onViewAlert = (): void => {
    const errorElement = document.getElementById('error')

    if (errorElement)
      errorElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
  }

  const onDeleteDicomFiles = (seriesNumber: number, filesId: string[]) => {
    const newDicomFiles: Study[] = dicomData
      .map((study) => {
        const filteredSeries = study.series.filter((series) => series.seriesNumber !== seriesNumber)

        // If the series array is empty after filtering, return null to remove the study
        if (filteredSeries.length === 0) {
          return null
        }

        // Otherwise, return the updated study with the filtered series
        return {
          ...study,
          series: filteredSeries,
        }
      })
      .filter((study): study is Study => study !== null) // Remove null values (empty studies)

    setDicomData(newDicomFiles)

    // Remove files from uppy
    filesId.forEach((fileId) => {
      uppy.removeFile(fileId)
    })
  }

  const onDeleteNonDicomFiles = (filesId: string[]) => {
    // Filter out non-DICOM files whose IDs are in filesId array
    const newNonDicomFiles: NonDicomFile[] = nonDicomData.filter((file) => !filesId.includes(file.id))
    setNonDicomData(newNonDicomFiles)

    // Remove files from uppy
    filesId.forEach((fileId) => {
      uppy.removeFile(fileId)
    })
  }

  const fileTypeAllowedNonDicom = getFileTypeAllowed(containerParam, 'NON_DICOM')
  const modalityAllowed = getModalityAllowed(containerParam, 'DICOM')

  const showFileTypeError = checkFileTypeError(fileTypeAllowedNonDicom, nonDicomData)
  const showModalityError = checkModalityError(modalityAllowed, dicomData)
  const showModalityMissing = checkModalityMissing(containerParam, dicomData, nonDicomData)

  useEffect(() => {
    const shouldContinue = !showModalityError && !showFileTypeError
    onCheckStepComplete(shouldContinue)

    return () => {
      onCheckStepComplete(false)
    }
  }, [showModalityError, showFileTypeError, onCheckStepComplete])

  if (!checkAdherenceResult) {
    return null
  }

  return (
    <Box pb={12}>
      <Typography color="text.primary" variant="h4">
        Exams and adherence criteria check
      </Typography>
      <Typography color="text.secondary" variant="body2" mb={4}>
        Check the exams from uploaded files and enter details for non-DICOM files.
      </Typography>
      {Object.values(checkAdherenceResult).some((x) => !x.isStudyValid) && adherenceErrorsAlertVisible ? (
        <Alert severity="error" variant="filled" sx={{ mb: 4 }} onClose={() => setAdherenceErrorsAlertVisible(false)}>
          <AlertTitle>We identified exam criteria issues during the adherence check</AlertTitle>
          Add a comment explaining the reason for the issues to submit the file upload
        </Alert>
      ) : null}

      {(showFileTypeError || showModalityError) && (
        <Alert
          sx={{ mb: 2 }}
          severity="warning"
          action={
            <Button color="inherit" size="small" onClick={onViewAlert}>
              VIEW
            </Button>
          }
        >
          <Typography variant="body1" fontWeight="700" component={'p'}>
            You uploaded a file with an unexpected modality or file type
          </Typography>
          <Typography variant="body1" component={'p'}>
            Please remove it and add the correct file to complete the upload.
          </Typography>
        </Alert>
      )}
      {showModalityMissing.DICOM ||
        (showModalityMissing.NON_DICOM && (
          <Alert
            sx={{ mb: 2 }}
            severity="warning"
            action={
              <Button color="inherit" size="small" onClick={onViewAlert}>
                VIEW
              </Button>
            }
          >
            <Typography variant="body1" fontWeight="700" component={'p'}>
              You uploaded a file with a modality previously marked as missing
            </Typography>
            <Typography variant="body1" component={'p'}>
              Please remove it and add the correct file to complete the upload.
            </Typography>
          </Alert>
        ))}
      <Stack gap={2}>
        {dicomData?.map((item, index) => {
          const showError = checkModalityError(modalityAllowed, item)
          return (
            <GroupedFilesBlock
              key={index}
              title={item.studyDescription}
              subtitle={`Exam date: ${item.date}`}
              onCommentAdd={(comment) => setCommentForFailedStudy(item.name, comment)}
              actions={renderStudyActions(
                subjectId,
                studyId,
                timepointSubmissionId,
                containerParam[0]?.containerSubmissionId,
                item.series[0]?.seriesInstanceUID,
              )}
              status={checkAdherenceResult[item.name]?.isStudyValid ? 'PASSED' : 'FAILED'}
              showModalityError={showError}
              showModalityMissing={showModalityMissing.DICOM}
            >
              <Box pt={4}>
                <Box display="flex" alignItems="center" mb={1}>
                  <Typography variant="subtitle2" color="text.secondary" mr={2.25}>
                    Series
                  </Typography>
                  <Badge variant="standard" color="secondary" badgeContent={item.series.length} />
                </Box>
                <Box borderRadius={3} overflow="hidden">
                  {item.series.map((series, subIndex) => (
                    <SeriesRow
                      key={subIndex}
                      fileIds={series.fileIds}
                      isBorderNeeded={subIndex !== item.series.length - 1}
                      description={series.description}
                      acquisitionDate={series.acquisitionDate}
                      seriesNumber={series.seriesNumber}
                      instances={series.instances.length}
                      modality={series.modality}
                      tooltipText={series.tooltipText}
                      modalityAllowed={modalityAllowed}
                      onDicomTagsDialogOpen={() => handleDicomTagsDialogOpen(series.seriesNumber)}
                      status={
                        checkAdherenceResult[item.name]?.series[series.seriesNumber]?.isSeriesValid
                          ? 'PASSED'
                          : 'FAILED'
                      }
                      onCriteriaDialogOpen={() => handleCriteriaDialogOpen(series.seriesNumber, item.name)}
                      onDelete={onDeleteDicomFiles}
                    />
                  ))}
                </Box>
              </Box>
            </GroupedFilesBlock>
          )
        })}

        {nonDicomData.length > 0 && (
          <GroupedFilesBlock
            title="Non-DICOM files"
            subtitle="Complete the missing information for each file. Adherence criteria check is not required."
            actions={renderNonDicomActions()}
            showFileTypeError={showFileTypeError}
            showModalityMissing={showModalityMissing.NON_DICOM}
          >
            {nonDicomConvert.length > 0 && (
              <Box pt={4}>
                <Box display="flex" alignItems="center" mb={1}>
                  <Typography variant="subtitle2" color="text.secondary" mr={2.25}>
                    Files to be converted to DICOM
                  </Typography>
                  <Badge variant="standard" color="secondary" badgeContent={nonDicomConvert.length} />
                </Box>
                <Box borderRadius={3} overflow="hidden">
                  {nonDicomConvert.map((item, index) => (
                    <NonDICOMRow
                      uppy={uppy}
                      fileId={item.id}
                      key={index}
                      name={item.name}
                      isBorderNeeded={index !== nonDicomData.length - 1}
                      seriesNumber={index + 1}
                      tooltipText={item.name}
                      seriesDescriptionText={item.basename}
                      fileTypeAllowed={fileTypeAllowedNonDicom}
                      onDelete={onDeleteNonDicomFiles}
                      mode="CONVERT"
                    />
                  ))}
                </Box>
              </Box>
            )}
            {nonDicomNonConvert.length > 0 && (
              <Box pt={4}>
                <Box display="flex" alignItems="center" mb={1}>
                  <Typography variant="subtitle2" color="text.secondary" mr={2.25}>
                    Files not to be converted to DICOM
                  </Typography>
                  <Badge variant="standard" color="secondary" badgeContent={nonDicomNonConvert.length} />
                </Box>
                <Box borderRadius={3} overflow="hidden">
                  {nonDicomNonConvert.map((item, index) => (
                    <NonDICOMRow
                      uppy={uppy}
                      fileId={item.id}
                      key={index}
                      name={item.name}
                      isBorderNeeded={index !== nonDicomData.length - 1}
                      seriesNumber={index + 1}
                      tooltipText={item.name}
                      seriesDescriptionText={item.basename}
                      fileTypeAllowed={fileTypeAllowedNonDicom}
                      onDelete={onDeleteNonDicomFiles}
                      mode="NON_CONVERT"
                    />
                  ))}
                </Box>
              </Box>
            )}
          </GroupedFilesBlock>
        )}
      </Stack>
      {dicomTagsDialogState.open && dicomData && studyId && (
        <DicomTagsDialog
          seriesId={dicomTagsDialogState.seriesId}
          studyId={studyId}
          dicomData={dicomData}
          containerParam={containerParam}
          onClose={handleDicomTagsDialogClose}
        />
      )}

      {checkCriteriaDialogState.open && (
        <CriteriaCheckDialog checks={checkCriteriaDialogState.checks} onClose={handleCloseCriteriaCheckDialog} />
      )}
    </Box>
  )
}
