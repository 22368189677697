import { useMemo } from 'react'
import { TableBody, TableRow, TableCell, Checkbox, Stack } from '@mui/material'
import TaskStateChip from '../TaskStateChip'
import { formatTaskCreationDate, getComparator } from '../../utils'
import { useParams } from 'react-router-dom'
import PopupMenuList from './PopupMenuList'
import PopupAssignMenuList from '../PopupAssignMenuList'
import { EnhancedTableBodyProps } from '@features/reader_task_list_iam_view/pages/reader_task_list.types'
import PriorityPopupMenuList from '../PriorityPopupMenuList'
import { User } from '@common/config/api/client'
import { AccessTimeFilledRounded } from '@mui/icons-material'

export default function EnhancedTableBodyTaskPool({
  rows,
  order,
  orderBy,
  selected,
  handleClick,
  assigneeList,
}: EnhancedTableBodyProps) {
  const isSelected = (id: string | null | undefined) => selected?.indexOf(id) !== -1

  const { studyId } = useParams<{ studyId: string }>()

  const visibleRows = useMemo(() => {
    const comparator = getComparator(order, orderBy)
    return [...rows].sort(comparator)
  }, [order, orderBy, rows])

  return (
    <TableBody>
      {visibleRows.map((row) => {
        const isItemSelected = isSelected(row.task_id)
        const assignedUser: User | undefined =
          row.assignee !== null && row.assignee.user_id !== null && row.assignee.user_name !== null
            ? {
                user_id: row.assignee?.user_id,
                user_name: row.assignee?.user_name,
              }
            : undefined

        return (
          <TableRow
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.task_id}
            selected={isItemSelected}
            sx={{
              borderBottom: '1px solid rgba(220, 227, 229, 1)',
            }}
          >
            <TableCell
              padding="checkbox"
              onClick={(event) => {
                if (handleClick) {
                  handleClick(event, row.task_id)
                }
                event.stopPropagation()
              }}
              data-testid="checkbox-cell"
            >
              <Checkbox color="primary" checked={isItemSelected} />
            </TableCell>
            <TableCell align="left" data-testid="subject-pseudo-id-cell">
              {row.subject_id}
            </TableCell>
            <TableCell align="left" data-testid="task-blind-code-cell">
              {row.blind_code}
            </TableCell>
            <TableCell align="left" data-testid="timepoint-name-cell">
              {row.timepoint_pseudo_name}
            </TableCell>
            <TableCell align="left" data-testid="task-pseudo-id-cell">
              {row.task_pseudo_id}
            </TableCell>
            <TableCell align="left" data-testid="task-type-cell">
              {row.task_type}
            </TableCell>
            <TableCell align="left" data-testid="task-priority-cell">
              <PriorityPopupMenuList priority={row.priority} task_id={row.task_id} study_id={studyId!} />
            </TableCell>
            <TableCell align="left" data-testid="task-state-cell">
              <TaskStateChip taskState={row.status} />
            </TableCell>

            <TableCell align="left" data-testid="create-date-cell">
              {formatTaskCreationDate(row.created_at)}
            </TableCell>

            <TableCell align="left" data-testid="due-date-cell">
              <Stack direction="row" spacing={1} alignItems="center">
                {formatTaskCreationDate(row.due_date)}
                {row.is_overdue && (
                  <AccessTimeFilledRounded
                    sx={{
                      width: 20,
                      height: 20,
                      color: 'error.light',
                    }}
                  />
                )}
              </Stack>
            </TableCell>

            <TableCell align="left" data-testid="assignee-cell">
              {
                <PopupAssignMenuList
                  assigneeList={assigneeList || []}
                  taskIds={[row.task_id]}
                  assignedUser={assignedUser}
                />
              }
            </TableCell>

            <TableCell align="right" data-testid="actions-cell">
              <PopupMenuList task_id={row.task_id} study_id={studyId!} />
            </TableCell>
          </TableRow>
        )
      })}
    </TableBody>
  )
}
