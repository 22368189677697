import { BreadcrumbItem } from '@common/components/Breadcrumb'
import { Stack } from '@mui/material'
import Grid from '@mui/material/Grid2'
import SubjectTimePointConfiguration from '../SubjectTimePointConfiguration/index.ts'
import TimePointManager from '../TimePointManager/index.ts'
import { useParams } from 'react-router-dom'
import { useGetStudyById } from '@study_setup/hooks/useStudyQueries'
import { findSubmissionById } from '../../utils.ts'
import { RouteKeyEnum } from 'src/routes.tsx'
import { useGetSubjectsList } from '@features/subject/hooks/useGetSubjectsList.ts'
import { useGetTimePointSubmissionDetails } from '@features/subject/hooks/useGetTimePointSubmissionDetails.ts'
import { FormProvider, useForm } from 'react-hook-form'
import { useGetSubjectById } from '@features/study_details/Subjects/hooks/useStudySubjectsQueries.tsx'
import { useLoadStudySubjectDetails } from '@features/study_details/Subjects/hooks/useLoadStudySubjectDetails.tsx'
import SubjectInformation from './SubjectInformation.tsx'
import SubjectDetailsHeader from './SubjectDetailsHeader.tsx'
import { SPACING } from '@common/theme/spacing.ts'
import { useGetStudySubjectConfiguration } from '@study_setup/SubjectConfiguration/hooks/useStudySubjectConfigurationQueries.ts'
import { SIZING } from '@common/theme/sizing.ts'
import { SubjectsTimepointSchema } from '@common/config/api/client/types.gen.ts'
import { SubjectDetailsFormValues } from '@features/subject/subject.types.ts'

export default function SubjectDetails() {
  const { studyId, subjectId, timepointSubmissionId } = useParams<{
    studyId: string
    subjectId: string
    timepointSubmissionId?: string
  }>()
  const {
    data: subjectData,
    isError,
    isLoading: isSubjectLoading,
  } = useGetSubjectById({
    study_id: studyId!,
    subject_id: subjectId!,
  })
  const { data, isLoading } = useGetSubjectsList(['SubjectsList'], {
    study_id: studyId!,
  })
  const { data: studyData, isLoading: isStudyLoading } = useGetStudyById(studyId!)
  const { data: studySubjectConfigData, isLoading: isSubjectConfigLoading } = useGetStudySubjectConfiguration(studyId!)

  const { data: timePointSubmissionData, isLoading: isTimePointSubmissionLoading } = useGetTimePointSubmissionDetails(
    timepointSubmissionId ? ['TimePointsDetails', timepointSubmissionId] : [],
    {
      study_id: studyId!,
      subject_id: subjectId!,
      timepoint_submission_id: timepointSubmissionId!,
    },
  )

  const methods = useForm<SubjectDetailsFormValues>()
  useLoadStudySubjectDetails(methods, studyId!, subjectData, studySubjectConfigData)

  if (isLoading || isStudyLoading || isTimePointSubmissionLoading || isSubjectLoading || isSubjectConfigLoading) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div data-testid="error-message">Failed to load subject</div>
  }

  const renderBreadCrumbs = () => {
    return [
      {
        title: 'Home',
        route_key: RouteKeyEnum.HOME,
      },
      {
        title: studyData?.nickname || '',
        route_key: RouteKeyEnum.STUDY_DETAILS,
        params: { studyId: studyId },
      },
      {
        title: `Subject ${subjectData?.pseudo_id}`,
        route_key: RouteKeyEnum.SUBJECTS,
      },
    ]
  }

  const selectedSubmission = timepointSubmissionId
    ? findSubmissionById(
        data?.data?.subjects?.find((x) => x.subject_id === subjectId)?.study_timepoints || [],
        timepointSubmissionId,
      )
    : null

  // Collect all unique file types from container requirements
  const containerRequirements = timePointSubmissionData?.data?.timepoint_submission.container_requirements || []

  const mergedFileTypes = Array.from(new Set(containerRequirements.flatMap((item) => item.file_types) || []))
  const mergedModalities = Array.from(
    new Set(containerRequirements.flatMap((item) => (item.modality ? [item.modality] : []))),
  )

  const containerParam = timepointSubmissionId
    ? containerRequirements.map((item) => {
        const containerSubmission = timePointSubmissionData?.data?.timepoint_submission.container_submissions.find(
          (req) => req.container_requirement_id === item.container_requirement_id,
        )

        // Transform exam metadata to match expected type
        const transformedExamMetadata = containerSubmission?.exam_metadata?.map((exam) => ({
          ...exam,
          series: exam.series.map((series) => ({
            ...series,
            qc_series_tags: series.qc_series_tags.map((tag) => ({
              ...tag,
              apply_to_all_series: tag.apply_to_all_series ?? false,
              dicom_tag_id: tag.dicom_tag_id || '',
              original_value: tag.original_value || '',
              new_value: tag.new_value || '',
            })),
          })),
        }))

        return {
          name: item.name,
          type: item.container_type,
          isOptional: item.is_optional || false,
          modality: item.modality as string,
          convertToDicom: item.convert_to_dicom,
          containerSubmissionId: containerSubmission?.container_submission_id || '',
          eFormFields: item.timepoint_container_eform_fields,
          eFormValues: containerSubmission?.container_eform_submission,
          status: containerSubmission?.status,
          noFilesSubmitted: !!containerSubmission?.no_file_submission_reason,
          criteriaCheck: containerSubmission?.criteria_check,
          examMetadata: transformedExamMetadata,
          comments: containerSubmission?.comments,
          noFileSubmissionReason: containerSubmission?.no_file_submission_reason,
          fileTypesAllowed: mergedFileTypes,
          containerFileTypes: item.file_types,
          modalityAllowed: mergedModalities,
        }
      }) || []
    : []

  const transformTimePoints = (data: SubjectsTimepointSchema, subjectId?: string) => {
    const subject = data?.subjects?.find((x) => x.subject_id === subjectId)
    return (
      subject?.study_timepoints?.map((timepoint) => ({
        name: timepoint.name,
        id: timepoint.timepoint_id,
        maximumAllowedTimepoints: timepoint.max_number_of_timepoint_submissions,
        children:
          timepoint.timepoint_submissions?.map((submission) => ({
            name: submission.name,
            id: submission?.timepoint_submission_id,
          })) || [],
      })) || []
    )
  }

  const timePoints = data?.data ? transformTimePoints(data.data, subjectId) : []

  return (
    <FormProvider {...methods}>
      <Stack px={SPACING.spacingXl}>
        <SubjectDetailsHeader breadcrumbs={renderBreadCrumbs() as BreadcrumbItem[]} subject={subjectData} />
        <Grid container columnSpacing={SPACING.spacingLg} mt={SPACING.spacingXxl}>
          <Grid size={SIZING.gridSizeLg}>
            <Stack spacing={SPACING.sectionsSpacing}>
              <SubjectInformation study={studyData} subjectConfig={studySubjectConfigData} />
              {timepointSubmissionId && (
                <SubjectTimePointConfiguration
                  timePointFormId={
                    timePointSubmissionData?.data?.timepoint_submission.timepoint_requirement?.timepoint_form_id
                  }
                  eFormFields={
                    timePointSubmissionData?.data?.timepoint_submission.timepoint_requirement?.timepoint_form_fields
                  }
                  subjectData={subjectData}
                  eFormValues={timePointSubmissionData?.data?.timepoint_submission.timepoint_form_submission}
                  containerParam={containerParam}
                  timePoints={timePoints}
                  status={selectedSubmission?.status}
                  name={selectedSubmission?.name || ''}
                />
              )}
            </Stack>
          </Grid>
          <Grid size={4}>
            <TimePointManager timePoints={timePoints} selectedTimePointSubmission={timepointSubmissionId || ''} />
          </Grid>
        </Grid>
      </Stack>
    </FormProvider>
  )
}
