import BaseDialog from '@common/components/BaseDialog'
import { TextField } from '@common/components/Form/TextField'
import { zodResolver } from '@hookform/resolvers/zod'
import { alpha, Stack, Typography, useTheme } from '@mui/material'
import { useForm } from 'react-hook-form'
import RadioGroup from '@common/components/Form/RadioGroup'
import { SPACING } from '@common/theme/spacing'
import { useEffect, useState } from 'react'
import PersonIcon from '@mui/icons-material/Person'
import { SIZING } from '@common/theme/sizing'
import { Dropdown } from '@common/components/Form/Dropdown/Dropdown'
import { useCompanyMetadata } from '../hooks'
import { CompanyType } from '@common/config/api/client'
import { CompanyCreateSchema } from '@features/schemas/companiesValidationSchema'

export type CompanyFormValues = {
  name: string
  type: CompanyType
  contact_person_name?: string | null
  email?: string | null
  phone?: string | null
}

export interface CreateOrUpdateCompanyDialogProps {
  onSave: (data: CompanyFormValues) => void
  onClose: () => void
  data?: CompanyFormValues | null
  title: string
}

const BULK_UPLOAD_COMPANIES = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
]

const transformEmptyToNull = (formData: CompanyFormValues) => {
  const transformed = { ...formData }

  if (transformed.contact_person_name === '') transformed.contact_person_name = null
  if (transformed.email === '') transformed.email = null
  if (transformed.phone === '') transformed.phone = null

  return transformed
}

const CreateOrUpdateCompanyDialog = ({ onSave, onClose, data, title }: CreateOrUpdateCompanyDialogProps) => {
  const isEditMode = !!data

  const [showForm, setShowForm] = useState(isEditMode)
  const theme = useTheme()

  const { data: companyMetadata, isLoading } = useCompanyMetadata()

  const companyCreationModeForm = useForm<{ bulk_upload_companies: boolean }>({
    mode: 'onSubmit',
  })
  const singleCompanyForm = useForm<CompanyFormValues>({
    resolver: zodResolver(CompanyCreateSchema),
    mode: 'onSubmit',
    defaultValues: {
      name: data?.name || '',
      type: data?.type || 'SITE',
      contact_person_name: data?.contact_person_name || null,
      email: data?.email || null,
      phone: data?.phone || null,
    },
  })

  const bulkUploadCompanies = companyCreationModeForm.watch('bulk_upload_companies')

  const isBulkUploadCompaniesTruthy = () => bulkUploadCompanies !== undefined && bulkUploadCompanies !== null

  const handleFormSubmit = async () => {
    if (!showForm && !isEditMode) {
      if (isBulkUploadCompaniesTruthy()) {
        setShowForm(true)
      }
      return
    }

    const isValid = await singleCompanyForm.trigger()
    if (!isValid) return

    const formData = singleCompanyForm.getValues()

    const transformedData = transformEmptyToNull(formData)

    onSave(transformedData)
  }

  const renderSingleCompanyForm = () => (
    <Stack spacing={SPACING.spacingXl}>
      <Typography variant="body1" color="text.primary">
        1. General information
      </Typography>
      <Stack direction="row" gap={SPACING.formFieldsSpacing}>
        <TextField name="name" control={singleCompanyForm.control} label="Name" />
        <TextField name="contact_person_name" control={singleCompanyForm.control} label="Contact Person" />
      </Stack>
      <Stack direction="row" gap={SPACING.formFieldsSpacing}>
        <TextField name="phone" control={singleCompanyForm.control} label="Phone number" />
        <TextField name="email" control={singleCompanyForm.control} label="Email address" />
      </Stack>
      <Typography variant="body1" color="text.primary">
        2. Company information
      </Typography>
      <Dropdown
        name="type"
        control={singleCompanyForm.control}
        options={companyMetadata?.companyTypes ?? []}
        loading={isLoading}
        label="Company type"
      />
    </Stack>
  )

  const renderBulkUploadForm = () => {
    return (
      <Stack spacing={SPACING.spacingXl}>
        <Stack
          px={SPACING.spacingLg}
          py={SPACING.spacingXxl}
          alignItems="center"
          justifyContent="center"
          sx={{
            width: 1,
            textAlign: 'center',
            bgcolor: alpha(theme.palette.primary.main, 0.08),
            border: `1px dashed ${theme.palette.primary.main}`,
          }}
        >
          <PersonIcon sx={{ color: theme.palette.primary.main }} />
          <Typography variant="body2" mb={SPACING.spacingSm} mt={SPACING.minSpacing}>
            <Typography
              variant="subtitle2"
              component="span"
              sx={{ color: 'primary.main', textDecoration: 'underline' }}
            >
              Click to upload
            </Typography>
            &nbsp;or drag and drop
          </Typography>
          <Typography gutterBottom variant="body2" color="text.secondary">
            .csv, .xlsx (max. 3MB)
          </Typography>
        </Stack>
      </Stack>
    )
  }

  useEffect(() => {
    if (data) {
      singleCompanyForm.reset({ ...data })
    }
  }, [data, singleCompanyForm])

  return (
    <BaseDialog
      title={title}
      description="Please provide the details for the company below"
      open={true}
      onClose={onClose}
      onSubmit={handleFormSubmit}
      form={singleCompanyForm}
      width={SIZING.boxModalWidth}
      submitLabel={showForm ? (bulkUploadCompanies ? 'Add Companies' : isEditMode ? 'Update' : 'Create') : 'Create'}
      cancelLabel={'Cancel'}
      scroll="paper"
    >
      <Stack spacing={SPACING.spacingXxl}>
        {!isEditMode && (
          <Stack direction="row" gap={SPACING.spacingXl} alignItems="center">
            <Typography>Do you want to bulk upload companies?</Typography>
            <RadioGroup
              name="bulk_upload_companies"
              control={companyCreationModeForm.control}
              options={BULK_UPLOAD_COMPANIES}
              row
            />
          </Stack>
        )}

        {showForm && <>{bulkUploadCompanies ? renderBulkUploadForm() : renderSingleCompanyForm()}</>}
      </Stack>
    </BaseDialog>
  )
}

export default CreateOrUpdateCompanyDialog
