import { useState, useEffect } from 'react'

import { InputAdornment, Paper, Stack, Table, TableContainer, TextField } from '@mui/material'
import ReviewDicomTagsTableBody from './ReviewDicomTagsTableBody'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { Dropdown } from '@common/components/Form/Dropdown'
import {
  StudyExamSeriesDicomTag,
  TimepointContainerSubmissionExamMetadataResponse,
  DicomTagRecord,
} from '@common/config/api/client'
import { useForm, FormProvider } from 'react-hook-form'
import { SeriesDicomTagsUpdateFormValues } from '@features/qc_task_details_view/schemas/DicomTagsValidationSchema'
import { useParams } from 'react-router-dom'
import { useGetStudyTagsDeid } from '@study_setup/hooks/useStudyTagsDeidQueries'
import ReviewDicomTagsTableHeader from './ReviewDicomTagsTableHeader'
import { SPACING } from '@common/theme/spacing'
interface Options {
  label: string
  value: string
}

export interface StudyExamSeriesDicomTagWithOverride extends StudyExamSeriesDicomTag {
  allow_iqm_override: boolean
}

const removeTagsDuplicates = (tags?: StudyExamSeriesDicomTag[]): StudyExamSeriesDicomTag[] => {
  if (!tags) {
    return []
  }

  const seen = new Set<string>()
  return tags.filter((tag) => {
    if (seen.has(tag.dicom_tag)) {
      return false
    }
    seen.add(tag.dicom_tag)
    return true
  })
}

const mapToDicomTagSchema = (tag: StudyExamSeriesDicomTag) => {
  return {
    dicom_tag_name: tag.dicom_tag_name,
    dicom_tag_id: tag.dicom_tag,
    tag_deid_strategy: tag.tag_deid_strategy,
    new_value: tag.new_value || '',
    value: tag.original_value || '',
    apply_to_all_series: tag.apply_to_all_series || false,
  }
}

const getSeriesOptions = (series?: TimepointContainerSubmissionExamMetadataResponse['series']): Options[] => {
  if (!series) {
    return []
  }
  return series.map((s, index) => ({
    label: `${s.series_uid?.split('.').pop()} ${s.description}`,
    value: index.toString(),
  }))
}

const sortDicomTags = (tags: StudyExamSeriesDicomTag[]): StudyExamSeriesDicomTag[] => {
  return [...tags].sort((a, b) => {
    if (a.apply_to_all_series && !b.apply_to_all_series) return -1
    if (!a.apply_to_all_series && b.apply_to_all_series) return 1
    return 0
  })
}

const addOverrideToTags = (
  tags: StudyExamSeriesDicomTag[],
  studyTags?: DicomTagRecord[],
): StudyExamSeriesDicomTagWithOverride[] => {
  if (!studyTags || studyTags.length === 0) {
    return tags.map((tag) => ({
      ...tag,
      allow_iqm_override: false,
    }))
  }

  return tags.map((tag) => {
    const matchingStudyTag = studyTags.find(
      (studyTag) => studyTag.tag_id === tag.dicom_tag || studyTag.tag_name === tag.dicom_tag_name,
    )

    return {
      ...tag,
      allow_iqm_override: matchingStudyTag?.allow_iqm_override || false,
    }
  })
}

interface ReviewDicomTagsTableProps {
  exam?: TimepointContainerSubmissionExamMetadataResponse
  onFormData?: (data: SeriesDicomTagsUpdateFormValues) => void
}

export default function ReviewDicomTagsTable({ exam, onFormData }: ReviewDicomTagsTableProps) {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const seriesOptions = getSeriesOptions(exam?.series)
  const { studyId } = useParams<{ studyId: string }>()
  const { data: studyTags, isLoading, error } = useGetStudyTagsDeid(studyId ?? '')

  const { control: seriesOptionControl, watch: watchSeriesOption } = useForm({
    defaultValues: {
      series_value: '0',
    },
  })
  const selectedSeriesIndex = parseInt(watchSeriesOption('series_value'))

  const series: TimepointContainerSubmissionExamMetadataResponse['series'] =
    exam?.series.map((s) => ({
      ...s,
      qc_series_tags: removeTagsDuplicates(s.qc_series_tags),
    })) || []

  const formMethods = useForm<SeriesDicomTagsUpdateFormValues>({
    defaultValues: {
      series: series.map((s) => ({
        series_uid: s.series_uid || '',
        dicom_tags: s.qc_series_tags.map(mapToDicomTagSchema),
      })),
    },
  })

  useEffect(() => {
    if (!onFormData) return

    const subscription = formMethods.watch((formValues) => {
      onFormData(formValues as SeriesDicomTagsUpdateFormValues)
    })

    return () => subscription.unsubscribe()
  }, [formMethods, onFormData])

  const filterDicomTags = (tags: StudyExamSeriesDicomTag[]) => {
    if (!searchTerm.trim()) {
      return tags
    }

    const lowercasedTerm = searchTerm.toLowerCase()

    return tags.filter((tag) => {
      return (
        tag.dicom_tag.toLowerCase().includes(lowercasedTerm) ||
        tag.dicom_tag_name.toLowerCase().includes(lowercasedTerm) ||
        tag.tag_deid_strategy.toLowerCase().includes(lowercasedTerm) ||
        tag.original_value?.toLowerCase().includes(lowercasedTerm) ||
        tag.new_value?.toLowerCase().includes(lowercasedTerm)
      )
    })
  }

  const filteredTags = series[selectedSeriesIndex]
    ? addOverrideToTags(sortDicomTags(filterDicomTags(series[selectedSeriesIndex].qc_series_tags)), studyTags)
    : []

  if (isLoading) {
    return <div>Loading...</div>
  }
  if (error) {
    return <div>Error: {error.message}</div>
  }

  return (
    <Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={SPACING.spacingXl}
        paddingBottom={SPACING.spacingMd}
      >
        <Stack sx={{ width: '33%' }}>
          <Dropdown
            size="small"
            control={seriesOptionControl}
            name="series_value"
            label="Series ID and name"
            options={seriesOptions}
            disableClearable
            multiple={false}
          />
        </Stack>
        <Stack sx={{ width: '33%' }}>
          <TextField
            size="small"
            variant="outlined"
            label="Search tags"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={(e) => e.stopPropagation()}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchRoundedIcon />
                  </InputAdornment>
                ),
              },
            }}
          />
        </Stack>
      </Stack>

      <Stack
        sx={{
          height: 600,
        }}
      >
        <Paper
          sx={{
            borderRadius: 2,
            border: '1px solid #DCE3E5',
            overflowY: 'auto',
          }}
        >
          <FormProvider {...formMethods}>
            <TableContainer
              sx={{
                maxHeight: 600,
              }}
            >
              <Table size="small" sx={{ tableLayout: 'fixed' }}>
                <ReviewDicomTagsTableHeader />
                <ReviewDicomTagsTableBody
                  series={series}
                  selectedSeriesIndex={selectedSeriesIndex}
                  control={formMethods.control}
                  filteredTags={filteredTags}
                />
              </Table>
            </TableContainer>
          </FormProvider>
        </Paper>
      </Stack>
    </Stack>
  )
}
