import {
  Radio,
  RadioGroup as MUIRadioGroup,
  FormControlLabel,
  RadioGroupProps as MUIRadioGroupProps,
  FormHelperText,
} from '@mui/material'
import { FieldValues, Path, useController, UseControllerProps } from 'react-hook-form'

type Option<T> = {
  label: string
  value: T
}

interface RadioGroupProps<T extends FieldValues, K extends keyof T>
  extends Omit<MUIRadioGroupProps, 'name' | 'onChange'> {
  options: Option<T[K]>[]
  name: Path<T>
  control: UseControllerProps<T>['control']
  rules?: UseControllerProps<T>['rules']
  defaultValue?: T[K]
  onChange?: (value: T[K]) => void
}

export default function RadioGroup<T extends FieldValues, K extends keyof T>({
  control,
  name,
  defaultValue,
  options,
  rules,
  onChange,
  ...rest
}: RadioGroupProps<T, K>) {
  const {
    field: { ref, value, onChange: onFieldChange, ...fieldRest },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedOption = options.find((opt) => String(opt.value) === event.target.value)
    if (selectedOption) {
      onFieldChange(selectedOption.value)
      onChange?.(selectedOption.value)
    }
  }

  return (
    <>
      <MUIRadioGroup value={String(value)} onChange={handleChange} {...fieldRest} {...rest}>
        {options.map((option) => (
          <FormControlLabel
            inputRef={ref}
            key={`${String(option.label)}-${String(option.value)}`}
            value={String(option.value)}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </MUIRadioGroup>
      {error?.message && <FormHelperText error>{error.message}</FormHelperText>}
    </>
  )
}
