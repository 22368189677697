import { Permission } from '@auth/permissionsEnum'
import { ReactNode } from 'react'
import { RouteConfig } from 'src/routes'

export type TabPanelProps = {
  children?: ReactNode
  index: number
  value: number
}

export type EnhancedTabsType = {
  label: string
  content: ReactNode
}

export type TabConfig = {
  label: string
  content: ReactNode
  permission?: Permission
}

export type EnhancedTabsProps<T extends string | number | symbol> = {
  tabs: Record<T, TabConfig>
  searchEnabled?: boolean
}

export const enum NavTabsVariant {
  SEGMENTED_CONTROLS = 'segmented-controls',
}

export type NavTabsProps = {
  routes: RouteConfig[]
  variant?: NavTabsVariant
}
