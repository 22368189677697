import { useMutation } from '@tanstack/react-query'
import {
  importStudySetupStudiesImportStudySetupPost,
  ImportStudySetupStudiesImportStudySetupPostResponse,
  HTTPValidationError,
} from '@common/config/api/client'
import { AxiosError } from 'axios'

interface ImportParams {
  file: File
  nickname?: string
  userFacingStudyId?: string
}

export default function useImportStudy() {
  return useMutation<
    ImportStudySetupStudiesImportStudySetupPostResponse,
    AxiosError<HTTPValidationError>,
    ImportParams
  >({
    mutationFn: async ({ file, nickname, userFacingStudyId }) => {
      const response = await importStudySetupStudiesImportStudySetupPost({
        body: { file, nickname, user_facing_study_id: userFacingStudyId },
        throwOnError: true,
      })
      return response.data
    },
  })
}
