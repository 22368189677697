import { Breadcrumb, BreadcrumbItem } from '@common/components/Breadcrumb'
import { StudySubjectViewSchema, SubjectStatus } from '@common/config/api/client'
import SubjectStatusChip from '@features/subject/components/SubjectsTimepointsTable/SubjectStatusChip'
import { Box, Typography } from '@mui/material'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'

export interface SubjectDetailsHeaderProps {
  breadcrumbs: BreadcrumbItem[]
  subject: StudySubjectViewSchema | undefined
}

const SubjectDetailsHeader = ({ breadcrumbs, subject }: SubjectDetailsHeaderProps) => {
  return (
    <>
      <Breadcrumb items={breadcrumbs} />
      <Box display="flex" mt={2} alignItems="center" gap={2}>
        <Typography variant="h4">Subject {subject?.pseudo_id}</Typography>
        <SubjectStatusChip subjectStatus={subject?.status as SubjectStatus} />
      </Box>
      <Box display="flex" mt={1} alignItems="center" gap={1}>
        <CalendarMonthRoundedIcon sx={{ color: 'text.secondary' }} fontSize="small" />
        <Typography variant="body1" color="text.secondary">
          First visit:
        </Typography>
      </Box>
    </>
  )
}

export default SubjectDetailsHeader
