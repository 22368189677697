import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function CopyAsIntrareadIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M5.5 20H15.5C16.05 20 16.5 20.45 16.5 21C16.5 21.55 16.05 22 15.5 22H5.5C4.4 22 3.5 21.1 3.5 20V7C3.5 6.45 3.95 6 4.5 6C5.05 6 5.5 6.45 5.5 7V20Z"
          fill="#546E7A"
        />
        <path
          d="M13 6.5C13 5.94772 13.4477 5.5 14 5.5C14.5523 5.5 15 5.94772 15 6.5C15 7.05228 14.5523 7.5 14 7.5C13.4477 7.5 13 7.05228 13 6.5Z"
          fill="#546E7A"
        />
        <path
          d="M13 10.5C13 9.94771 13.4477 9.5 14 9.5C14.5523 9.5 15 9.94771 15 10.5V13.5C15 14.0523 14.5523 14.5 14 14.5C13.4477 14.5 13 14.0523 13 13.5V10.5Z"
          fill="#546E7A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.5 4V16C20.5 17.1 19.6 18 18.5 18H9.5C8.4 18 7.5 17.1 7.5 16V4C7.5 2.9 8.4 2 9.5 2H18.5C19.6 2 20.5 2.9 20.5 4ZM9.5 16H18.5V4H9.5V16Z"
          fill="#546E7A"
        />
      </svg>
    </SvgIcon>
  )
}
