import { Breadcrumb } from '@common/components/Breadcrumb'
import { Box, Button, Chip, Stack, Typography } from '@mui/material'
import { RouteKeyEnum, ROUTES } from 'src/routes'
import { StudyDetailsHeaderProps } from './study.types'
import { getChipColorByStatus } from '@common/utils/styleUtils'
import { capitalize } from '@common/utils/stringUtils'
import { SPACING } from '@common/theme/spacing'
import { useNavigate, useParams } from 'react-router-dom'
import { useCombinedPermissions } from '@auth/hooks/useCombinedPermissions'
import { StudyPermission } from '@auth/permissionsEnum'
import { UpgradeRounded } from '@mui/icons-material'
import useExportStudy from '@features/study_details/components/StudyDetails/hooks/useExportStudy'
import { handleMutationError } from '@common/hooks/handleMutationError'
import { toast } from 'sonner'
import Toast from '../Toast'

export default function StudyDetailsHeader({ study }: StudyDetailsHeaderProps) {
  const navigate = useNavigate()
  const { studyId } = useParams()
  const { hasPermission } = useCombinedPermissions()
  const { mutate: exportStudy } = useExportStudy()

  const handleStudyExport = (studyId: string) => {
    exportStudy(
      { studyId },
      {
        onSuccess: () => {
          toast(<Toast message="Study exported successfully." variant="success" />)
        },
        onError: handleMutationError,
      },
    )
  }
  const BreadcrumbItems = [
    {
      title: 'Home',
      route_key: RouteKeyEnum.HOME,
    },
    {
      title: study.nickname,
      route_key: RouteKeyEnum.STUDY_DETAILS,
      params: { studyId: study.id },
    },
  ]

  return (
    <Box data-testid="study-shell" sx={{ paddingBottom: SPACING.headerSpacingY }}>
      <Breadcrumb items={BreadcrumbItems} sx={{ paddingBottom: 2 }} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 1.5,
            alignItems: 'center',
          }}
        >
          <Typography variant="h4" sx={{ color: 'text.primary' }}>
            {study.user_facing_study_id}, {study.nickname}
          </Typography>
          <Chip
            label={capitalize(study.status)}
            color={getChipColorByStatus(study.status)}
            sx={{ padding: 0.5, alignItems: 'center' }}
          />
        </Box>
        {hasPermission(StudyPermission.ALL) && (
          <Stack spacing={SPACING.buttonSpacing}>
            <Button
              variant="contained"
              onClick={() => navigate(ROUTES.UPDATE_STUDY.path.replace(':studyId', studyId!))}
            >
              Edit study
            </Button>
            <Button
              startIcon={<UpgradeRounded />}
              variant="contained"
              color="secondary"
              onClick={() => handleStudyExport(studyId!)}
            >
              Export
            </Button>
          </Stack>
        )}
      </Box>
    </Box>
  )
}
