import { ReactNode, useState } from 'react'
import { SearchContext, SearchContextType } from './SearchContext'

export const SearchProvider = ({ children }: { children: ReactNode }) => {
  const [value, setValue] = useState<string>('')

  const contextValue: SearchContextType = {
    value,
    setValue,
  }

  return <SearchContext.Provider value={contextValue}>{children}</SearchContext.Provider>
}
