import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { SubjectsTimepointsParams } from '../types/index'

export default function useStudySubjectsTimepointsTableHandlers() {
  const [page, setPage] = useState(0)
  const [isSubjectDialogVisible, setIsSubjectDialogVisible] = useState(false)
  const [editingSubjectId, setEditingSubjectId] = useState<string | null>(null)
  const { studyId } = useParams<{ studyId: string }>()
  const [subjectsTimepointsParams, setSubjectsTimepointsParams] = useState<SubjectsTimepointsParams>({
    study_id: studyId!,
    search: undefined,
    limit: 50,
    next_cursor: undefined,
    previous_cursor: undefined,
    order_by_field: 'pseudo_id',
    asc_order: true,
  })

  const handleSearch = (value: string) => {
    setSubjectsTimepointsParams((prev) => ({
      ...prev,
      search: value || undefined,
      next_cursor: undefined,
      previous_cursor: undefined,
    }))
    setPage(0)
  }

  const handleAddClick = () => {
    setIsSubjectDialogVisible(true)
  }

  const handleEditClick = (subjectId: string) => {
    setEditingSubjectId(subjectId)
    setIsSubjectDialogVisible(true)
  }

  return {
    page,
    isSubjectDialogVisible,
    editingSubjectId,
    subjectsTimepointsParams,
    setSubjectsTimepointsParams,
    handleSearch,
    handleAddClick,
    handleEditClick,
    setEditingSubjectId,
    setIsSubjectDialogVisible,
  }
}
