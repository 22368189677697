import { useMutation } from '@tanstack/react-query'
import {
  sendAccountActionEmailAuthAccountActionsPost,
  AccountActionEnum,
  HTTPValidationError,
  SendAccountActionEmailAuthAccountActionsPostResponse,
} from '@common/config/api/client'
import { AxiosError } from 'axios'

interface SendAccountActionParams {
  userId: string
  action: AccountActionEnum
  redirectUri?: string
  lifespan?: number
}

export default function useSendAccountActionEmail() {
  return useMutation<
    SendAccountActionEmailAuthAccountActionsPostResponse,
    AxiosError<HTTPValidationError>,
    SendAccountActionParams
  >({
    mutationFn: async ({ userId, action, redirectUri, lifespan }) => {
      const response = await sendAccountActionEmailAuthAccountActionsPost({
        body: {
          user_id: userId,
          action,
          redirect_uri: redirectUri,
          lifespan,
        },
        throwOnError: true,
      })
      return response.data
    },
  })
}
