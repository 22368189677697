import { Box, Toolbar, AppBar as MuiAppBar } from '@mui/material'
import { useMemo } from 'react'
import { Notifications } from '@common/components/Notifications'
import { useExtendedAuth } from '@auth/hooks/useExtendedAuth'
import ProfileMenu from './ProfileMenu'

export const APP_BAR_HEIGHT = '56px'

export function AppBar() {
  const auth = useExtendedAuth()
  const subscriberId = useMemo(() => auth.user?.profile.sub, [auth.user])

  const handleSignOut = () => {
    void auth.signoutRedirect()
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <MuiAppBar position="static" sx={{ bgcolor: 'background.default', boxShadow: 2 }}>
        <Toolbar sx={{ justifyContent: 'space-between', minHeight: `${APP_BAR_HEIGHT} !important` }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
            {subscriberId && <Notifications subscriberId={subscriberId} />}
            <ProfileMenu onSignOut={handleSignOut} />
          </Box>
        </Toolbar>
      </MuiAppBar>
    </Box>
  )
}
