import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  TextField as MUITextField,
  Stack,
  Link,
} from '@mui/material'
import { QcTaskEsignContext } from '@common/config/api/client'
import { styled } from '@mui/material/styles'
import { SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { TextField } from '@common/components/Form/TextField'
import { useExtendedAuth } from '@auth/hooks/useExtendedAuth.ts'
import { useParams } from 'react-router-dom'
import { SPACING } from '@common/theme/spacing'
import { useSignPayload } from '../../hooks/useSignPayload'

type ESignDialogProps = {
  onClose: () => void
  payload: QcTaskEsignContext
  qcTaskId: string
}

const ESignDialogStyled = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    padding: theme.spacing(SPACING.spacingXl),
    borderRadius: theme.spacing(SPACING.borderRadiusXl),
  },
  '& .MuiDialogActions-root': {
    paddingTop: theme.spacing(SPACING.spacingXl),
  },
}))

const PasswordSchema = z.object({
  password: z.string({ message: 'Required' }),
})

export default function ESignDialog({ onClose, payload, qcTaskId }: ESignDialogProps) {
  const { control, handleSubmit } = useForm<z.infer<typeof PasswordSchema>>({
    mode: 'onChange',
    resolver: zodResolver(PasswordSchema),
  })
  const { user } = useExtendedAuth()
  const { mutateAsync: sign, isPending } = useSignPayload()
  const { studyId } = useParams<{
    studyId: string
  }>()

  const handleSignClick: SubmitHandler<z.infer<typeof PasswordSchema>> = async ({ password }) => {
    await sign({
      body: {
        password,
        username: user?.profile.preferred_username || '',
        context: payload,
      },
      path: {
        qc_task_id: qcTaskId,
      },
      query: {
        study_id: studyId!,
      },
    })

    onClose()
  }

  return (
    <ESignDialogStyled sx={{ p: SPACING.spacingXl }} open={true} onClose={() => null}>
      <form onSubmit={handleSubmit(handleSignClick)}>
        <DialogTitle sx={{ p: SPACING.noSpacing }}>
          <Typography variant="h6">Sign to confirm</Typography>
          <Typography variant="body1" color="text.secondary" width={530}>
            Entering your credentials equals a legally binding signature
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ px: SPACING.noSpacing, pb: SPACING.noSpacing }}>
          <Stack gap={SPACING.spacingLg} pt={SPACING.spacingXl} mb={SPACING.spacingXl}>
            <MUITextField
              label="Email"
              variant="outlined"
              fullWidth
              disabled
              value={user?.profile.preferred_username}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
            />
            <TextField control={control} name="password" type="password" label="Password" />
          </Stack>
          <Link component="button" type="button">
            Forgot your password?
          </Link>
        </DialogContent>
        <DialogActions sx={{ p: SPACING.noSpacing }}>
          <Button size="large" variant="contained" color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button size="large" variant="contained" type="submit" disabled={isPending}>
            Sign
          </Button>
        </DialogActions>
      </form>
    </ESignDialogStyled>
  )
}
