import { Theme } from '@mui/material'

export const MuiPaperOverrides = (theme: Theme) => ({
  MuiPaper: {
    defaultProps: {
      elevation: 1,
    },
    variants: [
      {
        props: { variant: 'padded' },
        style: {
          padding: '1.25rem',
        },
      },
    ],
    styleOverrides: {
      root: {
        background: theme.palette.background.default,
      },
    },
  },
})
