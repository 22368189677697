import { Button, Paper, Stack, Typography } from '@mui/material'
import { TextField } from '@common/components/Form/TextField'
import { Dropdown } from '@common/components/Form/Dropdown/Dropdown'
import { useForm, FormProvider } from 'react-hook-form'
import { SyntheticEvent, useMemo, useState } from 'react'
import { CompaniesMetadataSchema, CompanyType, UserCreateSchema, UserSchema } from '@common/config/api/client/types.gen'
import { SPACING } from '@common/theme/spacing'
import { Switch } from '@common/components/Form/Switch'
import { zodResolver } from '@hookform/resolvers/zod'
import { CompanyUserCreateSchema } from '@features/user_management/schemas'
import { z } from 'zod'

interface Props {
  user: UserSchema | undefined
  companiesMetadata: CompaniesMetadataSchema | undefined
  onUpdateUser?: (userData: UserCreateSchema) => Promise<void>
}

export default function ProfileInformation({ user, companiesMetadata, onUpdateUser }: Props) {
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>(user?.company_id ?? '')
  const [selectedCompanyType, setSelectedCompanyType] = useState<CompanyType | null>(null)

  const methods = useForm<z.infer<typeof CompanyUserCreateSchema>>({
    defaultValues: {
      name: user?.user_name ?? '',
      email: user?.user_email ?? '',
      company_id: user?.company_id ?? '',
      company_type: user?.company_type,
      role_id: user?.role_id ?? '',
      phone: user?.phone ?? '',
      email_verified: user?.is_verified ?? false,
    },
    resolver: zodResolver(CompanyUserCreateSchema),
  })
  const filteredCompanyOptions = useMemo(() => {
    if (!companiesMetadata?.companies) return []

    if (!selectedCompanyType) {
      return companiesMetadata.companies.map((company) => ({
        label: company.company_name,
        value: company.company_id,
        type: company.company_type,
      }))
    }

    return companiesMetadata.companies
      .filter((company) => company.company_type === selectedCompanyType)
      .map((company) => ({
        label: company.company_name,
        value: company.company_id,
        type: company.company_type,
      }))
  }, [companiesMetadata, selectedCompanyType])

  const companyTypeOptions = useMemo(() => {
    if (!companiesMetadata?.company_types) return []
    return companiesMetadata.company_types.map(([value, label]) => ({
      label,
      value,
    }))
  }, [companiesMetadata])

  const roleOptions = useMemo(() => {
    if (!companiesMetadata?.companies || !selectedCompanyId) return []

    const selectedCompany = companiesMetadata.companies.find((company) => company.company_id === selectedCompanyId)

    return (
      selectedCompany?.roles.map((role) => ({
        label: role.role_name,
        value: role.role_id,
      })) ?? []
    )
  }, [companiesMetadata, selectedCompanyId])

  const handleCompanyChange = (
    _: SyntheticEvent<Element, Event>,
    value:
      | NonNullable<string | { label: string; value: string }>
      | (string | { label: string; value: string })[]
      | null,
  ) => {
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      const companyId = value.value as string
      setSelectedCompanyId(companyId)
      methods.setValue('company_id', companyId)
      methods.setValue('role_id', '')
      if (companiesMetadata?.companies) {
        const selectedCompany = companiesMetadata.companies.find((company) => company.company_id === companyId)
        if (selectedCompany) {
          setSelectedCompanyType(selectedCompany.company_type)
          methods.setValue('company_type', selectedCompany.company_type)
        }
      }
    } else if (value === null) {
      setSelectedCompanyId('')
      methods.setValue('company_id', '')
      methods.setValue('role_id', '')
    }
  }

  const handleCompanyTypeChange = (
    _: SyntheticEvent<Element, Event>,
    value:
      | NonNullable<string | { label: string; value: string }>
      | (string | { label: string; value: string })[]
      | null,
  ) => {
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      const newCompanyType = value.value as CompanyType
      setSelectedCompanyType(newCompanyType)
      methods.setValue('company_type', newCompanyType)
      setSelectedCompanyId('')
      methods.setValue('company_id', '')
      methods.setValue('role_id', '')
    } else if (value === null) {
      setSelectedCompanyType(null)
      methods.setValue('company_type', null)
      methods.setValue('company_id', '')
    }
  }

  const handleSave = async (data: UserCreateSchema) => {
    try {
      if (!onUpdateUser) return
      await onUpdateUser({
        name: data.name,
        email: data.email,
        phone: data.phone,
        company_id: data.company_id,
        role_id: data.role_id,
        email_verified: data.email_verified,
      })
    } catch (error) {
      console.error('Error saving user:', error)
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSave)}>
        <Paper
          sx={{
            padding: SPACING.paperInnerPadding,
            borderRadius: SPACING.borderRadiusXl,
          }}
        >
          <Stack spacing={SPACING.spacingXl}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h6">User information</Typography>
            </Stack>
            <Stack direction="column" spacing={SPACING.formFieldsInnerSectionSpacing}>
              <Typography variant="subtitle1">Company details</Typography>
              <Stack direction="row" spacing={SPACING.spacingLg}>
                <Dropdown
                  control={methods.control}
                  name="company_type"
                  label="Type"
                  options={companyTypeOptions}
                  size="small"
                  sx={{ width: '50%' }}
                  onChange={handleCompanyTypeChange}
                />
                <Dropdown
                  control={methods.control}
                  name="company_id"
                  label="Company name"
                  options={filteredCompanyOptions}
                  size="small"
                  sx={{ width: '50%' }}
                  onChange={handleCompanyChange}
                />
              </Stack>
            </Stack>
            <Stack direction="column" spacing={SPACING.formFieldsInnerSectionSpacing}>
              <Typography variant="subtitle1">Personal details</Typography>
              <TextField control={methods.control} name="name" label="Name" size="small" />
              <Dropdown control={methods.control} name="role_id" label="Role" options={roleOptions} size="small" />
              <TextField control={methods.control} name="email" label="Email" size="small" />
              <TextField name="phone" label="Phone number" size="small" />
              <Switch control={methods.control} name="email_verified" label="User is verified" />
            </Stack>
            <Stack direction="row" spacing={SPACING.spacingLg} justifyContent="flex-end">
              <Button type="submit" variant="contained">
                Save changes
              </Button>
            </Stack>
          </Stack>
        </Paper>
      </form>
    </FormProvider>
  )
}
