import {
  getTimepointSubmissionStudiesStudyIdSubjectsSubjectIdTimepointSubmissionTimepointSubmissionIdGet,
  GetTimepointSubmissionStudiesStudyIdSubjectsSubjectIdTimepointSubmissionTimepointSubmissionIdGetData,
} from '@common/config/api/client'
import { useQuery } from '@tanstack/react-query'

export const useGetTimePointSubmissionDetails = (
  queryKey: string[],
  params: GetTimepointSubmissionStudiesStudyIdSubjectsSubjectIdTimepointSubmissionTimepointSubmissionIdGetData['path'],
  query?: GetTimepointSubmissionStudiesStudyIdSubjectsSubjectIdTimepointSubmissionTimepointSubmissionIdGetData['query'],
) => {
  return useQuery({
    queryKey: queryKey,
    queryFn: () =>
      getTimepointSubmissionStudiesStudyIdSubjectsSubjectIdTimepointSubmissionTimepointSubmissionIdGet({
        path: params,
        query,
      }),
    enabled: !!params?.subject_id && !!params?.timepoint_submission_id && !!params?.study_id,
  })
}
