import { useState } from 'react'
import { Stack, Table, TableBody, TableCell, TableRow, Typography, useTheme } from '@mui/material'
import DefaultAdjudicationTableHeader from './DefaultAdjudicationTableHeader'
import TargetLesionsTableRow from './TargetLesionsTableRow'
import { ReadTaskSummaryData } from '@common/config/api/client'
import { TableScrollSize } from '@features/reader_summary/pages/summary.types'
import ExpandableSection from '../summary/ExpandableSection'

export default function ExpandableTargetLesionsTableRow({
  timepoints,
  tableScrollSize,
}: {
  timepoints: ReadTaskSummaryData['timepoints']
  tableScrollSize: TableScrollSize
}) {
  const [expanded, setExpanded] = useState(false)
  const theme = useTheme()

  const maxTargetLesionsNumber = timepoints.reduce((max, tp) => {
    const count1 = tp.reader_submissions[0].ecrf_form_submission?.target_lesion_measurements?.length ?? 0
    const count2 = tp.reader_submissions[1].ecrf_form_submission?.target_lesion_measurements?.length ?? 0
    const count = Math.max(count1, count2)
    return Math.max(max, count)
  }, 0)

  return (
    <>
      <TargetLesionsTableRow
        expanded={expanded}
        setExpanded={setExpanded}
        timepoints={timepoints}
        tableScrollSize={tableScrollSize}
      />
      <TableRow>
        <TableCell sx={{ p: 0 }} colSpan={tableScrollSize.minVisibleTimepoints + 2} data-testid="collapse-cell">
          <ExpandableSection expanded={expanded} disableAnimation>
            <Table
              data-testid="expanded-table"
              sx={{
                borderCollapse: 'separate',
              }}
            >
              <DefaultAdjudicationTableHeader isHidden timepoints={timepoints} tableScrollSize={tableScrollSize} />
              <TableBody>
                {Array.from({ length: maxTargetLesionsNumber }).map((_, lesionIndex) => {
                  const rowCells = Array.from({ length: tableScrollSize.minVisibleTimepoints }).map((_, colIndex) => {
                    if (colIndex < tableScrollSize.emptyCellsCount) {
                      return (
                        <TableCell
                          key={`empty-${colIndex}`}
                          sx={{
                            width: tableScrollSize.columnWidth,
                            minWidth: tableScrollSize.columnWidth,
                            borderBottom: `1px solid ${theme.palette.divider}`,
                          }}
                        />
                      )
                    }
                    const tpIndex = colIndex - tableScrollSize.emptyCellsCount
                    const timepoint = timepoints[tpIndex]

                    const measurement1 =
                      timepoint &&
                      timepoint.reader_submissions[0] &&
                      timepoint.reader_submissions[0].ecrf_form_submission &&
                      timepoint?.reader_submissions[0].ecrf_form_submission?.target_lesion_measurements?.[lesionIndex]
                    const measurement2 =
                      timepoint &&
                      timepoint.reader_submissions[1] &&
                      timepoint.reader_submissions[1].ecrf_form_submission &&
                      timepoint?.reader_submissions[1].ecrf_form_submission?.target_lesion_measurements?.[lesionIndex]

                    let stickyStyles = {}
                    if (colIndex === tableScrollSize.emptyCellsCount) {
                      stickyStyles = {
                        position: 'sticky',
                        left: tableScrollSize.firstTimepointLeftOffset,
                        background: 'inherit',
                        zIndex: 2,
                      }
                    }
                    if (colIndex === tableScrollSize.minVisibleTimepoints - 1) {
                      stickyStyles = {
                        position: 'sticky',
                        right: tableScrollSize.checkboxWidth,
                        background: 'inherit',
                        zIndex: 2,
                        borderLeft: `1px solid ${theme.palette.primary.main}`,
                      }
                    }

                    return (
                      <TableCell
                        key={`tp-${tpIndex}-lesion-${lesionIndex}`}
                        sx={{
                          padding: 0,
                          width: tableScrollSize.columnWidth,
                          minWidth: tableScrollSize.columnWidth,
                          borderBottom: `1px solid ${theme.palette.divider}`,
                          borderLeft: `1px solid ${theme.palette.divider}`,
                          ...stickyStyles,
                        }}
                      >
                        {(measurement1 || measurement2) && (
                          <Table sx={{ padding: 0 }}>
                            <TableRow>
                              <TableCell
                                sx={{
                                  maxWidth: tableScrollSize.columnWidth / 2,
                                  width: tableScrollSize.columnWidth / 2,
                                }}
                                align="left"
                              >
                                {measurement1 && (
                                  <Stack spacing={1} direction="row">
                                    <Typography variant="body2" sx={{ color: 'text.primary' }}>
                                      {lesionIndex + 1}
                                    </Typography>
                                    <Stack>
                                      <Typography variant="body2" sx={{ color: 'text.primary' }}>
                                        {measurement1.location}
                                      </Typography>
                                      <Stack>
                                        <Typography variant="button" sx={{ color: 'text.primary' }}>
                                          {measurement1.state}
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: 'text.primary' }}>
                                          {measurement1.long_axis} x
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: 'text.primary' }}>
                                          {measurement1.short_axis} {measurement1.unit}
                                        </Typography>
                                      </Stack>
                                    </Stack>
                                  </Stack>
                                )}
                              </TableCell>
                              <TableCell
                                align="right"
                                sx={{
                                  borderLeft: `1px solid ${theme.palette.divider}`,
                                  width: tableScrollSize.columnWidth / 2,
                                  maxWidth: tableScrollSize.columnWidth / 2,
                                }}
                              >
                                {measurement2 && (
                                  <Stack spacing={1} direction="row" justifyContent="flex-end">
                                    <Stack>
                                      <Typography variant="body2" sx={{ color: 'text.primary' }}>
                                        {measurement2.location}
                                      </Typography>
                                      <Stack>
                                        <Typography variant="button" sx={{ color: 'text.primary' }}>
                                          {measurement2.state}
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: 'text.primary' }}>
                                          {measurement2.long_axis}
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: 'text.primary' }}>
                                          {'x '}
                                          {measurement2.short_axis} {measurement2.unit}
                                        </Typography>
                                      </Stack>
                                    </Stack>
                                    <Typography variant="body2" sx={{ color: 'text.primary' }}>
                                      {lesionIndex + 1}
                                    </Typography>
                                  </Stack>
                                )}
                              </TableCell>
                            </TableRow>
                          </Table>
                        )}
                      </TableCell>
                    )
                  })

                  return (
                    <TableRow
                      key={`lesion-row-${lesionIndex}`}
                      sx={{
                        bgcolor: lesionIndex % 2 === 0 ? theme.palette.background.paper : theme.palette.grey[50],
                      }}
                    >
                      <TableCell
                        sx={{
                          width: tableScrollSize.columnWidth,
                          minWidth: tableScrollSize.columnWidth,
                          position: 'sticky',
                          left: 0,
                          background: 'inherit',
                          paddingLeft: 3,
                          zIndex: 3,
                          borderBottom: `1px solid ${theme.palette.divider}`,
                        }}
                      />
                      {rowCells}
                      <TableCell
                        padding="checkbox"
                        sx={{
                          width: tableScrollSize.checkboxWidth,
                          minWidth: tableScrollSize.checkboxWidth,
                          position: 'sticky',
                          right: 0,
                          background: 'inherit',
                          zIndex: 3,
                          borderBottom: `1px solid ${theme.palette.divider}`,
                          borderLeft: `1px solid ${theme.palette.primary.main}`,
                        }}
                      />
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </ExpandableSection>
        </TableCell>
      </TableRow>
    </>
  )
}
