import { Stack, Table, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material'
import ReaderAdjudicationTableBody from './ReaderAdjudicationTableBody'
import DefaultAdjudicationTableHeader from './DefaultAdjudicationTableHeader'
import { CheckCircleOutlineRounded } from '@mui/icons-material'
import { ReadTaskSummaryData } from '@common/config/api/client'
import { TableScrollSize } from '@features/reader_summary/pages/summary.types'

export default function ReaderAdjudicationTable({
  timepoints,
  readTaskSummaryMetaData,
  tableScrollSize,
}: {
  timepoints: ReadTaskSummaryData['timepoints']
  readTaskSummaryMetaData: ReadTaskSummaryData['read_task_summary_metadata']
  tableScrollSize: TableScrollSize
}) {
  const theme = useTheme()

  return (
    <TableContainer
      sx={{
        overflowX: tableScrollSize.isScrolling ? 'auto' : 'hidden',
        bgcolor: 'background.default',

        borderRadius: 4,
        border: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Table
        sx={{
          borderCollapse: 'separate',
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                width: tableScrollSize.columnWidth,
                minWidth: tableScrollSize.columnWidth,
                position: 'sticky',
                left: 0,
                backgroundColor: 'background.paper',
                paddingLeft: 3,
                paddingTop: 3,
                borderBottom: `1px solid ${theme.palette.divider}`,
              }}
            >
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  color: 'text.primary',
                }}
              >
                <CheckCircleOutlineRounded />
                <Typography variant="button">Criteria</Typography>
              </Stack>
            </TableCell>
            <TableCell
              sx={{
                width: tableScrollSize.columnWidth,
                minWidth: tableScrollSize.columnWidth,
                position: 'sticky',
                left: tableScrollSize.columnWidth,
                backgroundColor: 'background.paper',
                paddingTop: 3,
                borderBottom: `1px solid ${theme.palette.divider}`,
              }}
            >
              <Typography
                variant="button"
                sx={{
                  color: 'text.primary',
                }}
              >
                {timepoints[timepoints.length - 1]?.response_criteria}
              </Typography>
            </TableCell>

            {Array.from({ length: tableScrollSize.minVisibleTimepoints - 1 }).map((_, i) => {
              let stickyStyles = {}
              if (i === tableScrollSize.minVisibleTimepoints - 2) {
                stickyStyles = {
                  position: 'sticky',
                  right: tableScrollSize.checkboxWidth,
                  zIndex: 2,
                  borderBottom: `1px solid ${theme.palette.primary.main}`,
                }
              }
              return (
                <TableCell
                  key={`empty-${i}`}
                  sx={{
                    width: tableScrollSize.columnWidth,
                    minWidth: tableScrollSize.columnWidth,
                    backgroundColor: 'background.paper',
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    ...stickyStyles,
                  }}
                />
              )
            })}

            <TableCell
              sx={{
                width: tableScrollSize.checkboxWidth,
                minWidth: tableScrollSize.checkboxWidth,
                position: 'sticky',
                right: 0,
                paddingRight: 3,
                paddingTop: 3,
                backgroundColor: 'background.paper',
                borderBottom: `1px solid ${theme.palette.divider}`,
              }}
            />
          </TableRow>
        </TableHead>
        <DefaultAdjudicationTableHeader isHidden={false} timepoints={timepoints} tableScrollSize={tableScrollSize} />
        <ReaderAdjudicationTableBody
          timepoints={timepoints}
          readTaskSummaryMetaData={readTaskSummaryMetaData}
          tableScrollSize={tableScrollSize}
        />
      </Table>
    </TableContainer>
  )
}
