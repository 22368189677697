import { z } from 'zod'

export const readerEsignSchema = z.object({
  username: z.string().min(1, 'Username is required'),
  password: z.string().min(1, 'Password is required'),
  context: z.object({
    type: z.literal('reader_task_submission'),
  }),
})

export type ReaderEsignFormValues = z.infer<typeof readerEsignSchema>
