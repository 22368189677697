import { useState, useEffect } from 'react'
import { SPACING } from '@common/theme/spacing'
import ExpandableSection from '@study_setup/components/ExpandableSection'
import { Button, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { Dropdown } from '@common/components/Form/Dropdown'
import { useFormContext } from 'react-hook-form'
import { useGetStudyPrimarySites } from '@features/study_details/sites/hooks/useStudySitesQueries'
import { useParams } from 'react-router-dom'
import { Create, DeleteOutlineOutlined } from '@mui/icons-material'
import FormRendererDialog from '@features/subject/components/FormRendererDialog'
import { useGetStudySubjectsMetadata } from '@features/study_details/Subjects/hooks/useStudySubjectsQueries'
import { useUpdateStudySubject } from '@features/study_details/Subjects/hooks/useStudySubjectsMutations'
import { handleMutationError } from '@common/hooks/handleMutationError'
import Toast from '@common/components/Toast'
import { toast } from 'sonner'
import { queryClient } from '@common/config/api/queryClient'
import { queryKeyFactory } from '@features/study_details/Subjects/queryKeyFactory'
import { useCombinedPermissions } from '@auth/hooks/useCombinedPermissions'
import { SubjectPermission } from '@auth/permissionsEnum'
import { EligibilityStatus, Study, SubjectStatus } from '@common/config/api/client'
import { Option } from '@common/components/Form/Dropdown/Dropdown'
import { SubjectConfigurationFormValues } from '@study_setup/SubjectConfiguration/components/SubjectConfiguration'
import { SubjectDetailsFormValues } from '@features/subject/subject.types'

interface SubjectInformationProps {
  study: Study | undefined
  subjectConfig: SubjectConfigurationFormValues | undefined
}

const SubjectInformation = ({ study, subjectConfig }: SubjectInformationProps) => {
  const [isExpanded, setIsExpanded] = useState(true)
  const [secondarySiteOptions, setSecondarySiteOptions] = useState<Option[]>([])
  const [eFormDialogState, setEFormUploadDialogState] = useState<{
    visible: boolean
    eFormFields?: string | null
    eFormValues?: string | null
    source: 'SUBJECT'
    containerSubmissionId?: string | null
  }>({
    visible: false,
    eFormFields: null,
    eFormValues: null,
    source: 'SUBJECT',
  })

  const { control, watch, setValue, getValues } = useFormContext<SubjectDetailsFormValues>()
  const { studyId } = useParams<{ studyId: string }>()
  const { hasPermission } = useCombinedPermissions()

  const { data: primarySitesData, isLoading: isPrimarySitesLoading } = useGetStudyPrimarySites(
    {
      study_id: studyId!,
    },
    hasPermission(SubjectPermission.VIEW),
  )
  const { data: studySubjectMetadata, isLoading: isSubjectMetadataLoading } = useGetStudySubjectsMetadata(studyId!)
  const { mutate: updateSubject } = useUpdateStudySubject()

  const selectedForm = watch('selected_form')
  const primarySiteId = watch('primary_site_id')

  useEffect(() => {
    if (primarySiteId) {
      const selectedPrimarySite = primarySitesData?.primary_sites.find((site) => site.value === primarySiteId)
      if (selectedPrimarySite?.additional_fields?.secondary_sites) {
        setSecondarySiteOptions(selectedPrimarySite.additional_fields.secondary_sites as Option[])
      } else {
        setSecondarySiteOptions([])
      }
    } else {
      setSecondarySiteOptions([])
      setValue('secondary_site_id', null)
    }
  }, [primarySiteId, primarySitesData?.primary_sites, setValue])

  const handleDeleteSubjectEform = () => {
    setValue('subject_form_submission', null)
    setValue('selected_form', null)
  }

  const handleEditSubjectEform = () => {
    setEFormUploadDialogState({
      ...eFormDialogState,
      eFormFields: JSON.stringify(selectedForm?.fields),
      eFormValues: watch('subject_form_submission'),
      visible: true,
    })
  }

  const handleSubmitEForm = (data: Record<string, string | number | boolean>) => {
    setValue('subject_form_submission', JSON.stringify(data))
    setEFormUploadDialogState({ ...eFormDialogState, visible: false })
  }

  const handleEditSubjectInformations = () => {
    const formData = getValues()
    updateSubject(
      {
        studyId: studyId!,
        subjectId: formData.id,
        subjectPayload: {
          primary_site_study_site_id: hasPermission(SubjectPermission.UPDATE) ? formData.primary_site_id : null,
          secondary_site_study_site_id: hasPermission(SubjectPermission.UPDATE) ? formData.secondary_site_id : null,
          status: formData.status as SubjectStatus,
          eligibility_status: formData.eligibility_status as EligibilityStatus,
          subject_form_id: formData.subject_form_definition_id,
          subject_form_submission: formData.subject_form_submission,
        },
      },
      {
        onSuccess: () => {
          toast(<Toast message="Study subject updated successfully." variant="success" />)
          queryClient.invalidateQueries({
            queryKey: queryKeyFactory.studySubject({ study_id: studyId!, subject_id: formData.id }),
          })
        },
        onError: handleMutationError,
      },
    )
  }

  return (
    <ExpandableSection
      title="Subject Information"
      isFinished={false}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
    >
      <Stack sx={{ mt: SPACING.formFieldsSectionSpacing }} spacing={SPACING.formFieldsSpacing}>
        {hasPermission(SubjectPermission.VIEW) && (
          <>
            <Dropdown
              control={control}
              options={primarySitesData?.primary_sites || []}
              loading={isPrimarySitesLoading}
              name="primary_site_id"
              label="Primary Site assignment"
            />
            {study?.subjects_required_to_visit_multiple_sites && (
              <Dropdown
                control={control}
                options={secondarySiteOptions}
                loading={isPrimarySitesLoading}
                name="secondary_site_id"
                label="Secondary Site assignment"
                disabled={!primarySiteId}
              />
            )}
          </>
        )}
        <Dropdown
          control={control}
          options={studySubjectMetadata?.statuses || []}
          loading={isSubjectMetadataLoading}
          name="status"
          label="Status"
        />
        <Dropdown
          control={control}
          options={studySubjectMetadata?.eligibility_statuses || []}
          loading={isSubjectMetadataLoading}
          name="eligibility_status"
          label="Eligibility Status"
        />
        {selectedForm && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              paddingX: 1.5,
              paddingY: 1,
              backgroundColor: 'paper.main',
              borderRadius: 2,
              marginTop: 1.5,
              border: (theme) => `1px solid ${theme.palette.secondary.main}`,
            }}
          >
            <Typography>{selectedForm.name}</Typography>
            <Stack direction="row" spacing={0.75}>
              <Tooltip title="Edit eForm" placement="top" arrow>
                <IconButton onClick={() => handleEditSubjectEform()}>
                  <Create />
                </IconButton>
              </Tooltip>
              {!(subjectConfig?.eform_mandatory == true) && (
                <Tooltip title="Delete eForm" placement="top" arrow>
                  <IconButton onClick={() => handleDeleteSubjectEform()}>
                    <DeleteOutlineOutlined />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </Stack>
        )}
        <Button variant="contained" color="secondary" size="large" onClick={() => handleEditSubjectInformations()}>
          Update Informations
        </Button>
        {eFormDialogState.visible && (
          <FormRendererDialog
            header="Fill eForm"
            onClose={() => setEFormUploadDialogState({ ...eFormDialogState, visible: false })}
            fields={eFormDialogState.eFormFields}
            values={eFormDialogState.eFormValues}
            onSubmit={handleSubmitEForm}
          />
        )}
      </Stack>
    </ExpandableSection>
  )
}

export default SubjectInformation
