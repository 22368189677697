import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeOptions, ThemeProvider as MUIThemeProvider } from '@mui/material/styles'
import { createContext, ReactNode, useEffect, useMemo, useState, useContext } from 'react'
import { GlobalStyles } from '@mui/material'
import { componentsOverrides } from '@common/theme/overrides'
import palette from '@common/theme/palette'
import { typography } from '@common/theme/typography'
import { shadows } from '@common/theme/shadows'
import { breakpoints } from '@common/theme/breakpoints'
import { SPACING } from './spacing'

type ThemeProviderProps = {
  children: ReactNode
}

// Define Theme Context
interface ThemeContextProps {
  themeMode: 'light' | 'dark'
  toggleTheme: () => void
}

export const ThemeContext = createContext<ThemeContextProps | undefined>(undefined)

export function ThemeProvider({ children }: ThemeProviderProps) {
  const [themeMode, setThemeMode] = useState<'light' | 'dark'>('light')

  useEffect(() => {
    localStorage.setItem('theme', themeMode) // Persist theme selection
  }, [themeMode])

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: palette(themeMode),
      typography,
      shadows,
      breakpoints,
      spacing: SPACING.spacing2xl,
    }),
    [themeMode],
  )

  const theme = createTheme(themeOptions)
  theme.components = componentsOverrides(theme)

  const toggleTheme = () => {
    setThemeMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'))
  }

  return (
    <ThemeContext.Provider value={{ themeMode, toggleTheme }}>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles
          styles={`
          @font-face {
              font-family: 'Bayer Sans';
              src: url('/fonts/BayerSansWeb-Light.woff2') format('woff2');
              font-weight: 300;
              font-style: normal;
              font-display: swap;
          }
          @font-face {
              font-family: 'Bayer Sans';
              src: url('/fonts/BayerSansWeb-Regular.woff2') format('woff2');
              font-weight: 400;
              font-style: normal;
              font-display: swap;
          }
          @font-face {
              font-family: 'Bayer Sans';
              src: url('/fonts/BayerSansWeb-Medium.woff2') format('woff2');
              font-weight: 500;
              font-style: normal;
              font-display: swap;
          }
          @font-face {
              font-family: 'Bayer Sans';
              src: url('/fonts/BayerSansWeb-Bold.woff2') format('woff2');
              font-weight: 700;
              font-style: normal;
              font-display: swap;
          }
          #sentry-feedback {
          --inset: auto auto 80px -10px;
          --page-margin: 20px;
          --z-index: 9999;
        }`}
        />
        {children}
      </MUIThemeProvider>
    </ThemeContext.Provider>
  )
}

export const useThemeContext = () => {
  const context = useContext(ThemeContext)
  if (!context) throw new Error('useThemeContext must be used within ThemeProvider')
  return context
}
