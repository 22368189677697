import { useParams } from 'react-router-dom'
import QCDetailsHeader from './QCDetailsHeader'
import QCDetailsOverview from './QCDetailsOverview'
import { useQCTask } from '@features/qc_task_details_view/hooks/useQCTask'

export default function QCDetailsView() {
  const { studyId, qcTaskId } = useParams()

  const { data: qcTaskData, error: qcTaskError, isLoading: isQcTaskLoading } = useQCTask(['QCTask'], studyId, qcTaskId)

  if (isQcTaskLoading) {
    return <div>Loading...</div>
  }

  if (qcTaskError || !qcTaskData || !qcTaskData.data) {
    return <div>Error: {qcTaskError?.message}</div>
  }

  return (
    <>
      <QCDetailsHeader qcTask={qcTaskData.data} />
      <QCDetailsOverview qcTask={qcTaskData.data} />
    </>
  )
}
