import { useQuery } from '@tanstack/react-query'
import { studyTagsStudiesStudyIdTagsDeidGet } from '@common/config/api/client'

export const useGetStudyTagsDeid = (studyId: string) => {
  return useQuery({
    queryKey: ['studyTagsDeid', studyId],
    queryFn: () =>
      studyTagsStudiesStudyIdTagsDeidGet({
        path: { study_id: studyId },
      }),
    select: ({ data }) => {
      return data?.study_tags
    },
    enabled: !!studyId,
  })
}
