import { Stack } from '@mui/material'

import { SPACING } from '@common/theme/spacing'
import SubjectsTimepointsTable from '@features/subject/components/SubjectsTimepointsTable/SubjectsTimepointsTable'
import useStudySubjectsTimepointsTableHandlers from '../hooks/useStudySubjectsTimepointsTableHandlers'
import CreateStudySubjectDialog from '@features/study_details/Subjects/components/CreateStudySubjectDialog'

export default function SubjectsTimepoints() {
  const {
    page,
    subjectsTimepointsParams,
    handleSearch,
    handleAddClick,
    isSubjectDialogVisible,
    setIsSubjectDialogVisible,
  } = useStudySubjectsTimepointsTableHandlers()
  return (
    <>
      <Stack spacing={SPACING.sectionsSpacing}>
        <SubjectsTimepointsTable
          onSearch={handleSearch}
          onAddClick={handleAddClick}
          searchValue={subjectsTimepointsParams.search ?? ''}
          page={page}
        />
      </Stack>
      {isSubjectDialogVisible ? (
        <CreateStudySubjectDialog
          onClose={() => {
            setIsSubjectDialogVisible(false)
          }}
        />
      ) : null}
    </>
  )
}
