import SearchIcon from '@mui/icons-material/Search'
import { Avatar, Box, IconButton, InputAdornment, Menu, MenuItem, MenuList, TextField, Typography } from '@mui/material'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { Fragment } from 'react/jsx-runtime'
import { useState } from 'react'
import { HTTPValidationError, StudyUserRoleAssociation, User } from '@common/config/api/client'
import { toast } from 'sonner'
import Toast from './Toast'
import { AxiosError } from 'axios'
import { useParams } from 'react-router-dom'
import { UsePatchTaskMutation } from '../hooks/UsePatchTaskMutation'
import { PersonAddAltRounded } from '@mui/icons-material'
import PersonRemoveRoundedIcon from '@mui/icons-material/PersonRemoveRounded'

export default function PopupAssignMenuList({
  taskIds,
  assigneeList,
  assignedUser,
  isBulkAssign = false,
}: {
  taskIds: string[]
  assigneeList: StudyUserRoleAssociation[]
  assignedUser?: User
  isBulkAssign?: boolean
}) {
  const [query, setQuery] = useState<string>('')
  const [results, setResults] = useState<StudyUserRoleAssociation[]>(assigneeList)
  const { mutateAsync: assignTask } = UsePatchTaskMutation()
  const { studyId } = useParams()

  const handleAssignTask = async (user_id: string) => {
    await assignTask({
      query: {
        read_task_ids: taskIds,
        study_id: studyId!,
      },
      body: {
        assigned_reader_id: user_id,
      },
    })
      .then(() => {
        toast(<Toast message="You were assigned task" variant="success" data-testid="success-toast" />)
      })
      .catch((error: AxiosError<HTTPValidationError>) => {
        const errorMessage = Array.isArray(error.response?.data?.detail)
          ? error.response?.data?.detail.map((err) => err.msg).join(', ')
          : error.response?.data?.detail || error.message
        toast(<Toast message={errorMessage} variant="error" data-testid="error-toast" />)
      })
  }

  const handleUnassignTask = async () => {
    await assignTask({
      query: {
        read_task_ids: taskIds,
        study_id: studyId!,
      },
      body: {
        status: 'NOT_ASSIGNED',
      },
    })
      .then(() => {
        toast(<Toast message="You were assigned task" variant="success" data-testid="success-toast" />)
      })
      .catch((error: AxiosError<HTTPValidationError>) => {
        const errorMessage = Array.isArray(error.response?.data?.detail)
          ? error.response?.data?.detail.map((err) => err.msg).join(', ')
          : error.response?.data?.detail || error.message
        toast(<Toast message={errorMessage} variant="error" data-testid="error-toast" />)
      })
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value
    setQuery(input)

    if (input) {
      setResults(assigneeList.filter((item) => item.name?.toLowerCase().includes(input.toLowerCase())))
    } else {
      setResults(assigneeList)
    }
  }

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <Fragment>
          <Box {...bindTrigger(popupState)}>
            {isBulkAssign ? (
              <IconButton size="small">
                <PersonAddAltRounded sx={{ color: 'text.secondary' }} />
              </IconButton>
            ) : (
              <Typography
                sx={{
                  color: assignedUser ? 'inherit' : '#006B8C',
                  cursor: 'pointer',
                  '&:hover': {
                    textDecoration: assignedUser ? 'underline' : 'none',
                  },
                }}
                data-testid="assign-task"
              >
                {assignedUser ? assignedUser.user_name : 'Assign task'}
              </Typography>
            )}
          </Box>
          <Menu
            {...bindMenu(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            data-testid="popup-menu-list"
          >
            <MenuList
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                padding: 2,
                outline: 'none',
              }}
            >
              <MenuItem
                sx={{
                  width: 350,
                  padding: 0,
                }}
                onKeyDown={(e) => e.stopPropagation()}
                data-testid="popup-menu-list-item-search"
              >
                <TextField
                  variant="outlined"
                  size="small"
                  onChange={handleSearch}
                  value={query}
                  label="Search"
                  fullWidth
                  sx={{
                    boxShadow: 1,
                  }}
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              </MenuItem>
              {assignedUser && (
                <MenuItem
                  onClick={popupState.close}
                  sx={{
                    width: '100%',
                    padding: 0,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      alignSelf: 'stretch',
                      padding: 1,
                      width: '100%',
                    }}
                    data-testid="unassign-task"
                    onClick={() => {
                      handleUnassignTask()
                    }}
                  >
                    <PersonRemoveRoundedIcon
                      sx={{
                        marginRight: 1.5,
                        width: 24,
                        height: 24,
                        color: 'text.secondary',
                      }}
                    />
                    <Typography>Unassign</Typography>
                  </Box>
                </MenuItem>
              )}
              {results.map((assignee) => (
                <MenuItem
                  onClick={popupState.close}
                  key={assignee.user_id}
                  data-testid="assign-to-user"
                  sx={{
                    width: '100%',
                    padding: 0,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      alignSelf: 'stretch',
                      padding: 1,
                      width: '100%',
                    }}
                    onClick={() => {
                      handleAssignTask(assignee.user_id)
                    }}
                  >
                    <Avatar sx={{ marginRight: 1.5, width: 24, height: 24 }}>
                      <Typography variant="overline" color="text.primary">
                        {assignee.name
                          .split(' ')
                          .map((word) => word[0])
                          .join('')}
                      </Typography>
                    </Avatar>
                    <Typography variant="body1">{assignee.name}</Typography>
                  </Box>
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Fragment>
      )}
    </PopupState>
  )
}
