import { AddRounded } from '@mui/icons-material'
import { Chip } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useCreateTimepointSubmission } from '@features/subject/hooks/useCreateTimepointSubmission'
import { generatePathWithParams } from '@common/utils/urlUtils'
import { ROUTES } from 'src/routes'
import { SPACING } from '@common/theme/spacing'

export default function AddTimepointSubmissionChip({
  subjectId,
  timepointId,
}: {
  subjectId: string
  timepointId: string
}) {
  const { mutateAsync: createTimepointSubmission } = useCreateTimepointSubmission()
  const { studyId } = useParams<{ studyId: string }>()
  const navigate = useNavigate()

  const navigateToSubjectDetails = (timepointSubmissionId: string) => {
    const path = generatePathWithParams(ROUTES.SUBJECT_DETAILS.path, {
      studyId: studyId!,
      subjectId: subjectId!,
      timepointSubmissionId: timepointSubmissionId,
    })

    navigate(path)
  }

  const handleCreateTimepointSubmission = async () => {
    await createTimepointSubmission({
      body: {
        timepoint_id: timepointId,
      },
      path: {
        study_id: studyId!,
        subject_id: subjectId,
      },
    }).then((response) => {
      navigateToSubjectDetails(response?.timepoint_submission_id)
    })
  }

  return (
    <Chip
      data-testid="add-timepoint-chip"
      size="small"
      icon={<AddRounded data-testid="add-icon" />}
      sx={{
        backgroundColor: 'status.notStarted',
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'center',
        borderRadius: '8px',
        width: 'auto',
        '& .MuiChip-label': { padding: SPACING.noSpacing },
        paddingY: SPACING.spacingLg,
      }}
      onClick={handleCreateTimepointSubmission}
    ></Chip>
  )
}
