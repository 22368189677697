export const LIGHT_MODE = {
  primary: {
    light: '#42A4C2',
    main: '#02739A',
    dark: '#003140',
  },
  secondary: {
    light: '#F0F3F5',
    main: '#DCE3E5',
    dark: '#B0BEC5',
  },
  info: {
    light: '#31C6F7',
    main: '#00ACE5',
    dark: '#007CA6',
  },
  infoAlt: {
    light: '#E3F2FD',
    main: '#C7EFFC',
    dark: '#00ACE5',
    contrast: '#006080',
  },
  success: {
    light: '#51BA5F',
    main: '#43A04F',
    dark: '#2E7D39',
  },
  successAlt: {
    light: '#EBF5ED',
    main: '#D7F5DB',
    dark: '#43A04F',
    contrast: '#1B5E24',
  },
  warning: {
    light: '#FFCA28',
    main: '#FFB300',
    dark: '#BF6B00',
  },
  warningAlt: {
    light: '#FCF7E3',
    main: '#FFEFBF',
    dark: '#FFEFBF',
  },
  error: {
    light: '#F5737E',
    main: '#E53544',
    dark: '#AD0A18',
  },
  errorAlt: {
    light: '#FEF1F2',
    main: '#FEDADD',
    dark: '#FEDADD',
  },
  paper: {
    main: '#F0F4F5',
  },
  tooltip: {
    fill: '#37474F',
  },
  text: { primary: '#263238', secondary: '#546E7A', disabled: '#78909C', contrast: '#263238' },
  background: { default: '#fff', lightBlue: '#DFF2F7', paper: '#F0F4F5' },
  status: { inProgress: '#FFEFBF', notStarted: '#F0F3F5', complete: '#D7F5DB' },
}

export const DARK_MODE = {
  primary: {
    light: '#2186A6',
    main: '#42A4C2',
    dark: '#2186A6',
    contrast: '#263238',
  },
  secondary: {
    light: '#37474F',
    main: '#37474F',
    dark: '#78909C',
    contrast: '#F0F3F5',
  },
  info: {
    light: '#31C6F7',
    main: '#00ACE5',
    dark: '#007CA6',
    contrast: '#FFFFFF',
  },
  infoAlt: {
    light: '#006080',
    main: '#008FBF',
    dark: '#03BAF7',
    contrast: '#006080',
  },
  success: {
    light: '#98D9A0',
    main: '#7ACC85',
    dark: '#43A04F',
    contrast: '#FFFFFF',
  },
  successAlt: {
    light: '#1B5E24',
    main: '#43A04F',
    dark: '#51BA5F',
    contrast: '#1B5E24',
  },
  warning: {
    light: '#FFCA28',
    main: '#BF6B00',
    dark: '#FFB300',
    contrast: '#FFFFFF',
  },
  warningAlt: {
    light: '#994300',
    main: '#BF6B00',
    dark: '#FFC107',
    contrast: '#994300',
  },
  error: {
    light: '#F5737E',
    main: '#E53544',
    dark: '#FCB8BD',
    contrast: '#F0F3F5',
  },
  errorAlt: {
    light: '#910410',
    main: '#CC1F2D',
    dark: '#FCB8BD',
    contrast: '#910410',
  },
  paper: {
    main: '#263238',
  },
  tooltip: {
    fill: '#DCE3E5',
  },
  text: { primary: '#F0F3F5', secondary: '#B0BEC5', disabled: '#90A4AE', contrast: '#263238' },
  background: { default: '#263238', lightBlue: '#DFF2F7', paper: '#171E21' },
  status: { inProgress: '#FFEFBF', notStarted: '#90A4AE', complete: '#D7F5DB' },
}
