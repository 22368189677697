import { MenuItem } from '@mui/material'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { CompanyActionMenuProps } from '../types'
import { BaseDropdownMenu } from '@common/components/Menu/BaseDropdownMenu'

export default function CompanyActionMenu({ anchorEl, onClose, onDelete }: Readonly<CompanyActionMenuProps>) {
  return (
    <BaseDropdownMenu anchorEl={anchorEl} onClose={onClose}>
      <MenuItem onClick={onDelete}>
        <DeleteOutlineOutlinedIcon sx={{ mr: 1.5 }} />
        Delete Company
      </MenuItem>
    </BaseDropdownMenu>
  )
}
