import { useState, MouseEvent, ChangeEvent } from 'react'
import { TransformedSubjects } from '../types'

interface UseSubjectsTableActionsHandlersProps {
  onEditClick: (subjectId: string) => void
  subjects: TransformedSubjects | undefined
}

export default function useSubjectsTableActionsHandlers({ subjects }: UseSubjectsTableActionsHandlersProps) {
  const [selected, setSelected] = useState<string[]>([])
  const [selectedSubjectId, setSelectedSubjectId] = useState<string | null>(null)

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(subjects?.items.map((row) => row.id) ?? [])
    } else {
      setSelected([])
    }
  }
  const handleClick = (_event: MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id)
    const newSelected = selectedIndex === -1 ? [...selected, id] : selected.filter((selectedId) => selectedId !== id)
    setSelected(newSelected)
  }

  return {
    selected,
    selectedSubjectId,
    setSelectedSubjectId,
    handleSelectAllClick,
    handleClick,
  }
}
