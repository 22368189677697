import { Stack, Grid2 as Grid } from '@mui/material'
import { Breadcrumb } from '@common/components/Breadcrumb'
import UserTable from './management/components/UserTable'
import { USER_MANAGEMENT_BREADCRUMB_ITEMS } from './constants'
import { useEffect } from 'react'
import AddUserModal from './management/components/AddUserModal'

import { UserSchema } from '@common/config/api/client'
import { useLocation } from 'react-router-dom'
import { useUserManagementHandlers } from './hooks'
import { SPACING } from '@common/theme/spacing'
import { SIZING } from '@common/theme/sizing'
import { PageHeader } from '@common/components/PageHeader'

export default function UserManagement() {
  const location = useLocation()
  const {
    isLoading,
    isAddModalOpen,
    usersList,
    companiesMetadata,
    page,
    userListParams,
    setIsAddModalOpen,
    handlePageChange,
    handleRowsPerPageChange,
    handleSearch,
    handleRequestSort,
    handleAddUser,
    handleDeleteUser,
    handleCompanyTypeChange,
  } = useUserManagementHandlers()

  useEffect(() => {
    handleCompanyTypeChange()
  }, [location.hash, companiesMetadata?.companies, handleCompanyTypeChange])

  if (isLoading) {
    return <div>Loading...</div>
  }
  return (
    <>
      <Breadcrumb items={USER_MANAGEMENT_BREADCRUMB_ITEMS} />
      <Grid container columnSpacing={SPACING.spacingXl} sx={{ marginTop: SPACING.spacingLg }}>
        <Grid size={SIZING.gridSizeXxl}>
          <Stack spacing={SPACING.spacingXxl}>
            <PageHeader title="User management" />
            <UserTable
              companiesMetadata={companiesMetadata}
              usersList={usersList}
              onDeleteUser={handleDeleteUser}
              onAddUser={() => setIsAddModalOpen(true)}
              page={page}
              onPageChange={(event, newPage) => handlePageChange(usersList, event, newPage)}
              onRowsPerPageChange={handleRowsPerPageChange}
              onSearch={handleSearch}
              searchValue={userListParams.search ?? ''}
              orderBy={userListParams.order_by_field as keyof UserSchema}
              order={userListParams.asc_order ? 'asc' : 'desc'}
              onRequestSort={handleRequestSort}
            />
          </Stack>
        </Grid>
      </Grid>
      <AddUserModal
        companiesMetadata={companiesMetadata}
        open={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onSubmit={handleAddUser}
      />
    </>
  )
}
