import { Box } from '@mui/material'
import { styled, SxProps, Theme } from '@mui/material/styles'
import { ReactNode } from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'

import BlockContent from './BlockContent'
import FilesTree from '../FilesTree'
import { FileSystemItem } from '../../subject.types'

export type UploadMultiFileProps = {
  files?: FileSystemItem[]
  error?: boolean
  showPreview?: boolean
  sx?: SxProps<Theme>
  helperText?: ReactNode
  fileTypesAllowed: string[]
  onUpload?: VoidFunction
  onRemove: (fileIds: string[]) => void
  onRemoveAll?: VoidFunction
  data: FileSystemItem[]
} & DropzoneOptions

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  borderRadius: 16,
  border: `1px dashed ${theme.palette.secondary.dark}`,
  '&:hover': { cursor: 'pointer' },
}))

const Upload = ({ helperText, sx, data, fileTypesAllowed, onRemove, ...other }: UploadMultiFileProps) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    ...other,
  })

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
        }}
      >
        <input {...getInputProps()} />
        <BlockContent fileTypesAllowed={fileTypesAllowed} />
      </DropZoneStyle>

      {data.length ? <FilesTree onDelete={onRemove} data={data} /> : null}
      {helperText && helperText}
    </Box>
  )
}

export default Upload
