import { PaginatedResponse_StudySiteSchema_, StudySiteSchema, StudySitesMetadata } from '@common/config/api/client'
import { TransformedSites } from '.'
import { groupBy } from '@common/utils/arrayUtils'
import { TransformedPrimarySites, TransformedStudySitesMetadata } from './types'
import { SiteFormValues } from '../components/CreateSiteDialog'
import { SiteAssociationTypeEnum } from '../types'
export const transformGetSitesResponse = (data: PaginatedResponse_StudySiteSchema_ | undefined): TransformedSites => {
  return {
    items:
      data?.items.map((item) => ({
        id: item.id,
        name: item.site.name,
        pseudo_id: item.pseudo_id,
        modalities: item.modalities.map((modality) => modality.name).join(', '),
        createdAt: item.created_at
          ? new Date(item.created_at)
              .toLocaleDateString('de-DE', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
              })
              .replace(/\./g, '/')
          : null,
        updatedAt: item.updated_at
          ? new Date(item.updated_at)
              .toLocaleDateString('de-DE', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
              })
              .replace(/\./g, '/')
          : null,
        createdByUser: item.created_by_user,
      })) ?? [],
    pagination: {
      nextCursor: data?.pagination.next_cursor ?? null,
      previousCursor: data?.pagination.previous_cursor ?? null,
      limit: data?.pagination.limit ?? null,
    },
  }
}

export const transformGetPrimarySitesResponse = (
  data: PaginatedResponse_StudySiteSchema_ | undefined,
): TransformedPrimarySites => {
  return {
    primary_sites:
      data?.items
        .filter((item) => item.site_association_type === SiteAssociationTypeEnum.PRIMARY)
        .map((item) => ({
          label: `${item.pseudo_id} ${item.site.name}`,
          value: item.id,
          additional_fields: {
            site_psuedo_id: item.pseudo_id,
            secondary_sites:
              item.secondary_sites?.map((site) => ({
                label: `${site.pseudo_id} ${site.site.name}`,
                value: site.id,
              })) ?? [],
          },
        })) ?? [],
  }
}

export const transformGetStduySitesMetadataResponse = (data: StudySitesMetadata): TransformedStudySitesMetadata => {
  return {
    modalities: data.modalities.map((modality) => ({ label: modality.name, value: modality.id })),
    site_associations: groupBy(
      data.site_associations.map((site) => ({
        label: site.pseudo_id,
        value: site.id,
        additional_fields: {
          site_association_type: site.site_association_type,
        },
      })),
      'additional_fields.site_association_type',
    ),
    sites: data.sites,
  }
}

export const transformGetStudySiteByIdResponse = (data: StudySiteSchema): SiteFormValues => {
  const {
    site_association_type,
    site: { name, email, phone },
    modalities,
    ...rest
  } = data
  const site_associations =
    data.site_association_type === SiteAssociationTypeEnum.PRIMARY
      ? data.secondary_sites?.map((site) => site.id)
      : data.primary_site
        ? [data.primary_site.id]
        : []
  return {
    ...rest,
    name,
    site_associations: site_associations ?? [],
    contact_email: email ?? '',
    contact_phone: phone ?? '',
    site_association_type: site_association_type as SiteAssociationTypeEnum,
    modalities: modalities.map((modality) => modality.id),
  }
}
