import { Typography, Stack, Grid2 as Grid, Chip, Tabs, Tab } from '@mui/material'
import ProfileInformation from './details/components/ProfileInformation'
import StudyAssignments from './details/components/StudyAssignments'
import Settings from './details/components/Settings'
import { useCompaniesMetadata, useUpdateUser, useUserDetails } from './hooks'
import { useParams } from 'react-router-dom'
import { UserCreateSchema } from '@common/config/api/client'
import { toast } from 'sonner'
import Toast from '@common/components/Toast'
import { SPACING } from '@common/theme/spacing'
import { SIZING } from '@common/theme/sizing'
import { useMemo, useState } from 'react'
import EnhancedTabPanel from '@common/components/Tab/EnhancedTabPanel'

const formatDate = (date: string) => {
  return new Date(date)
    .toLocaleDateString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    })
    .replace(/\./g, '/')
}

export default function UserDetails() {
  const { userId } = useParams()
  const { data: user, isPending: isUserDetailsPending } = useUserDetails({
    userId: userId ?? '',
  })

  const updateUserMutation = useUpdateUser()
  const { data: companiesMetadata, isPending: isPendingCompaniesMetadata } = useCompaniesMetadata()
  const isLoading = isUserDetailsPending || isPendingCompaniesMetadata

  const isUserActive = useMemo(() => user?.user_status == 'ACTIVE', [user])
  const [activeTab, setActiveTab] = useState(0)
  const handleUpdateUser = async (userData: UserCreateSchema) => {
    if (!userId) return
    try {
      await updateUserMutation.mutateAsync({
        userId: user?.user_id as string,
        userData,
      })
      toast(<Toast message="User updated successfully" variant="success" />)
    } catch (error) {
      console.error('Failed to update user:', error)
      toast(<Toast message="Failed to update user. Please try again." variant="error" />)
    }
  }
  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  const tabs = [
    {
      label: 'Profile information',
      content: <ProfileInformation user={user} companiesMetadata={companiesMetadata} onUpdateUser={handleUpdateUser} />,
    },
    {
      label: 'Study Assignments',
      content: <StudyAssignments user={user} />,
    },
    {
      label: 'Settings',
      content: <Settings user={user} />,
    },
  ]

  return (
    <>
      <Grid container columnSpacing={SPACING.spacingXl}>
        <Grid size={SIZING.gridSizeXl}>
          <Stack spacing={SPACING.spacingLg}>
            <Stack>
              <Stack direction="row" spacing={SPACING.spacingXl} alignItems="center">
                <Typography variant="h4">{user?.user_name}</Typography>
                <Chip
                  label={isUserActive ? 'Active' : 'Deactivated'}
                  size="small"
                  color={isUserActive ? 'success-alt' : 'error-alt'}
                  sx={{ borderRadius: 100 }}
                />
              </Stack>
              <Stack direction="row" spacing={SPACING.minSpacing} alignItems="center">
                <Typography variant="body2" color="text.secondary">
                  User created: {user?.created_at && formatDate(user.created_at)}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  -{' '}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Created by: {user?.created_by ?? '-'}
                </Typography>
              </Stack>
            </Stack>
            <Tabs value={activeTab} onChange={handleTabChange}>
              {tabs.map((tab, index) => (
                <Tab key={index} label={tab.label} />
              ))}
            </Tabs>
            {tabs.map((tab, index) => (
              <EnhancedTabPanel value={activeTab} index={index}>
                {tab.content}
              </EnhancedTabPanel>
            ))}
          </Stack>
        </Grid>
      </Grid>
    </>
  )
}
