import React from 'react'
import { Typography } from '@mui/material'
import BaseDialog from '@common/components/BaseDialog'

interface TimepointSwitchDialogProps {
  timepointName?: string
  onClose: () => void
  onSubmit: () => void
}

const TimepointSwitchDialog: React.FC<TimepointSwitchDialogProps> = ({ timepointName, onClose, onSubmit }) => {
  return (
    <BaseDialog
      title={`You haven't finished upload for the ${timepointName}`}
      open={true}
      onClose={onClose}
      onSubmit={onSubmit}
      submitLabel="Switch Timepoint"
      cancelLabel="Cancel"
      bgcolor="transparent"
      width={600}
    >
      <Typography variant="body2">
        You will lose your upload progress if you leave the page or switch timepoints.
      </Typography>
    </BaseDialog>
  )
}

export default TimepointSwitchDialog
