import { Stack, Typography, Button, Input } from '@mui/material'
import { useMemo, useState, useRef } from 'react'
import Grid from '@mui/material/Grid2'
import CardView from '@features/study_view/card_view/pages/CardView'
import StudyTableView from '@features/study_view/Table_view/pages/StudyTableView'
import { AddRounded, FileDownload } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'src/routes'
import StudiesOverviewHeader from '../components/StudiesOverviewHeader'
import { useGlobalPermissions } from '@auth/hooks/useGlobalPermissions'
import { StudyPermission } from '@auth/permissionsEnum'
import { SPACING } from '@common/theme/spacing'
import { SIZING } from '@common/theme/sizing'
import useImportStudy from '../hooks/useImportStudy'
import { handleMutationError } from '@common/hooks/handleMutationError'
import { toast } from 'sonner'
import Toast from '@common/components/Toast'
import { queryClient } from '@common/config/api/queryClient'
import ImportStudyDialog from '../components/ImportStudyDialog'

export default function Home() {
  const [activeControl, setActiveControl] = useState(0)
  const layout = useMemo(() => (activeControl == 0 ? 'card' : 'table'), [activeControl])
  const { hasPermission } = useGlobalPermissions()
  const [importDialogOpen, setImportDialogOpen] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const importStudyMutation = useImportStudy()

  const navigate = useNavigate()

  const handleCreateStudyClick = () => {
    navigate(ROUTES.CREATE_STUDY.path)
  }

  const handleImportStudyClick = () => {
    fileInputRef.current?.click()
  }

  const handleFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files && files.length > 0) {
      setSelectedFile(files[0])
      setImportDialogOpen(true)
    }
  }

  const handleImportDialogClose = () => {
    setImportDialogOpen(false)
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
    setSelectedFile(null)
  }

  const handleImportConfirm = (nickname: string | undefined, userFacingId: string | undefined) => {
    if (selectedFile) {
      importStudyMutation.mutate(
        {
          file: selectedFile,
          nickname: nickname,
          userFacingStudyId: userFacingId,
        },
        {
          onSuccess: () => {
            setImportDialogOpen(false)
            toast(<Toast variant="success" message="Study imported successfully" />)
            queryClient.invalidateQueries({ queryKey: ['studiesHomepage'] })

            if (fileInputRef.current) {
              fileInputRef.current.value = ''
            }
            setSelectedFile(null)
          },
          onError: (error) => {
            handleMutationError(error)
          },
        },
      )
    }
  }

  return (
    <Grid container size={SIZING.gridSizeXxl}>
      <Stack spacing={SPACING.spacingXxl} width="100%">
        <Stack spacing={SPACING.spacingXxl} direction="row" justifyContent="space-between">
          <Typography variant="h4">Clinical Studies</Typography>
          {hasPermission(StudyPermission.ALL) && (
            <Stack direction="row" spacing={SPACING.buttonSpacing}>
              <Button
                variant="contained"
                sx={{ maxHeight: SIZING.buttonMaxHeight }}
                startIcon={<AddRounded />}
                onClick={handleCreateStudyClick}
              >
                Create Study
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{ maxHeight: SIZING.buttonMaxHeight }}
                startIcon={<FileDownload />}
                onClick={handleImportStudyClick}
                disabled={importStudyMutation.isPending}
              >
                Import Study
              </Button>
              <Input
                type="file"
                inputRef={fileInputRef}
                inputProps={{
                  accept: '.json',
                }}
                sx={{ display: 'none' }}
                onChange={handleFileSelected}
              />
            </Stack>
          )}
        </Stack>
        <Stack spacing={SPACING.spacingLg}>
          <StudiesOverviewHeader activeControl={activeControl} setActiveControl={setActiveControl} />
          {layout === 'card' ? <CardView /> : <StudyTableView />}
        </Stack>
      </Stack>

      {importDialogOpen && selectedFile && (
        <ImportStudyDialog
          open={importDialogOpen}
          onClose={handleImportDialogClose}
          onImport={handleImportConfirm}
          fileName={selectedFile.name}
          isImporting={importStudyMutation.isPending}
        />
      )}
    </Grid>
  )
}
