import { TableBody, TableRow, TableCell, Tooltip, Typography, Stack } from '@mui/material'
import Switch from '@mui/material/Switch'
import ChipTagStatus from './ChipTagStatus'
import { TimepointContainerSubmissionExamMetadataResponse } from '@common/config/api/client/types.gen'
import { Control, useFormContext } from 'react-hook-form'
import { SeriesDicomTagsUpdateFormValues } from '@features/qc_task_details_view/schemas/DicomTagsValidationSchema'
import { InputField } from '@features/qc_task_details_view/pages/qc/InputField'
import { useState } from 'react'
import { StudyExamSeriesDicomTagWithOverride } from './ReviewDicomTagsTable'

interface ReviewDicomTagsTableBodyProps {
  series: TimepointContainerSubmissionExamMetadataResponse['series']
  selectedSeriesIndex: number
  control: Control<SeriesDicomTagsUpdateFormValues>
  filteredTags: StudyExamSeriesDicomTagWithOverride[]
}

const getTagKey = (tagId: string, tagName: string) => `${tagId}-${tagName}`

const formatTagName = (tagId: string, tagName: string) => {
  const cleanId = tagId.replace(/[()]/g, '')
  const [part1, part2] = cleanId.split(',')

  const cleanPart1 = part1.replace(/^0+(?=\d)/, '')
  const cleanPart2 = part2.replace(/^0+(?=\w)/, '')

  return `${cleanPart1}, ${cleanPart2} ${tagName}`
}

export default function ReviewDicomTagsTableBody({
  series,
  selectedSeriesIndex,
  control,
  filteredTags,
}: ReviewDicomTagsTableBodyProps) {
  const [toggledTags, setToggledTags] = useState<Set<string>>(new Set())
  const { setValue, getValues } = useFormContext<SeriesDicomTagsUpdateFormValues>()

  function updateTagValuesAcrossSeries(tagKey: string, value: string, excludeSeriesIdx?: number) {
    series.forEach((seriesItem, seriesIdx) => {
      if (seriesIdx !== excludeSeriesIdx) {
        seriesItem.qc_series_tags.forEach((tag, tagIdx) => {
          const otherTagKey = getTagKey(tag.dicom_tag, tag.dicom_tag_name)
          if (otherTagKey === tagKey) {
            setValue(`series.${seriesIdx}.dicom_tags.${tagIdx}.new_value`, value)
          }
        })
      }
    })
  }

  function handleToggle(tagKey: string, checked: boolean, tagIndex: number) {
    if (checked) {
      setToggledTags((prev) => {
        const newToggledTags = new Set(prev)
        newToggledTags.add(tagKey)
        return newToggledTags
      })

      const currentValue = getValues(`series.${selectedSeriesIndex}.dicom_tags.${tagIndex}.new_value`)
      updateTagValuesAcrossSeries(tagKey, currentValue, selectedSeriesIndex)
    } else {
      setToggledTags((prev) => {
        const newToggledTags = new Set(prev)
        newToggledTags.delete(tagKey)
        return newToggledTags
      })
      updateTagValuesAcrossSeries(tagKey, '', selectedSeriesIndex)
    }
  }

  function handleTagValueChange(value: string, tagKey: string) {
    if (toggledTags.has(tagKey)) {
      updateTagValuesAcrossSeries(tagKey, value, selectedSeriesIndex)
    }
  }

  return (
    <TableBody data-testid="table-body">
      {filteredTags.length > 0 ? (
        filteredTags.map((tag, index) => {
          const tagKey = getTagKey(tag.dicom_tag, tag.dicom_tag_name)
          const tagName = formatTagName(tag.dicom_tag, tag.dicom_tag_name)

          const actualTagIndex = series[selectedSeriesIndex]?.qc_series_tags.findIndex(
            (originalTag) =>
              originalTag.dicom_tag === tag.dicom_tag && originalTag.dicom_tag_name === tag.dicom_tag_name,
          )
          const isLastTag = index === filteredTags.length - 1
          const isOdd = index % 2 === 0
          if (actualTagIndex === -1) return null

          return (
            <TableRow key={`${selectedSeriesIndex}-${tagKey}`} data-testid="table-row">
              <TableCell
                align="left"
                data-testid="cell-id-name"
                sx={{
                  padding: 2,
                  backgroundColor: isOdd ? '#F0F4F5' : 'none',
                  borderBottom: isLastTag ? 'none' : '1px solid #DCE3E5',
                }}
              >
                <Tooltip title={tagName} arrow placement="top">
                  <Typography variant="body2" noWrap>
                    {tagName}
                  </Typography>
                </Tooltip>
              </TableCell>
              <TableCell
                align="left"
                data-testid="cell-status"
                sx={{
                  backgroundColor: isOdd ? '#F0F4F5' : 'none',
                  borderBottom: isLastTag ? 'none' : '1px solid #DCE3E5',
                }}
              >
                <ChipTagStatus data={tag.tag_deid_strategy} size="small" />
              </TableCell>
              <TableCell
                align="left"
                data-testid="cell-currentValue"
                sx={{
                  backgroundColor: isOdd ? '#F0F4F5' : 'none',
                  borderBottom: isLastTag ? 'none' : '1px solid #DCE3E5',
                }}
              >
                <Tooltip title={tag.original_value} arrow placement="top">
                  <Typography variant="body2" noWrap>
                    {tag.original_value}
                  </Typography>
                </Tooltip>
              </TableCell>
              <TableCell
                align="left"
                data-testid="cell-newValue"
                sx={{
                  backgroundColor: isOdd ? '#F0F4F5' : 'none',
                  borderBottom: isLastTag ? 'none' : '1px solid #DCE3E5',
                }}
              >
                {tag.allow_iqm_override && (
                  <InputField
                    control={control}
                    name={`series.${selectedSeriesIndex}.dicom_tags.${actualTagIndex}.new_value`}
                    variant="outlined"
                    size="small"
                    placeholder="New value"
                    fullWidth
                    onCustomChange={(value) => handleTagValueChange(value, tagKey)}
                    onKeyDown={(e) => e.stopPropagation()}
                  />
                )}
              </TableCell>
              <TableCell
                align="left"
                data-testid="cell-applyToAll"
                sx={{
                  backgroundColor: isOdd ? '#F0F4F5' : 'none',
                  borderBottom: isLastTag ? 'none' : '1px solid #DCE3E5',
                }}
              >
                {tag.allow_iqm_override && tag.apply_to_all_series && (
                  <Switch
                    checked={toggledTags.has(tagKey)}
                    onChange={(e) => handleToggle(tagKey, e.target.checked, actualTagIndex)}
                    color="primary"
                    size="small"
                    sx={{ transform: 'scale(0.85)' }}
                  />
                )}
              </TableCell>
            </TableRow>
          )
        })
      ) : (
        <TableRow>
          <TableCell colSpan={5}>
            <Stack spacing={1} alignItems="center" height={500} justifyContent="center">
              <Typography variant="h5" color="text.primary" align="center">
                No matching tags found
              </Typography>
              <Typography variant="body1" color="text.secondary" align="center">
                Please ensure the tag name or ID is correct, or try a different one
              </Typography>
            </Stack>
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  )
}
