import { Divider, Stack, Typography } from '@mui/material'
import ExamDetailPopupMenuList from './ExamDetailPopupMenuList'
import {
  QcTimepointContainerSubmission,
  TimepointContainerSubmissionExamMetadataResponse,
} from '@common/config/api/client'
import { SPACING } from '@common/theme/spacing'

export default function ExamDetails({
  timepointSubmissionId,
  container,
  exam,
}: {
  timepointSubmissionId: string
  container: QcTimepointContainerSubmission
  exam: TimepointContainerSubmissionExamMetadataResponse
}) {
  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString('en-GB')
  }

  return (
    <Stack
      direction="row"
      spacing={SPACING.examDetailsSpacing}
      alignItems="center"
      justifyContent="space-between"
      paddingX={SPACING.examDetailsSpacing}
      paddingY={SPACING.minSpacing}
    >
      <Stack spacing={SPACING.examDetailsTypographySpacing}>
        <Typography
          sx={{
            color: 'text.secondary',
          }}
          variant="subtitle2"
        >
          Modality
        </Typography>
        <Typography variant="body2">{container.modality}</Typography>
      </Stack>
      <Stack spacing={SPACING.examDetailsTypographySpacing}>
        <Typography
          sx={{
            color: 'text.secondary',
          }}
          variant="subtitle2"
        >
          Study description
        </Typography>
        <Typography variant="body2">{exam.study_description}</Typography>
      </Stack>
      <Stack spacing={SPACING.examDetailsTypographySpacing}>
        <Typography
          sx={{
            color: 'text.secondary',
          }}
          variant="subtitle2"
        >
          Date
        </Typography>
        <Typography variant="body2">{formatDate(exam.acquisition_date || '')}</Typography>
      </Stack>
      <Stack spacing={SPACING.examDetailsTypographySpacing}>
        <Typography
          sx={{
            color: 'text.secondary',
          }}
          variant="subtitle2"
        >
          № of series
        </Typography>
        <Typography variant="body2">{exam.series.length}</Typography>
      </Stack>
      <Stack spacing={SPACING.examDetailsTypographySpacing}>
        <Typography
          sx={{
            color: 'text.secondary',
          }}
          variant="subtitle2"
        >
          Criteria check
        </Typography>
        <Typography variant="body2">{container.criteria_check} issues</Typography>
      </Stack>
      <Stack direction="row" spacing={SPACING.minSpacing} alignItems="center">
        <Divider orientation="vertical" flexItem />
        <ExamDetailPopupMenuList timepointSubmissionId={timepointSubmissionId} container={container} exam={exam} />
      </Stack>
    </Stack>
  )
}
