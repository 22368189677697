import { useMemo, useState } from 'react'
import { TableBody, TableRow, TableCell } from '@mui/material'
import { EnhancedTableBodyProps } from '@reader_task_list_view/reader_task_list.types'
import { calculateTimeSpent, formatTaskCreationDate, getComparator } from '@reader_task_list_view/utils'
import TaskStateChip from './TaskStateChip'
import TaskTypeChip from './TaskTypeChip'
import ReopenModal from './ReopenModal'
import { useParams } from 'react-router-dom'
import { composeOhifReaderLink } from '@common/utils/ohifUtils'
import ReaderSummaryModal from '@features/reader_summary/pages/pages/ReaderSummaryModal'

export default function EnhancedTableBodyCompletedTasks({
  rows,
  order,
  orderBy,
  authedUserToken,
  authedUserId,
}: EnhancedTableBodyProps) {
  const visibleRows = useMemo(() => [...rows].sort(getComparator(order, orderBy)), [order, orderBy, rows])
  const { studyId } = useParams()
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedTaskId, setSelectedTaskId] = useState<string | null>(null)

  const openInNewTab = (url: string, taskId: string) => {
    window.open(url, '_blank', 'noopener,noreferrer')
    setSelectedTaskId(taskId)
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
    setSelectedTaskId(null)
  }

  const handleRowClick = async (task_ids: string[]) => {
    if (authedUserId) {
      openInNewTab(composeOhifReaderLink(task_ids[0], studyId!, authedUserToken!), task_ids[0])
    }
  }

  return (
    <>
      <TableBody>
        {visibleRows.map((row) => {
          return (
            <TableRow
              tabIndex={-1}
              hover
              key={row.task_id}
              onClick={() => {
                handleRowClick([row.task_id])
              }}
              sx={{
                cursor: 'pointer',
                borderBottom: '1px solid rgba(220, 227, 229, 1)',
              }}
            >
              <TableCell align="left" data-testid="task-pseudo-id-cell">
                {row.task_pseudo_id}
              </TableCell>
              <TableCell align="left" data-testid="blind-code-cell">
                {row.blind_code}
              </TableCell>
              <TableCell align="left" data-testid="timepoint-name-cell">
                {row.timepoint_pseudo_name}
              </TableCell>
              <TableCell align="left" data-testid="task-type-cell">
                <TaskTypeChip taskType={row.task_type} />
              </TableCell>
              <TableCell align="left" data-testid="priority-cell">
                {row.priority}
              </TableCell>
              <TableCell align="left" data-testid="created-at-cell">
                {formatTaskCreationDate(row.created_at)}
              </TableCell>
              <TableCell align="left" data-testid="completed-date-cell">
                {formatTaskCreationDate(row.completed_date ?? '')}
              </TableCell>
              <TableCell align="left" data-testid="time-spent-cell">
                {calculateTimeSpent(row.created_at, row.completed_date ?? undefined)}
              </TableCell>
              <TableCell align="left" data-testid="status-cell">
                <TaskStateChip taskState={row.status} />
              </TableCell>
              <TableCell align="right" data-testid="reopen-cell">
                <ReopenModal read_task_id={row.task_id} read_pseudo_id={row.task_pseudo_id} study_id={studyId!} />
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>

      {selectedTaskId && <ReaderSummaryModal open={modalOpen} onClose={handleCloseModal} taskId={selectedTaskId} />}
    </>
  )
}
