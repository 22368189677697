import { NOVU_API_URL, NOVU_APPLICATION_ID, NOVU_WS_URL } from '@common/constants/novu'
import { Inbox } from '@novu/react'
import { useNavigate } from 'react-router-dom'

export interface NotificationsProps {
  subscriberId: string
}

export function Notifications({ subscriberId }: NotificationsProps) {
  const navigate = useNavigate()

  return (
    <Inbox
      applicationIdentifier={NOVU_APPLICATION_ID}
      subscriberId={subscriberId}
      routerPush={(path: string) => navigate(path)}
      backendUrl={NOVU_API_URL}
      socketUrl={NOVU_WS_URL}
    />
  )
}
