import { Chip } from '@mui/material'
import { TASK_STATE_COLOR, TASK_STATE_TEXT_COLOR } from './TaskStateColor'
import { QcTaskState } from '@common/config/api/client'
import { AccessTimeFilledRounded } from '@mui/icons-material'
import { SPACING } from '@common/theme/spacing'

const formatTaskStateText = (taskState: string) => {
  const splitTaskState = taskState.split('_')
  const formatedTaskState = splitTaskState.map((word, index) => {
    if (word === 'QC') {
      return word
    } else if (index === 0) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    } else return word.toLowerCase()
  })
  return formatedTaskState.join(' ')
}

const isPending = (taskState: QcTaskState) => {
  return taskState === 'RESET_PENDING_APPROVAL'
}

const removePending = (taskState: QcTaskState) => {
  return taskState.replace('_PENDING_APPROVAL', '')
}

export default function TaskStateChip({ taskState }: { taskState: QcTaskState }) {
  const state = isPending(taskState) ? removePending(taskState) : taskState

  return (
    <Chip
      data-testid="task-status-chip"
      label={formatTaskStateText(state)}
      size="small"
      deleteIcon={isPending(taskState) ? <AccessTimeFilledRounded data-testid="task-status-chip-icon" /> : undefined}
      onDelete={isPending(taskState) ? () => {} : undefined}
      sx={{
        '& .MuiChip-deleteIcon': {
          color: TASK_STATE_TEXT_COLOR[taskState],
        },
        backgroundColor: TASK_STATE_COLOR[taskState],
        color: TASK_STATE_TEXT_COLOR[taskState],
        padding: SPACING.spacingXs,
        alignItems: 'center',
      }}
    ></Chip>
  )
}
