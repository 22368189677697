import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
  Divider,
  MenuProps,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useState, MouseEvent, useMemo, useEffect } from 'react'
import { SIZING } from '@common/theme/sizing'
import { ExitToAppRounded, SettingsOutlined } from '@mui/icons-material'
import { CURRENT_ENV, Environment, ENVIRONMENT_CHANGE_ALLOWED_ROLES } from '@common/constants/environment'
import EnvironmentSelector from '../EnvironmentSelector'
import { redirectToEnvironment } from '@common/utils/environment'
import { SPACING } from '@common/theme/spacing'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'src/routes'
import useUserProfile from '@features/user_management/hooks/useUserProfile'

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: SPACING.borderRadiusSm,
    marginTop: theme.spacing(1),
    width: SIZING.profileMenuWidth,
    color: theme.palette.text.primary,
    '& .MuiMenu-list': {
      padding: '0',
    },
  },
}))

interface ProfileMenuProps {
  onSignOut: () => void
}

export default function ProfileMenu({ onSignOut }: ProfileMenuProps) {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)
  const [currentEnv, setCurrentEnv] = useState<Environment>(CURRENT_ENV)
  const { data: userProfile, isLoading, error } = useUserProfile()
  const navigate = useNavigate()

  useEffect(() => {
    setCurrentEnv(CURRENT_ENV)
  }, [])

  const userInitials = useMemo(() => {
    if (!userProfile?.name) return 'U'

    const nameParts = userProfile.name
      .trim()
      .split(' ')
      .filter((part) => part.length > 0)

    if (nameParts.length === 0) return 'U'

    if (nameParts.length >= 2) {
      return `${nameParts[0][0].toUpperCase()}${nameParts[nameParts.length - 1][0].toUpperCase()}`
    } else {
      return nameParts[0][0].toUpperCase()
    }
  }, [userProfile?.name])

  const canChangeEnvironment = useMemo(() => {
    if (!userProfile?.role) return false

    return ENVIRONMENT_CHANGE_ALLOWED_ROLES.includes(userProfile.role.name)
  }, [userProfile?.role])

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleLogout = () => {
    handleCloseUserMenu()
    onSignOut()
  }

  const handleEnvironmentChange = (env: Environment) => {
    handleCloseUserMenu()

    if (env !== currentEnv) {
      setCurrentEnv(env)
      redirectToEnvironment(env)
    }
  }

  if (isLoading) {
    return <div>Loading...</div>
  }
  if (error) {
    return <div>Error: {error.message}</div>
  }
  return (
    <>
      <IconButton
        onClick={handleOpenUserMenu}
        sx={{ p: 0, ml: 2 }}
        aria-controls={anchorElUser ? 'profile-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={anchorElUser ? 'true' : undefined}
      >
        <Avatar sx={{ width: SIZING.avatarSize, height: SIZING.avatarSize, backgroundColor: 'secondary.main' }}>
          <Typography color="textPrimary">{userInitials}</Typography>
        </Avatar>
      </IconButton>

      <StyledMenu
        id="profile-menu"
        anchorEl={anchorElUser}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        onClick={(e) => e.stopPropagation()}
      >
        <Stack direction="column" alignItems="center" sx={{ p: SPACING.sectionInnerSpacing }}>
          <Avatar
            sx={{
              width: SIZING.avatarSize,
              height: SIZING.avatarSize,
              backgroundColor: 'secondary.main',
              mb: 1,
            }}
          >
            <Typography color="textPrimary">{userInitials}</Typography>
          </Avatar>
          <Typography variant="subtitle2" color="textPrimary">
            {userProfile?.name}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {userProfile?.role.name}
          </Typography>
        </Stack>
        <Divider sx={{ my: SPACING.MenuDividerMarginY }} />

        <MenuItem
          onClick={() => {
            handleCloseUserMenu()
            navigate(ROUTES.PROFILE_SETTINGS.path)
          }}
          disableRipple
        >
          <ListItemIcon>
            <SettingsOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText sx={{ color: 'text.primary' }}>Settings</ListItemText>
        </MenuItem>

        <MenuItem onClick={handleLogout} disableRipple>
          <ListItemIcon>
            <ExitToAppRounded fontSize="small" />
          </ListItemIcon>
          <ListItemText sx={{ color: 'text.primary' }}>Logout</ListItemText>
        </MenuItem>

        {canChangeEnvironment && (
          <>
            <Divider sx={{ my: SPACING.MenuDividerMarginY }} />
            <EnvironmentSelector currentEnv={currentEnv} onEnvironmentChange={handleEnvironmentChange} />
          </>
        )}
      </StyledMenu>
    </>
  )
}
