import { Stack, Table, TableCell, TableRow, Typography, useTheme } from '@mui/material'
import { ReadTaskSummaryData } from '@common/config/api/client'
import { TableScrollSize } from '@features/reader_summary/pages/summary.types'
import { PersonOutlineRounded } from '@mui/icons-material'

export default function ReaderTableRow({
  timepoints,
  tableScrollSize,
}: {
  timepoints: ReadTaskSummaryData['timepoints']
  tableScrollSize: TableScrollSize
}) {
  const theme = useTheme()

  return (
    <TableRow
      data-testid="subject-table-row"
      hover
      sx={{
        '&:hover': {
          '& > td': {
            backgroundColor: (theme) => theme.palette.action.hover,
          },
        },
        bgcolor: 'background.paper',
      }}
    >
      <TableCell
        data-testid="pseudo-id-cell"
        sx={{
          width: tableScrollSize.columnWidth,
          minWidth: tableScrollSize.columnWidth,
          position: 'sticky',
          left: 0,
          background: 'inherit',
          zIndex: 3,
          paddingLeft: 3,
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          sx={{
            color: 'text.primary',
          }}
        >
          <PersonOutlineRounded />
          <Typography
            variant="button"
            sx={{
              color: 'text.primary',
            }}
          >
            Reader
          </Typography>
        </Stack>
      </TableCell>
      {Array.from({ length: tableScrollSize.emptyCellsCount }).map((_, i) => (
        <TableCell
          key={`empty-${i}`}
          sx={{
            width: tableScrollSize.columnWidth,
            minWidth: tableScrollSize.columnWidth,

            borderBottom: `1px solid ${theme.palette.divider}`,
          }}
        />
      ))}
      {timepoints.map((_, i) => {
        let stickyStyles = {}

        if (i === 0) {
          stickyStyles = {
            position: 'sticky',
            left: tableScrollSize.firstTimepointLeftOffset,
            background: 'inherit',
            zIndex: 2,
          }
        }

        if (i === timepoints.length - 1) {
          stickyStyles = {
            position: 'sticky',
            right: tableScrollSize.checkboxWidth,
            background: 'inherit',
            zIndex: 2,
            borderLeft: `1px solid ${theme.palette.primary.main}`,
          }
        }

        return (
          <TableCell
            key={`timepoint-${i}`}
            sx={{
              padding: 0,
              width: tableScrollSize.columnWidth,
              minWidth: tableScrollSize.columnWidth,
              borderBottom: `1px solid ${theme.palette.divider}`,
              borderLeft: `1px solid ${theme.palette.divider}`,
              ...stickyStyles,
            }}
          >
            <Table
              sx={{
                padding: 0,
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    width: tableScrollSize.columnWidth / 2,
                    maxWidth: tableScrollSize.columnWidth / 2,
                  }}
                >
                  <Typography variant="body2" sx={{ color: 'text.primary' }}>
                    I
                  </Typography>
                </TableCell>

                <TableCell
                  align="right"
                  sx={{
                    width: tableScrollSize.columnWidth / 2,
                    maxWidth: tableScrollSize.columnWidth / 2,
                    borderLeft: `1px solid ${theme.palette.divider}`,
                  }}
                >
                  <Typography variant="body2" sx={{ color: 'text.primary' }}>
                    II
                  </Typography>
                </TableCell>
              </TableRow>
            </Table>
          </TableCell>
        )
      })}
      <TableCell
        padding="checkbox"
        data-testid="expand-cell"
        sx={{
          width: tableScrollSize.checkboxWidth,
          minWidth: tableScrollSize.checkboxWidth,
          position: 'sticky',
          right: 0,
          background: 'inherit',
          zIndex: 3,
          borderBottom: `1px solid ${theme.palette.divider}`,
          borderLeft: `1px solid ${theme.palette.primary.main}`,
        }}
      />
    </TableRow>
  )
}
