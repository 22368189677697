import { IconButton, TableCell, TableRow } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import QueryStatusChip from './QueryStatusChip'
import SubjectStatusChip from './SubjectStatusChip'
import { TimepointSubject } from '@common/config/api/client'
import { generatePathWithParams } from '@common/utils/urlUtils'
import { ROUTES } from 'src/routes'
import { useNavigate, useParams } from 'react-router-dom'

export default function SubjectTableRow({
  subject,
  expanded,
  setExpanded,
}: {
  subject: TimepointSubject
  expanded: boolean
  setExpanded: (expanded: boolean) => void
}) {
  const { studyId } = useParams<{ studyId: string }>()
  const navigate = useNavigate()

  const navigateToSubjectDetails = () => {
    const timepointSubmissionId = subject.study_timepoints?.[0]?.timepoint_submissions?.[0]?.timepoint_submission_id
    const path = generatePathWithParams(ROUTES.SUBJECT_DETAILS.path, {
      studyId: studyId!,
      subjectId: subject.subject_id,
      timepointSubmissionId: timepointSubmissionId,
    })
    navigate(path)
  }

  return (
    <TableRow
      data-testid="subject-table-row"
      hover
      onClick={navigateToSubjectDetails}
      sx={{
        '&:hover': {
          '& > td': {
            backgroundColor: (theme) => theme.palette.action.hover,
          },
        },
      }}
    >
      <TableCell
        padding="checkbox"
        sx={{
          position: 'sticky',
          left: 0,
          bgcolor: 'background.default',
        }}
        data-testid="expand-cell"
      >
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation()
            setExpanded(!expanded)
          }}
          data-testid="expand-button"
        >
          {expanded ? (
            <KeyboardArrowUpIcon data-testid="icon-expanded" />
          ) : (
            <KeyboardArrowDownIcon data-testid="icon-collapsed" />
          )}
        </IconButton>
      </TableCell>
      <TableCell
        sx={{
          position: 'sticky',
          left: 48,
          bgcolor: 'background.default',
        }}
        data-testid="pseudo-id-cell"
      >
        {subject.pseudo_id}
      </TableCell>
      <TableCell
        sx={{
          position: 'sticky',
          left: 218,
          bgcolor: 'background.default',
        }}
        data-testid="site-id-cell"
      >
        {subject.study_site_pseudo_id}
      </TableCell>
      <TableCell
        sx={{
          position: 'sticky',
          left: 388,
          bgcolor: 'background.default',
        }}
        data-testid="status-chip-cell"
      >
        <SubjectStatusChip subjectStatus={subject.status} size="small" />
      </TableCell>
      <TableCell
        sx={{
          position: 'sticky',
          left: 558,
          bgcolor: 'background.default',
        }}
        data-testid="query-chip-cell"
      >
        <QueryStatusChip queryStatus="none" />
      </TableCell>
      {subject.study_timepoints?.map((studyTimepoint) => (
        <TableCell key={studyTimepoint.timepoint_id} data-testid={`timepoint-cell-${studyTimepoint.timepoint_id}`}>
          {studyTimepoint.timepoint_submissions?.length} Timepoints
        </TableCell>
      ))}
    </TableRow>
  )
}
