import {
  getPrimaryReadersReadTasksPrimaryReadersGet,
  GetPrimaryReadersReadTasksPrimaryReadersGetData,
} from '@common/config/api/client'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

export const useUserList = (
  queryKey: string[],
  queryParams: GetPrimaryReadersReadTasksPrimaryReadersGetData['query'],
) => {
  return useQuery({
    queryKey: queryKey,
    queryFn: () =>
      getPrimaryReadersReadTasksPrimaryReadersGet({
        query: queryParams,
      }),
    placeholderData: keepPreviousData,
  })
}
