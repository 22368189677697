import { Divider, Paper, Stack } from '@mui/material'
import ExamDetails from './ExamDetails'
import { Fragment } from 'react/jsx-runtime'
import { QcTimepointContainerSubmission } from '@common/config/api/client'
import { SPACING } from '@common/theme/spacing'

export default function ExamDetailsList({
  timepointSubmissionId,
  container,
}: {
  timepointSubmissionId: string
  container: QcTimepointContainerSubmission
}) {
  return (
    <Paper
      sx={{
        borderRadius: SPACING.borderRadiusXxl,
        bgcolor: 'paper.main',
      }}
    >
      <Stack spacing={SPACING.examDetailsListSpacing}>
        {container.exam_metadata.map((exam, index) => {
          return (
            <Fragment key={index}>
              {index !== 0 && <Divider />}
              <ExamDetails timepointSubmissionId={timepointSubmissionId} exam={exam} container={container} />
            </Fragment>
          )
        })}
      </Stack>
    </Paper>
  )
}
