import { z } from 'zod'

export const CompanyUserCreateSchema = z.object({
  role_id: z.string().uuid('Please enter a valid role name').min(1, { message: 'Please enter a valid role name' }),
  company_id: z
    .string()
    .uuid('Please enter a valid company name')
    .min(1, { message: 'Please enter a valid company name' }),
  company_type: z.enum(['INTERNAL', 'SPONSOR', 'RADIOLOGY_CENTER', 'INDEPENDENT_READER', 'SITE']).nullable().optional(),
  name: z.string().min(1, { message: 'Please enter a valid name' }).max(255, { message: 'Name is too long' }),
  email: z.string().email().min(1, { message: 'Please enter a valid email' }),
  phone: z.string().nullable(),
  email_verified: z.boolean().default(false),
})

export const ProfileSettingsSchema = z.object({
  name: z.string().min(1, { message: 'Please enter a valid name' }).max(255, { message: 'Name is too long' }),
})

export const UserAssignmentSchema = z.object({
  user_id: z.string().uuid('Please select a valid user').min(1, { message: 'User is required' }),
  role_id: z.string().uuid('Please select a valid role').min(1, { message: 'Role is required' }),
  company_id: z.string().uuid('Please select a valid company').min(1, { message: 'Company is required' }),
  company_type: z.enum(['INTERNAL', 'SPONSOR', 'RADIOLOGY_CENTER', 'INDEPENDENT_READER', 'SITE']).nullable().optional(),
  // These fields are for UI state and not sent to the server
  company_name: z.string().optional(),
  isCollapsed: z.boolean().optional().default(false),
  userName: z.string().optional(),
  roleName: z.string().optional(),
  companyName: z.string().optional(),
  companyTypeName: z.string().optional(),
})

export const AssignUserFormSchema = z.object({
  users: z.array(UserAssignmentSchema),
})
