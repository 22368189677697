import { SPACING } from '@common/theme/spacing'
import { Theme } from '@mui/material/styles'

export function ButtonOverrides(theme: Theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: theme.spacing(SPACING.borderRadiusSm),
        },
        textSecondary: {
          color: theme.palette.secondary.contrastText,
        },
        outlinedSecondary: {
          color: theme.palette.secondary.contrastText,
          borderColor: theme.palette.secondary.main,
          '&:hover': {
            borderColor: theme.palette.secondary.dark,
            backgroundColor: theme.palette.secondary.light,
          },
        },
      },
    },
  }
}
