import { useParams } from 'react-router-dom'
import {
  FilterReportTypeSchema,
  QCReportType,
  ReportTypeSchema,
  TimepointEFormReportType,
  GeneralReportType,
} from '@common/config/api/client'
import useGetReports from './useGetReports'
import useDownloadReport, { ReportDownloadType } from './useDownloadReport'

export default function useReportActions(reportType: FilterReportTypeSchema) {
  const { studyId } = useParams()
  const { data, isLoading } = useGetReports(studyId!, reportType)
  const downloadMutation = useDownloadReport()

  const handleDownload = (
    reportSubtype: QCReportType | TimepointEFormReportType | GeneralReportType,
    reportDownloadType: ReportTypeSchema,
  ) => {
    let downloadType: ReportDownloadType

    switch (reportDownloadType) {
      case 'QC':
        downloadType = { type: 'QC', reportType: reportSubtype as QCReportType }
        break
      case 'TIMEPOINT_EFORM':
        downloadType = { type: 'TIMEPOINT_EFORM', reportType: reportSubtype as TimepointEFormReportType }
        break
      case 'GENERAL':
        downloadType = { type: 'GENERAL', reportType: reportSubtype as GeneralReportType }
        break
      default:
        downloadType = { type: 'TIMEPOINT_EFORM', reportType: reportSubtype as TimepointEFormReportType }
        break
    }

    downloadMutation.mutate({
      studyId: studyId!,
      reportDownloadType: downloadType,
    })
  }

  return {
    reports: data?.reports ?? [],
    isLoading,
    handleDownload,
    isDownloading: downloadMutation.isPending,
  }
}
