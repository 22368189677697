import { Paper, Stack, Typography } from '@mui/material'
import EFormPopupMenuList from './EFormPopupMenuList'
import { QcTaskTimepointSchema } from '@common/config/api/client'
import { getPrimarySitePseudoId } from '@common/utils/getPrimarySitePseudoID'
import { SPACING } from '@common/theme/spacing'

export default function TimepointDetails({ qcTask }: { qcTask: QcTaskTimepointSchema }) {
  return (
    <Paper
      sx={{
        padding: SPACING.spacingXl,
        borderRadius: SPACING.borderRadiusXxl,
      }}
    >
      <Stack spacing={SPACING.spacingXxl}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Timepoint details</Typography>
          <EFormPopupMenuList />
        </Stack>
        <Stack spacing={SPACING.spacingXxl} direction="row" alignItems="center" justifyContent="start">
          <Stack spacing={SPACING.spacingXs}>
            <Typography variant="subtitle2">Blind Code</Typography>
            <Typography variant="body1">{qcTask.task_pseudo_id}</Typography>
          </Stack>
          <Stack spacing={SPACING.spacingXs}>
            <Typography variant="subtitle2">Subject ID</Typography>
            <Typography variant="body1">
              {getPrimarySitePseudoId(qcTask.sites)}-{qcTask.subject.subject_pseudo_id}
            </Typography>
          </Stack>
          <Stack spacing={SPACING.spacingXs}>
            <Typography variant="subtitle2">Timepoint</Typography>
            <Typography variant="body1">{qcTask.timepoint_requirement.name}</Typography>
          </Stack>
          <Stack spacing={SPACING.spacingXs}>
            <Typography variant="subtitle2">Study ID</Typography>
            <Typography variant="body1">21607</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  )
}
