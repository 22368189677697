import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { CompaniesParams } from '../types'
import { TransformedCompanyMetadata } from './useCompanyMetadata'

interface UseCompanyTypeNavigationProps {
  companyMetadata?: TransformedCompanyMetadata
  companiesParams: CompaniesParams
  setCompaniesParams: React.Dispatch<React.SetStateAction<CompaniesParams>>
}

export default function useCompanyTypeNavigation({
  companyMetadata,
  companiesParams,
  setCompaniesParams,
}: UseCompanyTypeNavigationProps) {
  const location = useLocation()

  // Set initial company type when metadata loads
  useEffect(() => {
    if (companyMetadata?.companyTypeFilter.length && !companiesParams.company_type) {
      setCompaniesParams((prev) => ({
        ...prev,
        company_type: companyMetadata.companyTypeFilter[0].id,
      }))
    }
  }, [companyMetadata?.companyTypes, companiesParams.company_type, setCompaniesParams])

  // Update company type based on URL hash
  useEffect(() => {
    if (companyMetadata?.companyTypeFilter) {
      const hash = location.hash.slice(1)
      const matchingType = hash
        ? companyMetadata.companyTypeFilter.find((type) => type.name === hash)
        : companyMetadata.companyTypeFilter[0]

      if (matchingType) {
        setCompaniesParams((prev) => ({
          ...prev,
          company_type: matchingType.id,
        }))
        if (!hash) {
          window.location.hash = matchingType.name
        }
      }
    }
  }, [location.hash, companyMetadata?.companyTypeFilter, setCompaniesParams])
}
