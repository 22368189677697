import { useCallback, useState } from 'react'
import { UserListParams } from '../types'
import { HTTPValidationError, UserCompanyTypeFilter, UserCreateSchema } from '@common/config/api/client'
import { toast } from 'sonner'
import Toast from '@common/components/Toast'
import { AssignUserFormData } from '../management/components/AssignUserModal'
import { useUsersList, useAssignUsersToStudy, useAddUser, useUnassignUserFromStudy } from '.'
import { handleMutationError } from '@common/hooks/handleMutationError'
import { AxiosError } from 'axios'
import useStudyUsersMetadata from './useStudyUsersMetadata'
import { useCompaniesMetadata } from '@features/user_management/hooks'

export default function useAccountManagement(studyId: string) {
  const [page, setPage] = useState(0)
  const [addMenuAnchor, setAddMenuAnchor] = useState<HTMLElement | null>(null)
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false)
  const [userListParams, setUserListParams] = useState<UserListParams>({
    user_type: 'all',
    search: undefined,
    limit: 50,
    next_cursor: undefined,
    previous_cursor: undefined,
    order_by_field: 'user_name',
    asc_order: true,
  })

  const { data: usersList, isPending: isPendingUsers } = useUsersList(studyId, userListParams)
  const { data: studyUsersMetadata, isPending: isPendingStudyUsersMetadata } = useStudyUsersMetadata()
  const { data: companiesMetadata, isPending: isPendingCompaniesMetadata } = useCompaniesMetadata()
  const assignUsersMutation = useAssignUsersToStudy()
  const addUserMutation = useAddUser()
  const unassignUserMutation = useUnassignUserFromStudy()

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAddMenuAnchor(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAddMenuAnchor(null)
  }

  const handleUserTypeSelect = (userType: string) => {
    if (userType === 'assign') {
      setIsAssignModalOpen(true)
    } else if (userType === 'create') {
      setIsAddModalOpen(true)
    }
    handleCloseUserMenu()
  }

  const handleAddUser = async (data: UserCreateSchema) => {
    try {
      await addUserMutation.mutateAsync({
        studyId,
        userData: data,
      })
      setIsAddModalOpen(false)
      toast(<Toast message="User created successfully." variant="success" />)
    } catch (error) {
      handleMutationError(error as AxiosError<HTTPValidationError>)
    }
  }

  const handleAssignUser = async (data: AssignUserFormData) => {
    try {
      await assignUsersMutation.mutateAsync({
        studyId,
        assignments: { users: data.users },
      })
      setIsAssignModalOpen(false)
      toast(<Toast message="Users assigned successfully." variant="success" />)
    } catch (error) {
      handleMutationError(error as AxiosError<HTTPValidationError>)
    }
  }

  const handleDeleteUser = async (userId: string) => {
    try {
      await unassignUserMutation.mutateAsync({
        studyId,
        userId,
      })
      toast(<Toast message="User unassigned successfully" variant="success" />)
    } catch (error) {
      handleMutationError(error as AxiosError<HTTPValidationError>)
    }
  }

  const handleSearch = (value: string) => {
    setUserListParams((prev) => ({
      ...prev,
      search: value || undefined,
      next_cursor: null,
      previous_cursor: null,
    }))
    setPage(0)
  }

  const handlePageChange = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    const isNextPage = newPage > page
    const cursor = isNextPage ? usersList?.pagination.next_cursor : usersList?.pagination.previous_cursor

    if (cursor) {
      setUserListParams((prev) => ({
        ...prev,
        next_cursor: isNextPage ? cursor : null,
        previous_cursor: isNextPage ? null : cursor,
      }))
      setPage(isNextPage ? page + 1 : page - 1)
    }
  }

  const handleRequestSort = (_event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = userListParams.order_by_field === property && userListParams.asc_order
    setUserListParams((prev) => ({
      ...prev,
      order_by_field: property,
      asc_order: !isAsc,
      next_cursor: undefined,
      previous_cursor: undefined,
    }))
    setPage(0)
  }

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserListParams((prev) => ({
      ...prev,
      limit: parseInt(event.target.value, 10),
      next_cursor: null,
      previous_cursor: null,
    }))
    setPage(0)
  }

  const handleUserTypeChange = useCallback(() => {
    if (studyUsersMetadata?.user_types) {
      const hash = location.hash.slice(1)
      const userType = hash || 'all'

      setUserListParams((prev) => ({
        ...prev,
        user_type: userType as UserCompanyTypeFilter,
        next_cursor: null,
        previous_cursor: null,
      }))
      setPage(0)
    }
  }, [studyUsersMetadata?.user_types, location.hash, setUserListParams, setPage])

  return {
    // State
    page,
    addMenuAnchor,
    isAddModalOpen,
    isAssignModalOpen,
    usersList,
    studyUsersMetadata,
    companiesMetadata,
    userListParams,
    isLoading: isPendingUsers || isPendingStudyUsersMetadata || isPendingCompaniesMetadata,

    // Handlers
    handleOpenUserMenu,
    handleCloseUserMenu,
    handleUserTypeSelect,
    handleAddUser,
    handleAssignUser,
    handleDeleteUser,
    handleSearch,
    handlePageChange,
    handleRequestSort,
    handleRowsPerPageChange,
    setIsAddModalOpen,
    setIsAssignModalOpen,
    handleUserTypeChange,
  }
}
