import { useQuery } from '@tanstack/react-query'
import {
  listStudySubjectConfigurationStudiesStudyIdSubjectConfigurationGet,
  StudySubjectConfigurationsSchema,
} from '@common/config/api/client'
import { SubjectConfigurationFormValues } from '@study_setup/SubjectConfiguration/components/SubjectConfiguration'
import { CharacterType } from '@study_setup/SubjectConfiguration/types'
import { convertCharacterTypeStringToArray } from '@study_setup/SubjectConfiguration/utils'

const transformSubjectConfiguration = (data: StudySubjectConfigurationsSchema): SubjectConfigurationFormValues => {
  const configuration = data.subject_configurations[0]
  return {
    id: configuration.id,
    total_subjects: configuration.total_subjects ?? 0,
    site_validation_number_of_digits: configuration.site_validation_number_of_digits ?? null,
    site_validation_accepted_charater_type: convertCharacterTypeStringToArray(
      configuration.site_validation_accepted_charater_type as CharacterType,
    ),
    subject_validation_number_of_digits: configuration.subject_validation_number_of_digits ?? null,
    subject_validation_accepted_charater_type: convertCharacterTypeStringToArray(
      configuration.subject_validation_accepted_charater_type as CharacterType,
    ),
    study_form_definition_id: configuration.study_form_definition_id ?? null,
    selected_form: null,
    eform_mandatory: configuration.eform_mandatory ?? false,
  }
}

export const useGetStudySubjectConfiguration = (studyId?: string) => {
  return useQuery({
    queryKey: ['subjectConfiguration', studyId],
    queryFn: async () => {
      if (!studyId) {
        throw new Error('studyId is required')
      }
      const response = await listStudySubjectConfigurationStudiesStudyIdSubjectConfigurationGet({
        path: { study_id: studyId },
        throwOnError: true,
      })
      return response.data
    },
    select: transformSubjectConfiguration,
    enabled: !!studyId,
    retry: false,
  })
}
