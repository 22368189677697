import { Button, Divider, Stack, Tab, Tabs, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import TaskStateChip from './TaskStateChip'
import { QcTaskTimepointSchema } from '@common/config/api/client'
import { useState } from 'react'
import { SPACING } from '@common/theme/spacing'
import { SIZING } from '@common/theme/sizing'
import ESignDialog from '../modals/eSignDialog'

const formatTaskDate = (date: string) => {
  return new Date(date).toLocaleDateString('en-GB')
}

export default function QCDetailsHeader({ qcTask }: { qcTask: QcTaskTimepointSchema }) {
  const [eSignDialogOpen, setESignDialogOpen] = useState(false)
  return (
    <>
      <Grid container size={SIZING.gridSizeXxl} paddingX={SPACING.QCDetailsSpacing}>
        <Grid size={SIZING.gridSizeXxl}>
          <Stack spacing={SPACING.spacingXxl}>
            <Stack spacing={SPACING.spacingSm}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack direction="row" spacing={SPACING.spacingLg} alignItems="center">
                  <Typography variant="h4">QC {qcTask.order}</Typography>
                  <TaskStateChip taskState={qcTask.task_state} />
                </Stack>
                {qcTask.task_state !== 'COMPLETE' && (
                  <Button variant="contained" onClick={() => setESignDialogOpen(true)}>
                    Complete QC {qcTask.order}
                  </Button>
                )}
                {eSignDialogOpen && (
                  <ESignDialog
                    qcTaskId={qcTask.task_id}
                    payload={{
                      type: 'qc_task_submission',
                    }}
                    onClose={() => setESignDialogOpen(false)}
                  />
                )}
              </Stack>
              <Typography
                variant="body1"
                sx={{
                  color: 'text.secondary',
                }}
              >
                Created: {formatTaskDate(qcTask.create_date)}
              </Typography>
            </Stack>
            <Tabs value={0}>
              <Tab label="Overview" />
              <Tab label="Queries" />
            </Tabs>
          </Stack>
        </Grid>
      </Grid>
      <Divider
        sx={{
          marginX: -5,
        }}
      />
    </>
  )
}
