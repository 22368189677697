import { useMutation, UseMutationResult } from '@tanstack/react-query'
import {
  createSubjectStudiesStudyIdSubjectsPost,
  updateSubjectStudiesStudyIdSubjectsSubjectIdPut,
} from '@common/config/api/client'
import { AxiosError } from 'axios'
import {
  StudySubjectCreateSchema,
  StudySubjectUpdateSchema,
  StudySubjectViewSchema,
  HTTPValidationError,
} from '@common/config/api/client'

export const useCreateStudySubject = (): UseMutationResult<
  StudySubjectViewSchema,
  AxiosError<HTTPValidationError>,
  { studyId: string; subjectPayload: StudySubjectCreateSchema }
> => {
  return useMutation({
    mutationFn: async ({ studyId, subjectPayload }) => {
      const response = await createSubjectStudiesStudyIdSubjectsPost({
        path: {
          study_id: studyId,
        },
        body: subjectPayload,
        throwOnError: true,
      })
      return response.data
    },
  })
}

export const useUpdateStudySubject = (): UseMutationResult<
  StudySubjectViewSchema,
  AxiosError<HTTPValidationError>,
  {
    studyId: string
    subjectId: string
    subjectPayload: StudySubjectUpdateSchema
  }
> => {
  return useMutation({
    mutationFn: async ({ studyId, subjectId, subjectPayload }) => {
      const response = await updateSubjectStudiesStudyIdSubjectsSubjectIdPut({
        path: {
          study_id: studyId,
          subject_id: subjectId,
        },
        body: subjectPayload,
        throwOnError: true,
      })
      return response.data
    },
  })
}
