import { z } from 'zod'

export const StudySubjectCreateSchema = z.object({
  pseudo_id: z.string().min(1, { message: 'Pseudo ID is required' }),
  primary_site_pseudo_id: z.string().min(1, { message: 'Primary site pseudo ID is required' }),
  primary_site_study_site_id: z
    .string({
      invalid_type_error: 'Primary site pseudo ID is required',
    })
    .uuid({ message: 'Primary site is required' }),
  secondary_site_study_site_id: z.string().uuid().optional().nullable(),
  status: z.string().min(1, { message: 'Status is required' }),
  eligibility_status: z.string().min(1, { message: 'Eligibility status is required' }),
  subject_form_id: z.string().uuid().optional().nullable(),
  subject_form_submission: z.string().optional().nullable(),
})

export const OwnSiteStudySubjectCreateSchema = z.object({
  pseudo_id: z.string().min(1, { message: 'Pseudo ID is required' }),
  status: z.string().min(1, { message: 'Status is required' }),
  eligibility_status: z.string().min(1, { message: 'Eligibility status is required' }),
  subject_form_id: z.string().uuid().optional().nullable(),
  subject_form_submission: z.string().optional().nullable(),
})
