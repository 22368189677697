import { TableBody, TableRow, TableCell, Badge } from '@mui/material'
import { useMemo } from 'react'
import {
  ChangeState,
  Color,
  DataStructure,
  DicomTagsTableBodyProps,
  DynamicItem,
} from '@features/subject/subject.types'
import { getComparatorDicom } from '@features/subject/utils'
import { legendItems } from './DicomTagsDialog'
import { useParams } from 'react-router-dom'

export default function DiacomTagsTableBodyView({ rows, order, orderBy, containerParam }: DicomTagsTableBodyProps) {
  const { siteId, studyId, subjectId, timepointSubmissionId } = useParams()

  const visibleRows = useMemo(() => [...rows].sort(getComparatorDicom(order, orderBy)), [order, orderBy, rows])

  const getColorByStatus = (status: ChangeState): Color => {
    const legendItem = legendItems.find((item) => {
      return item.status.toLowerCase() === status.toLowerCase()
    })
    return legendItem?.color || 'default'
  }

  const data: DataStructure = {
    site_id: {
      key: '{site.id}',
      value: siteId || '-',
    },
    study_id: {
      key: '{study.id}',
      value: studyId || '-',
    },
    subject_id: {
      key: '{subject.id}',
      value: subjectId || '-',
    },
    timepoint_id: {
      key: '{timepoint_id}',
      value: timepointSubmissionId || '-',
    },
    timepoint_name: {
      key: '{timepoint_name}',
      value: (containerParam && containerParam[0].name) || '-',
    },
  }

  const getData = (key: string) => {
    if (key.includes('{') && key.includes('}')) {
      // Split the key by underscores
      const keys = key.split('_')
      const values = []

      for (const k of keys) {
        let found = false

        for (const item of Object.values(data)) {
          const dataItem = item as DynamicItem

          if (dataItem.key === k) {
            values.push(dataItem.value)
            found = true
            break
          }
        }

        // If a key is not found, push a placeholder value
        if (!found) {
          values.push('-')
        }
      }

      // Join the values with underscores
      return values.join('_')
    } else {
      return key // Return the original key if no dynamic placeholders are found
    }
  }

  return (
    <TableBody data-testid="table-body">
      {visibleRows.map((row, i) => (
        <TableRow
          key={`${row.id}_${i}`}
          sx={{
            borderBottom: '1px solid rgba(220, 227, 229, 1)',
            cursor: 'pointer',
          }}
          hover
          data-testid="table-row"
        >
          <TableCell align="center" data-testid="cell-status" sx={{ p: 0.65 }}>
            <Badge variant="dot" color={getColorByStatus(row.status)} />
          </TableCell>
          <TableCell align="left" data-testid="cell-tag" sx={{ p: 0.65 }}>
            {row.tag} {row.tagName}
          </TableCell>
          <TableCell align="left" data-testid="cell-originalValue" sx={{ p: 0.65 }}>
            {getData(row.originalValue)}
          </TableCell>
          <TableCell align="left" data-testid="cell-newValue" sx={{ p: 0.65 }}>
            {getData(row.newValue)}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  )
}
