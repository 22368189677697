import { useStepper } from '@common/context/StepperContext'
import { SIZING } from '@common/theme/sizing'
import { SPACING } from '@common/theme/spacing'
import { Typography, Stack, Chip, Grid2 as Grid } from '@mui/material'
import { Suspense, lazy, useEffect } from 'react'

const StudyOverview = lazy(() => import('./StudyOverview'))
const TimePointDefinition = lazy(() => import('./TimePointDefinition'))
const StudyAdherence = lazy(() => import('../StudyAdherence/components'))
const SubjectConfiguration = lazy(() => import('../SubjectConfiguration/components'))
const QualityControl = lazy(() => import('./QualityControl'))
const DICOMTagsDeId = lazy(() => import('./DICOMTagsDeId'))
const ReadDesign = lazy(() => import('./ReadDesign'))

export default function CreateOrUpdateStudy() {
  const { activeStep, setActiveStep } = useStepper()

  useEffect(() => {
    setActiveStep(0)
    return () => {
      setActiveStep(0)
    }
  }, [])

  const stepComponents = [
    () => <StudyOverview />,
    () => <TimePointDefinition />,
    () => <SubjectConfiguration />,
    () => <StudyAdherence />,
    () => <QualityControl />,
    () => <DICOMTagsDeId />,
    () => <ReadDesign />,
  ]
  const StepComponent = stepComponents[activeStep]

  const getGridWidth = () => {
    if (activeStep === 0 || activeStep === 4 || activeStep === 5) {
      return 8
    }

    return 12
  }

  const getGridOffset = () => {
    if (activeStep === 0 || activeStep === 4 || activeStep === 5) {
      return 2
    }

    return 0
  }

  return (
    <Stack>
      <Grid container>
        <Grid size={{ xs: SIZING.gridSizeXxl, lg: getGridWidth() }} offset={{ xs: 0, lg: getGridOffset() }}>
          <Stack direction="row" spacing={SPACING.spacingLg} alignItems="center" mb={SPACING.spacingXxl}>
            <Typography variant="h4">Create a clinical study</Typography>
            <Chip label="Study setup" color="info-alt" />
          </Stack>
        </Grid>
      </Grid>

      <Suspense fallback={<div>Loading...</div>}>{StepComponent ? <StepComponent /> : null}</Suspense>
    </Stack>
  )
}
