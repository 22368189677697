import { Stack, Switch, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { TextField } from '@common/components/Form/TextField'
import { Dropdown } from '@common/components/Form/Dropdown/Dropdown'
import { CompaniesMetadataSchema, CompanyType } from '@common/config/api/client'
import { SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { UserFormData } from '@features/user_management/types'
import { SPACING } from '@common/theme/spacing'
import BaseDialog from '@common/components/BaseDialog'
import { SIZING } from '@common/theme/sizing'
import Upload from '@features/subject/components/UploadZone'
import { FileSystemItem } from '@features/subject/subject.types'

type BulkUserUploadData = {
  file: File | null
}

interface AddUserModalProps {
  open: boolean
  onClose: () => void
  onSubmit: (data: UserFormData) => void
  onBulkSubmit?: (data: BulkUserUploadData) => void
  companiesMetadata: CompaniesMetadataSchema | undefined
}

export default function AddUserModal({
  open,
  onClose,
  onSubmit,
  onBulkSubmit,
  companiesMetadata,
}: Readonly<AddUserModalProps>) {
  const [isBulkMode, setIsBulkMode] = useState(false)
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>('')
  const [selectedCompanyType, setSelectedCompanyType] = useState<CompanyType | null>(null)

  const singleUserForm = useForm<UserFormData & Record<string, unknown>>({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      company_id: '',
      company_type: null,
      role_id: '',
    },
  })

  const bulkUserForm = useForm<BulkUserUploadData>({
    defaultValues: {
      file: null,
    },
  })

  const filteredCompanyOptions = useMemo(() => {
    if (!companiesMetadata?.companies) return []

    if (!selectedCompanyType) {
      return companiesMetadata.companies.map((company) => ({
        label: company.company_name,
        value: company.company_id,
        type: company.company_type,
      }))
    }

    return companiesMetadata.companies
      .filter((company) => company.company_type === selectedCompanyType)
      .map((company) => ({
        label: company.company_name,
        value: company.company_id,
        type: company.company_type,
      }))
  }, [companiesMetadata, selectedCompanyType])

  const companyTypeOptions = useMemo(() => {
    if (!companiesMetadata?.company_types) return []
    return companiesMetadata.company_types.map(([value, label]) => ({
      label,
      value,
    }))
  }, [companiesMetadata])

  const roleOptions = useMemo(() => {
    if (!companiesMetadata?.companies || !selectedCompanyId) return []

    const selectedCompany = companiesMetadata.companies.find((company) => company.company_id === selectedCompanyId)

    return (
      selectedCompany?.roles.map((role) => ({
        label: role.role_name,
        value: role.role_id,
      })) ?? []
    )
  }, [companiesMetadata, selectedCompanyId])

  const handleSingleUserSubmit = async () => {
    const isValid = await singleUserForm.trigger()
    if (!isValid) return
    const formData = singleUserForm.getValues()
    onSubmit(formData)
    singleUserForm.reset()
    setSelectedCompanyId('')
    setSelectedCompanyType(null)
  }

  const handleBulkUserSubmit = async () => {
    const isValid = await bulkUserForm.trigger()
    if (!isValid) return
    const formData = bulkUserForm.getValues()
    if (onBulkSubmit) {
      onBulkSubmit(formData)
    }
    bulkUserForm.reset()
    setSelectedCompanyId('')
    setSelectedCompanyType(null)
  }

  const handleFormSubmit = async () => {
    if (isBulkMode) {
      await handleBulkUserSubmit()
    } else {
      await handleSingleUserSubmit()
    }
  }

  const handleCompanyChange = (
    _: SyntheticEvent<Element, Event>,
    value:
      | NonNullable<string | { label: string; value: string }>
      | (string | { label: string; value: string })[]
      | null,
    isBulk = false,
  ) => {
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      const companyId = value.value as string
      setSelectedCompanyId(companyId)

      if (!isBulk) {
        singleUserForm.setValue('company_id', companyId)
        singleUserForm.setValue('role_id', '')
      }

      if (companiesMetadata?.companies) {
        const selectedCompany = companiesMetadata.companies.find((company) => company.company_id === companyId)

        if (selectedCompany) {
          setSelectedCompanyType(selectedCompany.company_type)
          if (!isBulk) {
            singleUserForm.setValue('company_type', selectedCompany.company_type)
          }
        }
      }
    } else if (value === null) {
      setSelectedCompanyId('')
      if (!isBulk) {
        singleUserForm.setValue('company_id', '')
        singleUserForm.setValue('role_id', '')
      }
    }
  }

  const handleCompanyTypeChange = (
    _: SyntheticEvent<Element, Event>,
    value:
      | NonNullable<string | { label: string; value: string }>
      | (string | { label: string; value: string })[]
      | null,
    isBulk = false,
  ) => {
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      const newCompanyType = value.value as CompanyType
      setSelectedCompanyType(newCompanyType)

      if (!isBulk) {
        singleUserForm.setValue('company_type', newCompanyType)
      }

      setSelectedCompanyId('')

      if (!isBulk) {
        singleUserForm.setValue('company_id', '')
        singleUserForm.setValue('role_id', '')
      }
    } else if (value === null) {
      setSelectedCompanyType(null)

      if (!isBulk) {
        singleUserForm.setValue('company_type', null)
      }
    }
  }

  const handleBulkUserUploadSwitch = () => {
    setIsBulkMode(!isBulkMode)
  }

  useEffect(() => {
    if (!open) {
      singleUserForm.reset()
      bulkUserForm.reset()
      setSelectedCompanyId('')
      setSelectedCompanyType(null)
      setIsBulkMode(false)
    }
  }, [open, singleUserForm, bulkUserForm])

  const renderSingleUserForm = () => (
    <Stack spacing={SPACING.spacingXl}>
      <Typography variant="subtitle1">Company details</Typography>
      <Dropdown
        name="company_type"
        label="Type"
        options={companyTypeOptions ?? []}
        control={singleUserForm.control}
        size="small"
        onChange={(e, v) => handleCompanyTypeChange(e, v, false)}
      />
      <Dropdown
        name="company_id"
        label="Company name"
        options={filteredCompanyOptions}
        control={singleUserForm.control}
        rules={{ required: 'Company is required' }}
        size="small"
        onChange={(e, v) => handleCompanyChange(e, v, false)}
      />
      <Typography variant="subtitle1">User details</Typography>
      <TextField
        control={singleUserForm.control}
        name="name"
        label="Name"
        rules={{ required: 'Name is required' }}
        size="small"
      />
      <Dropdown
        name="role_id"
        label="Role"
        options={roleOptions}
        control={singleUserForm.control}
        rules={{ required: 'Role is required' }}
        size="small"
        disabled={!selectedCompanyId}
      />
      <TextField
        control={singleUserForm.control}
        name="email"
        label="Email address"
        size="small"
        rules={{
          required: 'Email is required',
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Invalid email address',
          },
        }}
      />
      <TextField
        control={singleUserForm.control}
        name="phone"
        label="Phone number"
        helperText="Optional"
        size="small"
      />
    </Stack>
  )

  const renderBulkUserForm = () => (
    <Stack spacing={SPACING.spacingXl}>
      <Upload
        fileTypesAllowed={['.csv', '.xlsx']}
        onDrop={(acceptedFiles) => {
          if (acceptedFiles.length > 0) {
            bulkUserForm.setValue('file', acceptedFiles[0])
          }
        }}
        data={
          bulkUserForm.watch('file')
            ? [
                {
                  id: '1',
                  name: bulkUserForm.watch('file')?.name || '',
                  size: bulkUserForm.watch('file')?.size || 0,
                  type: bulkUserForm.watch('file')?.type || '',
                } as FileSystemItem,
              ]
            : []
        }
        onRemove={() => {
          bulkUserForm.setValue('file', null)
        }}
        maxSize={3 * 1024 * 1024} // 3MB
      />
    </Stack>
  )

  return (
    <BaseDialog
      title="Add new user"
      description="Provide the new user details below"
      open={open}
      onClose={onClose}
      onSubmit={handleFormSubmit}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      form={isBulkMode ? bulkUserForm : singleUserForm}
      width={SIZING.modalWidth}
      sx={{ '& .MuiDialog-paper': { p: SPACING.minSpacing } }}
      submitLabel={isBulkMode ? 'Add users' : 'Add user'}
      cancelLabel="Cancel"
    >
      <Stack spacing={SPACING.spacingXl}>
        <Stack
          direction="row"
          gap="10px"
          sx={{
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: SPACING.borderRadiusLg,
            padding: SPACING.spacingLg,
          }}
          alignItems="center"
        >
          <Switch checked={isBulkMode} onChange={handleBulkUserUploadSwitch} sx={{ marginRight: 0, paddingX: 2 }} />
          <Typography>Bulk user upload</Typography>
        </Stack>

        {isBulkMode ? renderBulkUserForm() : renderSingleUserForm()}
      </Stack>
    </BaseDialog>
  )
}
