import { useQuery } from '@tanstack/react-query'
import {
  getDicomTagsStudiesDicomTagsGet,
  getStudyMetadataStudiesStudyIdMetadataGet,
  metadataStudiesMetadataGet,
  MetadataTypeEnum,
} from '@common/config/api/client'
import { Option } from '@common/components/Form/Dropdown/Dropdown'

export const useStudiesMetadataOptions = () => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['studiesMetadata'],
    queryFn: () => metadataStudiesMetadataGet(),
    select: ({ data }) => {
      return {
        studyTypeOptions: data?.study_types.map(([value, label]) => ({ value, label })) ?? [],
        anatomyOptions: data?.anatomies.map(({ id, name }) => ({ value: id, label: name })) ?? [],
        modalityOptions: data?.imaging_modalities.map(({ id, name }) => ({ value: id, label: name })) ?? [],
        therapeuticAreaOptions: data?.therapeutic_areas.map(([value, label]) => ({ value, label })) ?? [],
        locationOptions: data?.locations.map(({ id, name }) => ({ value: id, label: name })) ?? [],
        phaseOptions: data?.phases.map(([value, label]) => ({ value, label })) ?? [],
        readDesignTypeOptions: data?.read_design_types.map(({ id, name }) => ({ value: id, label: name })) ?? [],
        readCriteriaOptions: data?.read_criterias.map(({ id, name }) => ({ value: id, label: name })) ?? [],
        uploadTypes: data?.upload_types.map(([value, label]) => ({ value, label })) ?? [],
        gcpRegionsOptions: data?.gcp_regions?.map(({ id, name }) => ({ value: id, label: name })) ?? [],
        queryTemplates:
          data?.query_templates.map(({ id, title, template, category, allow_site_to_raise_query }) => ({
            value: id,
            label: `${data?.query_categories.find((elm) => elm[0] == category)?.[1] ?? category}: ${title}`,
            additional_fields: {
              category: 'TEMPLATE',
              template,
              title,
              query_category: category,
              allow_site_to_raise_query,
            },
          })) ?? [],
        queryCategories: data?.query_categories.map(([value, label]) => ({ value, label })) ?? [],
        dynamicModifications: data?.dynamic_modifications.map(([value, label]) => ({ value, label })) ?? [],
        valueRepresentations: data?.value_representations.map(({ id, name, abbreviation }) => ({
          value: id,
          label: `${abbreviation}:${name}`,
        })),
        sponsorOptions: data?.sponsors.map(({ id, name }) => ({ value: id, label: name })),
      }
    },
  })

  const {
    studyTypeOptions = [],
    anatomyOptions = [],
    modalityOptions = [],
    therapeuticAreaOptions = [],
    locationOptions = [],
    phaseOptions = [],
    readDesignTypeOptions = [],
    readCriteriaOptions = [],
    uploadTypes = [],
    queryTemplates = [],
    queryCategories = [],
    dynamicModifications = [],
    valueRepresentations = [],
    sponsorOptions = [],
    gcpRegionsOptions = [],
  } = data || {}

  return {
    studyTypeOptions,
    anatomyOptions,
    modalityOptions,
    therapeuticAreaOptions,
    locationOptions,
    phaseOptions,
    readDesignTypeOptions,
    readCriteriaOptions,
    gcpRegionsOptions,
    uploadTypes,
    queryTemplates,
    queryCategories,
    dynamicModifications,
    valueRepresentations,
    sponsorOptions,
    isLoading,
    isError,
    error,
  }
}

export const useStudyMetadataOptions = (
  studyId: string,
  metadataTypes: MetadataTypeEnum[],
  queryCategories?: Option[],
) => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['studyMetadata', studyId, metadataTypes],
    queryFn: () =>
      getStudyMetadataStudiesStudyIdMetadataGet({
        path: { study_id: studyId },
        query: { types: metadataTypes },
      }),
    select: ({ data }) => {
      return {
        anatomyOptions: data?.anatomies?.map(({ id, name }) => ({ value: id, label: name })) ?? [],
        modalityOptions: data?.imaging_modalities?.map(({ id, name }) => ({ value: id, label: name })) ?? [],
        locationOptions: data?.locations?.map(({ id, name }) => ({ value: id, label: name })) ?? [],
        phaseOptions: data?.phases?.map((phase) => ({ value: phase, label: phase })) ?? [],
        readDesignTypeOptions: data?.read_types?.map(({ id, name }) => ({ value: id, label: name })) ?? [],
        readCriteriaOptions: data?.read_criterias?.map(({ id, name }) => ({ value: id, label: name })) ?? [],
        seriesLabelOptions: data?.series_labels?.map(({ id, name }) => ({ value: id, label: name })) ?? [],
        queryTemplateOptions:
          data?.query_templates?.map(({ id, title, template, category, allow_site_to_raise_query }) => ({
            value: id,
            label: `${queryCategories?.find((elm) => elm.value == category)?.label ?? category}: ${title}`,
            additional_fields: {
              category: 'STUDY',
              template,
              title,
              query_category: category,
              allow_site_to_raise_query,
            },
          })) ?? [],
      }
    },
    enabled: !!studyId,
  })
  const {
    anatomyOptions = [],
    modalityOptions = [],
    locationOptions = [],
    phaseOptions = [],
    readDesignTypeOptions = [],
    readCriteriaOptions = [],
    queryTemplateOptions = [],
    seriesLabelOptions = [],
  } = data || {}

  return {
    anatomyOptions,
    modalityOptions,
    locationOptions,
    phaseOptions,
    readDesignTypeOptions,
    readCriteriaOptions,
    queryTemplateOptions,
    seriesLabelOptions,
    isLoading,
    isError,
    error,
  }
}

export const useDicomTagsMetadata = () => {
  return useQuery({
    queryKey: ['dicomTags'],
    queryFn: async () => await getDicomTagsStudiesDicomTagsGet(),
    select: ({ data }) => data,
  })
}
