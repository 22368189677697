import { useMutation } from '@tanstack/react-query'
import {
  toggleUserStatusAuthUserUserIdToggleStatusPost,
  HTTPValidationError,
  ToggleUserStatusAuthUserUserIdToggleStatusPostResponse,
} from '@common/config/api/client'
import { AxiosError } from 'axios'

interface ToggleUserStatusParams {
  userId: string
}

export default function useToggleAccountStatus() {
  return useMutation<
    ToggleUserStatusAuthUserUserIdToggleStatusPostResponse,
    AxiosError<HTTPValidationError>,
    ToggleUserStatusParams
  >({
    mutationFn: async ({ userId }) => {
      const response = await toggleUserStatusAuthUserUserIdToggleStatusPost({
        path: {
          user_id: userId,
        },
        throwOnError: true,
      })
      return response.data
    },
  })
}
