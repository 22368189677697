import { useNavigate } from 'react-router-dom'
import { CompaniesMetadataSchema, UserSchema } from '@common/config/api/client'

export default function useUserTable(
  companiesMetadata: CompaniesMetadataSchema | undefined,
  users: UserSchema[],
  onDeleteUser: (userId: string) => void,
) {
  const navigate = useNavigate()

  const handleEditUser = (selectedUserId: string) => {
    const selectedUser = users.find((user) => user.user_id === selectedUserId)
    if (selectedUserId && selectedUser) {
      navigate(`/users_management/companies/${selectedUser.company_id}/users/${selectedUserId}?edit=true`)
    }
  }

  const handleDeleteUser = (selectedUserId: string) => {
    const selectedUser = users.find((user) => user.user_id === selectedUserId)
    if (selectedUser && selectedUserId) {
      onDeleteUser(selectedUserId)
    }
  }

  const userTypeRoutes = getCompanyTypeRoutes(companiesMetadata)

  return {
    handleEditUser,
    handleDeleteUser,
    userTypeRoutes,
  }
}

function transformCompanyType(type: string): string {
  return type
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

function getCompanyTypeRoutes(companiesMetadata: { companies: Array<{ company_type: string }> } | undefined) {
  return [
    {
      path: '',
      label: 'All users',
      id: 'all-users',
    },
    ...(companiesMetadata?.companies ?? [])
      .map((company) => company.company_type)
      .filter((value, index, self) => self.indexOf(value) === index)
      .map((type) => ({
        path: `#${type.toLowerCase()}`,
        label: transformCompanyType(type),
        id: type.toLowerCase(),
      })),
  ]
}
