import { StudySiteUserSchema } from '@common/config/api/client'
import { MouseEvent } from 'react'
export interface SiteData {
  id: string
  name: string
  pseudo_id: string
  modalities: string
  created_by: StudySiteUserSchema | null
  created_at: string | null
  updated_at: string | null
  editSite?: string
  actions?: string
}

export interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

export interface SitesParams {
  study_id: string
  search?: string | null
  limit: number
  next_cursor?: string | null
  previous_cursor?: string | null
  order_by_field: string
  asc_order: boolean
}

export interface PrimarySitesParams {
  study_id: string
  limit?: number
}

export const enum SiteAssociationTypeEnum {
  PRIMARY = 'PRIMARY_SITE',
  SECONDARY = 'SECONDARY_SITE',
}

export interface SiteMenuProps {
  anchorEl: HTMLElement | null
  onClose: () => void
}

export interface SiteActionMenuProps extends SiteMenuProps {
  onDelete: () => void
  onEdit: (e: MouseEvent<HTMLElement>) => void
}
