import { Stack } from '@mui/material'
import Grid from '@mui/material/Grid2'
import TimepointDetails from './TimepointDetails'
import QCForm from './QCForm'
import ScrollMenu from './ScrollMenu'
import { useRef } from 'react'
import { QcTaskTimepointSchema } from '@common/config/api/client'
import { SPACING } from '@common/theme/spacing'
import { SIZING } from '@common/theme/sizing'
import { useParams } from 'react-router-dom'
import { useGetQcTaskSubmissionMetadata } from '@features/qc_task_details_view/hooks/useGetQCTaskSubmissionQueries'
import Exams from '../exam/Exams'
import StudyAndSeriesDefinitionSection from '../study/StudyAndSeriesDefinitionSection'

const menuItems = ['Timepoint details', 'Exams', 'Study and series definition', 'QC form']

export interface QCDetailsOverviewProps {
  qcTask: QcTaskTimepointSchema
}

export default function QCDetailsOverview({ qcTask }: QCDetailsOverviewProps) {
  const { studyId, qcTaskId } = useParams<{ studyId: string; qcTaskId: string }>()
  const { data: metadata, isLoading } = useGetQcTaskSubmissionMetadata({ study_id: studyId!, qc_task_id: qcTaskId! })

  const timepointDetailsRef = useRef<HTMLDivElement>(null)
  const examsRef = useRef<HTMLDivElement>(null)
  const studyAndSeriesDefinitionRef = useRef<HTMLDivElement>(null)
  const qcFormRef = useRef<HTMLDivElement>(null)
  const handleScroll = (section: string) => {
    switch (section) {
      case menuItems[0]:
        timepointDetailsRef.current?.scrollIntoView({ behavior: 'smooth' })
        break
      case menuItems[1]:
        examsRef.current?.scrollIntoView({ behavior: 'smooth' })
        break
      case menuItems[2]:
        studyAndSeriesDefinitionRef.current?.scrollIntoView({ behavior: 'smooth' })
        break
      case menuItems[3]:
        qcFormRef.current?.scrollIntoView({ behavior: 'smooth' })
        break
      default:
        break
    }
  }
  return (
    <Grid container size={SIZING.gridSizeXxl} paddingX={10}>
      <Grid
        size={SIZING.gridSizeLg}
        height={SIZING.gridHeight}
        overflow="auto"
        sx={{
          '&::-webkit-scrollbar': {
            display: 'none', // Hides the scrollbar for WebKit-based browsers
          },
          scrollbarWidth: 'none', // Hides the scrollbar for Firefox
        }}
      >
        <Stack padding={SPACING.minSpacing} />
        <Stack spacing={SPACING.spacingLg}>
          <div ref={timepointDetailsRef}>
            <TimepointDetails qcTask={qcTask} />
          </div>
          <div ref={examsRef}>
            <Exams qcTask={qcTask} />
          </div>
          <div ref={studyAndSeriesDefinitionRef}>
            <StudyAndSeriesDefinitionSection qcTask={qcTask} />
          </div>
          <div ref={qcFormRef}>
            {metadata?.qc_task_form_definition && !isLoading && (
              <QCForm formMetadata={metadata} taskState={qcTask.task_state} />
            )}
          </div>
        </Stack>
      </Grid>
      <Grid size={SIZING.gridSizeS}>
        <ScrollMenu menuItems={menuItems} onMenuItemClick={handleScroll} />
      </Grid>
    </Grid>
  )
}
