import { Box, IconButton, Menu, MenuItem, MenuList, Typography } from '@mui/material'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { Fragment } from 'react/jsx-runtime'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import ExcludeModal from './ExcludeModal'
import { useParams } from 'react-router-dom'
import { useUnassignTasksMutation } from '@qc_task_list_view/hooks/useUnassignTasksMutation'
import { toast } from 'sonner'
import Toast from './Toast'
import { AxiosError } from 'axios'
import PersonRemoveRoundedIcon from '@mui/icons-material/PersonRemoveRounded'
import { HTTPValidationError } from '@common/config/api/client'
import { SIZING } from '@common/theme/sizing'
import { SPACING } from '@common/theme/spacing'

export default function MyTasksPopupMenuList({
  task_id,
  pseudo_id,
  study_id,
}: {
  task_id: string
  pseudo_id: string
  study_id: string
}) {
  const { studyId } = useParams()
  const { mutateAsync: unassignTask } = useUnassignTasksMutation()

  const handleUnassignTask = async (task_ids: string[]) => {
    await unassignTask({
      study_qc_task_ids: task_ids,
      study_id: studyId!,
    })
      .then((res) => {
        toast(
          <Toast
            message="You unassigned task"
            task_ids={res.processed_task_ids.map((task) => {
              return task.split('-')[0]
            })}
            variant="success"
            data-testid="success-toast"
          />,
        )
      })
      .catch((error: AxiosError<HTTPValidationError>) => {
        const errorMessage = Array.isArray(error.response?.data?.detail)
          ? error.response?.data?.detail.map((err) => err.msg).join(', ')
          : error.response?.data?.detail || error.message
        toast(<Toast message={errorMessage} variant="error" data-testid="error-toast" />)
      })
  }

  return (
    <PopupState variant="popover">
      {(popupState) => (
        <Fragment>
          <IconButton
            {...bindTrigger(popupState)}
            onClick={(e) => {
              popupState.open(e)
              e.stopPropagation()
            }}
          >
            <MoreVertRoundedIcon fontSize="small" data-testid="popup-menu-icon" />
          </IconButton>
          <Menu
            {...bindMenu(popupState)}
            onClick={(e) => e.stopPropagation()}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            data-testid="popup-menu-list"
          >
            <MenuList
              sx={{
                width: SIZING.menuListWidth,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                paddingY: SPACING.spacingSm,
                outline: 'none',
              }}
            >
              <MenuItem
                sx={{
                  width: '100%',
                }}
                data-testid="popup-menu-list-item-unassign"
                onClick={() => {
                  handleUnassignTask([task_id])
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    alignSelf: 'stretch',
                    width: '100%',
                  }}
                  data-testid="unassign-task"
                >
                  <PersonRemoveRoundedIcon
                    sx={{
                      marginRight: SPACING.popupMenuIconSpacing,
                      width: SIZING.popupMenuIconWidth,
                      height: SIZING.popupMenuIconWidth,
                      color: 'text.secondary',
                    }}
                  />
                  <Typography>Unassign task</Typography>
                </Box>
              </MenuItem>
              <MenuItem
                sx={{
                  width: '100%',
                }}
                data-testid="popup-menu-list-item-exclude"
              >
                <ExcludeModal task_id={task_id} pseudo_id={pseudo_id} study_id={study_id} popupState={popupState} />
              </MenuItem>
            </MenuList>
          </Menu>
        </Fragment>
      )}
    </PopupState>
  )
}
