import { useMemo } from 'react'
import { useForm, useFieldArray, useWatch } from 'react-hook-form'
import { Button, IconButton, Typography, Stack } from '@mui/material'
import { AddRounded, DeleteOutline } from '@mui/icons-material'
import { SPACING } from '@common/theme/spacing'
import BaseDialog from '@common/components/BaseDialog'
import { useAddStudyAssignments } from '@features/user_management/hooks/useAddStudyAssignments'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { Dropdown } from '@common/components/Form/Dropdown'
import { SIZING } from '@common/theme/sizing'
import { StudyAssignmentsSchema } from './studyAssignmentSchemas'
import { useGetStudyAssignmentsMetadata } from '@features/user_management/hooks/useGetStudyAssignmentsMetadata'
import { Study } from '@features/user_management/hooks/useStudyAssignmentsHandlers'

interface CreateStudyAssignmentsDialogProps {
  open: boolean
  onClose: () => void
  userId: string
  studies?: Study[]
}

type FormValues = z.infer<typeof StudyAssignmentsSchema>

const CreateStudyAssignmentsDialog = ({ open, onClose, userId, studies = [] }: CreateStudyAssignmentsDialogProps) => {
  const { data: metadata, isLoading: isLoadingMetadata } = useGetStudyAssignmentsMetadata()
  const { mutate: addStudyAssignments, isPending } = useAddStudyAssignments()

  const form = useForm<FormValues>({
    resolver: zodResolver(StudyAssignmentsSchema),
    defaultValues: {
      assignments: [{ role_id: '', study_id: '' }],
    },
  })

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'assignments',
  })

  const currentAssignments = useWatch({
    control: form.control,
    name: 'assignments',
  })

  const roleOptions = useMemo(
    () => metadata?.roles.map((role) => ({ label: role.name, value: role.id })) || [],
    [metadata],
  )

  const selectedStudyIds = useMemo(() => {
    return new Set(
      currentAssignments
        .map((assignment) => assignment.study_id)
        .filter((id): id is string => id !== null && id !== ''),
    )
  }, [currentAssignments])

  const remainingStudiesCount = useMemo(() => {
    if (!studies) return 0
    return studies.filter((study) => !selectedStudyIds.has(study.id)).length
  }, [studies, selectedStudyIds])

  const getStudyOptionsForField = (fieldIndex: number) => {
    if (!studies || studies.length === 0) return []

    const otherSelectedStudyIds = currentAssignments
      .map((assignment, idx) => (idx !== fieldIndex && assignment.study_id ? assignment.study_id : null))
      .filter((id): id is string => id !== null && id !== '')

    const selectedStudyIdSet = new Set(otherSelectedStudyIds)

    return studies
      .filter((study) => !selectedStudyIdSet.has(study.id))
      .map((study) => ({ label: study.name, value: study.id }))
  }

  const handleAddAssignment = () => {
    append({ role_id: '', study_id: '' })
  }

  const handleRemoveAssignment = (index: number) => {
    remove(index)
  }

  const onSubmit = async () => {
    const isValid = await form.trigger()

    if (isValid) {
      const formData = form.getValues()

      addStudyAssignments(
        {
          userId,
          studyAssignments: formData.assignments.map((assignment) => ({
            role_id: assignment.role_id,
            study_id: assignment.study_id,
          })),
        },
        {
          onSuccess: () => {
            form.reset({
              assignments: [{ role_id: '', study_id: '' }],
            })
            onClose()
          },
        },
      )
    }
  }

  return (
    <BaseDialog
      title="Assign user to study"
      description="Provide the role and study you wish to assign to this user"
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      form={form}
      submitLabel="Save changes"
      cancelLabel="Cancel"
      width={SIZING.dialogWidthLg}
      isSubmitting={isPending}
    >
      <Stack direction="column" spacing={SPACING.spacingXl}>
        {fields.map((field, index) => (
          <Stack direction="column" spacing={SPACING.spacingLg} key={field.id} sx={{ mb: SPACING.spacingMd }}>
            <Typography variant="subtitle1">Assignment {index + 1}</Typography>
            <Stack direction="row" spacing={SPACING.spacingXl} alignItems="flex-start">
              <Dropdown
                name={`assignments.${index}.role_id`}
                size="small"
                control={form.control}
                options={roleOptions ?? []}
                loading={isLoadingMetadata}
                label="Role"
                disabled={isPending}
              />
              <Dropdown
                name={`assignments.${index}.study_id`}
                size="small"
                control={form.control}
                options={getStudyOptionsForField(index)}
                label="Study"
                disabled={isPending}
              />
              <IconButton
                onClick={() => handleRemoveAssignment(index)}
                disabled={fields.length <= 1 || isPending}
                sx={{ mt: 3.5 }}
              >
                <DeleteOutline />
              </IconButton>
            </Stack>
          </Stack>
        ))}
      </Stack>
      <Button
        startIcon={<AddRounded />}
        onClick={handleAddAssignment}
        variant="text"
        color="primary"
        disabled={isPending || remainingStudiesCount <= 0}
        sx={{ alignSelf: 'flex-start', mt: SPACING.spacingMd }}
      >
        Add a new assignment
      </Button>
    </BaseDialog>
  )
}

export default CreateStudyAssignmentsDialog
