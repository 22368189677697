import { IconButton, Menu, MenuItem, MenuList, Stack, Typography } from '@mui/material'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { Fragment } from 'react/jsx-runtime'
import { FileDownloadRounded, MoreVertRounded, RemoveRedEyeRounded } from '@mui/icons-material'
import {
  client,
  QcTimepointContainerSubmission,
  TimepointContainerSubmissionExamMetadataResponse,
} from '@common/config/api/client'
import DeleteModal from '../modals/DeleteModal'
import { SPACING } from '@common/theme/spacing'
import SeriesModal from '../modals/SeriesModal'
import { useDownloadExamMetadataMutation } from '@features/qc_task_details_view/hooks/useDownloadExamMetadataMutation'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import { lauchDicomViewerForQC } from '@common/utils/ohifUtils'
import { useExtendedAuth } from '@auth/hooks/useExtendedAuth'
import ReviewDicomTagsModal from '../modals/ReviewDicomTagsModal'

export default function ExamDetailPopupMenuList({
  timepointSubmissionId,
  container,
  exam,
}: {
  timepointSubmissionId: string
  container?: QcTimepointContainerSubmission
  exam?: TimepointContainerSubmissionExamMetadataResponse
}) {
  const { qcTaskId, studyId } = useParams()
  const [loading, setLoading] = useState(false)
  const { user } = useExtendedAuth()

  const { mutateAsync: downloadExamMetadata } = useDownloadExamMetadataMutation()

  const handledDownloadExamMetadata = async () => {
    if (!container) return
    downloadExamMetadata({
      path: {
        qc_task_id: qcTaskId!,
      },

      query: {
        timepoint_submission_id: timepointSubmissionId,
        study_id: studyId!,
        timepoint_container_submission_id: container?.container_submission_id,
      },
    })
      .then(async (response) => {
        try {
          const fileUrl = response.url
          if (fileUrl) {
            const fileResponse = await client.instance.get(fileUrl, {
              responseType: 'blob',
            })
            if (fileResponse.status !== 200) throw new Error('Failed to fetch file')
            const blob = fileResponse.data
            const blobUrl = window.URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = blobUrl
            link.download = fileUrl.split('/').pop()?.split('?').shift() || 'exam_metadata.zip'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          } else {
            throw new Error('Failed to fetch file')
          }
        } catch (error) {
          throw new Error(`Error downloading the file: ${error}`)
        } finally {
          setLoading(false)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <PopupState variant="popover">
      {(popupState) => (
        <Fragment>
          <IconButton {...bindTrigger(popupState)}>
            <MoreVertRounded
              sx={{
                color: 'text.secondary',
                width: 20,
                height: 20,
              }}
            />
          </IconButton>
          <Menu
            {...bindMenu(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            data-testid="popup-menu-list"
          >
            <MenuList
              sx={{
                width: 220,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                paddingY: SPACING.examDetailPopupMenuListSpacing,
                outline: 'none',
              }}
            >
              <MenuItem
                sx={{
                  height: 36,
                  width: '100%',
                  paddingY: SPACING.examDetailPopupMenuListSpacing,
                }}
                onClick={() =>
                  lauchDicomViewerForQC(qcTaskId, studyId, exam?.series[0].series_uid || undefined, user?.access_token)
                }
                data-testid="popup-menu-list-item-reopen"
              >
                <Stack direction="row" spacing={SPACING.popupMenuIconSpacing} alignItems="center">
                  <RemoveRedEyeRounded
                    sx={{
                      color: 'text.secondary',
                    }}
                  />
                  <Typography>Open in viewer</Typography>
                </Stack>
              </MenuItem>
              <MenuItem
                sx={{
                  height: 36,
                  width: '100%',
                  paddingY: SPACING.examDetailPopupMenuListSpacing,
                }}
                data-testid="popup-menu-list-item-reset"
              >
                <ReviewDicomTagsModal exam={exam} popupState={popupState} />
              </MenuItem>
              <MenuItem
                sx={{
                  height: 36,
                  width: '100%',
                  paddingY: SPACING.examDetailPopupMenuListSpacing,
                }}
                onClick={() => {
                  setLoading(true)
                  handledDownloadExamMetadata()
                  popupState.close()
                }}
                disabled={loading}
                data-testid="popup-menu-list-item-reset"
              >
                <Stack direction="row" spacing={SPACING.popupMenuIconSpacing} alignItems="center">
                  <FileDownloadRounded
                    sx={{
                      color: 'text.secondary',
                    }}
                  />
                  <Typography>Download</Typography>
                </Stack>
              </MenuItem>
              <MenuItem
                sx={{
                  height: 36,
                  width: '100%',
                  paddingY: SPACING.examDetailPopupMenuListSpacing,
                }}
                data-testid="popup-menu-list-item-reset"
              >
                <SeriesModal
                  container_submission_id={container?.container_submission_id}
                  exam={exam}
                  popupState={popupState}
                />
              </MenuItem>
              <MenuItem
                sx={{
                  height: 36,
                  width: '100%',
                  paddingY: SPACING.examDetailPopupMenuListSpacing,
                }}
                data-testid="popup-menu-list-item-reset"
              >
                <DeleteModal
                  container_submission_id={container?.container_submission_id}
                  exam={exam}
                  popupState={popupState}
                />
              </MenuItem>
            </MenuList>
          </Menu>
        </Fragment>
      )}
    </PopupState>
  )
}
