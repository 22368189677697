import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { useState } from 'react'
import { RefreshRounded } from '@mui/icons-material'
import { useReopenTasksMutation } from '@reader_task_list_view/hooks/useReopenTasksMutation'
import { toast } from 'sonner'
import Toast from './Toast'
import { AxiosError } from 'axios'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { TextField } from '@common/components/Form/TextField'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  borderRadius: 2,
  bgcolor: 'background.default',
  boxShadow: 8,
  padding: 1,
}

const ReopenValidationMessage = 'Provide a reason why you need to re-open the task'

const ReopenValidationSchema = z.object({
  reason: z.string().min(1, ReopenValidationMessage),
})

export default function ReopenModal({
  read_task_id,
  read_pseudo_id,
  study_id,
}: {
  read_task_id: string
  read_pseudo_id: number
  study_id: string
}) {
  const [open, setOpen] = useState(false)
  const {
    control,
    watch,
    formState: { isValid },
    setError,
  } = useForm({ mode: 'onChange', defaultValues: { reason: '' }, resolver: zodResolver(ReopenValidationSchema) })
  const reason = watch('reason')
  const handleClose = () => {
    setOpen(false)
  }
  const { mutateAsync: reopenTask } = useReopenTasksMutation()

  const handleReopenTask = async (task_ids: string[], study_id: string, reason: string) => {
    if (isValid) {
      await reopenTask({
        reason: reason,
        read_task_ids: task_ids,
        study_id: study_id,
      })
        .then(() => {
          toast(<Toast message="Your request was sent successfully" variant="success" data-testid="success-toast" />)
          handleClose()
        })
        .catch((error) => {
          toast(<Toast message={(error as AxiosError).message} variant="error" data-testid="error-toast" />)
        })
    } else {
      setError('reason', { message: ReopenValidationMessage })
    }
  }

  return (
    <>
      <Box
        onClick={(e) => {
          setOpen(true)
          e.stopPropagation()
        }}
        sx={{
          display: 'flex',
          justifyContent: 'end',
          width: '100%',
          gap: 1.5,
        }}
        data-testid="reopen-task-button"
      >
        <RefreshRounded
          sx={{
            cursor: 'pointer',
            color: 'text.primary',
          }}
        />
      </Box>
      <Modal
        open={open}
        onClick={(e) => {
          e.stopPropagation()
        }}
        onClose={handleClose}
        data-testid="reopen-modal"
      >
        <Box sx={modalStyle}>
          <Box
            sx={{
              display: 'flex',
              padding: 2,
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: 0.5,
              alignSelf: 'stretch',
              width: 600,
            }}
          >
            <Typography variant="h6" color="text.primary">
              {`Re-open task №${read_pseudo_id}?`}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              An Imaging Application Manager will review your request. You’ll be reassigned to the task if no issues are
              found.
            </Typography>
          </Box>
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              alignSelf: 'stretch',
              padding: 2,
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              name="reason"
              control={control}
              id="outlined-multiline-static"
              label="Provide a reason"
              multiline
              rows={6}
              fullWidth
              onKeyDown={(e) => e.stopPropagation()}
              data-testid="reason-textfield"
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              alignSelf: 'stretch',
              padding: 2,
              gap: 1,
            }}
          >
            <Button
              variant="contained"
              sx={{
                bgcolor: 'secondary.main',
                color: 'secondary.contrastText',
              }}
              onClick={handleClose}
              data-testid="cancel-reopen-task-button"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                bgcolor: 'primary.main',
                color: 'primary.contrastText',
              }}
              onClick={() => handleReopenTask([read_task_id], study_id, reason)}
              data-testid="confirm-reopen-task-button"
            >
              Re-open task
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}
