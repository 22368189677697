import { useState } from 'react'
import { toast } from 'sonner'
import Toast from '@common/components/Toast'
import { queryClient } from '@common/config/api/queryClient'
import { useBulkDeleteStudySites, useDeleteStudySite, useCreateStudySite, useUpdateStudySite } from '.'
import { TransformedSites } from '.'
import { handleMutationError } from '@common/hooks/handleMutationError'
import { SitesParams } from '../types'
import { useParams } from 'react-router-dom'
import { useGetStudySiteById } from './useStudySitesQueries'
import queryKeyFactory from '../queryKeyFactory'
import { CreateSitePayload, SiteFormValues } from '../components/CreateSiteDialog'

export default function useStudySitesTableHandlers() {
  const [page, setPage] = useState(0)
  const [isSiteDialogVisible, setIsSiteDialogVisible] = useState(false)
  const [editingSiteId, setEditingSiteId] = useState<string | null>(null)
  const { studyId } = useParams<{ studyId: string }>()
  const [sitesParams, setSitesParams] = useState<SitesParams>({
    study_id: studyId!,
    search: undefined,
    limit: 50,
    next_cursor: undefined,
    previous_cursor: undefined,
    order_by_field: 'pseudo_id',
    asc_order: true,
  })

  const { mutate: createSite } = useCreateStudySite()
  const { mutate: updateSite } = useUpdateStudySite()
  const { mutate: deleteSite } = useDeleteStudySite()
  const { mutate: bulkDeleteSites } = useBulkDeleteStudySites()
  const { data: editingSite, isPending: isEditingSitePending } = useGetStudySiteById({
    studyId: studyId!,
    siteId: editingSiteId!,
  })

  const handleSaveSite = (sitePayload: CreateSitePayload) => {
    createSite(
      { studyId: studyId!, sitePayload },
      {
        onSuccess: () => {
          toast(<Toast message="Study site created successfully." variant="success" />)
          setIsSiteDialogVisible(false)
          queryClient.invalidateQueries({ queryKey: ['studySites'] })
        },
        onError: handleMutationError,
      },
    )
  }

  const handlePageChange = (
    sites: TransformedSites | undefined,
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    const isNextPage = newPage > page
    const cursor = isNextPage ? sites?.pagination.nextCursor : sites?.pagination.previousCursor

    if (cursor) {
      setSitesParams((prev) => ({
        ...prev,
        next_cursor: isNextPage ? cursor : null,
        previous_cursor: isNextPage ? null : cursor,
      }))
      setPage(isNextPage ? page + 1 : page - 1)
    }
  }
  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSitesParams((prev) => ({
      ...prev,
      limit: parseInt(event.target.value, 10),
      next_cursor: null,
      previous_cursor: null,
    }))
    setPage(0)
  }

  const handleSearch = (value: string) => {
    setSitesParams((prev) => ({
      ...prev,
      search: value || undefined,
      next_cursor: undefined,
      previous_cursor: undefined,
    }))
    setPage(0)
  }

  const handleRequestSort = (_event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = sitesParams.order_by_field === property && sitesParams.asc_order
    setSitesParams((prev) => ({
      ...prev,
      order_by_field: property,
      asc_order: !isAsc,
      next_cursor: undefined,
      previous_cursor: undefined,
    }))
    setPage(0)
  }

  const handleAddClick = () => {
    setIsSiteDialogVisible(true)
  }

  const handleEditClick = (siteId: string) => {
    setEditingSiteId(siteId)
    setIsSiteDialogVisible(true)
  }

  const handleSaveEditedSite = (sitePayload: SiteFormValues) => {
    if (!editingSiteId || !editingSite) return
    updateSite(
      { studyId: studyId!, siteId: editingSiteId, sitePayload },
      {
        onSuccess: () => {
          toast(<Toast message="Study site updated successfully" variant="success" />)
          queryClient.invalidateQueries({ queryKey: ['studySites'] })
          queryClient.refetchQueries({
            queryKey: queryKeyFactory.studySite(studyId!, editingSiteId),
            exact: true,
          })
          setEditingSiteId(null)
          setIsSiteDialogVisible(false)
        },
        onError: handleMutationError,
      },
    )
  }

  const handleDeleteSite = (siteId: string) => {
    deleteSite(
      { studyId: studyId!, siteId },
      {
        onSuccess: () => {
          toast(<Toast message="Study site deleted successfully" variant="success" />)
          queryClient.invalidateQueries({ queryKey: ['studySites'] })
        },
        onError: (error) => {
          toast(<Toast message={error.message || 'Failed to delete study site'} variant="error" />)
        },
      },
    )
  }

  const handleBulkDeleteSites = (siteIds: Array<string>) => {
    bulkDeleteSites(
      { studyId: studyId!, siteIds },
      {
        onSuccess: () => {
          toast(<Toast message="Study sites deleted successfully" variant="success" />)
          queryClient.invalidateQueries({ queryKey: ['studySites'] })
        },
        onError: (error) => {
          toast(<Toast message={error.message || 'Failed to delete study site'} variant="error" />)
        },
      },
    )
  }

  return {
    page,
    isSiteDialogVisible,
    editingSiteId,
    sitesParams,
    setSitesParams,
    editingSite,
    handleSaveSite,
    handlePageChange,
    handleRowsPerPageChange,
    handleSearch,
    handleRequestSort,
    handleAddClick,
    handleEditClick,
    handleSaveEditedSite,
    handleDeleteSite,
    setEditingSiteId,
    setIsSiteDialogVisible,
    handleBulkDeleteSites,
    isEditingSitePending,
  }
}
