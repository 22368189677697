import { Stack } from '@mui/material'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { SPACING } from '@common/theme/spacing'
import SubjectsTable from './SubjectsTable'
import SubjectsTableSkeleton from './SubjectsTableSkeleton'
import { useGetStudySubjects } from '../hooks/useStudySubjectsQueries'
import useStudySubjectsTableHandlers from '../hooks/useSubjectsTableHandlers'
import CreateStudySubjectDialog from './CreateStudySubjectDialog'
import { ROUTES } from 'src/routes'
import { generatePathWithParams } from '@common/utils/urlUtils'

export default function Subjects() {
  const navigate = useNavigate()
  const { studyId } = useParams<{ studyId: string }>()

  // Redirect to Subject Timepoints view on component mount
  useEffect(() => {
    const subjectTimepointsPath = generatePathWithParams(ROUTES.SUBJECTS_TIMEPOINTS.path, { studyId: studyId! })
    navigate(subjectTimepointsPath)
  }, [navigate, studyId])

  const {
    page,
    subjectsParams,
    handlePageChange,
    handleRowsPerPageChange,
    handleSearch,
    handleRequestSort,
    handleAddClick,
    handleEditClick,
    isSubjectDialogVisible,
    setIsSubjectDialogVisible,
  } = useStudySubjectsTableHandlers()

  const { data: subjects, isPending: isSubjectsPending } = useGetStudySubjects(subjectsParams)

  const isLoading = isSubjectsPending

  return (
    <>
      <Stack spacing={SPACING.sectionsSpacing}>
        {isLoading ? (
          <SubjectsTableSkeleton />
        ) : (
          <SubjectsTable
            subjects={subjects}
            handlePageChange={(event, newPage) => handlePageChange(subjects, event, newPage)}
            onRowsPerPageChange={handleRowsPerPageChange}
            onSearch={handleSearch}
            searchValue={subjectsParams.search ?? ''}
            page={page}
            orderBy={subjectsParams.order_by_field ?? ''}
            order={subjectsParams.asc_order ? 'asc' : 'desc'}
            onRequestSort={handleRequestSort}
            onAddClick={handleAddClick}
            onEditClick={handleEditClick}
          />
        )}
      </Stack>
      {isSubjectDialogVisible ? (
        <CreateStudySubjectDialog
          onClose={() => {
            setIsSubjectDialogVisible(false)
          }}
        />
      ) : null}
    </>
  )
}
