import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Fragment, useState } from 'react'
import { PopupState } from 'material-ui-popup-state/hooks'
import { User } from '@common/config/api/client'
import { ChatBubbleOutlineRounded, SpeakerNotesOff } from '@mui/icons-material'
import { Avatar, Paper, Stack } from '@mui/material'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  borderRadius: 2,
  bgcolor: 'background.default',
  boxShadow: 8,
}

export default function CommentModal({
  reader,
  comment,
  popupState,
}: {
  reader?: User
  comment?: string
  popupState: PopupState
}) {
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
    popupState.close()
  }

  return (
    <Fragment>
      <Stack
        direction={'row'}
        spacing={1.5}
        alignItems={'center'}
        alignSelf={'stretch'}
        width={'100%'}
        onClick={() => setOpen(true)}
      >
        <ChatBubbleOutlineRounded
          fontSize="small"
          sx={{
            color: 'text.secondary',
          }}
        />
        <Typography variant="body1">Read comment</Typography>
      </Stack>
      <Modal
        open={open}
        onClose={handleClose}
        data-testid="comment-modal"
        aria-labelledby="comment-modal-title"
        aria-describedby="comment-modal-description"
      >
        <Paper sx={modalStyle}>
          <Stack>
            <Stack padding={3}>
              <Stack height={32}>
                {reader && comment && (
                  <Typography variant="h6" color="text.primary" id="comment-modal-title">
                    Reader’s comment
                  </Typography>
                )}
              </Stack>
            </Stack>
            <Stack paddingX={3}>
              {reader && comment ? (
                <Box
                  sx={{
                    bgcolor: 'paper.main',
                    padding: 2,
                    borderRadius: 2,
                    width: 538,
                  }}
                >
                  <Stack spacing={1}>
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                      <Avatar sx={{ bgcolor: 'secondary.main', width: 32, height: 32 }}>
                        <Typography variant="caption" color="text.primary">
                          {reader.user_name
                            .split(' ')
                            .map((name) => name[0])
                            .join('')}
                        </Typography>
                      </Avatar>
                      <Typography variant="subtitle2" color="text.primary">
                        {reader.user_name}
                      </Typography>
                    </Stack>
                    <Stack alignSelf={'stretch'}>
                      <Typography
                        variant="body2"
                        color="text.primary"
                        sx={{
                          overflow: 'hidden',
                          fontFeatureSettings: "'liga' off, 'clig' off",
                          textOverflow: 'ellipsis',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2,
                          display: '-webkit-box',
                        }}
                      >
                        {comment}
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>
              ) : (
                <Stack spacing={1} width={538} alignItems={'center'}>
                  <Stack>
                    <SpeakerNotesOff
                      sx={{
                        width: 48,
                        height: 48,
                        color: 'text.disabled',
                      }}
                    />
                  </Stack>
                  <Stack alignItems={'center'}>
                    <Typography variant="h6" color="text.primary">
                      No comment added
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      You transferred the task to On-hold without adding a comment
                    </Typography>
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Stack>
          <Stack padding={3} alignSelf={'stretch'} alignItems={'flex-end'}>
            <Button
              variant="contained"
              sx={{
                bgcolor: 'secondary.main',
                color: 'secondary.contrastText',
              }}
              onClick={handleClose}
              data-testid="close-comment-task-button"
            >
              Close
            </Button>
          </Stack>
        </Paper>
      </Modal>
    </Fragment>
  )
}
