import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'sonner'
import { AxiosError } from 'axios'
import { deleteUserStudyAssignmentUsersUserIdStudyAssignmentsStudyUserRoleIdDelete } from '@common/config/api/client'
import Toast from '@common/components/Toast'

interface DeleteStudyAssignmentParams {
  userId: string
  studyUserRoleId: string
}

export const useDeleteStudyAssignment = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ userId, studyUserRoleId }: DeleteStudyAssignmentParams) => {
      return await deleteUserStudyAssignmentUsersUserIdStudyAssignmentsStudyUserRoleIdDelete({
        path: {
          user_id: userId,
          study_user_role_id: studyUserRoleId,
        },
      })
    },
    onSuccess: (_, variables) => {
      toast(<Toast message="Study assignment deleted successfully" variant="success" />)
      queryClient.invalidateQueries({ queryKey: ['studyAssignments', variables.userId] })
    },
    onError: (error: AxiosError) => {
      toast(<Toast message={error.message || 'Failed to delete study assignment'} variant="error" />)
    },
  })
}
