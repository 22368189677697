import { Box, Collapse, Stack, Typography, CircularProgress, Badge } from '@mui/material'
import { SPACING } from '@common/theme/spacing.ts'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { styled } from '@mui/material/styles'
import { useState } from 'react'
import { SeriesModel } from '@common/config/api/client'

const ExpandMore = styled('div')(({ theme }) => ({
  display: 'flex',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

type StudiesAndSeriesGroupProps = {
  name: string
  children: SeriesModel[]
  selectedSeries: string
  onSeriesClick: (series: string) => void
  hasPhiDetected?: boolean // Indicator for study level PHI
  seriesWithPhiDetected?: string[] // List of series UIDs with PHI detected
  isPhiLoading?: boolean
}

export default function StudiesAndSeriesGroup({
  name,
  children,
  selectedSeries,
  onSeriesClick,
  hasPhiDetected = false,
  seriesWithPhiDetected = [],
  isPhiLoading,
}: StudiesAndSeriesGroupProps) {
  const [isExpanded, setExpanded] = useState(children.some((x) => x.SeriesInstanceUID === selectedSeries))

  return (
    <Box py={SPACING.spacingMd}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        onClick={() => setExpanded(!isExpanded)}
        sx={{ cursor: 'pointer' }}
      >
        <Box px={SPACING.minSpacing} display="flex" alignItems="center">
          <ExpandMore
            sx={{
              transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          >
            <ExpandMoreIcon />
          </ExpandMore>
          <Typography variant="body1" ml={SPACING.typographySpacing} fontWeight={500}>
            {name}
          </Typography>
        </Box>
        {hasPhiDetected && <Badge variant="dot" color="warning" sx={{ mx: 2 }} />}
      </Box>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <Stack gap={SPACING.minSpacing} mt={SPACING.minSpacing}>
          {children.map((item, index) => {
            const hasSeriesPhi = seriesWithPhiDetected.includes(item.SeriesInstanceUID)

            return (
              <Box
                key={index}
                borderRadius={SPACING.borderRadiusXl}
                bgcolor={selectedSeries === item.SeriesInstanceUID ? 'secondary.main' : ''}
                px={SPACING.minSpacing}
                py={SPACING.timePointSpacing}
                sx={{ cursor: 'pointer' }}
                onClick={() => onSeriesClick(item.SeriesInstanceUID)}
              >
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    {isPhiLoading && !hasSeriesPhi && (
                      <CircularProgress
                        size={16}
                        thickness={3}
                        sx={(theme) => ({
                          mr: 1,
                          ml: 1,
                          color: theme.palette.text.secondary,
                        })}
                      />
                    )}
                    <Typography variant="body1" fontWeight={500} ml={isPhiLoading && !hasSeriesPhi ? 0 : 4.5}>
                      {item.SeriesDescription}
                    </Typography>
                  </Box>
                  {hasSeriesPhi && (
                    <Typography variant="body1" color="text.secondary" mr={1.5}>
                      PHI suspected
                    </Typography>
                  )}
                </Box>
              </Box>
            )
          })}
        </Stack>
      </Collapse>
    </Box>
  )
}
