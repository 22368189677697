import { styled, Box, Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { SPACING } from '@common/theme/spacing'

export interface StudyAssignmentsTableToolbarProps {
  onAddStudyAssignment: () => void
}

const ToolbarContainer = styled(Box)({
  padding: 16,
  justifyContent: 'flex-end',
  gap: 16,
  alignItems: 'center',
  textAlign: 'right',
})

export default function StudyAssignmentsTableToolbar({
  onAddStudyAssignment,
}: Readonly<StudyAssignmentsTableToolbarProps>) {
  return (
    <ToolbarContainer
      sx={{ paddingX: 0, display: 'flex', justifyContent: 'flex-end', gap: SPACING.tableToolbarItemsSpacing }}
    >
      <Button
        variant="contained"
        color="secondary"
        startIcon={<AddIcon />}
        size="small"
        onClick={() => onAddStudyAssignment()}
      >
        Assign new study
      </Button>
    </ToolbarContainer>
  )
}
