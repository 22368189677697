import { useQuery } from '@tanstack/react-query'
import queryKeyFactory from '../queryKeyFactory'
import { getCurrentUserInfoAuthProfileGet, UserInfoSchema } from '@common/config/api/client'

export default function useUserProfile() {
  return useQuery<UserInfoSchema>({
    queryKey: queryKeyFactory.userProfile(),
    queryFn: async () => {
      const response = await getCurrentUserInfoAuthProfileGet({
        throwOnError: true,
      })
      return response.data
    },
  })
}
