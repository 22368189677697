import { SPACING } from '@common/theme/spacing'
import { Box, Paper, Skeleton, Stack } from '@mui/material'

export default function SitesTableSkeleton() {
  return (
    <Box component="section" mt={SPACING.spacingXxl}>
      {/* Toolbar Skeleton */}
      <Stack
        direction="row"
        spacing={SPACING.spacingLg}
        sx={{ mb: SPACING.spacingLg, p: SPACING.spacingLg }}
        alignItems="center"
      >
        {/* Tab Navigation Skeleton */}
        <Stack direction="row" spacing={SPACING.spacingLg}>
          {[1, 2, 3, 4].map((i) => (
            <Skeleton
              key={i}
              variant="rectangular"
              width={100}
              height={32}
              sx={{ borderRadius: SPACING.borderRadiusSm }}
            />
          ))}
        </Stack>

        {/* Add Button and Search Skeleton */}
        <Stack direction="row" spacing={SPACING.spacingLg} sx={{ ml: 'auto' }}>
          <Skeleton variant="rectangular" width={120} height={32} sx={{ borderRadius: SPACING.borderRadiusSm }} />
          <Skeleton variant="rectangular" width={200} height={32} sx={{ borderRadius: SPACING.borderRadiusSm }} />
        </Stack>
      </Stack>

      {/* Table Skeleton */}
      <Paper sx={{ p: SPACING.spacingXl }}>
        {/* Table Header Skeleton */}
        <Skeleton variant="rectangular" width="100%" height={52} sx={{ mb: SPACING.minSpacing }} />

        {/* Table Rows Skeleton */}
        {[1, 2, 3, 4, 5].map((i) => (
          <Skeleton
            key={i}
            variant="rectangular"
            width="100%"
            height={52}
            sx={{ mb: SPACING.minSpacing, borderRadius: SPACING.borderRadiusSm }}
          />
        ))}

        {/* Pagination Skeleton */}
        <Stack direction="row" justifyContent="flex-end" sx={{ mt: SPACING.spacingLg }}>
          <Skeleton variant="rectangular" width={300} height={32} sx={{ borderRadius: SPACING.borderRadiusSm }} />
        </Stack>
      </Paper>
    </Box>
  )
}
