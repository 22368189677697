import { TableBody, TableCell, TableRow } from '@mui/material'
import ExpandableResponseTableRow from './ExpandableResponseTableRow'
import ExpandableTargetLesionsTableRow from './ExpandableTargetLesionsTableRow'
import ExpandableNonTargetLesionsTableRow from './ExpandableNonTargetLesionsTableRow'
import ExpandableNewLesionsTableRow from './ExpandableNewLesionsTableRow'
import ExpandableErcfTableRow from './ExpandableErcfTableRow'
import ExpandableCommentTableRow from './ExpandableCommentTableRow'
import { ReadTaskSummaryData } from '@common/config/api/client'
import { TableScrollSize } from '@features/reader_summary/pages/summary.types'
import TimepointEformTableRow from '../adjudication/TimepointEformTableRow'

export default function ReaderSummaryTableBody({
  timepoints,
  readTaskSummaryMetaData,
  tableScrollSize,
}: {
  timepoints: ReadTaskSummaryData['timepoints']
  readTaskSummaryMetaData: ReadTaskSummaryData['read_task_summary_metadata']
  tableScrollSize: TableScrollSize
}) {
  return (
    <TableBody data-testid="subject-list-table-body">
      <TimepointEformTableRow timepoints={timepoints} tableScrollSize={tableScrollSize} />
      <ExpandableResponseTableRow timepoints={timepoints} tableScrollSize={tableScrollSize} />
      <ExpandableTargetLesionsTableRow timepoints={timepoints} tableScrollSize={tableScrollSize} />
      <ExpandableNonTargetLesionsTableRow timepoints={timepoints} tableScrollSize={tableScrollSize} />
      <ExpandableNewLesionsTableRow timepoints={timepoints} tableScrollSize={tableScrollSize} />
      <ExpandableErcfTableRow
        ecrfDefinition={readTaskSummaryMetaData.ecrf_definition}
        timepoints={timepoints}
        tableScrollSize={tableScrollSize}
      />
      <ExpandableCommentTableRow timepoints={timepoints} tableScrollSize={tableScrollSize} />
      {!tableScrollSize.isScrolling && (
        <TableRow>
          <TableCell
            colSpan={1}
            sx={{
              padding: 1,
            }}
          />
        </TableRow>
      )}
    </TableBody>
  )
}
