import { IconButton, Stack, Table, TableCell, TableRow, Typography, useTheme } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { TargetIcon } from '@common/components/Icons'
import { ReadTaskSummaryData } from '@common/config/api/client'
import { TableScrollSize } from '@features/reader_summary/pages/summary.types'

export default function TargetLesionsTableRow({
  expanded,
  timepoints,
  tableScrollSize,
  setExpanded,
}: {
  expanded: boolean
  timepoints: ReadTaskSummaryData['timepoints']
  tableScrollSize: TableScrollSize
  setExpanded: (expanded: boolean) => void
}) {
  const theme = useTheme()

  const hasTargetLesionMeasurement = timepoints.some((timepoint) =>
    timepoint.reader_submissions
      .map((reader_submission) => {
        const targetLesionMeasurement = reader_submission.ecrf_form_submission?.target_lesion_measurements?.length
        return targetLesionMeasurement && targetLesionMeasurement > 0
      })
      .includes(true),
  )

  return (
    <TableRow
      data-testid="subject-table-row"
      hover
      sx={{
        '&:hover': {
          '& > td': {
            backgroundColor: (theme) => theme.palette.action.hover,
          },
        },
        bgcolor: 'background.paper',
      }}
    >
      <TableCell
        data-testid="pseudo-id-cell"
        sx={{
          width: tableScrollSize.columnWidth,
          minWidth: tableScrollSize.columnWidth,
          position: 'sticky',
          left: 0,
          background: 'inherit',
          zIndex: 3,
          paddingLeft: 3,
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          sx={{
            color: 'text.primary',
          }}
        >
          <TargetIcon />
          <Typography variant="button">Target lesions</Typography>
        </Stack>
      </TableCell>
      {Array.from({ length: tableScrollSize.emptyCellsCount }).map((_, i) => (
        <TableCell
          key={`empty-${i}`}
          sx={{
            width: tableScrollSize.columnWidth,
            minWidth: tableScrollSize.columnWidth,

            borderBottom: `1px solid ${theme.palette.divider}`,
          }}
        />
      ))}

      {timepoints.map((timepoint, i) => {
        let stickyStyles = {}

        if (i === 0) {
          stickyStyles = {
            position: 'sticky',
            left: tableScrollSize.firstTimepointLeftOffset,
            background: 'inherit',
            zIndex: 2,
          }
        }

        if (i === timepoints.length - 1) {
          stickyStyles = {
            position: 'sticky',
            right: tableScrollSize.checkboxWidth,
            background: 'inherit',
            zIndex: 2,
            borderLeft: `1px solid ${theme.palette.primary.main}`,
          }
        }

        return (
          <TableCell
            key={`target-lesions-length-${i}`}
            sx={{
              padding: 0,
              width: tableScrollSize.columnWidth,
              minWidth: tableScrollSize.columnWidth,
              borderBottom: `1px solid ${theme.palette.divider}`,
              borderLeft: `1px solid ${theme.palette.divider}`,
              ...stickyStyles,
            }}
          >
            {hasTargetLesionMeasurement && (
              <Table
                sx={{
                  padding: 0,
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{
                      maxWidth: tableScrollSize.columnWidth / 2,
                      width: tableScrollSize.columnWidth / 2,
                    }}
                  >
                    {timepoint.reader_submissions[0].ecrf_form_submission?.target_lesion_measurements?.length && (
                      <Typography variant="button" sx={{ color: 'text.primary' }}>
                        {timepoint.reader_submissions[0].ecrf_form_submission?.target_lesion_measurements?.length}
                      </Typography>
                    )}
                  </TableCell>

                  <TableCell
                    align="right"
                    sx={{
                      borderLeft: `1px solid ${theme.palette.divider}`,
                      width: tableScrollSize.columnWidth / 2,
                      maxWidth: tableScrollSize.columnWidth / 2,
                    }}
                  >
                    {timepoint.reader_submissions[1].ecrf_form_submission?.target_lesion_measurements?.length && (
                      <Typography variant="button" sx={{ color: 'text.primary' }}>
                        {timepoint.reader_submissions[1].ecrf_form_submission?.target_lesion_measurements?.length}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              </Table>
            )}
          </TableCell>
        )
      })}

      {hasTargetLesionMeasurement && (
        <TableCell
          padding="checkbox"
          data-testid="expand-cell"
          sx={{
            width: tableScrollSize.checkboxWidth,
            minWidth: tableScrollSize.checkboxWidth,
            position: 'sticky',
            right: 0,
            background: 'inherit',
            zIndex: 3,
            borderBottom: `1px solid ${theme.palette.divider}`,
            borderLeft: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation()
              setExpanded(!expanded)
            }}
            data-testid="expand-button"
          >
            {expanded ? (
              <KeyboardArrowUpIcon data-testid="icon-expanded" />
            ) : (
              <KeyboardArrowDownIcon data-testid="icon-collapsed" />
            )}
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  )
}
