import { useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { PaginatedResponse_StudyUserRoleAssociationSchema_ } from '@common/config/api/client'
import { StudyAssignmentsListParams } from '../types'
import { useListStudyAssignments } from './useListStudyAssignments'
import { useUpdateStudyRole } from './useUpdateStudyRole'
import { useDeleteStudyAssignment } from './useDeleteStudyAssignment'
import { useAddStudyAssignments } from './useAddStudyAssignments'
import { useGetStudyAssignmentsMetadata } from './useGetStudyAssignmentsMetadata'

export interface Study {
  id: string
  name: string
}

export default function useStudyAssignmentsHandlers() {
  const { userId } = useParams<{ userId: string }>()
  const [page, setPage] = useState(0)
  const [studyAssignmentsParams, setStudyAssignmentsParams] = useState<StudyAssignmentsListParams>({
    limit: 50,
    next_cursor: undefined,
    previous_cursor: undefined,
    order_by_field: 'study_id',
    asc_order: true,
  })

  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false)

  const [roleAnchorEl, setRoleAnchorEl] = useState<HTMLElement | null>(null)
  const [activeStudyUserRoleId, setActiveStudyUserRoleId] = useState<string | null>(null)

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [studyUserRoleIdToDelete, setStudyUserRoleIdToDelete] = useState<string>('')

  const { data: studyAssignmentsList, isPending: isStudyAssignmentsListPending } = useListStudyAssignments(
    studyAssignmentsParams,
    userId!,
  )

  const { data: metadata, isLoading: isLoadingMetadata } = useGetStudyAssignmentsMetadata()
  const updateStudyRole = useUpdateStudyRole()
  const deleteStudyAssignment = useDeleteStudyAssignment()
  const addStudyAssignments = useAddStudyAssignments()

  const isLoading =
    isStudyAssignmentsListPending ||
    updateStudyRole.isPending ||
    deleteStudyAssignment.isPending ||
    isLoadingMetadata ||
    addStudyAssignments.isPending

  const activeStudyAssignment = useMemo(() => {
    if (!studyUserRoleIdToDelete || !studyAssignmentsList?.items) return null
    return studyAssignmentsList.items.find((assignment) => assignment.study_user_role_id === studyUserRoleIdToDelete)
  }, [studyUserRoleIdToDelete, studyAssignmentsList?.items])

  const availableStudies = useMemo(() => {
    if (!metadata?.studies || !studyAssignmentsList?.items) return []

    const assignedStudyIds = new Set(studyAssignmentsList.items.map((assignment) => assignment.study_id))

    return metadata.studies.filter((study: Study) => !assignedStudyIds.has(study.id))
  }, [metadata?.studies, studyAssignmentsList?.items])

  const handlePageChange = (
    studyAssignmentsList: PaginatedResponse_StudyUserRoleAssociationSchema_ | undefined,
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    const isNextPage = newPage > page
    const cursor = isNextPage
      ? studyAssignmentsList?.pagination.next_cursor
      : studyAssignmentsList?.pagination.previous_cursor

    if (cursor) {
      setStudyAssignmentsParams((prev) => ({
        ...prev,
        next_cursor: isNextPage ? cursor : null,
        previous_cursor: isNextPage ? null : cursor,
      }))
      setPage(isNextPage ? page + 1 : page - 1)
    }
  }

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStudyAssignmentsParams((prev) => ({
      ...prev,
      limit: parseInt(event.target.value, 10),
      next_cursor: null,
      previous_cursor: null,
    }))
    setPage(0)
  }

  const handleRequestSort = (_event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = studyAssignmentsParams.order_by_field === property && studyAssignmentsParams.asc_order
    setStudyAssignmentsParams((prev) => ({
      ...prev,
      order_by_field: property,
      asc_order: !isAsc,
      next_cursor: undefined,
      previous_cursor: undefined,
    }))
    setPage(0)
  }

  const handleRoleClick = (event: React.MouseEvent<HTMLElement>, studyId: string) => {
    event.stopPropagation()
    setRoleAnchorEl(event.currentTarget)
    setActiveStudyUserRoleId(studyId)
  }

  const handleRoleMenuClose = () => {
    setRoleAnchorEl(null)
    setActiveStudyUserRoleId(null)
  }

  const handleRoleSelect = (roleId: string) => {
    if (activeStudyUserRoleId && userId) {
      updateStudyRole.mutate({
        userId,
        studyUserRoleId: activeStudyUserRoleId,
        roleId,
      })
    }
    handleRoleMenuClose()
  }

  // Delete handlers
  const handleDeleteStudyAssignmentClick = (event: React.MouseEvent, studyUserRoleId: string) => {
    event.stopPropagation()
    setStudyUserRoleIdToDelete(studyUserRoleId)
    setDeleteDialogOpen(true)
  }

  const handleConfirmDelete = () => {
    if (userId) {
      deleteStudyAssignment.mutate({
        userId,
        studyUserRoleId: studyUserRoleIdToDelete,
      })
    }
    setDeleteDialogOpen(false)
  }

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false)
  }

  const handleAddStudyAssignment = () => {
    setIsAddDialogOpen(true)
  }

  const handleCloseAddDialog = () => {
    setIsAddDialogOpen(false)
  }

  return {
    isLoading,
    studyAssignmentsList,
    page,
    studyAssignmentsParams,
    roleAnchorEl,
    activeStudyUserRoleId,
    handleRoleClick,
    handleRoleMenuClose,
    handleRoleSelect,
    deleteDialogOpen,
    studyUserRoleIdToDelete,
    activeStudyAssignment,
    handleDeleteStudyAssignmentClick,
    handleConfirmDelete,
    handleCancelDelete,
    handlePageChange,
    handleRowsPerPageChange,
    handleRequestSort,
    handleAddStudyAssignment,
    isAddDialogOpen,
    handleCloseAddDialog,
    availableStudies,
    isUpdatingRole: updateStudyRole.isPending,
    isDeletingAssignment: deleteStudyAssignment.isPending,
  }
}
