import { FormControl, Stack, Typography } from '@mui/material'
import { useFormContext, Path } from 'react-hook-form'
import { InputField } from '@features/qc_task_details_view/pages/qc/InputField'

interface FormWithCredentials {
  username: string
  password: string
}

export default function EsignStep<TFormValues extends FormWithCredentials>() {
  const { control } = useFormContext<TFormValues>()

  return (
    <>
      <Stack alignItems="flex-start" alignSelf="stretch">
        <Typography variant="h6" color="text.primary">
          Sign to confirm
        </Typography>
        <Typography variant="body1" color="text.primary">
          Entering your credentials equals a legally binding signature
        </Typography>
      </Stack>
      <FormControl fullWidth>
        <Stack spacing={2} alignSelf="stretch" paddingX={1}>
          <InputField control={control} name={'username' as Path<TFormValues>} label="Username" disabled />
          <InputField control={control} name={'password' as Path<TFormValues>} label="Password" type="password" />
        </Stack>
      </FormControl>
    </>
  )
}
