/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Stack } from '@mui/material'
import { useForm, FormProvider, useFieldArray } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import AddIcon from '@mui/icons-material/Add'
import { useState, useEffect } from 'react'
import { useUsersList } from '@features/user_management/hooks'
import { CompaniesMetadataSchema, UserAssignSchema, CompanyType } from '@common/config/api/client'
import { SPACING } from '@common/theme/spacing'
import { SIZING } from '@common/theme/sizing'
import BaseDialog from '@common/components/BaseDialog'
import { AssignUserFormSchema } from '@features/user_management/schemas/userValidationSchema'
import { z } from 'zod'
import AssignUserCard from './AssignUserCard'

export interface AssignUserFormData {
  users: (UserAssignSchema & {
    company_type?: CompanyType | null
    company_name?: string
    isCollapsed?: boolean
    userName?: string
    roleName?: string
    companyName?: string
    companyTypeName?: string
  })[]
}

interface Props {
  open: boolean
  onClose: () => void
  companiesMetadata: CompaniesMetadataSchema | undefined
  onSubmit: (data: AssignUserFormData) => void
}

type UserAssignmentFormData = z.infer<typeof AssignUserFormSchema>

export default function AssignUserModal({ open, onClose, onSubmit, companiesMetadata }: Readonly<Props>) {
  const [userSearchQuery] = useState('')

  const methods = useForm<UserAssignmentFormData>({
    defaultValues: {
      users: [
        {
          user_id: '',
          role_id: '',
          company_id: '',
          company_type: null,
          company_name: '',
        },
      ],
    },
    resolver: zodResolver(AssignUserFormSchema),
    mode: 'onChange',
  })

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'users',
  })

  const { data: usersList } = useUsersList({
    search: userSearchQuery,
    company_type: 'all',
    order_by_field: 'user_name',
    limit: 200,
  })

  const handleSubmit = (data: AssignUserFormData) => {
    const cleanedData = {
      users: data.users.map(
        ({ company_type, company_name, isCollapsed, userName, roleName, companyName, companyTypeName, ...rest }) =>
          rest,
      ),
    }
    onSubmit(cleanedData as AssignUserFormData)
    methods.reset()
  }

  useEffect(() => {
    if (!open) {
      methods.reset({
        users: [
          {
            user_id: '',
            role_id: '',
            company_id: '',
            company_type: null,
            company_name: '',
            isCollapsed: false,
            userName: '',
            roleName: '',
            companyName: '',
            companyTypeName: '',
          },
        ],
      })
    }
  }, [open, methods])

  const handleAddUser = async () => {
    const lastIndex = fields.length - 1

    const userFieldPath = `users.${lastIndex}`
    const isValid = await methods.trigger(userFieldPath as keyof AssignUserFormData)

    if (isValid) {
      const currentUser = methods.getValues(`users.${lastIndex}`)

      const selectedUser = usersList?.items.find((user) => user.user_id === currentUser.user_id)
      const selectedCompany = companiesMetadata?.companies.find(
        (company) => company.company_id === currentUser.company_id,
      )
      const selectedRole = selectedCompany?.roles.find((role) => role.role_id === currentUser.role_id)
      const selectedCompanyType = companiesMetadata?.company_types.find(
        ([value, _]) => value === currentUser.company_type,
      )

      methods.setValue(`users.${lastIndex}.isCollapsed`, true)
      methods.setValue(`users.${lastIndex}.userName`, selectedUser?.user_name || '')
      methods.setValue(`users.${lastIndex}.roleName`, selectedRole?.role_name || '')
      methods.setValue(`users.${lastIndex}.companyName`, selectedCompany?.company_name || '')
      methods.setValue(`users.${lastIndex}.companyTypeName`, selectedCompanyType ? selectedCompanyType[1] : '')

      append({
        user_id: '',
        role_id: '',
        company_id: '',
        company_type: null,
        company_name: '',
        isCollapsed: false,
        userName: '',
        roleName: '',
        companyName: '',
        companyTypeName: '',
      })
    }
  }

  return (
    <BaseDialog
      title="Assign existing users"
      description="Provide the new users details below"
      open={open}
      onClose={onClose}
      onSubmit={methods.handleSubmit(handleSubmit)}
      width={SIZING.modalWidth}
      sx={{ '& .MuiDialog-paper': { p: SPACING.minSpacing } }}
      submitLabel="Assign users"
      cancelLabel="Cancel"
    >
      <FormProvider {...methods}>
        <Stack spacing={SPACING.spacingXl}>
          {fields.map((field, index) => (
            <AssignUserCard
              key={field.id}
              index={index}
              companiesMetadata={companiesMetadata}
              usersList={usersList}
              onRemove={remove}
              isLastField={index === fields.length - 1}
            />
          ))}

          <Button
            startIcon={<AddIcon />}
            onClick={handleAddUser}
            sx={{ alignSelf: 'flex-start', color: 'primary.main' }}
            variant="text"
          >
            Add user
          </Button>
        </Stack>
      </FormProvider>
    </BaseDialog>
  )
}
