import { QcTaskMetadataSchema, QcTaskState } from '@common/config/api/client'
import FormRendererDialog from '@features/subject/components/FormRendererDialog'
import { AddRounded, EditRounded, RemoveRedEyeRounded } from '@mui/icons-material'
import { Button, Paper, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { toast } from 'sonner'
import { handleMutationError } from '@common/hooks/handleMutationError'
import Toast from '@common/components/Toast'
import { queryClient } from '@common/config/api/queryClient'
import { useUpdateQcTaskFormSubmission } from '@features/qc_task_details_view/hooks/useGetQCTaskSubmussionMutations'
import { queryKeyFactory } from '@features/qc_task_details_view/hooks/queryKeyFactory'

export interface QcFormProps {
  formMetadata: QcTaskMetadataSchema
  taskState: QcTaskState
}

export interface QcFormValues {
  qc_form_id: string | null
  qc_form_submission: string
  eFormFields: string
}
export default function QCForm({
  formMetadata: { qc_task_form_definition, qc_task_id, study_id, submitted_form },
  taskState,
}: QcFormProps) {
  const [eFormDialogState, setEFormUploadDialogState] = useState<{
    visible: boolean
    eFormFields?: string | null
    eFormValues?: string | null
    source: 'QC'
  }>({
    visible: false,
    eFormFields: JSON.stringify(qc_task_form_definition.fields),
    eFormValues: submitted_form,
    source: 'QC',
  })
  const { mutate: updateQcTaskFormSubmission } = useUpdateQcTaskFormSubmission()

  const handleSubmitEForm = async (data: Record<string, string | number | boolean>) => {
    const stringifiedData = JSON.stringify(data)
    setEFormUploadDialogState({
      ...eFormDialogState,
      eFormValues: stringifiedData,
    })
    updateQcTaskFormSubmission(
      {
        qc_task_id: qc_task_id,
        study_id: study_id,
        study_form_id: qc_task_form_definition.id,
        submission_data: {
          submitted_form: stringifiedData ?? '',
        },
      },
      {
        onSuccess: () => {
          setEFormUploadDialogState({
            ...eFormDialogState,
            eFormValues: stringifiedData,
            visible: false,
          })
          toast(<Toast message="QC Form submitted successfully." variant="success" />)
          queryClient.invalidateQueries({
            queryKey: queryKeyFactory.qcTaskMetadata({ study_id, qc_task_id }),
          })
        },
        onError: handleMutationError,
      },
    )
  }

  return (
    <>
      <Paper
        sx={{
          padding: 3,
          borderRadius: 4,
        }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">{qc_task_form_definition.name}</Typography>
          <Button
            size="large"
            sx={{
              borderStyle: submitted_form ? '' : 'solid',
              borderColor: 'secondary.dark',
              borderWidth: submitted_form ? 0 : 1,
              borderRadius: 2,
              marginTop: 2,
            }}
            onClick={() =>
              setEFormUploadDialogState({
                ...eFormDialogState,
                visible: true,
              })
            }
          >
            {taskState !== 'COMPLETE' ? (
              submitted_form ? (
                <EditRounded sx={{ color: 'black' }} />
              ) : (
                <>
                  <AddRounded sx={{ color: 'black' }} />
                  <Typography variant="button" color="black">
                    Fill out eForm
                  </Typography>
                </>
              )
            ) : (
              <RemoveRedEyeRounded sx={{ color: 'black' }} />
            )}
          </Button>
        </Stack>
      </Paper>
      {eFormDialogState.visible && (
        <FormRendererDialog
          header="Fill eForm"
          onClose={() => setEFormUploadDialogState({ ...eFormDialogState, visible: false })}
          fields={eFormDialogState.eFormFields}
          values={eFormDialogState.eFormValues}
          onSubmit={handleSubmitEForm}
          readOnly={taskState == 'COMPLETE'}
        />
      )}
    </>
  )
}
