import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { useState } from 'react'
import { PopupState } from 'material-ui-popup-state/hooks'
import { z } from 'zod'
import { Control, useForm, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { QcStudyDefinitionCreateFormValues } from '../../schemas/StudyAndSeriesDefinitionValidationSchema'
import { CellMergeIcon } from '@common/components/Icons'
import { SelectField } from '../qc/SelectField'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  borderRadius: 2,
  bgcolor: 'background.default',
  boxShadow: 8,
  padding: 1,
}

const MergeValidationMessage = 'Source series description is required.'

const MergeValidationSchema = z.object({
  new_series_description: z.string().min(1, MergeValidationMessage),
})

export default function MergeModal({
  control,
  popupState,
  index,
  newSeriesDescriptions,
  handleMerge,
}: {
  control: Control<QcStudyDefinitionCreateFormValues>
  newSeriesDescriptions: { value: string; label: string }[]
  popupState?: PopupState
  index?: number
  handleMerge: (new_series_description: string, index?: number) => void
}) {
  const [open, setOpen] = useState(false)
  const {
    control: modalControl,
    watch,
    formState: { isValid },
    setError,
  } = useForm({
    mode: 'onChange',
    defaultValues: { new_series_description: '' },
    resolver: zodResolver(MergeValidationSchema),
  })

  const [series_definitions] = useWatch({ control, name: ['series_definitions'] })

  const new_series_description = watch('new_series_description')

  const selectedNewSeriesDescriptionOptions = series_definitions.map((item) => item.series_label_id)
  const availableNewSeriesDescriptionOptions = newSeriesDescriptions.filter((item) =>
    selectedNewSeriesDescriptionOptions.includes(item.value),
  )

  const handleClose = () => {
    setOpen(false)
    if (popupState) {
      popupState.close()
    }
  }

  const handleSubmit = () => {
    if (isValid) {
      handleMerge(new_series_description, index)
      handleClose()
    } else {
      setError('new_series_description', { message: MergeValidationMessage })
    }
  }

  return (
    <>
      <Box
        onClick={() => setOpen(true)}
        sx={{
          display: 'flex',
          justifyContent: 'start',
          width: '100%',
          gap: 1.5,
          alignItems: 'center',
        }}
        data-testid="merge-button"
      >
        <CellMergeIcon />
        {index !== undefined && <Typography>Merge series</Typography>}
      </Box>
      <Modal open={open} onClose={handleClose} data-testid="merge-modal">
        <Box sx={modalStyle}>
          <Box
            sx={{
              display: 'flex',
              padding: 2,
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: 0.5,
              alignSelf: 'stretch',
              width: 600,
            }}
          >
            <Typography variant="h6" color="text.primary">
              Series merge
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Select a new series description to merge into
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              alignSelf: 'stretch',
              padding: 2,
            }}
          >
            <SelectField
              control={modalControl}
              name="new_series_description"
              label="New series description"
              options={availableNewSeriesDescriptionOptions}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              alignSelf: 'stretch',
              padding: 2,
              gap: 1,
            }}
          >
            <Button
              variant="contained"
              sx={{
                bgcolor: 'secondary.main',
                color: 'secondary.contrastText',
              }}
              onClick={handleClose}
              data-testid="cancel-merge-button"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                bgcolor: 'primary.main',
                color: 'primary.contrastText',
              }}
              data-testid="confirm-merge-button"
              onClick={handleSubmit}
            >
              Submit merge
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}
