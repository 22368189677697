import { Collapse, Table, TableBody, TableCell } from '@mui/material'
import SubjectTableRow from './SubjectTableRow'
import { useState } from 'react'
import TimepointSubmissionTableRow from './TimepointSubmissionTableRow'
import DefaultSubjectTableHeader from './DefaultSubjectTableHeader'
import { TimepointSubject } from '@common/config/api/client'
import { TimepointSubmissionCell, TimepointSubmissionRow } from '@features/subject/subject.types'

function formatTimepointRows(subject: TimepointSubject): TimepointSubmissionRow[] {
  const timepointCellsPerTimepoint: TimepointSubmissionCell[][] = subject.study_timepoints!.map((tp) => {
    const cells: TimepointSubmissionCell[] = tp.timepoint_submissions!.map((submission) => ({
      timepoint_submission: submission,
    }))
    if (cells.length < tp.max_number_of_timepoint_submissions) {
      cells.push({ isLast: true, timepoint_id: tp.timepoint_id })
    }
    return cells
  })

  const maxCells = Math.max(...timepointCellsPerTimepoint.map((cells) => cells.length))

  const timepointRows: TimepointSubmissionRow[] = []
  for (let rowIndex = 0; rowIndex < maxCells; rowIndex++) {
    const timepoint_submissions = timepointCellsPerTimepoint.map((cells) => cells[rowIndex] || {})
    timepointRows.push({ timepoint_submissions })
  }

  return timepointRows
}

export default function ExpandableSubjectTableRow({ subject }: { subject: TimepointSubject }) {
  const [expanded, setExpanded] = useState(false)
  const timepointRows = formatTimepointRows(subject)

  return (
    <>
      <SubjectTableRow
        subject={subject}
        expanded={expanded}
        setExpanded={setExpanded}
        data-testid="subject-table-row"
      />
      <TableCell style={{ padding: 0 }} colSpan={subject.study_timepoints!.length + 5} data-testid="collapse-cell">
        <Collapse in={expanded} timeout="auto" data-testid="collapse-section">
          <Table data-testid="expanded-table">
            <DefaultSubjectTableHeader studyTimepoints={subject.study_timepoints!} />
            <TableBody>
              {timepointRows.map((timepointRow, index) => (
                <TimepointSubmissionTableRow
                  key={index}
                  timepointSubmissionRow={timepointRow}
                  subjectId={subject.subject_id}
                  data-testid={`timepoint-submission-row-${index}`}
                />
              ))}
            </TableBody>
          </Table>
        </Collapse>
      </TableCell>
    </>
  )
}
