import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import GoldenRetriever from '@uppy/golden-retriever'

import { ALLOWED_META_FIELDS } from '@features/subject/utils'
import { SESSION_STORAGE_KEY } from '@common/constants/api'

let uppyInstance: Uppy | null = null

export const createUppy = () => {
  if (uppyInstance) return uppyInstance

  const userData = sessionStorage.getItem(SESSION_STORAGE_KEY)

  if (!userData) {
    throw new Error('No user data found in Session Storage')
  }

  const user = JSON.parse(userData)
  const access_token = user['access_token']

  uppyInstance = new Uppy()
    .use(Tus, {
      endpoint: `${import.meta.env.VITE_API_URL}/timepoint/files`,
      allowedMetaFields: ALLOWED_META_FIELDS,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
    .use(GoldenRetriever, { serviceWorker: true })

  return uppyInstance
}
