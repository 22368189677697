import { FormControl, FormHelperText, MenuItem, Select, Stack, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { InputField } from '@features/qc_task_details_view/pages/qc/InputField'
import { ReaderTaskSubmissionFormValues } from '../../schemas/ReaderTaskSubmissionValidationSchema'
import { useGetAdjudicationJustifications } from '../../hooks/useGetAdjudicationJustifications'

export default function DisagreeStep() {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<ReaderTaskSubmissionFormValues>()
  const readTaskSubmission = watch('context.read_task_submission')

  const { data, error, isLoading } = useGetAdjudicationJustifications(['ReadTasksAdjudicationJustifications'])

  if (isLoading) {
    return <div>Loading...</div>
  }
  if (error) {
    return <div>Error: {error.message}</div>
  }
  return (
    <>
      <Stack alignItems="flex-start" alignSelf="stretch">
        <Typography variant="h6" color="text.primary">
          Why do you not agree with Reader I or Reader II?
        </Typography>
      </Stack>
      <FormControl fullWidth>
        <Stack spacing={2} alignSelf="stretch" paddingX={1}>
          <FormControl fullWidth error={!!errors?.context?.read_task_submission?.adjudication_justification_id}>
            <Select
              displayEmpty
              value={readTaskSubmission.adjudication_justification_id}
              onChange={(e) => setValue('context.read_task_submission.adjudication_justification_id', e.target.value)}
            >
              <MenuItem value="" disabled>
                Select a reason
              </MenuItem>
              {data?.data?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.justification}
                </MenuItem>
              ))}
            </Select>
            {errors?.context?.read_task_submission?.adjudication_justification_id && (
              <FormHelperText error>
                {errors.context.read_task_submission.adjudication_justification_id.message}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth>
            <InputField
              control={control}
              name="context.read_task_submission.comment"
              label="Comment"
              multiline
              rows={6}
              fullWidth
            />
          </FormControl>
        </Stack>
      </FormControl>
    </>
  )
}
