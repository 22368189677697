import { useReadTaskESignMutation } from './useReadTaskESignMutation'
import { ReaderTaskSubmissionFormValues } from '../schemas/ReaderTaskSubmissionValidationSchema'
import { ReadTaskSubmissionResponseSchema, ReadTaskSubmissionSchema } from '@common/config/api/client'
import { AxiosError } from 'axios'
import { HTTPValidationError } from '@common/config/api/client'

type UseAdjudicationDecisionMutationParams = {
  studyId: string
  readTaskId: string
}

export const useAdjudicationDecisionMutation = ({ studyId, readTaskId }: UseAdjudicationDecisionMutationParams) => {
  const mutation = useReadTaskESignMutation()

  const submitAdjudicationDecision = async (data: ReaderTaskSubmissionFormValues) => {
    const readTaskSubmission: ReadTaskSubmissionSchema = {
      adjudication_agreed: data.context.read_task_submission.adjudication_agreed,
      adjudication_justification_id: data.context.read_task_submission.adjudication_justification_id,
      justification_comment: data.context.read_task_submission.justification_comment ?? '',
      comment: data.context.read_task_submission.comment,
      ...(data.context.read_task_submission.adjudication_selected_read_task_id
        ? { adjudication_selected_read_task_id: data.context.read_task_submission.adjudication_selected_read_task_id }
        : {}),
    }

    return mutation.mutateAsync({
      body: {
        username: data.username,
        password: data.password,
        context: {
          type: 'reader_task_submission',
          read_task_submission: readTaskSubmission,
        },
      },
      query: {
        read_task_id: readTaskId,
        study_id: studyId,
      },
    })
  }

  return {
    submitAdjudicationDecision,
    isLoading: mutation.isPending,
    isError: mutation.isError,
    error: mutation.error as AxiosError<HTTPValidationError> | null,
    data: mutation.data as ReadTaskSubmissionResponseSchema | undefined,
  }
}
