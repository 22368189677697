import { Box, Stack, Typography } from '@mui/material'
import TimePointsGroup from '../TimePointsGroup'

export type TimePointManagerProps = {
  selectedTimePointSubmission: string
  timePoints: Array<{
    name: string
    id: string
    maximumAllowedTimepoints: number
    children: Array<{ name: string; id: string }>
  }>
}

export default function TimePointManager({ selectedTimePointSubmission, timePoints }: TimePointManagerProps) {
  return (
    <Box px={3}>
      <Typography variant="h6" mb={0.75}>
        All timepoints
      </Typography>
      <Stack gap={1} py={3} data-testid="time-points-group">
        {timePoints.map((item, index) => (
          <TimePointsGroup
            key={index}
            id={item.id}
            name={item.name}
            children={item.children}
            selectedTimePointSubmission={selectedTimePointSubmission}
            maximumAllowedTimepoints={item.maximumAllowedTimepoints}
          />
        ))}
      </Stack>
    </Box>
  )
}
