import BaseDialog from '@common/components/BaseDialog'
import { TextField } from '@common/components/Form/TextField'
import { zodResolver } from '@hookform/resolvers/zod'
import { alpha, Stack, Typography, useTheme } from '@mui/material'
import { useForm, UseFormWatch } from 'react-hook-form'
import { SiteCreateSchema } from '@features/study_details/sites/schemas/sitesValidationSchema'
import RadioGroup from '@common/components/Form/RadioGroup'
import { SPACING } from '@common/theme/spacing'
import { Dropdown } from '@common/components/Form/Dropdown'
import { useGetStudySitesMetadata } from '../hooks/useStudySitesQueries'
import { useParams } from 'react-router-dom'
import { SiteAssociationTypeEnum } from '../types'
import { SyntheticEvent, useMemo, useState } from 'react'
import PersonIcon from '@mui/icons-material/Person'
import { useGetStudyById } from '@study_setup/hooks/useStudyQueries'
import { SIZING } from '@common/theme/sizing'
import { Option } from '@common/components/Form/Dropdown/Dropdown'

export type SiteFormValues = {
  pseudo_id: string
  name: string
  contact_email: string
  contact_phone: string | null
  site_association_type: SiteAssociationTypeEnum
  modalities: Array<string>
  site_associations: Array<string>
}

export interface CreateSitePayload extends Omit<SiteFormValues, 'name' | 'contact_email' | 'contact_phone'> {
  site_id?: string
  name?: string | null
  contact_email?: string | null
  contact_phone?: string | null
}

export const useExistingSiteCheck = (watch: UseFormWatch<SiteFormValues>, siteOptions: Option[]) => {
  const siteName = watch('name')

  return {
    isExistingSite: siteOptions.some((option) => option.value === siteName),
    selectedSite: siteOptions.find((option) => option.value === siteName),
  }
}

export interface CreateSiteDialogProps {
  onSave: (data: CreateSitePayload) => void
  onClose: () => void
  data?: SiteFormValues | null
  title: string
}

const BULK_UPLOAD_SITES = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
]
const SITE_ASSOCIATION_TYPE = [
  {
    label: 'Primary',
    value: SiteAssociationTypeEnum.PRIMARY,
  },
  {
    label: 'Secondary',
    value: SiteAssociationTypeEnum.SECONDARY,
  },
]

const CreateSiteDialog = ({ onSave, onClose, data, title }: CreateSiteDialogProps) => {
  const { studyId } = useParams<{ studyId: string }>()

  const [showForm, setShowForm] = useState(false)
  const theme = useTheme()

  const { data: studySitesMetadata, isLoading } = useGetStudySitesMetadata(studyId!)
  const { data: studyData } = useGetStudyById(studyId!)

  const siteCreationModeForm = useForm<{ bulk_upload_sites: boolean }>({
    mode: 'onSubmit',
  })
  const singleSiteForm = useForm<SiteFormValues>({
    resolver: zodResolver(SiteCreateSchema),
    mode: 'onSubmit',
    defaultValues: {
      name: data?.name || '',
      pseudo_id: data?.pseudo_id,
      site_association_type: data?.site_association_type ?? SiteAssociationTypeEnum.PRIMARY,
      contact_email: '',
      contact_phone: null,
      modalities: [],
    },
  })

  const siteOptions = useMemo(
    () =>
      studySitesMetadata?.sites.map((site) => ({
        label: site.name,
        value: site.name,
        additional_fields: {
          site_id: site.id,
          contact_email: site.email,
          contact_phone: site.phone,
        },
      })) || [],
    [studySitesMetadata],
  )

  const { isExistingSite } = useExistingSiteCheck(singleSiteForm.watch, siteOptions)

  const bulkUploadSites = siteCreationModeForm.watch('bulk_upload_sites')
  const siteAssociationType = singleSiteForm.watch('site_association_type')
  const siteAssociations = singleSiteForm.watch('site_associations')

  const getOppositeAssociationTypeData = (type: SiteAssociationTypeEnum) => {
    return type === SiteAssociationTypeEnum.PRIMARY
      ? {
          value: SiteAssociationTypeEnum.SECONDARY,
          label: 'Secondary',
        }
      : {
          value: SiteAssociationTypeEnum.PRIMARY,
          label: 'Primary',
        }
  }

  const getAvailableSiteAssociations = () => {
    if (!studySitesMetadata?.site_associations) return []
    const oppositeType = getOppositeAssociationTypeData(siteAssociationType as SiteAssociationTypeEnum).value
    return studySitesMetadata.site_associations[oppositeType] || []
  }

  const isBulkUploadSitesTruthy = () => bulkUploadSites !== undefined && bulkUploadSites !== null

  const handleSiteNameChange = (_: SyntheticEvent, value: unknown) => {
    const option = value as Option | null

    singleSiteForm.setValue('name', option?.value ?? '')
    singleSiteForm.setValue('contact_email', (option?.additional_fields?.contact_email as string) ?? '')
    singleSiteForm.setValue('contact_phone', (option?.additional_fields?.contact_phone as string) ?? null)
  }

  const handleSiteNameInput = (_: SyntheticEvent, value: string) => {
    singleSiteForm.setValue('name', value)

    const existingSite = siteOptions.find((option) => option.value === value)

    if (existingSite) {
      singleSiteForm.setValue('contact_email', (existingSite.additional_fields?.contact_email as string) ?? '')
      singleSiteForm.setValue('contact_phone', (existingSite.additional_fields?.contact_phone as string) ?? null)
    } else {
      singleSiteForm.setValue('contact_email', '')
      singleSiteForm.setValue('contact_phone', null)
    }
  }

  const handleFormSubmit = async () => {
    if (!showForm) {
      if (isBulkUploadSitesTruthy()) {
        setShowForm(true)
      }
      return
    }

    const isValid = await singleSiteForm.trigger()
    if (!isValid) return

    const formData = singleSiteForm.getValues()
    const existingSite = siteOptions.find((option) => option.value === formData.name)

    const payload: CreateSitePayload = {
      pseudo_id: formData.pseudo_id,
      site_association_type: formData.site_association_type,
      modalities: formData.modalities,
      site_associations: formData.site_associations,
    }

    if (existingSite) {
      payload.site_id = existingSite.additional_fields?.site_id as string
      payload.name = null
      payload.contact_email = null
      payload.contact_phone = null
    } else {
      payload.name = formData.name
      payload.contact_email = formData.contact_email
      payload.contact_phone = formData.contact_phone
    }

    onSave(payload)
  }

  const renderSingleSiteForm = () => (
    <Stack spacing={SPACING.spacingXl}>
      <Typography variant="body1" color="text.primary">
        1. General information
      </Typography>
      <Stack direction="row" gap={SPACING.formFieldsSpacing}>
        <TextField name="pseudo_id" control={singleSiteForm.control} label="Site ID" />
        <Dropdown
          name="name"
          control={singleSiteForm.control}
          options={siteOptions}
          loading={isLoading}
          label="Name"
          freeSolo
          onChange={handleSiteNameChange}
          onInputChange={handleSiteNameInput}
        />
      </Stack>
      <Stack direction="row" gap={SPACING.formFieldsSpacing}>
        <TextField
          name="contact_email"
          control={singleSiteForm.control}
          label="Email address"
          disabled={isExistingSite}
        />

        <TextField
          name="contact_phone"
          control={singleSiteForm.control}
          label="Phone number"
          disabled={isExistingSite}
        />
      </Stack>
      <Typography variant="body1" color="text.primary">
        2. Modalities
      </Typography>
      <Dropdown
        name="modalities"
        control={singleSiteForm.control}
        options={studySitesMetadata?.modalities ?? []}
        loading={isLoading}
        label="Modalities"
        multiple
      />
      {studyData?.subjects_required_to_visit_multiple_sites && (
        <>
          <Typography variant="body1" color="text.primary">
            3. Site relationships
          </Typography>
          <Stack direction="row" gap={3} alignItems="center">
            <Typography>Is this a primary or secondary site?</Typography>
            <RadioGroup
              name="site_association_type"
              control={singleSiteForm.control}
              options={SITE_ASSOCIATION_TYPE}
              row
              onChange={clearSiteassociations}
            />
          </Stack>
          <Dropdown
            name="site_associations"
            control={singleSiteForm.control}
            options={getAvailableSiteAssociations()}
            label={`Select ${getOppositeAssociationTypeData(siteAssociationType as SiteAssociationTypeEnum).label} site associations`}
            multiple
            getOptionDisabled={() => (siteAssociations?.length > 0 ? true : false)}
          />
        </>
      )}
    </Stack>
  )

  const clearSiteassociations = () => {
    singleSiteForm.setValue('site_associations', [])
  }

  const renderBulkUploadForm = () => {
    return (
      <Stack spacing={SPACING.spacingXl}>
        <Stack
          px={SPACING.spacingLg}
          py={SPACING.spacingXxl}
          alignItems="center"
          justifyContent="center"
          sx={{
            width: 1,
            textAlign: 'center',
            bgcolor: alpha(theme.palette.primary.main, 0.08),
            border: `1px dashed ${theme.palette.primary.main}`,
          }}
        >
          <PersonIcon sx={{ color: theme.palette.primary.main }} />
          <Typography variant="body2" mb={SPACING.spacingSm} mt={SPACING.minSpacing}>
            <Typography
              variant="subtitle2"
              component="span"
              sx={{ color: 'primary.main', textDecoration: 'underline' }}
            >
              Click to upload
            </Typography>
            &nbsp;or drag and drop
          </Typography>
          <Typography gutterBottom variant="body2" color="text.secondary">
            .csv, .xlsx (max. 3MB)
          </Typography>
        </Stack>
      </Stack>
    )
  }

  return (
    <BaseDialog
      title={title}
      description="Please provide the details for this study site below"
      open={true}
      onClose={onClose}
      onSubmit={handleFormSubmit}
      form={singleSiteForm}
      width={SIZING.boxModalWidth}
      submitLabel={showForm ? (bulkUploadSites ? 'Add Sites' : 'Create new site') : 'Create Site'}
      cancelLabel={'Cancel'}
      scroll="paper"
    >
      <Stack spacing={SPACING.spacingXxl}>
        <Stack direction="row" gap={SPACING.spacingXl} alignItems="center">
          <Typography>Do you want to bulk upload sites?</Typography>
          <RadioGroup name="bulk_upload_sites" control={siteCreationModeForm.control} options={BULK_UPLOAD_SITES} row />
        </Stack>

        {showForm && <>{bulkUploadSites ? renderBulkUploadForm() : renderSingleSiteForm()}</>}
      </Stack>
    </BaseDialog>
  )
}

export default CreateSiteDialog
