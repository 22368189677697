import {
  HTTPValidationError,
  DownloadExamMetadataQcTasksQcTaskIdExamDownloadGetResponse,
  DownloadExamMetadataQcTasksQcTaskIdExamDownloadGetData,
  downloadExamMetadataQcTasksQcTaskIdExamDownloadGet,
} from '@common/config/api/client'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export const useDownloadExamMetadataMutation = (): UseMutationResult<
  DownloadExamMetadataQcTasksQcTaskIdExamDownloadGetResponse,
  AxiosError<HTTPValidationError>,
  DownloadExamMetadataQcTasksQcTaskIdExamDownloadGetData
> => {
  return useMutation<
    DownloadExamMetadataQcTasksQcTaskIdExamDownloadGetResponse,
    AxiosError<HTTPValidationError>,
    DownloadExamMetadataQcTasksQcTaskIdExamDownloadGetData
  >({
    mutationFn: async (query) => {
      const response = await downloadExamMetadataQcTasksQcTaskIdExamDownloadGet({
        path: query.path,
        query: query.query,
        throwOnError: true,
      })
      return response.data
    },
  })
}
