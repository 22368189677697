import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function CopyAndClearIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M21.0711 17.0711L19.6569 18.4853L19.084 17.9125C18.8991 17.9694 18.703 18 18.5 18H9.5C8.4 18 7.5 17.1 7.5 16V6.32845L5.51472 4.34317L6.92893 2.92896L21.0711 17.0711ZM9.5 8.32845V16H17.1716L9.5 8.32845Z"
          fill="#F0F3F5"
        />
        <path
          d="M18.5 4V11.6716L20.5 13.6716V4C20.5 2.9 19.6 2 18.5 2H9.5C9.29703 2 9.10086 2.03064 8.91591 2.08753L10.8284 4H18.5Z"
          fill="#F0F3F5"
        />
        <path
          d="M15.5 20H5.5V7C5.5 6.45 5.05 6 4.5 6C3.95 6 3.5 6.45 3.5 7V20C3.5 21.1 4.4 22 5.5 22H15.5C16.05 22 16.5 21.55 16.5 21C16.5 20.45 16.05 20 15.5 20Z"
          fill="#F0F3F5"
        />
      </svg>
    </SvgIcon>
  )
}
