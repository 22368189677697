import { useQuery } from '@tanstack/react-query'
import {
  getStudyByIdStudiesStudyIdGet,
  GetStudyByIdStudiesStudyIdGetResponse,
  HTTPValidationError,
} from '@common/config/api/client'
import { AxiosError } from 'axios'
import queryKeyFactory from '../queryKeyFactory'

export const useGetStudyById = (studyId?: string) => {
  return useQuery<GetStudyByIdStudiesStudyIdGetResponse, AxiosError<HTTPValidationError>>({
    queryKey: queryKeyFactory.study(studyId),
    queryFn: async () => {
      if (!studyId) {
        throw new Error('studyId is required')
      }
      const response = await getStudyByIdStudiesStudyIdGet({
        path: { study_id: studyId },
        throwOnError: true,
      })
      return response.data
    },
    enabled: !!studyId,
    retry: false,
  })
}
