import { useEffect, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import IconButton from '@mui/material/IconButton'
import { OpenInNewRounded } from '@mui/icons-material'
import { FormControl, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material'
import { ReadTaskSummaryData } from '@common/config/api/client'
import { Model } from 'survey-core'
import { Survey } from 'survey-react-ui'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  borderRadius: 2,
  bgcolor: 'background.default',
  boxShadow: 8,
  width: '1040px',
}

interface TimepointOption {
  value: string
  label: string
}

interface TimepointEformModalProps {
  timepoints: ReadTaskSummaryData['timepoints']
  index: number
}

export default function TimepointEformModal({ timepoints, index }: TimepointEformModalProps) {
  const [open, setOpen] = useState(false)
  const [selectedTimepointIndex, setSelectedTimepointIndex] = useState(index)

  useEffect(() => {
    if (open) {
      setSelectedTimepointIndex(index)
    }
  }, [open, index])

  const options: TimepointOption[] = timepoints
    .map((timepoint, i) =>
      timepoint.timepoint_eform_submission ? { value: i.toString(), label: timepoint.timepoint_name } : null,
    )
    .filter((option): option is TimepointOption => option !== null)

  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedTimepointIndex(Number(event.target.value))
  }

  const survey = useMemo(() => {
    const model = new Model(timepoints[selectedTimepointIndex].timepoint_eform_definition)
    model.data = timepoints[selectedTimepointIndex].timepoint_eform_submission
    model.mode = 'display'
    return model
  }, [selectedTimepointIndex, timepoints])

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <IconButton onClick={() => setOpen(true)} aria-label="Open Timepoint eForm">
        <OpenInNewRounded />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        data-testid="reset-modal"
        aria-labelledby="timepoint-eform-modal-title"
        aria-describedby="timepoint-eform-modal-description"
      >
        <Box sx={modalStyle}>
          <Stack padding={3} alignItems="flex-start" alignSelf="stretch">
            <Typography id="timepoint-eform-modal-title" variant="h6">
              Timepoint eForm
            </Typography>
          </Stack>
          <Stack alignSelf="stretch">
            <Stack paddingX={3}>
              <FormControl fullWidth>
                <Select
                  value={selectedTimepointIndex.toString()}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Select Timepoint' }}
                >
                  {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <Stack sx={{ height: 740 }}>
              <Survey model={survey} />
            </Stack>
          </Stack>
          <Stack padding={3} alignItems="flex-end" alignSelf="stretch">
            <Button
              variant="contained"
              sx={{
                bgcolor: 'primary.main',
                color: 'primary.contrastText',
              }}
              onClick={handleClose}
              data-testid="cancel-reset-task-button"
              aria-label="Close Timepoint eForm"
            >
              Close
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  )
}
