import { Box, Button, Modal, Stack } from '@mui/material'
import { FormProvider, UseFormReturn } from 'react-hook-form'
import { ReaderEsignFormValues } from '../../schemas/ReaderEsignValidationSchema'
import EsignStep from '../adjudication/EsignStep'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  borderRadius: 2,
  bgcolor: 'background.default',
  boxShadow: 8,
  padding: 3,
  width: 600,
}

interface ReaderEsignModalProps {
  open: boolean
  onClose: () => void
  onSubmit: (data: ReaderEsignFormValues) => void
  methods: UseFormReturn<ReaderEsignFormValues>
  isLoading?: boolean
}

export default function ReaderEsignModal({
  open,
  onClose,
  onSubmit,
  methods,
  isLoading = false,
}: ReaderEsignModalProps) {
  return (
    <Modal open={open} onClose={onClose} onClick={(e) => e.stopPropagation()}>
      <Box sx={modalStyle}>
        <FormProvider {...methods}>
          <Stack spacing={2} component="form" onSubmit={methods.handleSubmit(onSubmit)} sx={{ width: '100%' }}>
            <EsignStep<ReaderEsignFormValues> />
            <Stack justifyContent="space-between" direction="row" alignSelf="stretch" alignItems="center">
              <Button
                variant="contained"
                sx={{ bgcolor: 'secondary.main', color: 'secondary.contrastText' }}
                onClick={onClose}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{ bgcolor: 'primary.main', color: 'primary.contrastText' }}
                type="submit"
                loading={isLoading}
              >
                Sign
              </Button>
            </Stack>
          </Stack>
        </FormProvider>
      </Box>
    </Modal>
  )
}
