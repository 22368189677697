import { useMutation } from '@tanstack/react-query'
import {
  exportStudySetupStudiesStudyIdExportStudySetupGet,
  ExportStudySetupStudiesStudyIdExportStudySetupGetResponse,
  HTTPValidationError,
} from '@common/config/api/client'
import { AxiosError } from 'axios'

interface ExportParams {
  studyId: string
}

export default function useExportStudy() {
  return useMutation<
    ExportStudySetupStudiesStudyIdExportStudySetupGetResponse,
    AxiosError<HTTPValidationError>,
    ExportParams
  >({
    mutationFn: async ({ studyId }) => {
      const response = await exportStudySetupStudiesStudyIdExportStudySetupGet({
        path: { study_id: studyId },
        throwOnError: true,
        responseType: 'blob',
      })

      const blob = new Blob([response.data as Blob], {
        type: 'application/json',
      })

      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url

      const contentDisposition = response.headers?.['content-disposition']
      const filenameMatch = contentDisposition?.match(/filename="?([^"]*)"?/)
      const filename = filenameMatch?.[1] || `study_export_${studyId}.json`

      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)

      return response.data
    },
  })
}
