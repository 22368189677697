import { ReadTaskSummaryData } from '@common/config/api/client'
import { CHECKBOX_WIDTH, COLUMN_WIDTH, MIN_VISIBLE_TIMEPOINTS } from '@features/reader_summary/pages/constants'
import { TableScrollSize } from '@features/reader_summary/pages/summary.types'
import { ArrowBackRounded } from '@mui/icons-material'
import { Box, Button, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import ReaderSummaryTable from './ReaderSummaryTable'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useExtendedAuth } from '@auth/hooks/useExtendedAuth'
import { ReaderEsignFormValues, readerEsignSchema } from '../../schemas/ReaderEsignValidationSchema'
import ReaderEsignModal from './ReaderEsignModal'
import { useParams } from 'react-router-dom'
import { useReaderTaskEsignMutation } from '../../hooks/useReaderTaskEsignMutation'
import { toast } from 'sonner'
import Toast from '@common/components/Toast'

export default function ReaderSummaryLayout({
  timepoints,
  readTaskId,
  read_task_summary_metadata,
  onClose,
}: {
  timepoints: ReadTaskSummaryData['timepoints']
  readTaskId: string
  read_task_summary_metadata: ReadTaskSummaryData['read_task_summary_metadata']
  onClose?: () => void
}) {
  const [tableScrollSize, setTableScrollSize] = useState<TableScrollSize>({
    columnWidth: COLUMN_WIDTH,
    minVisibleTimepoints: MIN_VISIBLE_TIMEPOINTS,
    emptyCellsCount: 0,
    firstTimepointLeftOffset: COLUMN_WIDTH,
    lastTimepointLeftOffset: 0,
    checkboxWidth: CHECKBOX_WIDTH,
    isScrolling: false,
  })
  const [showEsignModal, setShowEsignModal] = useState(false)
  const { user } = useExtendedAuth()
  const { studyId } = useParams<{ studyId: string }>()

  const methods = useForm<ReaderEsignFormValues>({
    resolver: zodResolver(readerEsignSchema),
    defaultValues: {
      username: user?.profile.preferred_username,
      password: '',
      context: {
        type: 'reader_task_submission',
      },
    },
  })

  const { submitReaderTaskEsign, isLoading } = useReaderTaskEsignMutation({
    studyId: studyId!,
    readTaskId,
  })

  const handleEsignSubmit = async (data: ReaderEsignFormValues) => {
    try {
      await submitReaderTaskEsign(data)
      toast(
        <Toast message="Read task signed and submitted successfully" variant="success" data-testid="success-toast" />,
      )
      setShowEsignModal(false)
      if (onClose) {
        onClose()
      }
    } catch {
      toast(<Toast message="Failed to submit read task" variant="error" data-testid="error-toast" />)
    }
  }

  useEffect(() => {
    const handleResize = () => {
      const scrollBarSize = window.innerWidth - document.body.offsetWidth
      const availableWidth = window.innerWidth - scrollBarSize - 128 - CHECKBOX_WIDTH

      const potentialTimepoints = Math.floor(availableWidth / (COLUMN_WIDTH + 0.4)) - 1
      const newMinVisibleTimepoints = Math.max(potentialTimepoints, 3)

      const newColumnWidth = availableWidth / (newMinVisibleTimepoints + 1) - 0.4

      const totalTimepointColumns = Math.max(newMinVisibleTimepoints, timepoints.length)
      const emptyCellsCount = totalTimepointColumns - timepoints.length
      const firstTimepointLeftOffset = newColumnWidth + emptyCellsCount * newColumnWidth
      const lastTimepointLeftOffset = firstTimepointLeftOffset + (newMinVisibleTimepoints - 1) * newColumnWidth

      const isScrolling = newMinVisibleTimepoints < timepoints.length

      setTableScrollSize((prev) => ({
        ...prev,
        lastTimepointLeftOffset: lastTimepointLeftOffset,
        emptyCellsCount: emptyCellsCount,
        firstTimepointLeftOffset: firstTimepointLeftOffset,
        columnWidth: newColumnWidth,
        minVisibleTimepoints: totalTimepointColumns,
        isScrolling: isScrolling,
      }))
    }

    window.addEventListener('resize', handleResize)

    const observer = new ResizeObserver(handleResize)
    observer.observe(document.body)

    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
      observer.disconnect()
    }
  }, [timepoints])

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          right: 0,
          width: '100%',
          zIndex: 20,
        }}
      >
        <Stack
          direction="row"
          paddingX={4}
          justifyContent="space-between"
          alignItems="center"
          height={56}
          sx={{
            backgroundColor: 'background.default',
          }}
        >
          <Stack>
            <Typography
              variant="body2"
              sx={{
                color: 'text.primary',
              }}
            >
              {read_task_summary_metadata.timepoint_name}
            </Typography>
            <Typography
              variant="caption"
              sx={{
                color: 'text.secondary',
              }}
            >
              Task ID {read_task_summary_metadata.task_pseudo_id}
            </Typography>
          </Stack>
          <Stack>
            <Button variant="text" onClick={onClose}>
              <Typography
                variant="button"
                sx={{
                  color: 'text.primary',
                }}
              >
                Go to tasks
              </Typography>
            </Button>
          </Stack>
        </Stack>
      </Box>
      <Stack paddingX={8} paddingTop={10} paddingBottom={10}>
        <ReaderSummaryTable
          timepoints={timepoints}
          readTaskSummaryMetaData={read_task_summary_metadata}
          tableScrollSize={tableScrollSize}
        />
      </Stack>

      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          width: '100%',
          zIndex: 20,
        }}
      >
        <Stack
          direction="row"
          paddingX={4}
          justifyContent="space-between"
          alignItems="center"
          height={56}
          sx={{
            backgroundColor: 'background.default',
          }}
        >
          <Stack>
            <Button variant="text" onClick={onClose}>
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  color: 'text.primary',
                }}
              >
                <ArrowBackRounded />
                <Typography variant="button">Back to Viewer</Typography>
              </Stack>
            </Button>
          </Stack>
          <Stack>
            <Button
              size="large"
              sx={{
                backgroundColor: 'primary.main',
              }}
              onClick={() => setShowEsignModal(true)}
            >
              <Typography
                variant="button"
                sx={{
                  color: 'primary.contrastText',
                }}
              >
                Sign and send
              </Typography>
            </Button>
          </Stack>
        </Stack>
      </Box>

      <ReaderEsignModal
        open={showEsignModal}
        onClose={() => setShowEsignModal(false)}
        onSubmit={handleEsignSubmit}
        methods={methods}
        isLoading={isLoading}
      />
    </>
  )
}
