import {
  listStudyAdherenceConfigsStudiesStudyIdAdherenceConfigGet,
  HTTPValidationError,
  ListStudyAdherenceConfigsStudiesStudyIdAdherenceConfigGetResponse,
} from '@common/config/api/client'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export const useGetStudyAdherenceConfig = (
  studyId: string,
): UseQueryResult<
  ListStudyAdherenceConfigsStudiesStudyIdAdherenceConfigGetResponse,
  AxiosError<HTTPValidationError>
> => {
  return useQuery({
    queryKey: ['studyAdherenceConfig', studyId],
    queryFn: async () => {
      const response = await listStudyAdherenceConfigsStudiesStudyIdAdherenceConfigGet({
        path: { study_id: studyId },
        throwOnError: true,
      })
      return response.data
    },
  })
}
