import { ListItem, ListItemIcon, ListItemText, Typography, Menu, MenuItem } from '@mui/material'
import { useState, MouseEvent } from 'react'
import { KeyboardArrowRightRounded, CheckRounded, PlayCircleRounded } from '@mui/icons-material'
import { SIZING } from '@common/theme/sizing'
import { SPACING } from '@common/theme/spacing'
import { LabsIcon } from '@common/components/Icons'
import CodeBlocks from '@common/components/Icons/CodeBlocks'
import { Environment } from '@common/constants/environment'

export interface EnvironmentSelectorProps {
  currentEnv: Environment
  onEnvironmentChange: (env: Environment) => void
}

export default function EnvironmentSelector({ currentEnv, onEnvironmentChange }: EnvironmentSelectorProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleOpenEnvMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    event.stopPropagation()
  }

  const handleCloseEnvMenu = () => {
    setAnchorEl(null)
  }

  const handleEnvironmentChange = (env: Environment) => {
    onEnvironmentChange(env)
    handleCloseEnvMenu()
  }

  const envMetadata = {
    DEV: { icon: <CodeBlocks />, label: 'Dev' },
    UAT: { icon: <LabsIcon />, label: 'UAT' },
    PRODUCTION: {
      icon: <PlayCircleRounded sx={{ color: 'success.main', width: SIZING.popupMenuIconWidth }} />,
      label: 'Production',
    },
  }

  return (
    <>
      <Typography
        variant="caption"
        color="text.secondary"
        sx={{ px: SPACING.spacingLg, py: SPACING.spacingMd, display: 'block' }}
      >
        Current environment
      </Typography>

      <ListItem
        component="div"
        onClick={handleOpenEnvMenu}
        sx={{
          px: 2,
          cursor: 'pointer',
          '&:hover': {
            bgcolor: 'action.hover',
          },
        }}
      >
        <ListItemIcon sx={{ minWidth: 36 }}>{envMetadata[currentEnv].icon}</ListItemIcon>
        <ListItemText primary={envMetadata[currentEnv].label} />
        <KeyboardArrowRightRounded fontSize="small" sx={{ ml: 'auto' }} />
      </ListItem>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseEnvMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClick={(e) => e.stopPropagation()}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: SPACING.borderRadiusXl,
            minWidth: SIZING.profileMenuWidth,
          },
        }}
      >
        {(['DEV', 'UAT', 'PRODUCTION'] as Environment[]).map((env) => (
          <MenuItem key={env} onClick={() => handleEnvironmentChange(env)} sx={{ py: 1.5 }}>
            <ListItemIcon>{envMetadata[env].icon}</ListItemIcon>
            <ListItemText primary={envMetadata[env].label} />
            {env === currentEnv && <CheckRounded fontSize="small" sx={{ ml: 'auto', color: 'primary.main' }} />}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
