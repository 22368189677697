import { useMutation } from '@tanstack/react-query'
import {
  promoteStudyStatusStudiesStudyIdPromotePatch,
  PromoteStudyStatusStudiesStudyIdPromotePatchResponse,
  HTTPValidationError,
} from '@common/config/api/client'
import { AxiosError } from 'axios'
import { queryClient } from '@common/config/api/queryClient'
import queryKeyFactory from '../queryKeyFactory'

interface Params {
  studyId: string
}

export default function usePromoteStudyStatus() {
  return useMutation<PromoteStudyStatusStudiesStudyIdPromotePatchResponse, AxiosError<HTTPValidationError>, Params>({
    mutationFn: async ({ studyId }) => {
      const response = await promoteStudyStatusStudiesStudyIdPromotePatch({
        path: { study_id: studyId },
        throwOnError: true,
      })
      return response.data
    },
    onSuccess: (_, { studyId }) => {
      queryClient.invalidateQueries({ queryKey: queryKeyFactory.studyDetails(studyId) })
    },
  })
}
