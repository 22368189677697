import { Button, TextField, InputAdornment, styled, Box } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import { FormType } from '../types'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { SPACING } from '@common/theme/spacing'
import { SIZING } from '@common/theme/sizing'

interface FormManagementTableToolbarProps {
  formTypes?: FormType[]
  onSearch: (value: string) => void
  searchValue?: string
  onAddClick: (formType: FormType) => void
  currentFormType: FormType | null
  onFormTypeChange: (formType: FormType | null) => void
}

const ToolbarContainer = styled(Box)({
  padding: 16,
  display: 'flex',
  gap: 24,
  alignItems: 'center',
})

export default function FormManagementTableToolbar({
  formTypes,
  onSearch,
  searchValue,
  onAddClick,
  currentFormType,
  onFormTypeChange,
}: Readonly<FormManagementTableToolbarProps>) {
  const location = useLocation()
  const [buttonLabel, setButtonLabel] = useState('Add eCRF')

  useEffect(() => {
    if (formTypes) {
      const hash = location.hash.slice(1)
      const newType = hash ? formTypes.find((type) => type.name === hash) : formTypes[0]
      if (newType) {
        setButtonLabel(`Add ${newType.label}`)
        onFormTypeChange(newType)
      }
    }
  }, [location.hash, formTypes, onFormTypeChange])

  const [localSearchValue, setLocalSearchValue] = useState(searchValue ?? '')
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalSearchValue(event.target.value)
  }
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSearch(localSearchValue)
    }
  }

  useEffect(() => {
    setLocalSearchValue(searchValue ?? '')
  }, [searchValue])

  const handleAddClick = () => {
    if (currentFormType) {
      onAddClick(currentFormType)
    }
  }

  return (
    <ToolbarContainer
      sx={{ paddingX: 0, display: 'flex', justifyContent: 'flex-end', gap: SPACING.tableToolbarItemsSpacing }}
    >
      <Button variant="contained" color="secondary" startIcon={<AddIcon />} size="small" onClick={handleAddClick}>
        {buttonLabel}
      </Button>
      <TextField
        placeholder="Search"
        variant="outlined"
        size="small"
        sx={{ minWidth: SIZING.textFieldWidth }}
        value={localSearchValue}
        onChange={handleSearchChange}
        onKeyDown={handleKeyDown}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          },
        }}
      />
    </ToolbarContainer>
  )
}
