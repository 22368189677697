import { useState, MouseEvent } from 'react'

import BaseDialog from '@common/components/BaseDialog'

import { Badge, Box, Typography } from '@mui/material'
import EnhancedTable from '@common/components/Table/EnhancedTable'
import { HeadCell, Order } from '@common/components/Table/table.types'
import DiacomTagsTableBodyView from './DicomTagsTableBodyView'
import {
  ChangeState,
  DicomTagsData,
  DicomTagsDialogProps,
  LegendItemProps,
  Study,
} from '@features/subject/subject.types'
import { useGetStudyTagsDeid } from '@study_setup/hooks/useStudyTagsDeidQueries'
import { DicomTagRecord } from '@common/config/api/client/types.gen'

export const legendItems: LegendItemProps[] = [
  { label: 'Tag Added', status: ChangeState.ADDED_TAG, color: 'success' },
  { label: 'Tag Changed', status: ChangeState.MODIFY_TAG, color: 'warning' },
  { label: 'Tag Unchanged', status: ChangeState.UNCHANGE_TAG, color: 'secondary' },
  { label: 'Tag Removed', status: ChangeState.REMOVE_TAG, color: 'error' },
]

const headCells: readonly HeadCell<DicomTagsData>[] = [
  {
    id: 'status',
    label: '',
    disableSort: false,
    disablePadding: true,
    stickyColumn: true,
    roundCorner: true,
    boldText: true,
  },
  {
    id: 'tag',
    label: 'ID and Tag name',
    disableSort: false,
    disablePadding: true,
    stickyColumn: true,
    roundCorner: true,
    boldText: true,
  },
  {
    id: 'originalValue',
    label: 'Original value',
    disableSort: false,
    disablePadding: true,
    stickyColumn: true,
    roundCorner: true,
    boldText: true,
  },
  {
    id: 'newValue',
    label: 'New value',
    disableSort: false,
    disablePadding: true,
    stickyColumn: true,
    roundCorner: true,
    boldText: true,
  },
]

const getValues = (status: ChangeState, originalValue: string, newValue: string) => {
  switch (status) {
    case ChangeState.MODIFY_TAG:
      return { originalValue: originalValue || '-', newValue: newValue || '-' }

    case ChangeState.UNCHANGE_TAG:
      return { originalValue: originalValue || '-', newValue: originalValue || '-' }

    case ChangeState.REMOVE_TAG:
      return { originalValue: originalValue || '-', newValue: 'Tag will be removed' }

    case ChangeState.ADDED_TAG:
      return { originalValue: '-', newValue: newValue || '-' }
  }
}

const mapTagData = (data1: Study[], data2: DicomTagRecord[], seriesId: number): DicomTagsData[] => {
  const result: DicomTagsData[] = []

  data1.forEach((entry) => {
    entry.series
      .find((x) => x.seriesNumber === seriesId)
      ?.tagData.forEach((tag, index) => {
        const matchingTag = data2.find((item) => item.tag_id === tag.tagId)
        const tagModify = data2.find((item) => item.tag_deid_strategy === ChangeState.MODIFY_TAG)
        if (matchingTag) {
          const status = matchingTag.tag_deid_strategy as ChangeState
          result.push({
            id: index,
            tag: tag.tagId,
            tagName: tag.tagName,
            status: status,
            originalValue: getValues(status, tag.value, matchingTag.value).originalValue,
            newValue: getValues(status, tag.value, matchingTag.value).newValue,
          })

          if (tagModify?.tag_id !== tag.tagId) {
            result.push({
              id: index,
              tag: tag.tagId,
              tagName: tag.tagName,
              status: ChangeState.ADDED_TAG,
              originalValue: getValues(ChangeState.ADDED_TAG, tag.value, tag.value).originalValue,
              newValue: getValues(ChangeState.ADDED_TAG, tag.value, tag.value).newValue,
            })
          }
        }
      })
  })

  return result
}

const DicomTagsDialog = ({ studyId, dicomData, containerParam, onClose, seriesId }: DicomTagsDialogProps) => {
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof DicomTagsData>('status')

  const { data, isLoading, error } = useGetStudyTagsDeid(studyId!)

  const tableData = data && dicomData && mapTagData(dicomData, data, seriesId)

  const rows: Record<string, unknown>[] | undefined = tableData?.map((item) => ({
    ...item,
  }))

  const onRequestSort = (_event: MouseEvent<unknown>, property: keyof DicomTagsData) => {
    const isAsc = order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')

    switch (property) {
      case 'id':
        setOrderBy('status')
        break

      case 'tag':
        setOrderBy('tagName')
        break

      default:
        setOrderBy(property)
    }
  }

  if (isLoading) {
    return <div>Loading...</div>
  }
  if (error) {
    return <div>Error: {error.message}</div>
  }

  return (
    <BaseDialog
      width={1260}
      title="DICOM tags de-identification"
      open={true}
      bgcolor="transparent"
      cancelLabel="Close"
      onClose={() => onClose(undefined)}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 6,
          mb: 2,
        }}
      >
        {legendItems.map((item) => (
          <Box
            key={item.label}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Badge variant="dot" color={item.color} />
            <Typography variant="caption">{item.label}</Typography>
          </Box>
        ))}
      </Box>
      <EnhancedTable<DicomTagsData>
        rows={rows ? rows : []}
        headCells={headCells}
        order={order}
        orderBy={orderBy}
        onRequestSort={onRequestSort}
      >
        <DiacomTagsTableBodyView
          rows={tableData ? tableData : []}
          order={order}
          orderBy={orderBy}
          containerParam={containerParam}
        />
      </EnhancedTable>
    </BaseDialog>
  )
}

export default DicomTagsDialog
