import { readerSummaryReadTasksReaderSummaryGet } from '@common/config/api/client'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

export const useGetSummaryReadTasks = (queryKey: string[], taskId: string) => {
  const { studyId } = useParams<{ studyId: string }>()

  return useQuery({
    queryKey: queryKey,
    queryFn: () =>
      readerSummaryReadTasksReaderSummaryGet({
        query: {
          task_id: taskId,
          study_id: studyId!,
        },
      }),
    enabled: !!studyId,
  })
}
