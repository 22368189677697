import { useState, MouseEvent, ChangeEvent } from 'react'
import { TransformedCompanies } from '@features/company_management/hooks'

interface UseCompanyManagementTableHandlersProps {
  onEditClick: (companyId: string) => void
  onDeleteCompany: (companyId: string) => void
  onBulkDeleteCompanies: (seriesLabelIds: Array<string>) => void
  companies: TransformedCompanies | undefined
}

export default function useCompanyManagementTableHandlers({
  onEditClick,
  onDeleteCompany,
  onBulkDeleteCompanies,
  companies,
}: UseCompanyManagementTableHandlersProps) {
  const [selected, setSelected] = useState<string[]>([])
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(null)

  const handleEditClick = (e: MouseEvent<HTMLElement>, companyId: string) => {
    e.preventDefault()
    e.stopPropagation()
    onEditClick(companyId)
  }

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(companies?.items.map((row) => row.id) ?? [])
    } else {
      setSelected([])
    }
  }
  const handleClick = (_event: MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id)
    const newSelected = selectedIndex === -1 ? [...selected, id] : selected.filter((selectedId) => selectedId !== id)
    setSelected(newSelected)
  }

  const handleOpenMenu = (event: MouseEvent<HTMLElement>, id: string) => {
    event.stopPropagation()
    setMenuAnchorEl(event.currentTarget)
    setSelectedCompanyId(id)
  }

  const handleCloseMenu = () => {
    setMenuAnchorEl(null)
    setSelectedCompanyId(null)
  }

  const handleDeleteCompany = () => {
    if (selectedCompanyId) {
      onDeleteCompany(selectedCompanyId)
      handleCloseMenu()
    }
  }

  const handleBulkDeleteCompanies = () => {
    if (selected.length) {
      onBulkDeleteCompanies(selected)
      setSelected([])
    }
  }
  return {
    selected,
    menuAnchorEl,
    selectedCompanyId,
    handleEditClick,
    handleSelectAllClick,
    handleClick,
    handleOpenMenu,
    handleCloseMenu,
    handleDeleteCompany,
    handleBulkDeleteCompanies,
  }
}
