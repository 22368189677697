import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { deleteCompanyCompaniesCompanyIdDelete } from '@common/config/api/client/services.gen'
import { DeleteCompanyCompaniesCompanyIdDeleteResponse, HTTPValidationError } from '@common/config/api/client'
import { AxiosError } from 'axios'

export default function useDeleteCompany(): UseMutationResult<
  DeleteCompanyCompaniesCompanyIdDeleteResponse,
  AxiosError<HTTPValidationError>,
  string
> {
  return useMutation<DeleteCompanyCompaniesCompanyIdDeleteResponse, AxiosError<HTTPValidationError>, string>({
    mutationFn: async (companyId: string) => {
      const response = await deleteCompanyCompaniesCompanyIdDelete({
        path: { company_id: companyId },
        throwOnError: true,
      })
      return response.data
    },
  })
}
