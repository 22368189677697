import { Chip, type ChipProps } from '@mui/material'
import { TagDeidStrategy } from '@common/config/api/client/types.gen'

interface ChipTagStatusProps extends Omit<ChipProps, 'label' | 'color'> {
  data: TagDeidStrategy
}

const mapTag = (status: TagDeidStrategy) => {
  switch (status) {
    case 'REMOVE_TAG':
      return {
        label: 'Removed',
        color: 'error-alt' as const,
        bgColor: '#FFEEEE',
        textColor: '#D91E1E',
      }
    case 'MODIFY_TAG':
      return {
        label: 'Modified',
        color: 'warning-alt' as const,
        bgColor: '#FFF8E6',
        textColor: '#E39500',
      }
    case 'UNCHANGE_TAG':
      return {
        label: 'Unchanged',
        color: 'info-alt' as const,
        bgColor: '#E6F5FF',
        textColor: '#0079CC',
      }
    default:
      return {
        label: 'Unchanged',
        color: 'info-alt' as const,
        bgColor: '#E6F5FF',
        textColor: '#0079CC',
      }
  }
}

export default function ChipTagStatus({ data, ...chipProps }: ChipTagStatusProps) {
  const { label, color, bgColor, textColor } = mapTag(data)

  return (
    <Chip
      label={label}
      color={color}
      size="small"
      sx={{
        height: 'auto',
        fontSize: '0.75rem',
        fontWeight: 500,
        padding: '2px 8px',
        borderRadius: '16px',
        backgroundColor: bgColor,
        color: textColor,
        '& .MuiChip-label': {
          padding: '0px 6px',
        },
      }}
      {...chipProps}
    />
  )
}
