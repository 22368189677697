import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { useState } from 'react'
import { PopupState } from 'material-ui-popup-state/hooks'
import { toast } from 'sonner'
import Toast from '../Toast'
import { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'
import { TextField } from '@common/components/Form/TextField'
import { HTTPValidationError } from '@common/config/api/client'
import { CheckRounded } from '@mui/icons-material'
import { useReopenTaskMutation } from '../../hooks/useReopenTaskMutation'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  borderRadius: 2,
  bgcolor: 'background.default',
  boxShadow: 8,
  padding: 1,
}

export default function ApproveModal({
  task_id,
  study_id,
  popupState,
}: {
  task_id: string
  study_id: string
  popupState: PopupState
}) {
  const [open, setOpen] = useState(false)
  const { control, watch } = useForm({ mode: 'onChange', defaultValues: { reason: '' } })
  const reason = watch('reason')
  const handleClose = () => {
    setOpen(false)
    popupState.close()
  }
  const { mutateAsync: approveTask } = useReopenTaskMutation()

  const handleApproveTask = async (reason: string) => {
    await approveTask({
      body: {
        action: 'approve',
        comment: reason === '' ? null : reason,
      },
      query: {
        read_task_ids: [task_id],
        study_id: study_id,
      },
    })
      .then(() => {
        toast(<Toast message="Your request was sent successfully" variant="success" data-testid="success-toast" />)
        handleClose()
      })
      .catch((error: AxiosError<HTTPValidationError>) => {
        const errorMessage = Array.isArray(error.response?.data?.detail)
          ? error.response?.data?.detail.map((err) => err.msg).join(', ')
          : error.response?.data?.detail || error.message
        toast(<Toast message={errorMessage} variant="error" data-testid="error-toast" />)
      })
  }

  return (
    <>
      <Box
        onClick={() => setOpen(true)}
        sx={{
          display: 'flex',
          width: '100%',
          gap: 1.5,
          alignItems: 'center',
        }}
        data-testid="approve-task-button"
        tabIndex={0}
      >
        <CheckRounded
          fontSize="small"
          sx={{
            color: 'text.secondary',
          }}
        />
        <Typography variant="body1">Approve request</Typography>
      </Box>
      <Modal open={open} onClose={handleClose} data-testid="approve-modal">
        <Box sx={modalStyle}>
          <Box
            sx={{
              display: 'flex',
              padding: 2,
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: 0.5,
              alignSelf: 'stretch',
              width: 600,
            }}
          >
            <Typography variant="h6" color="text.primary" id="approve-modal-title">
              Approve request?
            </Typography>
            <Typography variant="body1" color="text.secondary" id="approve-modal-description">
              The task will be reopened, allowing the reader to work on it again.
            </Typography>
          </Box>
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              alignSelf: 'stretch',
              padding: 2,
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              name="reason"
              control={control}
              id="outlined-multiline-static"
              label="Provide a reason"
              multiline
              rows={6}
              fullWidth
              onKeyDown={(e) => e.stopPropagation()}
              data-testid="reason-textfield"
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              alignSelf: 'stretch',
              padding: 2,
              gap: 1,
            }}
          >
            <Button
              variant="contained"
              sx={{
                bgcolor: 'secondary.main',
                color: 'secondary.contrastText',
              }}
              onClick={handleClose}
              data-testid="cancel-approve-task-button"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                bgcolor: 'primary.main',
                color: 'primary.contrastText',
              }}
              onClick={() => handleApproveTask(reason)}
              data-testid="confirm-approve-task-button"
            >
              Approve
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}
