import SvgIcon from '@mui/material/SvgIcon'

export default function CellMergeIcon() {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <mask id="mask0_9967_41270" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
          <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_9967_41270)">
          <path
            d="M8.88747 14.8875C9.27997 15.28 9.91719 15.2771 10.3062 14.8812C10.6904 14.4902 10.6876 13.8626 10.3 13.475L8.825 12L10.3 10.525C10.6876 10.1374 10.6904 9.50983 10.3062 9.11881C9.91719 8.72285 9.27997 8.72003 8.88747 9.11253L6.70711 11.2929C6.31658 11.6834 6.31658 12.3166 6.70711 12.7071L8.88747 14.8875ZM13.6938 14.8812C14.0828 15.2771 14.72 15.28 15.1125 14.8875L17.2929 12.7071C17.6834 12.3166 17.6834 11.6834 17.2929 11.2929L15.1125 9.11253C14.72 8.72003 14.0828 8.72285 13.6938 9.1188C13.3096 9.50983 13.3124 10.1374 13.7 10.525L15.175 12L13.7 13.475C13.3124 13.8626 13.3096 14.4902 13.6938 14.8812ZM5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5Z"
            fill="#FFB300"
          />
        </g>
      </svg>
    </SvgIcon>
  )
}
