import { useReadTaskESignMutation } from './useReadTaskESignMutation'
import { ReaderEsignFormValues } from '../schemas/ReaderEsignValidationSchema'
import { ReadTaskSubmissionResponseSchema } from '@common/config/api/client'
import { AxiosError } from 'axios'
import { HTTPValidationError } from '@common/config/api/client'

type UseReaderTaskEsignMutationParams = {
  studyId: string
  readTaskId: string
}

export const useReaderTaskEsignMutation = ({ studyId, readTaskId }: UseReaderTaskEsignMutationParams) => {
  const mutation = useReadTaskESignMutation()

  const submitReaderTaskEsign = async (data: ReaderEsignFormValues) => {
    return mutation.mutateAsync({
      body: {
        username: data.username,
        password: data.password,
        context: {
          type: 'reader_task_submission',
          read_task_submission: {
            adjudication_justification_id: '',
            justification_comment: '',
          },
        },
      },
      query: {
        read_task_id: readTaskId,
        study_id: studyId,
      },
    })
  }

  return {
    submitReaderTaskEsign,
    isLoading: mutation.isPending,
    isError: mutation.isError,
    error: mutation.error as AxiosError<HTTPValidationError> | null,
    data: mutation.data as ReadTaskSubmissionResponseSchema | undefined,
  }
}
