export const SPACING = {
  minSpacing: 1,
  unit: 8,
  pageSpacingY: 4,
  pageSpacingX: 4,
  headerSpacingY: 4,
  formFieldsSpacing: 2,
  sectionsSpacing: 4,
  sectionInnerSpacing: 3,
  paperInnerPadding: 3,
  paperHeadingMarginY: 3,
  dividerMarginY: 3,
  MenuDividerMarginY: 0.5,
  breadcrumbsMarginBottom: 2,
  tableToolbarItemsSpacing: 4,
  formFieldsSectionSpacing: 4,
  formFieldsInnerSectionSpacing: 2,
  checkboxesSpacing: 3,
  iconsSpacing: 2,
  contentSpacing: 3,
  gridSpacing: 2,
  tabsSpacing: 0.5,
  noSpacing: 0,
  paginationSpacing: 2,
  buttonSpacing: 1.5,
  commentModalSpacing: 0.5,
  commentBoxSpacing: 2,
  commentModalButtonSpacing: 3,
  popupMenuSpacingX: 2,
  PopupMenuSpacingY: 0.75,
  popupMenuIconSpacing: 1.5,
  examDetailPopupMenuListSpacing: 0.5,
  examDetailsSpacing: 2,
  examDetailsTypographySpacing: 0.25,
  examDetailsListSpacing: 0.5,
  examReasonSpacingX: 2,
  examReasonSpacingY: 1,
  examReasonSpacingSm: 0.25,
  QCDetailsSpacing: 10,
  seriesDefinitionsFormSpacing: 1.5,
  seriesDefinitionsPopupListSpacing: 1.25,
  seriesModalSpacing: 1.5,
  tableToolbar: 16,
  configurationSpacing: 1.5,
  sideBarSpacing: 1.75,
  sidebarTypographySpacing: 1.25,
  timePointSpacing: 1.75,
  timePointTypographySpacing: 1.25,
  DICOMTagTableBoxSpacing: 6,
  fileTreeBoxSpacing: 1.25,
  fileTreeSectionSpacing: 20,
  typographySpacing: 1.5,
  uploadStepSpacing: 6,
  ModalInnerSpacing: 2,
  //General Spacing
  spacingXs: 0.25,
  spacingSm: 0.5,
  spacingMd: 0.75,
  spacingLg: 2,
  spacingXl: 3,
  spacingXxl: 4,
  spacing2xl: 8,
  borderRadiusLg: 1.5,
  borderRadiusSm: 1,
  borderRadiusXl: 2,
  borderRadiusXxl: 4,
  tableCellPaddingY: 0.75,
  tableCellPaddingX: 3,
  emptyTableSpacingY: 2,
}
