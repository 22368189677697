import { PrimarySitesParams, SitesParams } from './types'

const queryKeyFactory = {
  studySite: (studyId: string, siteId: string) => ['studySite', studyId, siteId],
  studySitesMetadata: (studyId: string) => ['studySitesMetadata', studyId],
  studySites: (params: SitesParams) => [
    'studySites',
    params.study_id,
    params.search,
    params.limit,
    params.next_cursor,
    params.previous_cursor,
    params.order_by_field,
    params.asc_order,
  ],
  primaryStudySites: (params: PrimarySitesParams) => ['primaryStudySites', params.study_id],
}

export default queryKeyFactory
