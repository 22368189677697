import { IconButton, Stack, Table, TableCell, TableRow, Typography, useTheme } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { FiberNew } from '@common/components/Icons'
import { ReadTaskSummaryData } from '@common/config/api/client'
import { TableScrollSize } from '@features/reader_summary/pages/summary.types'

export default function NewLesionsTableRow({
  expanded,
  timepoints,
  tableScrollSize,
  setExpanded,
}: {
  expanded: boolean
  timepoints: ReadTaskSummaryData['timepoints']
  tableScrollSize: TableScrollSize
  setExpanded: (expanded: boolean) => void
}) {
  const theme = useTheme()

  const hasNewLesionMeasurement = timepoints.some((timepoint) => {
    const count1 = timepoint.reader_submissions[0].ecrf_form_submission?.newLesion?.length ?? 0
    const count2 = timepoint.reader_submissions[1].ecrf_form_submission?.newLesion?.length ?? 0
    return count1 > 0 || count2 > 0
  })

  return (
    <TableRow
      data-testid="subject-table-row"
      hover
      sx={{
        '&:hover': {
          '& > td': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        bgcolor: 'background.paper',
      }}
    >
      <TableCell
        data-testid="pseudo-id-cell"
        sx={{
          width: tableScrollSize.columnWidth,
          minWidth: tableScrollSize.columnWidth,
          position: 'sticky',
          left: 0,
          background: 'inherit',
          zIndex: 3,
          paddingLeft: 3,
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Stack direction="row" spacing={1} sx={{ color: 'text.primary' }}>
          <FiberNew />
          <Typography variant="button">New lesions</Typography>
        </Stack>
      </TableCell>
      {Array.from({ length: tableScrollSize.emptyCellsCount }).map((_, i) => (
        <TableCell
          key={`empty-${i}`}
          sx={{
            width: tableScrollSize.columnWidth,
            minWidth: tableScrollSize.columnWidth,
            borderBottom: `1px solid ${theme.palette.divider}`,
          }}
        />
      ))}
      {timepoints.map((timepoint, i) => {
        let stickyStyles = {}
        if (i === 0) {
          stickyStyles = {
            position: 'sticky',
            left: tableScrollSize.firstTimepointLeftOffset,
            background: 'inherit',
            zIndex: 2,
          }
        }
        if (i === timepoints.length - 1) {
          stickyStyles = {
            position: 'sticky',
            right: tableScrollSize.checkboxWidth,
            background: 'inherit',
            zIndex: 2,
            borderLeft: `1px solid ${theme.palette.primary.main}`,
          }
        }

        const measurement1 = timepoint.reader_submissions[0].ecrf_form_submission?.newLesion?.length
        const measurement2 = timepoint.reader_submissions[1].ecrf_form_submission?.newLesion?.length

        return (
          <TableCell
            key={`new-lesions-length-${i}`}
            sx={{
              padding: 0,
              width: tableScrollSize.columnWidth,
              minWidth: tableScrollSize.columnWidth,
              borderBottom: `1px solid ${theme.palette.divider}`,
              borderLeft: `1px solid ${theme.palette.divider}`,
              ...stickyStyles,
            }}
          >
            {(measurement1 || measurement2) && (
              <Table sx={{ padding: 0 }}>
                <TableRow>
                  <TableCell
                    align="left"
                    sx={{
                      maxWidth: tableScrollSize.columnWidth / 2,
                      width: tableScrollSize.columnWidth / 2,
                    }}
                  >
                    {measurement1 ? (
                      <Typography variant="button" sx={{ color: 'text.primary' }}>
                        {measurement1}
                      </Typography>
                    ) : null}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      borderLeft: `1px solid ${theme.palette.divider}`,
                      width: tableScrollSize.columnWidth / 2,
                      maxWidth: tableScrollSize.columnWidth / 2,
                    }}
                  >
                    {measurement2 ? (
                      <Typography variant="button" sx={{ color: 'text.primary' }}>
                        {measurement2}
                      </Typography>
                    ) : null}
                  </TableCell>
                </TableRow>
              </Table>
            )}
          </TableCell>
        )
      })}
      {hasNewLesionMeasurement && (
        <TableCell
          padding="checkbox"
          data-testid="expand-cell"
          sx={{
            width: tableScrollSize.checkboxWidth,
            minWidth: tableScrollSize.checkboxWidth,
            position: 'sticky',
            right: 0,
            background: 'inherit',
            zIndex: 3,
            borderBottom: `1px solid ${theme.palette.divider}`,
            borderLeft: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation()
              setExpanded(!expanded)
            }}
            data-testid="expand-button"
          >
            {expanded ? (
              <KeyboardArrowUpIcon data-testid="icon-expanded" />
            ) : (
              <KeyboardArrowDownIcon data-testid="icon-collapsed" />
            )}
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  )
}
