import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'sonner'
import Toast from '@common/components/Toast'
import {
  createUserStudyAssignmentsUsersUserIdStudyAssignmentsPost,
  CreateStudyUserRoleAssocationSchema,
} from '@common/config/api/client'
import { handleMutationError } from '@common/hooks/handleMutationError'

interface AddStudyAssignmentsParams {
  userId: string
  studyAssignments: CreateStudyUserRoleAssocationSchema[]
}

export const useAddStudyAssignments = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ userId, studyAssignments }: AddStudyAssignmentsParams) => {
      return await createUserStudyAssignmentsUsersUserIdStudyAssignmentsPost({
        path: {
          user_id: userId,
        },
        body: {
          study_assignments: studyAssignments,
        },
      })
    },
    onSuccess: (_, variables) => {
      const assignmentCount = variables.studyAssignments.length
      const message =
        assignmentCount === 1
          ? 'Study assignment added successfully'
          : `${assignmentCount} study assignments added successfully`

      toast(<Toast message={message} variant="success" />)
      queryClient.invalidateQueries({ queryKey: ['studyAssignments', variables.userId] })
    },
    onError: handleMutationError,
  })
}
