import { useState } from 'react'
import { Button, Typography, Modal, Stack } from '@mui/material'
import { useForm, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useExtendedAuth } from '@auth/hooks/useExtendedAuth'
import {
  ReaderTaskSubmissionFormValues,
  readerTaskSubmissionSchema,
} from '../../schemas/ReaderTaskSubmissionValidationSchema'
import DecisionStep from './DecisionStep'
import DisagreeStep from './DisagreeStep'
import EsignStep from './EsignStep'
import { useAdjudicationDecisionMutation } from '../../hooks/useAdjudicationDecisionMutation'
import { toast } from 'sonner'
import Toast from '@common/components/Toast'
import { useParams } from 'react-router-dom'
import { ReadTaskSummaryData } from '@common/config/api/client'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  borderRadius: 2,
  bgcolor: 'background.default',
  boxShadow: 8,
  padding: 3,
  width: 600,
}

type ModalStep = 'decision' | 'disagree' | 'esign'

interface AdjudicationDecisionModalProps {
  taskId: string
  timepoint: ReadTaskSummaryData['timepoints'][0]
}

export default function AdjudicationDecisionModal({ timepoint, taskId }: AdjudicationDecisionModalProps) {
  const [open, setOpen] = useState(false)
  const [currentStep, setCurrentStep] = useState<ModalStep>('decision')
  const { user } = useExtendedAuth()
  const { studyId } = useParams<{ studyId: string }>()

  const { submitAdjudicationDecision, isLoading } = useAdjudicationDecisionMutation({
    studyId: studyId!,
    readTaskId: taskId,
  })

  const methods = useForm<ReaderTaskSubmissionFormValues>({
    resolver: zodResolver(readerTaskSubmissionSchema),
    defaultValues: {
      username: user?.profile.preferred_username,
      password: '',
      context: {
        type: 'reader_task_submission',
        read_task_submission: {
          adjudication_agreed: false,
          adjudication_selected_read_task_id: '',
          adjudication_justification_id: '',
          justification_comment: '',
          comment: '',
        },
      },
    },
  })

  const { handleSubmit, watch } = methods
  const readTaskSubmission = watch('context.read_task_submission')

  const handleClose = () => {
    setOpen(false)
    setCurrentStep('decision')
    methods.reset()
  }

  const handleNext = async () => {
    if (currentStep === 'decision' || currentStep === 'disagree') {
      const isValid = await methods.trigger([
        'context.read_task_submission.adjudication_selected_read_task_id',
        'context.read_task_submission.comment',
        'context.read_task_submission.adjudication_justification_id',
        'context.read_task_submission.justification_comment',
      ])

      if (!isValid) return

      setCurrentStep('esign')
    } else {
      handleSubmit(onSubmit)()
    }
  }

  const handleBack = () => {
    if (currentStep === 'esign') {
      setCurrentStep(readTaskSubmission.adjudication_agreed ? 'decision' : 'disagree')
    }
  }

  const toggleDisagree = () => {
    methods.setValue('context.read_task_submission.adjudication_agreed', false)
    methods.setValue('context.read_task_submission.adjudication_selected_read_task_id', undefined)
    methods.setValue('context.read_task_submission.justification_comment', undefined)
    setCurrentStep('disagree')
  }

  const onSubmit = async (data: ReaderTaskSubmissionFormValues) => {
    try {
      await submitAdjudicationDecision(data)
      toast(
        <Toast message="Adjudication decision submitted successfully" variant="success" data-testid="success-toast" />,
      )
      handleClose()
    } catch {
      toast(<Toast message="Failed to submit adjudication decision" variant="error" data-testid="error-toast" />)
    }
  }

  const renderModalContent = () => {
    switch (currentStep) {
      case 'decision':
        return <DecisionStep toggleDisagree={toggleDisagree} timepoint={timepoint} />
      case 'disagree':
        return <DisagreeStep />
      case 'esign':
        return <EsignStep<ReaderTaskSubmissionFormValues> />
    }
  }

  const renderActions = () => {
    return (
      <Stack justifyContent="space-between" direction="row" alignSelf="stretch" alignItems="center">
        <Button
          variant="contained"
          sx={{ bgcolor: 'secondary.main', color: 'secondary.contrastText' }}
          onClick={handleClose}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Stack direction="row" spacing={2}>
          {currentStep === 'esign' && (
            <Button
              variant="contained"
              sx={{ bgcolor: 'primary.main', color: 'primary.contrastText' }}
              onClick={handleBack}
              disabled={isLoading}
            >
              Back
            </Button>
          )}
          <Button
            variant="contained"
            sx={{ bgcolor: 'primary.main', color: 'primary.contrastText' }}
            onClick={handleNext}
            loading={isLoading}
          >
            {isLoading ? 'Submitting...' : currentStep === 'esign' ? 'Sign' : 'Next'}
          </Button>
        </Stack>
      </Stack>
    )
  }

  return (
    <>
      <Stack
        onClick={(e) => {
          setOpen(true)
          e.stopPropagation()
        }}
        data-testid="reopen-task-button"
      >
        <Button
          size="large"
          sx={{
            backgroundColor: 'primary.main',
          }}
        >
          <Typography
            variant="button"
            sx={{
              color: 'primary.contrastText',
            }}
          >
            Decide
          </Typography>
        </Button>
      </Stack>
      <Modal open={open} onClick={(e) => e.stopPropagation()} onClose={handleClose} data-testid="adjudication-modal">
        <FormProvider {...methods}>
          <Stack sx={modalStyle} spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
            {renderModalContent()}
            {renderActions()}
          </Stack>
        </FormProvider>
      </Modal>
    </>
  )
}
