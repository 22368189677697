import { matchPath } from 'react-router-dom'

export function getActive(path: string | null, pathname: string) {
  if (!path) {
    return false
  }
  return !!matchPath({ path, end: path === '/' }, pathname)
}

export function generatePathWithParams(
  path: string,
  params: Record<string, string | number | null | undefined>,
): string {
  // First, handle required parameters
  let updatedPath = path

  // Get all parameter names from the path
  const paramNames = path.match(/:[^/?]+\??/g)?.map((p) => p.replace(/[:/?.]/g, '')) || []

  for (const paramName of paramNames) {
    const paramValue = params[paramName]
    const paramPattern = new RegExp(`:${paramName}\\??`)

    if (paramValue == null) {
      // If the parameter is optional (has a ? suffix) and value is null/undefined,
      // remove the entire optional segment
      const optionalParamPattern = new RegExp(`/:[^/]+\\?`)
      if (path.match(new RegExp(`:${paramName}\\?`))) {
        updatedPath = updatedPath.replace(optionalParamPattern, '')
      }
    } else {
      // Replace the parameter with its value
      updatedPath = updatedPath.replace(paramPattern, String(paramValue))
    }
  }

  // Clean up any remaining optional parameters and double slashes
  updatedPath = updatedPath
    .replace(/\/:[^/]+\?/g, '') // Remove any remaining optional parameters
    .replace(/\/+/g, '/') // Replace multiple consecutive slashes with a single one

  return updatedPath
}

export function isParentActive(subItems: Array<{ path: string | null }>, pathname: string): boolean {
  return subItems.some((item) => item.path && !!matchPath({ path: item.path, end: true }, pathname))
}
