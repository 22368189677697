import { Button, TextField, InputAdornment, styled, Box } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import { ImagingModalityCategory } from '@features/series_label_management/types'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { SPACING } from '@common/theme/spacing'
import { SIZING } from '@common/theme/sizing'

interface SeriesLabelManagementTableToolbarProps {
  imagingModalityCategories?: ImagingModalityCategory[]
  onSearch: (value: string) => void
  searchValue?: string
  onAddClick: (imagingModalityCateogry: ImagingModalityCategory) => void
  currentImagingModalityCategory: ImagingModalityCategory | null
  onImagingModalityCategoryChange: (imagingModalityCateogry: ImagingModalityCategory | null) => void
}

const ToolbarContainer = styled(Box)({
  padding: SPACING.tableToolbar,
  display: 'flex',
  gap: 24,
  alignItems: 'center',
})

export default function SeriesLabelManagementTableToolbar({
  imagingModalityCategories,
  onSearch,
  searchValue,
  onAddClick,
  currentImagingModalityCategory,
  onImagingModalityCategoryChange,
}: Readonly<SeriesLabelManagementTableToolbarProps>) {
  const location = useLocation()
  const [buttonLabel, setButtonLabel] = useState('Add eCRF')

  useEffect(() => {
    if (imagingModalityCategories) {
      const hash = location.hash.slice(1)
      const newImagingModalityCategory = hash
        ? imagingModalityCategories.find((imagingModalityCateogry) => imagingModalityCateogry.value === hash)
        : imagingModalityCategories[0]
      if (newImagingModalityCategory) {
        setButtonLabel(`Add ${newImagingModalityCategory.label}`)
        onImagingModalityCategoryChange(newImagingModalityCategory)
      }
    }
  }, [location.hash, imagingModalityCategories, onImagingModalityCategoryChange])

  const [localSearchValue, setLocalSearchValue] = useState(searchValue ?? '')
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalSearchValue(event.target.value)
  }
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSearch(localSearchValue)
    }
  }

  useEffect(() => {
    setLocalSearchValue(searchValue ?? '')
  }, [searchValue])

  const handleAddClick = () => {
    if (currentImagingModalityCategory) {
      onAddClick(currentImagingModalityCategory)
    }
  }

  return (
    <ToolbarContainer
      sx={{
        paddingX: SPACING.noSpacing,
        display: 'flex',
        justifyContent: 'flex-end',
        gap: SPACING.tableToolbarItemsSpacing,
      }}
    >
      <Button variant="contained" color="secondary" startIcon={<AddIcon />} size="small" onClick={handleAddClick}>
        {buttonLabel}
      </Button>
      <TextField
        placeholder="Search"
        variant="outlined"
        size="small"
        sx={{ minWidth: SIZING.textFieldWidth }}
        value={localSearchValue}
        onChange={handleSearchChange}
        onKeyDown={handleKeyDown}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          },
        }}
      />
    </ToolbarContainer>
  )
}
