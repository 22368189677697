import BaseDialog from '../BaseDialog'

export interface ConfirmationDialogProps {
  onConfirm: () => void
  onCancel: () => void
  open: boolean
  title: string
  description: string
  confirmLabel: string
  cancelLabel?: string
}

const ConfirmationDialog = ({
  onConfirm,
  onCancel,
  open,
  title,
  description,
  confirmLabel,
  cancelLabel = 'Cancel',
}: ConfirmationDialogProps) => {
  return (
    <BaseDialog
      title={title}
      description={description}
      open={open}
      onClose={onCancel}
      onSubmit={onConfirm}
      width={600}
      submitLabel={confirmLabel}
      cancelLabel={cancelLabel}
    ></BaseDialog>
  )
}

export default ConfirmationDialog
