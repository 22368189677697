import { DARK_MODE, LIGHT_MODE } from './colors'

const lightModePalette = {
  common: { black: '#263238', white: '#fff' },
  primary: { ...LIGHT_MODE.primary, contrastText: '#fff' },
  secondary: { ...LIGHT_MODE.secondary, contrastText: '#263238' },
  info: { ...LIGHT_MODE.info, contrastText: '#fff' },
  'info-alt': { ...LIGHT_MODE.infoAlt, contrastText: LIGHT_MODE.infoAlt.contrast },
  success: { ...LIGHT_MODE.success, contrastText: '#fff' },
  warning: { ...LIGHT_MODE.warning, contrastText: '#fff' },
  'warning-alt': { ...LIGHT_MODE.warningAlt, contrastText: '#994300' },
  error: { ...LIGHT_MODE.error, contrastText: '#fff' },
  'error-alt': { ...LIGHT_MODE.errorAlt, contrastText: '#910410' },
  'success-alt': { ...LIGHT_MODE.successAlt, contrastText: '#1B5E24' },
  divider: '#DCE3E5',
  text: LIGHT_MODE.text,
  background: LIGHT_MODE.background,
  paper: { ...LIGHT_MODE.paper },
  tooltip: { ...LIGHT_MODE.tooltip },
  status: { ...LIGHT_MODE.status },
  action: {
    active: '#546E7A',
    hover: '#F0F3F5',
    selected: '#DCE3E5',
    disabled: '#90A4AE',
    disabledBackground: '#DCE3E5',
    focus: '#DCE3E5',
  },
} as const

const darkModePalette = {
  common: { black: '#546E7A', white: '#FFFFFF', grey: '#263238' },
  primary: { ...DARK_MODE.primary, contrastText: DARK_MODE.primary.contrast },
  secondary: { ...DARK_MODE.secondary, contrastText: DARK_MODE.secondary.contrast },
  info: { ...DARK_MODE.info, contrastText: DARK_MODE.info.contrast },
  'info-alt': { ...DARK_MODE.infoAlt, contrastText: DARK_MODE.infoAlt.contrast },
  success: { ...DARK_MODE.success, contrastText: DARK_MODE.success.contrast },
  warning: { ...DARK_MODE.warning, contrastText: DARK_MODE.warning.contrast },
  'warning-alt': { ...DARK_MODE.warningAlt, contrastText: DARK_MODE.warningAlt.contrast },
  error: { ...DARK_MODE.error, contrastText: DARK_MODE.error.contrast },
  'error-alt': { ...DARK_MODE.errorAlt, contrastText: DARK_MODE.errorAlt.contrast },
  'success-alt': { ...DARK_MODE.successAlt, contrastText: DARK_MODE.successAlt.contrast },
  divider: '#455A64',
  text: DARK_MODE.text,
  background: DARK_MODE.background,
  paper: { ...DARK_MODE.paper },
  tooltip: { ...DARK_MODE.tooltip },
  status: { ...DARK_MODE.status },
  action: {
    active: '#455A64',
    hover: '#546E7A',
    selected: '#546E7A',
    disabled: '#B0BEC5',
    disabledBackground: '#607D8B',
    focus: '#546E7A',
  },
} as const

export default function palette(themeMode: 'light' | 'dark') {
  return themeMode === 'light' ? lightModePalette : darkModePalette
}
