import { SPACING } from '@common/theme/spacing'
import { Button, Divider, Grid2 as Grid, Paper, Stack, Typography } from '@mui/material'
import MyStudiesTable from './MyStudiesTable'
import { useForm } from 'react-hook-form'
import { TextField } from '@common/components/Form/TextField'
import { useEffect, useState } from 'react'
import useUserProfile from '@features/user_management/hooks/useUserProfile'
import { PageHeader } from '@common/components/PageHeader'
import { zodResolver } from '@hookform/resolvers/zod'
import { ProfileSettingsSchema } from '@features/user_management/schemas/userValidationSchema'
import useUpdateProfile from '@features/user_management/hooks/useUpdateProfile'
import { handleMutationError } from '@common/hooks/handleMutationError'
import Toast from '@common/components/Toast'
import { toast } from 'sonner'
import useSendAccountActionEmail from '@features/user_management/hooks/useSendAccountActionEmail'
import { AccountActionEnum } from './Settings'
import ConfirmationDialog from '@common/components/ConfirmationDialog/ConfirmationDialog'

const ProfileSettings = () => {
  const { data: userProfile, isLoading, error } = useUserProfile()
  const updateProfile = useUpdateProfile()
  const sendAccountAction = useSendAccountActionEmail()
  const [passwordResetDialog, setPasswordResetDialog] = useState({
    open: false,
  })
  const form = useForm({
    defaultValues: {
      name: '',
      email: '',
      role_name: '',
      role_id: '',
    },
    resolver: zodResolver(ProfileSettingsSchema),
  })

  useEffect(() => {
    if (userProfile) {
      form.reset({
        name: userProfile.name,
        email: userProfile.email,
        role_name: userProfile.role.name,
        role_id: userProfile.role.id,
      })
    }
  }, [userProfile, form])

  const studies =
    userProfile?.study_roles.map((studyRole) => ({
      studyName: studyRole.study_name,
      role: studyRole.role_name,
    })) || []

  const onSubmit = form.handleSubmit(async (data) => {
    try {
      updateProfile.mutate(
        {
          userData: {
            name: data.name,
          },
        },
        {
          onSuccess: () => {
            toast(<Toast message="Profile updated successfully." variant="success" />)
          },
          onError: handleMutationError,
        },
      )
    } catch (error) {
      console.error('Error updating profile:', error)
    }
  })

  const handleChangePasswordBtnClick = () => {
    setPasswordResetDialog({ open: true })
  }

  const handlePasswordResetConfirm = () => {
    if (!userProfile?.id) return

    sendAccountAction.mutate(
      {
        userId: userProfile.id,
        action: AccountActionEnum.UPDATE_PASSWORD,
      },
      {
        onSuccess: () => {
          toast(<Toast message="Password reset email sent successfully." variant="success" />)
          setPasswordResetDialog({ open: false })
        },
        onError: (error) => {
          handleMutationError(error)
          setPasswordResetDialog({ open: false })
        },
      },
    )
  }

  const handlePasswordResetCancel = () => {
    setPasswordResetDialog({ open: false })
  }

  if (isLoading) {
    return <Stack>Loading...</Stack>
  }
  if (error) {
    return <Stack>Error: {error.message}</Stack>
  }

  return (
    <Grid container direction="column">
      <PageHeader title="Settings" sx={{ mb: SPACING.headerSpacingY }} />
      <Grid size={9}>
        <Paper
          sx={{
            padding: SPACING.paperInnerPadding,
            borderRadius: SPACING.borderRadiusXl,
          }}
        >
          <form onSubmit={onSubmit}>
            <Stack spacing={SPACING.spacingXl}>
              <Stack direction="column" spacing={SPACING.formFieldsInnerSectionSpacing}>
                <Typography variant="subtitle1">Personal details</Typography>
                <TextField control={form.control} name="name" label="Name" size="small" />
                <TextField
                  control={form.control}
                  name="email"
                  label="Email"
                  size="small"
                  slotProps={{
                    input: { readOnly: true },
                  }}
                />
                <TextField
                  control={form.control}
                  name="role_name"
                  label="Global role"
                  size="small"
                  slotProps={{
                    input: { readOnly: true },
                  }}
                />
              </Stack>
              <Divider sx={{ my: SPACING.dividerMarginY }} />
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="column">
                  <Typography variant="subtitle1">Password</Typography>
                  <Typography variant="body1" color="textSecondary">
                    Change your password to login to your account.
                  </Typography>
                </Stack>
                <Button size="medium" variant="outlined" color="secondary" onClick={handleChangePasswordBtnClick}>
                  Change Password
                </Button>
              </Stack>
              <Divider sx={{ my: SPACING.dividerMarginY }} />
              <Stack direction="column" spacing={SPACING.formFieldsInnerSectionSpacing}>
                <Typography variant="subtitle1">My studies</Typography>
                <MyStudiesTable studies={studies} />
              </Stack>
              <Stack direction="row" spacing={SPACING.spacingLg} justifyContent="flex-end">
                <Button type="submit" variant="contained">
                  Save changes
                </Button>
              </Stack>
            </Stack>
          </form>
        </Paper>
      </Grid>
      <ConfirmationDialog
        open={passwordResetDialog.open}
        title="Change password?"
        description="You will receive an email with a link to create your new password."
        onConfirm={handlePasswordResetConfirm}
        onCancel={handlePasswordResetCancel}
        confirmLabel="Send link"
      />
    </Grid>
  )
}

export default ProfileSettings
