import {
  updateTimepointSubmissionStudiesStudyIdSubjectsSubjectIdTimepointSubmissionTimepointSubmissionIdPatch,
  UpdateTimepointSubmissionStudiesStudyIdSubjectsSubjectIdTimepointSubmissionTimepointSubmissionIdPatchResponse,
  UpdateTimepointSubmissionStudiesStudyIdSubjectsSubjectIdTimepointSubmissionTimepointSubmissionIdPatchData,
  HTTPValidationError,
} from '@common/config/api/client'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { queryClient } from '@common/config/api/queryClient.ts'

export const useSubmitTimePointEForm = () => {
  return useMutation<
    UpdateTimepointSubmissionStudiesStudyIdSubjectsSubjectIdTimepointSubmissionTimepointSubmissionIdPatchResponse,
    AxiosError<HTTPValidationError>,
    UpdateTimepointSubmissionStudiesStudyIdSubjectsSubjectIdTimepointSubmissionTimepointSubmissionIdPatchData
  >({
    mutationFn: async (query) => {
      const response =
        await updateTimepointSubmissionStudiesStudyIdSubjectsSubjectIdTimepointSubmissionTimepointSubmissionIdPatch({
          body: query.body,
          path: query.path,
          throwOnError: true,
        })
      return response.data
    },
    onSuccess: (_, query) => {
      void queryClient.invalidateQueries({ queryKey: ['TimePointsDetails', query.path.timepoint_submission_id] })
    },
  })
}
