import { useMutation, UseMutationResult } from '@tanstack/react-query'
import {
  updateCompanyCompaniesCompanyIdPut,
  HTTPValidationError,
  UpdateCompanyCompaniesCompanyIdPutResponse,
  CompanyUpdateSchema,
} from '@common/config/api/client'
import { AxiosError } from 'axios'

export interface UpdateCompanyInput {
  companyId: string
  companyData: CompanyUpdateSchema
}

export default function useUpdateCompany(): UseMutationResult<
  UpdateCompanyCompaniesCompanyIdPutResponse,
  AxiosError<HTTPValidationError>,
  UpdateCompanyInput
> {
  return useMutation<
    UpdateCompanyCompaniesCompanyIdPutResponse,
    AxiosError<HTTPValidationError>,
    { companyId: string; companyData: CompanyUpdateSchema }
  >({
    mutationFn: async ({ companyId, companyData }) => {
      const response = await updateCompanyCompaniesCompanyIdPut({
        path: { company_id: companyId },
        body: companyData,
        throwOnError: true,
      })
      return response.data
    },
  })
}
