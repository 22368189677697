import { useCombinedPermissions } from '@auth/hooks/useCombinedPermissions'
import EnhancedNavTabs from '@common/components/Tab/EnhancedNavTabs'
import { tabItems } from '@common/constants/tabs'
import { useParams } from 'react-router-dom'

export default function StudyDetailsTabs() {
  const { studyId } = useParams<{ studyId: string }>()
  const { hasAnyPermission } = useCombinedPermissions()

  const filteredTabRoutes = tabItems
    .filter((item) => !item.permissions || hasAnyPermission(item.permissions))
    .map((item) => ({
      ...item.route,
      path: item.route.path.replace(':studyId', studyId!),
    }))
  return <EnhancedNavTabs routes={filteredTabRoutes} />
}
