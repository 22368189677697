import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { useState } from 'react'
import { PopupState } from 'material-ui-popup-state/hooks'
import { useForm } from 'react-hook-form'
import { TextField } from '@common/components/Form/TextField'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { CopyAndClearIcon } from '@common/components/Icons'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  borderRadius: 2,
  bgcolor: 'background.default',
  boxShadow: 8,
  padding: 1,
}

const CopyEmptyReReadValidationMessage = 'Provide a reason'

const CopyEmptyReReadValidationSchema = z.object({
  reason: z.string().min(1, CopyEmptyReReadValidationMessage),
})

export default function CopyEmptyReReadModal({
  popupState,
}: {
  task_id: string
  study_id: string
  popupState: PopupState
}) {
  const [open, setOpen] = useState(false)
  const {
    control,
    watch,
    formState: { isValid },
    setError,
  } = useForm({
    mode: 'onChange',
    defaultValues: { reason: '' },
    resolver: zodResolver(CopyEmptyReReadValidationSchema),
  })
  const reason = watch('reason')
  const handleClose = () => {
    setOpen(false)
    popupState.close()
  }

  const handledCopyEmptyReReadTask = async (reason: string) => {
    if (!isValid) {
      setError('reason', {
        message: CopyEmptyReReadValidationMessage,
      })
      return
    }
    console.log(reason)
  }

  return (
    <>
      <Box
        onClick={() => setOpen(true)}
        sx={{
          display: 'flex',
          width: '100%',
          gap: 1.5,
          alignItems: 'center',
        }}
        data-testid="copy-empty-re-read-task-button"
        tabIndex={0}
      >
        <CopyAndClearIcon
          fontSize="small"
          sx={{
            color: 'text.secondary',
          }}
        />
        <Typography variant="body1">Copy as empty re-read</Typography>
      </Box>
      <Modal open={open} onClose={handleClose} data-testid="copy-empty-re-read-modal">
        <Box sx={modalStyle}>
          <Box
            sx={{
              display: 'flex',
              padding: 2,
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: 0.5,
              alignSelf: 'stretch',
              width: 600,
            }}
          >
            <Typography variant="h6" color="text.primary" id="copy-empty-re-read-modal-title">
              Copy task as empty re-read?
            </Typography>
            <Typography variant="body1" color="text.secondary" id="copy-empty-re-read-modal-description">
              A copy of this task with an empty eCRF will be created and assigned to the same reader.
            </Typography>
          </Box>
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              alignSelf: 'stretch',
              padding: 2,
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              name="reason"
              control={control}
              id="outlined-multiline-static"
              label="Provide a reason"
              multiline
              rows={6}
              fullWidth
              onKeyDown={(e) => e.stopPropagation()}
              data-testid="reason-textfield"
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              alignSelf: 'stretch',
              padding: 2,
              gap: 1,
            }}
          >
            <Button
              variant="contained"
              sx={{
                bgcolor: 'secondary.main',
                color: 'secondary.contrastText',
              }}
              onClick={handleClose}
              data-testid="cancel-copy-empty-re-read-task-button"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                bgcolor: 'primary.main',
                color: 'primary.contrastText',
              }}
              onClick={() => handledCopyEmptyReReadTask(reason)}
              data-testid="confirm-copy-empty-re-read-task-button"
            >
              Copy
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}
