import { useState } from 'react'
import { TransformedSubjects } from '../types'
import { SubjectsParams } from '../types'
import { useParams } from 'react-router-dom'

export default function useStudySubjectsTableHandlers() {
  const [page, setPage] = useState(0)
  const [isSubjectDialogVisible, setIsSubjectDialogVisible] = useState(false)
  const [editingSubjectId, setEditingSubjectId] = useState<string | null>(null)
  const { studyId } = useParams<{ studyId: string }>()
  const [subjectsParams, setSubjectsParams] = useState<SubjectsParams>({
    study_id: studyId!,
    search: undefined,
    limit: 50,
    next_cursor: undefined,
    previous_cursor: undefined,
    order_by_field: 'pseudo_id',
    asc_order: true,
  })

  const handlePageChange = (
    subjects: TransformedSubjects | undefined,
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    const isNextPage = newPage > page
    const cursor = isNextPage ? subjects?.metadata.next_cursor : subjects?.metadata.previous_cursor

    if (cursor) {
      setSubjectsParams((prev) => ({
        ...prev,
        next_cursor: isNextPage ? cursor : null,
        previous_cursor: isNextPage ? null : cursor,
      }))
      setPage(isNextPage ? page + 1 : page - 1)
    }
  }
  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubjectsParams((prev) => ({
      ...prev,
      limit: parseInt(event.target.value, 10),
      next_cursor: null,
      previous_cursor: null,
    }))
    setPage(0)
  }

  const handleSearch = (value: string) => {
    setSubjectsParams((prev) => ({
      ...prev,
      search: value || undefined,
      next_cursor: undefined,
      previous_cursor: undefined,
    }))
    setPage(0)
  }

  const handleRequestSort = (_event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = subjectsParams.order_by_field === property && subjectsParams.asc_order
    setSubjectsParams((prev) => ({
      ...prev,
      order_by_field: property,
      asc_order: !isAsc,
      next_cursor: undefined,
      previous_cursor: undefined,
    }))
    setPage(0)
  }

  const handleAddClick = () => {
    setIsSubjectDialogVisible(true)
  }

  const handleEditClick = (subjectId: string) => {
    setEditingSubjectId(subjectId)
    setIsSubjectDialogVisible(true)
  }

  const handleBulkDeleteSubjects = () => {}

  return {
    page,
    isSubjectDialogVisible,
    editingSubjectId,
    subjectsParams,
    setSubjectsParams,
    handlePageChange,
    handleRowsPerPageChange,
    handleSearch,
    handleRequestSort,
    handleAddClick,
    handleEditClick,
    setEditingSubjectId,
    setIsSubjectDialogVisible,
    handleBulkDeleteSubjects,
  }
}
