import { z } from 'zod'

const AssignmentSchema = z.object({
  role_id: z.string().min(1, 'Role is required'),
  study_id: z.string().min(1, 'Study is required'),
})

export const StudyAssignmentsSchema = z.object({
  assignments: z.array(AssignmentSchema).min(1, 'At least one assignment is required'),
})
