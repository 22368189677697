import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { useState } from 'react'
import { PopupState } from 'material-ui-popup-state/hooks'
import { LabelOutlined } from '@mui/icons-material'
import { Paper, Stack } from '@mui/material'
import {
  ExamSeriesDicomTagMetadataSchema,
  TimepointContainerSubmissionExamMetadataResponse,
} from '@common/config/api/client'
import { SPACING } from '@common/theme/spacing'
import ReviewDicomTagsTable from '../series/ReviewDicomTagsTable'
import { useUpdateExamMetadataMutation } from '@features/qc_task_details_view/hooks/useUpdateExamMetadataMutation'
import { SeriesDicomTagsUpdateFormValues } from '@features/qc_task_details_view/schemas/DicomTagsValidationSchema'
import { useParams } from 'react-router-dom'
import { toast } from 'sonner'
import Toast from '@common/components/Toast'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  borderRadius: 2,
  bgcolor: 'background.default',
  boxShadow: 8,
  width: 1190,
}

export default function ReviewDicomTagsModal({
  exam,
  popupState,
}: {
  exam?: TimepointContainerSubmissionExamMetadataResponse
  popupState: PopupState
}) {
  const [open, setOpen] = useState(false)
  const [formData, setFormData] = useState<SeriesDicomTagsUpdateFormValues | null>(null)
  const { mutateAsync: updateExamMetadata, isPending } = useUpdateExamMetadataMutation()
  const { qcTaskId, studyId } = useParams<{ qcTaskId: string; studyId: string }>()

  const handleClose = () => {
    setOpen(false)
    popupState.close()
  }

  const handleFormData = (data: SeriesDicomTagsUpdateFormValues) => {
    setFormData(data)
  }

  const handleSave = async () => {
    if (!formData || !exam) return

    try {
      const updatePromises = formData.series.map(async (series, index) => {
        const tagsWithNewValues = series.dicom_tags.filter((tag) => tag.new_value?.trim() !== '')

        if (tagsWithNewValues.length === 0) return null

        const seriesMetadataId = exam.series[index]?.series_metadata_id
        if (!seriesMetadataId) return null

        const apiTags: ExamSeriesDicomTagMetadataSchema[] = tagsWithNewValues.map((tag) => ({
          dicom_tag_name: tag.dicom_tag_name,
          dicom_tag_id: tag.dicom_tag_id,
          tag_deid_strategy: tag.tag_deid_strategy,
          value: tag.value,
          new_value: tag.new_value,
          apply_to_all_series: tag.apply_to_all_series,
        }))

        if (apiTags.length > 0) {
          return updateExamMetadata({
            path: {
              qc_task_id: qcTaskId || '',
              series_metadata_id: seriesMetadataId,
            },
            query: {
              study_id: studyId || '',
            },
            body: {
              dicom_tags: apiTags,
            },
          })
        }
        return null
      })

      await Promise.all(updatePromises.filter(Boolean))
      toast(<Toast message="Tags updated successfully" variant="success" />)
      handleClose()
    } catch (error) {
      toast(<Toast message={`Error updating tags: ${error}`} variant="error" />)
    }
  }

  return (
    <>
      <Stack
        direction={'row'}
        spacing={SPACING.seriesModalSpacing}
        alignItems={'center'}
        alignSelf={'stretch'}
        width={'100%'}
        onClick={() => setOpen(true)}
      >
        <LabelOutlined
          sx={{
            color: 'text.secondary',
          }}
        />
        <Typography>Review DICOM tags</Typography>
      </Stack>
      <Modal
        open={open}
        onClose={handleClose}
        data-testid="series-modal"
        aria-labelledby="series-modal-title"
        aria-describedby="series-modal-description"
      >
        <Paper sx={modalStyle}>
          <Stack>
            <Stack>
              <Stack padding={SPACING.spacingXl}>
                <Typography variant="h6" color="text.primary" id="series-modal-title">
                  {exam && exam.study_description}
                </Typography>
                <Typography variant="body1" color="text.secondary" id="series-modal-title">
                  Study and series tags remain the same when «New Value» is blank.
                </Typography>
              </Stack>
              <Stack paddingX={SPACING.spacingXl}>
                <ReviewDicomTagsTable exam={exam} onFormData={handleFormData} />
              </Stack>
            </Stack>
            <Stack
              padding={SPACING.spacingXl}
              spacing={SPACING.spacingXl}
              alignSelf="stretch"
              alignItems="flex-end"
              direction="row"
              justifyContent="flex-end"
            >
              <Button
                variant="contained"
                sx={{
                  bgcolor: 'secondary.main',
                  color: 'secondary.contrastText',
                }}
                onClick={handleClose}
                data-testid="close-series-task-button"
                disabled={isPending}
              >
                Close
              </Button>
              <Button
                variant="contained"
                sx={{
                  bgcolor: 'primary.main',
                  color: 'primary.contrastText',
                }}
                onClick={handleSave}
                data-testid="save-series-task-button"
                loading={isPending}
              >
                Save
              </Button>
            </Stack>
          </Stack>
        </Paper>
      </Modal>
    </>
  )
}
