import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Fragment, useState } from 'react'
import { PopupState } from 'material-ui-popup-state/hooks'
import { FormatListBulletedRounded } from '@mui/icons-material'
import { Paper, Stack } from '@mui/material'
import { TimepointContainerSubmissionExamMetadataResponse } from '@common/config/api/client'
import { SPACING } from '@common/theme/spacing'
import SeriesTable from '../series/SeriesTable'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  borderRadius: 2,
  bgcolor: 'background.default',
  boxShadow: 8,
}

export default function SeriesModal({
  container_submission_id,
  exam,
  popupState,
}: {
  container_submission_id?: string
  exam?: TimepointContainerSubmissionExamMetadataResponse
  popupState: PopupState
}) {
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
    popupState.close()
  }

  return (
    <Fragment>
      <Stack
        direction={'row'}
        spacing={SPACING.seriesModalSpacing}
        alignItems={'center'}
        alignSelf={'stretch'}
        width={'100%'}
        onClick={() => setOpen(true)}
      >
        <FormatListBulletedRounded
          sx={{
            color: 'text.secondary',
          }}
        />
        <Typography>Show series</Typography>
      </Stack>
      <Modal
        open={open}
        onClose={handleClose}
        data-testid="series-modal"
        aria-labelledby="series-modal-title"
        aria-describedby="series-modal-description"
      >
        <Paper sx={modalStyle}>
          <Stack>
            <Stack padding={SPACING.spacingXl}>
              <Typography variant="h6" color="text.primary" id="series-modal-title">
                Series
              </Typography>
              <Typography variant="body1" color="text.secondary" id="series-modal-title">
                {exam?.study_description}
              </Typography>
            </Stack>
            <Stack paddingX={SPACING.spacingXl}>
              <SeriesTable container_submission_id={container_submission_id} exam={exam} />
            </Stack>
          </Stack>
          <Stack padding={SPACING.spacingXl} alignSelf={'stretch'} alignItems={'flex-end'}>
            <Button
              variant="contained"
              sx={{
                bgcolor: 'secondary.main',
                color: 'secondary.contrastText',
              }}
              onClick={handleClose}
              data-testid="close-series-task-button"
            >
              Close
            </Button>
          </Stack>
        </Paper>
      </Modal>
    </Fragment>
  )
}
