// import { Site } from '@common/config/api/client'

// export const DEFAULT_SITE: Site = {
//   site_id: import.meta.env.VITE_DEFAULT_SITE_ID,
//   site_pseudo_id: '10293847',
//   site_name: 'Test',
//   site_association_type: 'PRIMARY_SITE',
// }

export const MINIMUM_ALLOWED_TIMEPOINTS: number = 1
