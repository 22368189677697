/**
 * CompanyAddMenu component displays a dropdown menu for selecting compant types.
 * It is used in the Company Management page to allow users to create new companies
 * of different types (e.g. Site, Internal, Radiology center).
 */
import { MenuItem } from '@mui/material'
import { CompanyAddMenuProps } from '../types'
import { BaseDropdownMenu } from '@common/components/Menu/BaseDropdownMenu'

export default function CompanyAddMenu({
  anchorEl,
  onClose,
  companyTypes,
  onCompanyTypeSelect,
}: Readonly<CompanyAddMenuProps>) {
  return (
    <BaseDropdownMenu anchorEl={anchorEl} onClose={onClose}>
      {companyTypes?.map((companyType) => (
        <MenuItem
          key={companyType.id}
          onClick={() => {
            onCompanyTypeSelect(companyType)
            onClose()
          }}
        >
          {companyType.label}
        </MenuItem>
      ))}
    </BaseDropdownMenu>
  )
}
