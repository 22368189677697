import { SPACING } from '@common/theme/spacing'
import { RemoveRedEyeRounded } from '@mui/icons-material'
import { Divider, IconButton, Stack, Typography } from '@mui/material'
import DeleteModal from '../modals/DeleteModal'
import { TimepointContainerSubmissionExamMetadataResponse } from '@common/config/api/client'

export default function SeriesTableBulk({
  container_submission_id,
  exam,
  selectedIds,
}: {
  container_submission_id?: string
  exam?: TimepointContainerSubmissionExamMetadataResponse
  selectedIds: string[]
}) {
  if (selectedIds.length === 0) return null
  return (
    <Stack direction="row" alignItems="center" spacing={SPACING.spacingLg} paddingX={SPACING.spacingLg}>
      <Typography variant="body1" color="text.secondary">
        {selectedIds.length} series selected
      </Typography>
      <Stack direction="row" alignItems="center" spacing={SPACING.minSpacing}>
        <Divider orientation="vertical" flexItem />
        <IconButton>
          <RemoveRedEyeRounded
            sx={{
              color: 'text.secondary',
              width: 20,
              height: 20,
            }}
          />
        </IconButton>
        <DeleteModal container_submission_id={container_submission_id} exam={exam} selectedIds={selectedIds} />
      </Stack>
    </Stack>
  )
}
