import {
  CompanyCreateSchema,
  createCompanyCompaniesPost,
  CreateCompanyCompaniesPostResponse,
  HTTPValidationError,
} from '@common/config/api/client'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export const useCreateCompany = (): UseMutationResult<
  CreateCompanyCompaniesPostResponse,
  AxiosError<HTTPValidationError>,
  { companyData: CompanyCreateSchema }
> => {
  return useMutation<
    CreateCompanyCompaniesPostResponse,
    AxiosError<HTTPValidationError>,
    { companyData: CompanyCreateSchema }
  >({
    mutationFn: async ({ companyData }) => {
      const response = await createCompanyCompaniesPost({
        body: companyData,
        throwOnError: true,
      })
      return response.data
    },
  })
}
