import { getStudyAssignmentsMetadataUsersStudyAssignmentsMetadataGet } from '@common/config/api/client'
import { useQuery } from '@tanstack/react-query'

export const useGetStudyAssignmentsMetadata = () => {
  return useQuery({
    queryKey: ['studyAssignmentsMetadata'],
    queryFn: async () => {
      const response = await getStudyAssignmentsMetadataUsersStudyAssignmentsMetadataGet()
      return response.data
    },
  })
}
