import { Box, Typography, Grid2 as Grid, Stack, CircularProgress, Button, Menu, MenuItem, Chip } from '@mui/material'
import { useGetTimePointSubmissionDetails } from '@features/subject/hooks/useGetTimePointSubmissionDetails'
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { useParams } from 'react-router-dom'
import type { Types } from '@cornerstonejs/core'
import { RenderingEngine, Enums, init as csRenderInit, EVENTS, utilities as csUtilities } from '@cornerstonejs/core'
import * as cornerstoneTools from '@cornerstonejs/tools'
import { init as dicomImageLoaderInit } from '@cornerstonejs/dicom-image-loader'
import { useEffect, useRef, useState } from 'react'
import { SESSION_STORAGE_KEY } from '@common/constants/api.ts'
import axios from 'axios'
import createImageIdsAndCacheMetaData from '@features/subject/utils.ts'
import { StudyModel } from '@common/config/api/client'
import StudiesAndSeriesGroup from '@features/subject/components/StudiesAndSeriesGroup'
import { useGetPHIRedactionStatus } from '@features/subject/hooks/useGetPHIRedactionStatus.ts'
import dayjs from 'dayjs'

const {
  PanTool,
  WindowLevelTool,
  StackScrollTool,
  ZoomTool,
  PlanarRotateTool,
  ToolGroupManager,
  Enums: csToolsEnums,
  RectangleROITool,
  annotation,
} = cornerstoneTools

const { MouseBindings } = csToolsEnums

// Define the PHI prediction type
type PHIPrediction = {
  study_instance_uid: string
  series_instance_uid: string
  sop_instance_uid: string
  x: number
  y: number
  width: number
  height: number
}

// Define the container with PHI status type
type PHIContainer = {
  container_submission_id: string
  phi_status: string
  exam_metadata_id: string
  series_metadata_id: string
  predictions: PHIPrediction[] | null
}

const doesStudyHavePhi = (
  phiData: unknown,
  studyInstanceUID: string,
  dataToRender: Record<string, StudyModel>,
): boolean => {
  const containers = (phiData as { containers?: PHIContainer[] })?.containers
  if (!containers || !Array.isArray(containers)) return false

  const seriesUIDs = dataToRender[studyInstanceUID]?.series.map((series) => series.SeriesInstanceUID) || []

  return containers.some((container) => {
    if (!container.predictions) return false
    return container.predictions.some((prediction) => seriesUIDs.includes(prediction.series_instance_uid))
  })
}

const getSeriesWithPhiDetected = (
  phiData: unknown,
  studyInstanceUID: string,
  dataToRender: Record<string, StudyModel>,
): string[] => {
  const containers = (phiData as { containers?: PHIContainer[] })?.containers
  if (!containers || !Array.isArray(containers)) return []

  const seriesInStudy = dataToRender[studyInstanceUID]?.series || []
  const seriesWithPhi: string[] = []

  seriesInStudy.forEach((series) => {
    const seriesUID = series.SeriesInstanceUID

    const hasPhi = containers.some((container) => {
      if (!container.predictions) return false
      return container.predictions.some((prediction) => prediction.series_instance_uid === seriesUID)
    })

    if (hasPhi) {
      seriesWithPhi.push(seriesUID)
    }
  })

  return seriesWithPhi
}

export default function PHIRedactionStep() {
  const { studyId, subjectId, timepointSubmissionId } = useParams<{
    studyId: string
    subjectId: string
    timepointSubmissionId?: string
  }>()

  const { data: timePointSubmissionData, isLoading: isTimePointSubmissionLoading } = useGetTimePointSubmissionDetails(
    [],
    {
      study_id: studyId!,
      subject_id: subjectId!,
      timepoint_submission_id: timepointSubmissionId!,
    },
    { get_dicom_json: true },
  )
  const { data: phiData } = useGetPHIRedactionStatus(
    {
      study_id: studyId!,
      subject_id: subjectId!,
      timepoint_submission_id: timepointSubmissionId!,
    },
    {
      container_submission_ids:
        timePointSubmissionData?.data?.timepoint_submission.container_submissions.map(
          (x) => x.container_submission_id,
        ) || [],
    },
  )

  const elementRef = useRef<HTMLDivElement>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [engine, setEngine] = useState<RenderingEngine | null>(null)
  const [isInitialized, setIsInitialized] = useState(false)
  const [dataToRender, setDataToRender] = useState<Record<string, StudyModel>>({})
  const [selectedSeries, setSelectedSeries] = useState({
    series: '',
    study: '',
  })
  const [isLoading, setIsLoading] = useState(false)
  const [currentImageId, setCurrentImageId] = useState<string | null>(null)
  const [phiPredictions, setPhiPredictions] = useState<Record<string, PHIPrediction[]>>({})
  const [urlToMetadataMap, setUrlToMetadataMap] = useState({})

  // Track which series have had their PHI manually redacted
  const [redactedSeries, setRedactedSeries] = useState<Set<string>>(new Set())

  // Track which SOP instances (individual images) have been redacted per series
  // Format: { seriesInstanceUID: Set<sopInstanceUID> }
  const [redactedSOPInstances, setRedactedSOPInstances] = useState<Record<string, Set<string>>>({})

  const renderingEngineId = 'dicomRenderingEngine'
  const viewportId = 'STACK_VIEWPORT'
  const toolGroupId = 'STACK_TOOL_GROUP_ID'
  const seriesToProcess = dataToRender[selectedSeries.study]?.series.find(
    (x) => x.SeriesInstanceUID === selectedSeries.series,
  )

  // Process PHI predictions data
  useEffect(() => {
    if (phiData?.containers) {
      // Log the entire PHI data structure for debugging
      console.log('PHI Data structure:', phiData)

      // Log PHI detection status per study
      if (Object.keys(dataToRender).length > 0) {
        Object.keys(dataToRender).forEach((studyUID) => {
          const studyHasPhi = doesStudyHavePhi(phiData, studyUID, dataToRender)
          console.log(`Study ${studyUID.slice(0, 8)}... has PHI: ${studyHasPhi}`)
        })
      }

      // Log the status values from all containers
      const containers = phiData.containers as PHIContainer[]
      if (containers && Array.isArray(containers)) {
        // Create a map of predictions by SOP instance UID for faster lookup
        const predictionsMap: Record<string, PHIPrediction[]> = {}

        containers.forEach((container) => {
          if (container.predictions && container.predictions.length > 0) {
            container.predictions.forEach((prediction) => {
              const key = prediction.sop_instance_uid
              if (!predictionsMap[key]) {
                predictionsMap[key] = []
              }
              predictionsMap[key].push(prediction)
            })
          }
        })

        setPhiPredictions(predictionsMap)
      }
    }
  }, [phiData, dataToRender])

  const updateOverlay = () => {
    // Get stack info
    const viewport = engine?.getStackViewport(viewportId)

    if (!viewport) {
      return
    }

    const stack = viewport.getImageData()
    if (!stack) return

    const currentIndex = viewport.getCurrentImageIdIndex() + 1
    const total = viewport.getImageIds().length
    const currentImgId = viewport.getCurrentImageId()

    // Update current image ID for PHI box drawing
    setCurrentImageId(currentImgId)

    // Create or update overlay
    let overlayDiv = elementRef.current?.querySelector('.image-index-overlay') as HTMLDivElement

    if (!overlayDiv) {
      overlayDiv = document.createElement('div')
      overlayDiv.className = 'image-index-overlay'
      overlayDiv.style.position = 'absolute'
      overlayDiv.style.bottom = '10px'
      overlayDiv.style.right = '10px'
      overlayDiv.style.color = '#5acce6'
      overlayDiv.style.textShadow = '1px 1px 2px black'
      overlayDiv.style.fontSize = '13px'
      overlayDiv.style.pointerEvents = 'none'
      elementRef.current?.appendChild(overlayDiv)
    }

    overlayDiv.innerHTML = `${currentIndex}/${total}`
  }

  // Draw PHI bounding boxes for the current image
  const drawPHIBoxes = () => {
    if (!currentImageId || !phiPredictions || Object.keys(phiPredictions).length === 0) {
      return
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const sopInstanceUid = urlToMetadataMap[currentImageId.slice('wadouri:'.length) || ''].SOPInstanceUID

    // If this image is in a redacted series or its SOP instance has been redacted, don't draw anything
    if (
      redactedSeries.has(selectedSeries.series) ||
      (redactedSOPInstances[selectedSeries.series] && redactedSOPInstances[selectedSeries.series].has(sopInstanceUid))
    ) {
      // Clear any existing annotations
      annotation.state.removeAllAnnotations()
      return
    }

    const predictions = phiPredictions[sopInstanceUid]

    if (!predictions || predictions.length === 0) {
      return
    }

    const viewport = engine?.getViewport(viewportId) as Types.IStackViewport
    if (!viewport) {
      return
    }

    // Clear any existing annotations
    annotation.state.removeAllAnnotations()

    // Get the canvas element
    const canvas = viewport.element
    if (!canvas) {
      return
    }

    // For each prediction, draw a rectangle ROI
    predictions.forEach((prediction) => {
      // Create a unique annotation ID
      const annotationUID = crypto.randomUUID()

      // Convert image coordinates to world coordinates
      const imagePointTopLeft: Types.Point2 = [prediction.x, prediction.y]
      const imagePointTopRight: Types.Point2 = [prediction.x + prediction.width, prediction.y]
      const imagePointBottomRight: Types.Point2 = [prediction.x + prediction.width, prediction.y + prediction.height]
      const imagePointBottomLeft: Types.Point2 = [prediction.x, prediction.y + prediction.height]

      // Convert each point to world coordinates
      const worldPointTopLeft = csUtilities.imageToWorldCoords(currentImageId, imagePointTopLeft)
      const worldPointTopRight = csUtilities.imageToWorldCoords(currentImageId, imagePointTopRight)
      const worldPointBottomRight = csUtilities.imageToWorldCoords(currentImageId, imagePointBottomRight)
      const worldPointBottomLeft = csUtilities.imageToWorldCoords(currentImageId, imagePointBottomLeft)

      // Ensure all points were converted successfully
      if (!worldPointTopLeft || !worldPointTopRight || !worldPointBottomRight || !worldPointBottomLeft) {
        return
      }

      // Create proper Point3 objects
      const point1: Types.Point3 = [worldPointTopLeft[0], worldPointTopLeft[1], worldPointTopLeft[2] || 0]
      const point2: Types.Point3 = [worldPointTopRight[0], worldPointTopRight[1], worldPointTopRight[2] || 0]
      const point3: Types.Point3 = [worldPointBottomRight[0], worldPointBottomRight[1], worldPointBottomRight[2] || 0]
      const point4: Types.Point3 = [worldPointBottomLeft[0], worldPointBottomLeft[1], worldPointBottomLeft[2] || 0]

      // Create a rectangle annotation WITHOUT any text properties
      const rectangleAnnotation = {
        annotationUID,
        metadata: {
          toolName: RectangleROITool.toolName,
          referencedImageId: currentImageId,
        },
        data: {
          handles: {
            points: [point1, point2, point3, point4] as Types.Point3[],
            activeHandleIndex: null,
          },
          cachedStats: {}, // Empty stats = no measurements = no text
          initialRotation: 0,
          invalidated: true,
          visible: true,
          highlighted: false,
          color: '#5acce6',
          lineWidth: 3,
          // Critical: NO textBox property and NO label property
        },
      }

      // Add the annotation to the state
      annotation.state.addAnnotation(rectangleAnnotation, canvas)
    })

    // Force render to show the annotations
    viewport.render()
  }

  // Redraw PHI boxes when dependencies change
  useEffect(() => {
    if (currentImageId) {
      drawPHIBoxes()
    }
  }, [currentImageId, phiPredictions, redactedSeries, redactedSOPInstances, selectedSeries])

  useEffect(() => {
    if (timePointSubmissionData && !isTimePointSubmissionLoading) {
      const result: Array<StudyModel> = []

      timePointSubmissionData.data?.timepoint_submission.container_submissions.forEach((item) => {
        if (item.dicom_json) {
          result.push(...(item.dicom_json.studies as Array<StudyModel>))
        }
      })

      const preparedData = result.reduce(
        (acc, item) => {
          if (acc[item.StudyInstanceUID]) {
            acc[item.StudyInstanceUID].series.push(...item.series)
          } else {
            acc[item.StudyInstanceUID] = item
          }

          return acc
        },
        {} as Record<string, StudyModel>,
      )

      // Get all studies instead of just the first one
      const allStudies = Object.keys(preparedData)

      // If we have studies, select the first series of the first study as default
      if (allStudies.length > 0) {
        const firstStudy = allStudies[0]
        if (preparedData[firstStudy].series.length > 0) {
          setSelectedSeries({
            series: preparedData[firstStudy].series[0].SeriesInstanceUID,
            study: firstStudy,
          })
        }
      }

      setDataToRender(preparedData)
    }
  }, [isTimePointSubmissionLoading])

  useEffect(() => {
    const init = async () => {
      try {
        // Initialize Cornerstone Core
        await csRenderInit()

        await cornerstoneTools.init()

        cornerstoneTools.addTool(PanTool)
        cornerstoneTools.addTool(WindowLevelTool)
        cornerstoneTools.addTool(StackScrollTool)
        cornerstoneTools.addTool(ZoomTool)
        cornerstoneTools.addTool(PlanarRotateTool)
        cornerstoneTools.addTool(RectangleROITool)

        const toolGroup = ToolGroupManager.createToolGroup(toolGroupId)

        toolGroup?.addTool(WindowLevelTool.toolName)
        toolGroup?.addTool(PanTool.toolName)
        toolGroup?.addTool(ZoomTool.toolName)
        toolGroup?.addTool(StackScrollTool.toolName, {
          configuration: {
            deltaY: 0.25, // Reduce speed to 25% of default
          },
        })
        toolGroup?.addTool(RectangleROITool.toolName, {
          configuration: {
            color: '#5acce6', // Red color for PHI boxes
            lineWidth: 3,
            showHandles: false, // Don't show handles
            hideHandlesIfMoving: true,
            preventHandleOutsideImage: true,
          },
        })

        // Configure tool activation and bindings
        toolGroup?.setToolActive(StackScrollTool.toolName, {
          bindings: [
            {
              mouseButton: MouseBindings.Primary, // Left Click
            },
          ],
        })
        toolGroup?.setToolActive(PanTool.toolName, {
          bindings: [
            {
              mouseButton: MouseBindings.Auxiliary, // Middle Click
            },
          ],
        })
        toolGroup?.setToolActive(ZoomTool.toolName, {
          bindings: [
            {
              mouseButton: MouseBindings.Secondary, // Right Click
            },
          ],
        })

        toolGroup?.setToolActive(StackScrollTool.toolName, {
          bindings: [
            {
              mouseButton: MouseBindings.Wheel, // Wheel Mouse
            },
          ],
        })

        toolGroup?.setToolEnabled(RectangleROITool.toolName)

        // CRITICAL: Configure annotation style to hide text
        // This is a global setting for all annotations - we do NOT want any text
        annotation.config.style.setDefaultToolStyles({
          global: {
            color: '#5acce6',
            textBoxFontSize: '0px', // Make text invisible
            textBoxColor: 'rgba(0,0,0,0)', // Transparent text color
            textBoxBackground: 'rgba(0,0,0,0)', // Transparent background
            textBoxLinkLineWidth: 0, // No link line
            textBoxLinkLineDash: '0', // No link line
          },
          RectangleROI: {
            color: '#5acce6',
            textBoxFontSize: '0px',
            textBoxColor: 'rgba(0,0,0,0)',
            textBoxBackground: 'rgba(0,0,0,0)',
            textBoxLinkLineWidth: 0,
            textBoxLinkLineDash: '0',
          },
        })

        dicomImageLoaderInit({
          maxWebWorkers: 1,
        })

        const engine = new RenderingEngine(renderingEngineId)

        const viewportConfig = {
          viewportId,
          element: elementRef.current,
          type: Enums.ViewportType.STACK,
          background: [0, 0, 0],
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        engine.enableElement(viewportConfig)
        setEngine(engine)
        toolGroup?.addViewport(viewportId, renderingEngineId)

        setIsInitialized(true)
      } catch (err) {
        console.error('Error initializing Cornerstone:', err)
      }
    }

    void init()
  }, [])

  useEffect(() => {
    if (!isInitialized || !elementRef.current || !selectedSeries.series) return

    const setupViewport = async () => {
      try {
        setIsLoading(true)
        const userData = sessionStorage.getItem(SESSION_STORAGE_KEY)
        const user = JSON.parse(userData as string)
        const access_token = user['access_token']
        const seriesToProcess = dataToRender[selectedSeries.study].series.find(
          (x) => x.SeriesInstanceUID === selectedSeries.series,
        )

        if (!seriesToProcess) {
          console.error('Selected series not found')
          return
        }

        const instancePromises: Promise<{
          data: { url: string }
          instanceMetadata: Record<string, string | number | string[] | number[] | null>
          instanceNumber: number
        }>[] = []

        // Process all instances in the series
        for (let i = 0; i < seriesToProcess.instances.length; i++) {
          const instanceUrl = seriesToProcess.instances[i].url.replace(/^dicomweb:/, '')
          const instanceMetadata = seriesToProcess.instances[i].metadata || {}
          // Extract instance number from metadata or default to 0 if not available
          const instanceNumber = instanceMetadata.InstanceNumber
            ? parseInt(String(instanceMetadata.InstanceNumber), 10)
            : 0

          const instancePromise = axios
            .get(instanceUrl, {
              headers: {
                authorization: `Bearer ${access_token}`,
              },
            })
            .then((response) => ({
              data: response.data,
              instanceMetadata: instanceMetadata as Record<string, string | number | string[] | number[] | null>,
              instanceNumber,
            }))

          instancePromises.push(instancePromise)
        }

        // Wait for all instance data
        const instanceResults = await Promise.all(instancePromises)

        // Sort instances by InstanceNumber
        instanceResults.sort((a, b) => a.instanceNumber - b.instanceNumber)

        const allImageIds: string[] = []
        const urlToMetadataMap: Record<string, Record<string, string | number | string[] | number[] | null>> = {}

        // Process each instance
        for (let i = 0; i < instanceResults.length; i++) {
          const instanceData = instanceResults[i].data
          const instanceMetadata = instanceResults[i].instanceMetadata

          // Create image IDs for this instance
          const instanceImageIds = createImageIdsAndCacheMetaData(instanceData.url, instanceMetadata)

          allImageIds.push(...instanceImageIds)
          urlToMetadataMap[instanceData.url] = instanceMetadata
        }

        const viewport = engine?.getViewport(viewportId) as Types.IStackViewport
        setUrlToMetadataMap(urlToMetadataMap)

        viewport.reset(true)
        await viewport.setStack(allImageIds)
        await viewport.setImageIdIndex(0)
        cornerstoneTools.utilities.stackPrefetch.enable(viewport.element)
        viewport.render()

        // Remove previous event listeners to avoid duplicates
        elementRef?.current?.removeEventListener(EVENTS.IMAGE_RENDERED, updateOverlay)
        elementRef?.current?.removeEventListener(EVENTS.STACK_VIEWPORT_SCROLL, updateOverlay)

        // Add event listeners for overlay and PHI box drawing
        elementRef?.current?.addEventListener(EVENTS.IMAGE_RENDERED, updateOverlay)
        elementRef?.current?.addEventListener(EVENTS.STACK_VIEWPORT_SCROLL, updateOverlay)

        updateOverlay()
        setIsLoading(false)
      } catch (err) {
        console.error('Error setting up viewport:', err)
        setIsLoading(false)
      }
    }

    void setupViewport()
  }, [isInitialized, selectedSeries.series])

  // Add a visual indicator for series with PHI
  const getSeriesPHIStatus = (seriesInstanceUID: string): string => {
    if (!phiData?.containers) return 'unknown'

    const containers = phiData.containers as PHIContainer[]
    if (!containers || !Array.isArray(containers)) return 'unknown'

    const container = containers.find((c) => {
      if (!c.predictions) return false
      return c.predictions.some((p) => p.series_instance_uid === seriesInstanceUID)
    })

    return container ? container.phi_status : 'phi_not_detected'
  }

  // Function to check if the current image has PHI masks
  const doesCurrentImageHaveMasks = (): boolean => {
    if (!currentImageId) return false

    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const sopInstanceUid = urlToMetadataMap[currentImageId.slice('wadouri:'.length) || ''].SOPInstanceUID

      // Check if this image's SOP instance has been redacted
      if (
        redactedSeries.has(selectedSeries.series) ||
        (redactedSOPInstances[selectedSeries.series] && redactedSOPInstances[selectedSeries.series].has(sopInstanceUid))
      ) {
        return false
      }

      return !!phiPredictions[sopInstanceUid]?.length
    } catch (error) {
      console.error('Error checking if current image has masks:', error)
      return false
    }
  }

  const removeMasksFromCurrentImage = () => {
    if (!currentImageId) {
      return
    }

    // Get the viewport
    const viewport = engine?.getViewport(viewportId) as Types.IStackViewport
    if (!viewport) {
      return
    }

    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const sopInstanceUid = urlToMetadataMap[currentImageId.slice('wadouri:'.length) || ''].SOPInstanceUID

      const currentSeriesUID = selectedSeries.series
      const updatedRedactedSOPInstances = { ...redactedSOPInstances }

      if (!updatedRedactedSOPInstances[currentSeriesUID]) {
        updatedRedactedSOPInstances[currentSeriesUID] = new Set<string>()
      }

      updatedRedactedSOPInstances[currentSeriesUID].add(sopInstanceUid)
      setRedactedSOPInstances(updatedRedactedSOPInstances)

      // Clear any existing annotations on the current image
      annotation.state.removeAllAnnotations()
    } catch (error) {
      console.error('Error removing masks from current image:', error)
    }

    // Re-render the viewport
    viewport.render()

    setAnchorEl(null)
  }

  const removeMasksFromAllImages = () => {
    const viewport = engine?.getViewport(viewportId) as Types.IStackViewport
    if (!viewport) {
      return
    }

    const currentSeriesUID = selectedSeries.series

    // Update the redacted series set
    const updatedRedactedSeries = new Set(redactedSeries)
    updatedRedactedSeries.add(currentSeriesUID)
    setRedactedSeries(updatedRedactedSeries)

    // Clear any existing annotations
    annotation.state.removeAllAnnotations()
    viewport.render()

    // Close the menu
    setAnchorEl(null)
  }

  const doesCurrentSeriesHaveMasks = (): boolean => {
    if (!selectedSeries.series || !phiData?.containers) {
      return false
    }

    if (redactedSeries.has(selectedSeries.series)) {
      return false
    }

    const seriesUID = selectedSeries.series
    const redactedSOPs = redactedSOPInstances[seriesUID] || new Set<string>()

    // Look through all the predictions to see if any match the current series
    // and exclude any SOP instances that have been redacted
    return Object.entries(phiPredictions).some(
      ([sopInstanceUID, predictions]) =>
        !redactedSOPs.has(sopInstanceUID) &&
        predictions.some((prediction) => prediction.series_instance_uid === seriesUID),
    )
  }

  const hasSeriesPHI = (seriesInstanceUID: string): boolean => {
    if (redactedSeries.has(seriesInstanceUID)) {
      return false
    }

    const redactedSOPs = redactedSOPInstances[seriesInstanceUID] || new Set<string>()
    const hasPHIPredictions = Object.entries(phiPredictions).some(
      ([sopInstanceUID, predictions]) =>
        !redactedSOPs.has(sopInstanceUID) &&
        predictions.some((prediction) => prediction.series_instance_uid === seriesInstanceUID),
    )

    if (!hasPHIPredictions) {
      return false
    }

    return getSeriesPHIStatus(seriesInstanceUID) === 'phi_detected'
  }

  return (
    <Box px={8} pb={5}>
      <Typography color="text.primary" variant="h4">
        DICOM files PHI redaction
      </Typography>
      <Typography color="text.secondary" variant="body2" mb={4}>
        AI will analyze images in all series to detect PHI. Review the series labeled "PHI suspected" and confirm the
        PHI redaction.
      </Typography>
      <Grid container spacing={2}>
        <Grid size={8}>
          <Stack>
            <Box bgcolor="white" borderRadius={4} p={4}>
              {isTimePointSubmissionLoading || !isInitialized ? null : (
                <>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">{seriesToProcess?.SeriesDescription}</Typography>
                    {hasSeriesPHI(selectedSeries.series) && (
                      <Chip label="PHI suspected" variant="filled" color="warning-alt" sx={{ borderRadius: 100 }} />
                    )}
                  </Box>
                  <Stack spacing={1} direction="row" mb={2}>
                    <Typography color="text.secondary">
                      {dayjs(dataToRender[selectedSeries.study]?.StudyDate, 'YYYYMMDD').format('DD/MM/YYYY')}
                    </Typography>
                    <Typography color="text.secondary">•</Typography>
                    <Typography color="text.secondary">{seriesToProcess?.SeriesNumber}</Typography>
                    <Typography color="text.secondary">•</Typography>
                    <Typography color="text.secondary">{seriesToProcess?.Modality}</Typography>
                    <Typography color="text.secondary">•</Typography>
                    <Typography color="text.secondary">{seriesToProcess?.instances.length} instance(s)</Typography>
                  </Stack>
                  {hasSeriesPHI(selectedSeries.series) &&
                    !redactedSeries.has(selectedSeries.series) &&
                    doesCurrentSeriesHaveMasks() && (
                      <Box mb={2} display="flex" justifyContent="flex-end">
                        <Button
                          color="inherit"
                          id="remove-all-masks-button"
                          aria-controls={anchorEl ? 'remove-all-masks-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={anchorEl ? 'true' : undefined}
                          onClick={(event) => setAnchorEl(event.currentTarget)}
                          endIcon={anchorEl ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
                        >
                          Remove masks
                        </Button>
                        <Menu
                          id="remove-all-masks-menu"
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={() => setAnchorEl(null)}
                          slotProps={{
                            list: {
                              'aria-labelledby': 'remove-all-masks-button',
                            },
                          }}
                        >
                          {doesCurrentImageHaveMasks() ? (
                            <MenuItem onClick={removeMasksFromCurrentImage}>From selected image</MenuItem>
                          ) : (
                            <MenuItem disabled>No masks on current image</MenuItem>
                          )}
                          <MenuItem onClick={removeMasksFromAllImages}>From all images</MenuItem>
                        </Menu>
                      </Box>
                    )}
                </>
              )}
              <Box position="relative">
                <div
                  ref={elementRef}
                  style={{
                    display: 'flex',
                    height: '512px',
                    backgroundColor: '#000',
                  }}
                />
                {isLoading && (
                  <Box
                    position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      zIndex: 10,
                    }}
                  >
                    <CircularProgress color="primary" />
                  </Box>
                )}
              </Box>
            </Box>
          </Stack>
        </Grid>
        <Grid size={4} px={4}>
          <Typography variant="h6" mb={4}>
            Studies and series
          </Typography>
          {Object.keys(dataToRender).map((studyUID, index) => {
            const seriesWithPhiDetected = getSeriesWithPhiDetected(phiData, studyUID, dataToRender)

            const filteredSeriesWithPhiDetected = seriesWithPhiDetected.filter(
              (seriesUID) => !redactedSeries.has(seriesUID),
            )

            const hasStudyPhiDetected = filteredSeriesWithPhiDetected.length > 0

            return (
              <StudiesAndSeriesGroup
                key={index}
                name={dataToRender[studyUID].StudyDescription}
                children={dataToRender[studyUID].series}
                selectedSeries={selectedSeries.series}
                onSeriesClick={(series) => setSelectedSeries({ series, study: studyUID })}
                hasPhiDetected={hasStudyPhiDetected}
                seriesWithPhiDetected={filteredSeriesWithPhiDetected}
                isPhiLoading={phiData?.containers.some((x) => x.phi_status === 'in_progress')}
              />
            )
          })}
        </Grid>
      </Grid>
    </Box>
  )
}
