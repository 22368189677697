import {
  createTimepointSubmissionStudiesStudyIdSubjectsSubjectIdTimepointSubmissionPost,
  CreateTimepointSubmissionStudiesStudyIdSubjectsSubjectIdTimepointSubmissionPostResponse,
  CreateTimepointSubmissionStudiesStudyIdSubjectsSubjectIdTimepointSubmissionPostData,
  HTTPValidationError,
} from '@common/config/api/client'
import { queryClient } from '@common/config/api/queryClient'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export const useCreateTimepointSubmission = (): UseMutationResult<
  CreateTimepointSubmissionStudiesStudyIdSubjectsSubjectIdTimepointSubmissionPostResponse,
  AxiosError<HTTPValidationError>,
  CreateTimepointSubmissionStudiesStudyIdSubjectsSubjectIdTimepointSubmissionPostData
> => {
  return useMutation<
    CreateTimepointSubmissionStudiesStudyIdSubjectsSubjectIdTimepointSubmissionPostResponse,
    AxiosError<HTTPValidationError>,
    CreateTimepointSubmissionStudiesStudyIdSubjectsSubjectIdTimepointSubmissionPostData
  >({
    mutationFn: async (query) => {
      const response = await createTimepointSubmissionStudiesStudyIdSubjectsSubjectIdTimepointSubmissionPost({
        body: query.body,
        path: query.path,
        throwOnError: true,
      })
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['SubjectsList'] })
    },
  })
}
