import { Theme } from '@mui/material/styles'

export const AlertOverrides = (theme: Theme) => {
  return {
    MuiAlert: {
      styleOverrides: {
        filledError: {
          borderRadius: 8,
          fontWeight: 400,
          color: theme.palette['error-alt'].contrastText,
          backgroundColor: theme.palette['error-alt'].main,
        },
      },
    },
  }
}
