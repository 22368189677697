import { useState, MouseEvent } from 'react'
import { CompanyType, CompaniesParams } from '../types'
import { useCreateCompany } from '@study_setup/hooks/useCompanyMutations'
import { useUpdateCompany, useDeleteCompany, useGetCompanyById, TransformedCompanies } from '.'
import { toast } from 'sonner'
import Toast from '@common/components/Toast'
import { queryClient } from '@common/config/api/queryClient'
import { CompanyFormValues } from '../components/CreateOrUpdateCompanyDialog'
import { handleMutationError } from '@common/hooks/handleMutationError'
import useBulkDeleteCompanies from './useBulkDeleteCompanies'

export default function useCompanyManagementHandlers() {
  const [page, setPage] = useState(0)
  const [isCompanyDialogVisible, setIsCompanyDialogVisible] = useState(false)
  const [currentCompanyType, setCurrentCompanyType] = useState<CompanyType | null>(null)
  const [selectedCompanyType, setSelectedCompanyType] = useState<CompanyType | null>(null)
  const [editingCompanyId, setEditingCompanyId] = useState<string | null>(null)
  const [addMenuAnchor, setAddMenuAnchor] = useState<null | HTMLElement>(null)
  const [companiesParams, setCompaniesParams] = useState<CompaniesParams>({
    company_type: undefined,
    search: undefined,
    limit: 50,
    next_cursor: undefined,
    previous_cursor: undefined,
    order_by_field: 'name',
    asc_order: true,
  })

  const { mutate: createCompany } = useCreateCompany()
  const { mutate: updateCompany } = useUpdateCompany()
  const { mutate: deleteCompany } = useDeleteCompany()
  const { mutate: bulkDeleteCompanies } = useBulkDeleteCompanies()
  const { data: editingCompany, isPending: isEditingCompanyPending } = useGetCompanyById(editingCompanyId)

  const handleOpenCompanyMenu = (event: MouseEvent<HTMLElement>) => {
    setAddMenuAnchor(event.currentTarget)
  }

  const handleCloseCompanyMenu = () => {
    setAddMenuAnchor(null)
  }

  const handleCompanyTypeSelect = (companyType: CompanyType) => {
    setSelectedCompanyType(companyType)
    setIsCompanyDialogVisible(true)
    handleCloseCompanyMenu()
  }

  const handleSaveCompany = (companyData: CompanyFormValues) => {
    if (!selectedCompanyType) return
    createCompany(
      { companyData },
      {
        onSuccess: () => {
          toast(<Toast message="Company created successfully." variant="success" />)
          setIsCompanyDialogVisible(false)
          setSelectedCompanyType(null)
          queryClient.invalidateQueries({ queryKey: ['companies'] })
        },
        onError: handleMutationError,
      },
    )
  }

  const handlePageChange = (
    companies: TransformedCompanies | undefined,
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    const isNextPage = newPage > page
    const cursor = isNextPage ? companies?.pagination.nextCursor : companies?.pagination.previousCursor

    if (cursor) {
      setCompaniesParams((prev) => ({
        ...prev,
        next_cursor: isNextPage ? cursor : null,
        previous_cursor: isNextPage ? null : cursor,
      }))
      setPage(isNextPage ? page + 1 : page - 1)
    }
  }
  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompaniesParams((prev) => ({
      ...prev,
      limit: parseInt(event.target.value, 10),
      next_cursor: null,
      previous_cursor: null,
    }))
    setPage(0)
  }

  const handleSearch = (value: string) => {
    setCompaniesParams((prev) => ({
      ...prev,
      search: value || undefined,
      next_cursor: undefined,
      previous_cursor: undefined,
    }))
    setPage(0)
  }

  const handleRequestSort = (_event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = companiesParams.order_by_field === property && companiesParams.asc_order
    setCompaniesParams((prev) => ({
      ...prev,
      order_by_field: property,
      asc_order: !isAsc,
      next_cursor: undefined,
      previous_cursor: undefined,
    }))
    setPage(0)
  }

  const handleAddClick = (companyType: CompanyType) => {
    setSelectedCompanyType(companyType)
    setIsCompanyDialogVisible(true)
  }

  const handleEditClick = (companyId: string) => {
    setEditingCompanyId(companyId)
    setIsCompanyDialogVisible(true)
  }

  const handleSaveEditedCompany = (companyData: CompanyFormValues) => {
    updateCompany(
      { companyId: editingCompanyId!, companyData: companyData },
      {
        onSuccess: () => {
          toast(<Toast message="Company updated successfully" variant="success" />)
          setEditingCompanyId(null)
          setIsCompanyDialogVisible(false)
          queryClient.invalidateQueries({ queryKey: ['companies'] })
        },
        onError: handleMutationError,
      },
    )
  }

  const handleDeleteCompany = (companyId: string) => {
    deleteCompany(companyId, {
      onSuccess: () => {
        toast(<Toast message="Company deleted successfully" variant="success" />)
        queryClient.invalidateQueries({ queryKey: ['companies'] })
      },
      onError: handleMutationError,
    })
  }

  const handleBulkDeleteCompanies = (companyIds: string[]) => {
    bulkDeleteCompanies(
      { company_ids: companyIds.join(',') },
      {
        onSuccess: () => {
          toast(<Toast message="Company deleted successfully" variant="success" />)
          queryClient.invalidateQueries({ queryKey: ['companies'] })
        },
        onError: handleMutationError,
      },
    )
  }

  const handleCompanyTypeChange = (companyType: CompanyType | null) => {
    setCurrentCompanyType(companyType)
  }

  return {
    page,
    isCompanyDialogVisible,
    selectedCompanyType,
    editingCompanyId,
    addMenuAnchor,
    companiesParams,
    setCompaniesParams,
    editingCompany,
    currentCompanyType,
    handleOpenCompanyMenu,
    handleCloseCompanyMenu,
    handleCompanyTypeSelect,
    handleSaveCompany,
    handlePageChange,
    handleRowsPerPageChange,
    handleSearch,
    handleRequestSort,
    handleAddClick,
    handleEditClick,
    handleSaveEditedCompany,
    handleDeleteCompany,
    handleBulkDeleteCompanies,
    setEditingCompanyId,
    setIsCompanyDialogVisible,
    handleCompanyTypeChange,
    isEditingCompanyPending,
  }
}
