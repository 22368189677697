import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'sonner'
import { AxiosError } from 'axios'
import Toast from '@common/components/Toast'
import {
  HTTPValidationError,
  updateUserStudyAssignmentUsersUserIdStudyAssignmentsStudyUserRoleIdPatch,
} from '@common/config/api/client'

interface UpdateStudyRoleParams {
  userId: string
  studyUserRoleId: string
  roleId: string
}

export const useUpdateStudyRole = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ userId, studyUserRoleId, roleId }: UpdateStudyRoleParams) => {
      return await updateUserStudyAssignmentUsersUserIdStudyAssignmentsStudyUserRoleIdPatch({
        path: {
          user_id: userId,
          study_user_role_id: studyUserRoleId,
        },
        body: {
          role_id: roleId,
        },
      })
    },
    onSuccess: (_, variables) => {
      toast(<Toast message="Role updated successfully" variant="success" />)
      queryClient.invalidateQueries({ queryKey: ['studyAssignments', variables.userId] })
    },
    onError: (error: AxiosError<HTTPValidationError>) => {
      const errorMessage =
        typeof error.response?.data?.detail === 'string'
          ? error.response.data.detail
          : error.message || 'Failed to update role'
      toast(<Toast message={errorMessage} variant="error" />)
    },
  })
}
