import { EditDocumentIcon, LabsIcon } from '@common/components/Icons'
import { Status } from '@common/config/api/client'
import { SPACING } from '@common/theme/spacing'
import { formatStatusText } from '@features/study_view/utils'
import { ArchiveRounded, CheckCircleRounded, PlayCircleRounded } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { ReactNode } from 'react'

function getStatusIcon(status: Status): ReactNode {
  switch (status) {
    case 'STUDY_SETUP':
      return <EditDocumentIcon data-testid="status-icon-study-setup" />
    case 'DRAFT':
      return <EditDocumentIcon data-testid="status-icon-draft" />
    case 'UAT':
      return <LabsIcon data-testid="status-icon-uat" />
    case 'LIVE':
      return (
        <PlayCircleRounded
          data-testid="status-icon-live"
          sx={{
            color: 'primary.light',
          }}
        />
      )
    case 'COMPLETED':
      return (
        <CheckCircleRounded
          data-testid="status-icon-completed"
          sx={{
            color: 'success.main',
          }}
        />
      )
    case 'ARCHIVED':
      return (
        <ArchiveRounded
          data-testid="status-icon-archived"
          sx={{
            color: 'text.disabled',
          }}
        />
      )
  }
}

export default function StudyStatus({ status }: { status: Status }) {
  return (
    <Stack
      direction={'row'}
      spacing={SPACING.spacingXs}
      sx={{
        alignItems: 'center',
      }}
      data-testid="study-status"
    >
      {getStatusIcon(status)}
      <Typography
        variant="body2"
        sx={{
          color: 'text.primary',
        }}
        data-testid="status-text"
      >
        {formatStatusText(status)}
      </Typography>
    </Stack>
  )
}
