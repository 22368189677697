import { SPACING } from '@common/theme/spacing'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box } from '@mui/material'

export interface Study {
  studyName: string
  role: string
}

export interface MyStudiesTableProps {
  studies: Study[]
}

const MyStudiesTable: React.FC<MyStudiesTableProps> = ({ studies }) => {
  const hasStudies = studies.length > 0
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      sx={{
        borderRadius: 2,
        border: '1px solid',
        borderColor: 'secondary.main',
        overflow: 'hidden',
      }}
    >
      <Table sx={{ minWidth: 650 }}>
        <TableHead sx={{ backgroundColor: 'paper.main' }}>
          <TableRow>
            <TableCell
              sx={{
                py: SPACING.tableCellPaddingY,
                px: SPACING.tableCellPaddingX,
              }}
            >
              <Typography variant="subtitle2" color="text.secondary">
                Study name
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                py: SPACING.tableCellPaddingY,
                px: SPACING.tableCellPaddingX,
              }}
            >
              <Typography variant="subtitle2" color="text.secondary">
                My role
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {hasStudies ? (
            studies.map((study, index) => (
              <TableRow key={`${study.studyName}-${index}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderBottom: '1px solid',
                    borderColor: 'secondary.main',
                    py: SPACING.tableCellPaddingY,
                    px: SPACING.tableCellPaddingX,
                  }}
                >
                  {study.studyName}
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: '1px solid',
                    borderColor: 'secondary.main',
                    py: SPACING.tableCellPaddingY,
                    px: SPACING.tableCellPaddingX,
                  }}
                >
                  {study.role}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={SPACING.spacingLg} sx={{ border: 0, py: 0 }}>
                <Box
                  sx={{
                    py: SPACING.emptyTableSpacingY,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  <Typography variant="subtitle2">You're not added to any studies yet</Typography>
                  <Typography variant="body2" color="text.secondary">
                    When you'll be added to a study, it will show up here
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default MyStudiesTable
