import { Home } from '@mui/icons-material'
import { RouteKeyEnum } from 'src/routes'

export const COMPANY_MANAGEMENT_BREADCRUMB_ITEMS = [
  {
    title: 'Home',
    route_key: RouteKeyEnum.HOME,
    icon: Home,
  },
  {
    title: 'Companies',
    route_key: RouteKeyEnum.COMPANIES_MANAGEMENT,
  },
]
