import { useQuery } from '@tanstack/react-query'
import { getUserUsersUserIdGet } from '@common/config/api/client'
import queryKeyFactory from '../queryKeyFactory'

interface UseUserDetailsParams {
  userId: string
}

export default function useUserDetails({ userId }: UseUserDetailsParams) {
  return useQuery({
    queryKey: queryKeyFactory.userDetails(userId),
    queryFn: async () => {
      const response = await getUserUsersUserIdGet({
        path: {
          user_id: userId,
        },
        throwOnError: true,
      })
      return response.data
    },
    enabled: !!userId,
  })
}
