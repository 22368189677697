import { CompaniesParams } from './types'

const queryKeyFactory = {
  companyMetadata: () => ['companyMetadata'],
  company: (companyId: string) => ['company', companyId],
  companies: (params: CompaniesParams) => [
    'companies',
    params.company_type,
    params.search,
    params.limit,
    params.next_cursor,
    params.previous_cursor,
    params.order_by_field,
    params.asc_order,
  ],
}

export default queryKeyFactory
