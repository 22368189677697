import { Checkbox, Divider, Stack } from '@mui/material'
import { QcStudyDefinitionCreateFormValues } from '../../schemas/StudyAndSeriesDefinitionValidationSchema'
import { Control, useController } from 'react-hook-form'
import { QcTimepointContainerSubmission } from '@common/config/api/client'
import { useEffect } from 'react'
import { SPACING } from '@common/theme/spacing'
import { SelectField } from '../qc/SelectField'
import { InputField } from '../qc/InputField'
import SeriesDefinitionPopupMenuList from './SeriesDefinitionPopupMenuList'

interface Option {
  value: string
  label: string
}

export default function SeriesDefinitionForm({
  control,
  index,
  qcTimepointContainerSubmissions,
  defaultNewSeriesDescriptionValue,
  defaultSourceSeriesDescriptionValue,
  availableNewSeriesDescriptionOptions,
  availableSourceSeriesDescriptionOptions,
  handleDelete,
  handleDuplicate,
  handleMerge,
}: {
  control: Control<QcStudyDefinitionCreateFormValues>
  index: number
  qcTimepointContainerSubmissions: QcTimepointContainerSubmission[]
  defaultNewSeriesDescriptionValue: string
  defaultSourceSeriesDescriptionValue: string
  availableNewSeriesDescriptionOptions: Option[]
  availableSourceSeriesDescriptionOptions: Option[]
  handleDelete: () => void
  handleDuplicate: () => void
  handleMerge: (new_series_description: string, index?: number) => void
}) {
  const { field: selectedField } = useController({
    name: `series_definitions.${index}.is_selected`,
    control,
  })

  const { field: maxInstanceField } = useController({
    name: `series_definitions.${index}.max_instance_number`,
    control,
  })

  const { field: instanceNumberRangesField } = useController({
    name: `series_definitions.${index}.instance_number_ranges`,
    control,
  })

  const instanceNumber = qcTimepointContainerSubmissions
    .flatMap((submission) => submission.exam_metadata)
    .flatMap((metadata) => metadata.series)
    .find((series) => series.series_metadata_id === defaultSourceSeriesDescriptionValue)?.total_instances

  useEffect(() => {
    if (instanceNumber) {
      maxInstanceField.onChange(instanceNumber)
      instanceNumberRangesField.onChange('1-' + instanceNumber)
    }
  }, [instanceNumber])

  return (
    <Stack
      direction="row"
      alignItems="start"
      justifyContent="space-between"
      spacing={SPACING.spacingLg}
      paddingX={SPACING.seriesDefinitionsFormSpacing}
    >
      <Stack direction="row" alignItems="start" width="100%" spacing={SPACING.minSpacing}>
        <Stack paddingTop={SPACING.seriesDefinitionsFormSpacing}>
          <Checkbox
            sx={{
              width: 32,
              height: 32,
            }}
            checked={!!selectedField.value}
            onChange={(e) => selectedField.onChange(e.target.checked)}
          />
        </Stack>
        <Stack direction="row" alignItems="start" width="100%" spacing={SPACING.minSpacing}>
          <SelectField
            control={control}
            name={`series_definitions.${index}.series_metadata_id`}
            label="Source series description"
            options={availableSourceSeriesDescriptionOptions}
            defaultValue={defaultSourceSeriesDescriptionValue}
          />
          <SelectField
            control={control}
            name={`series_definitions.${index}.series_label_id`}
            label="New series description"
            options={availableNewSeriesDescriptionOptions}
            defaultValue={defaultNewSeriesDescriptionValue}
          />
          <InputField
            control={control}
            name={`series_definitions.${index}.instance_number_ranges`}
            label="Image №"
            sx={{
              width: '50%',
            }}
          />
        </Stack>
      </Stack>
      <Stack
        direction="row"
        spacing={SPACING.minSpacing}
        alignItems="center"
        paddingTop={SPACING.seriesDefinitionsPopupListSpacing}
      >
        <Divider orientation="vertical" flexItem />
        <SeriesDefinitionPopupMenuList
          control={control}
          index={index}
          newSeriesDescriptions={availableNewSeriesDescriptionOptions}
          handleDelete={handleDelete}
          handleDuplicate={handleDuplicate}
          handleMerge={handleMerge}
        />
      </Stack>
    </Stack>
  )
}
