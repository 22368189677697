import { getCompaniesMetadataCompaniesMetadataGet } from '@common/config/api/client'
import { useQuery } from '@tanstack/react-query'
import queryKeyFactory from '../queryKeyFactory'

export default function useCompaniesMetadata() {
  return useQuery({
    queryKey: queryKeyFactory.companiesMetadata(),
    queryFn: async () => {
      const response = await getCompaniesMetadataCompaniesMetadataGet()
      return response.data ?? { companies: [], company_types: [], company_type_filter: [] }
    },
  })
}
