import { AppBar, Box, Button, Dialog, DialogContent, Typography } from '@mui/material'
import 'survey-core/defaultV2.min.css'
import { Survey } from 'survey-react-ui'
import { Model } from 'survey-core'

type FormRendererDialogProps = {
  onClose: () => void
  header: string
  fields?: string | null
  values?: string | null
  readOnly?: boolean
  onSubmit: (params: Record<string, string | number | boolean>) => void
}

export default function FormRendererDialog({
  onClose,
  header,
  fields,
  onSubmit,
  values,
  readOnly,
}: FormRendererDialogProps) {
  const survey = new Model({
    elements: JSON.parse(fields || '[]'),
  })

  if (values) {
    survey.data = JSON.parse(values)
  }
  if (readOnly) {
    survey.mode = 'display'
  }

  survey.onComplete.add((sender) => {
    onSubmit(sender.data)
  })

  return (
    <Dialog fullScreen open={true} onClose={() => null}>
      <AppBar sx={{ position: 'relative', bgcolor: '#F0F4F5' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
          <Typography variant="subtitle1" color="text.primary" ml={1}>
            {header}
          </Typography>
          <Box display="flex">
            <Button variant="text" onClick={onClose}>
              Close
            </Button>
          </Box>
        </Box>
      </AppBar>
      <DialogContent sx={{ p: 0 }}>
        <Survey model={survey} />
      </DialogContent>
    </Dialog>
  )
}
