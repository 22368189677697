import { Modal, Box } from '@mui/material'
import { ReadTaskSummaryData } from '@common/config/api/client'
import { useGetSummaryReadTasks } from '../hooks/useGetSummaryReadTasks'
import { READ_TASK_SUMMARY_MOCK_DATA } from '../constants'
import ReaderSummaryLayout from '../components/summary/ReaderSummaryLayout'
import ReaderAdjudicationLayout from '../components/adjudication/ReaderAdjudicationLayout'

interface ReaderSummaryModalProps {
  open: boolean
  onClose: () => void
  taskId: string
}

export default function ReaderSummaryModal({ open, onClose, taskId }: ReaderSummaryModalProps) {
  const { data, error, isLoading } = useGetSummaryReadTasks(['SummaryReadTasks', taskId], taskId)
  const { timepoints, read_task_summary_metadata }: ReadTaskSummaryData = data?.data ?? READ_TASK_SUMMARY_MOCK_DATA

  if (isLoading) {
    return <div>Loading...</div>
  }
  if (error) {
    return <div>Error: {error.message}</div>
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
      >
        {read_task_summary_metadata.read_task_type === 'ADJUDICATION' ? (
          <ReaderAdjudicationLayout
            timepoints={timepoints}
            readTaskId={taskId}
            read_task_summary_metadata={read_task_summary_metadata}
            onClose={onClose}
          />
        ) : (
          <ReaderSummaryLayout
            timepoints={timepoints}
            readTaskId={taskId}
            read_task_summary_metadata={read_task_summary_metadata}
            onClose={onClose}
          />
        )}
      </Box>
    </Modal>
  )
}
