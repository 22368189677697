import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Typography,
  Button,
} from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { InputField } from '@features/qc_task_details_view/pages/qc/InputField'
import { ReaderTaskSubmissionFormValues } from '../../schemas/ReaderTaskSubmissionValidationSchema'
import ReaderLabel from './ReaderLabel'
import { ReadTaskSummaryData } from '@common/config/api/client'
import { useGetAdjudicationJustifications } from '../../hooks/useGetAdjudicationJustifications'

interface DecisionStepProps {
  timepoint: ReadTaskSummaryData['timepoints'][0]
  toggleDisagree: () => void
}

export default function DecisionStep({ timepoint, toggleDisagree }: DecisionStepProps) {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<ReaderTaskSubmissionFormValues>()
  const readTaskSubmission = watch('context.read_task_submission')

  const { data, error, isLoading } = useGetAdjudicationJustifications(['ReadTasksAdjudicationJustifications'])

  if (isLoading) {
    return <div>Loading...</div>
  }
  if (error) {
    return <div>Error: {error.message}</div>
  }

  return (
    <>
      <Stack alignItems="flex-start" alignSelf="stretch">
        <Typography variant="h6" color="text.primary">
          Which reader do you agree with most?
        </Typography>
      </Stack>
      <FormControl fullWidth>
        <Stack spacing={2} alignSelf="stretch" paddingX={1}>
          <FormControl fullWidth>
            <RadioGroup
              value={readTaskSubmission.adjudication_selected_read_task_id}
              onChange={(e) => {
                setValue('context.read_task_submission.adjudication_agreed', true)
                setValue('context.read_task_submission.adjudication_selected_read_task_id', e.target.value)
              }}
            >
              <Stack direction="row" alignItems="center" alignSelf="stretch" spacing={12}>
                <FormControlLabel
                  value={timepoint.reader_submissions[0]?.assigned_reader_id}
                  control={<Radio />}
                  label={
                    <ReaderLabel
                      readerNumber="I"
                      responseType={
                        timepoint.reader_submissions[0]?.ecrf_form_submission?.overall_response?.overall_response || ''
                      }
                    />
                  }
                />
                <FormControlLabel
                  value={timepoint.reader_submissions[1]?.assigned_reader_id}
                  control={<Radio />}
                  label={
                    <ReaderLabel
                      readerNumber="II"
                      responseType={
                        timepoint.reader_submissions[1]?.ecrf_form_submission?.overall_response?.overall_response || ''
                      }
                    />
                  }
                />
              </Stack>
            </RadioGroup>
            {errors?.context?.read_task_submission?.adjudication_selected_read_task_id && (
              <FormHelperText error>
                {errors.context.read_task_submission.adjudication_selected_read_task_id.message}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth>
            <Select
              displayEmpty
              value={readTaskSubmission.adjudication_justification_id}
              onChange={(e) => setValue('context.read_task_submission.adjudication_justification_id', e.target.value)}
            >
              <MenuItem value="" disabled>
                Select a reason
              </MenuItem>
              {data?.data?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.justification}
                </MenuItem>
              ))}
            </Select>
            {errors?.context?.read_task_submission?.adjudication_justification_id && (
              <FormHelperText error>
                {errors.context.read_task_submission.adjudication_justification_id.message}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth>
            <InputField
              control={control}
              name="context.read_task_submission.justification_comment"
              label="Justification"
              multiline
              rows={6}
              fullWidth
            />
          </FormControl>
          <Button variant="text" sx={{ color: 'primary.main', alignSelf: 'flex-start' }} onClick={toggleDisagree}>
            I do not agree with Reader I or Reader II
          </Button>
        </Stack>
      </FormControl>
    </>
  )
}
