import {
  HTTPValidationError,
  DeleteSeriesMetadataQcTasksQcTaskIdSeriesDeleteResponse,
  DeleteSeriesMetadataQcTasksQcTaskIdSeriesDeleteData,
  deleteSeriesMetadataQcTasksQcTaskIdSeriesDelete,
} from '@common/config/api/client'
import { queryClient } from '@common/config/api/queryClient'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export const useDeleteSeriesMetadataMutation = (): UseMutationResult<
  DeleteSeriesMetadataQcTasksQcTaskIdSeriesDeleteResponse,
  AxiosError<HTTPValidationError>,
  DeleteSeriesMetadataQcTasksQcTaskIdSeriesDeleteData
> => {
  return useMutation<
    DeleteSeriesMetadataQcTasksQcTaskIdSeriesDeleteResponse,
    AxiosError<HTTPValidationError>,
    DeleteSeriesMetadataQcTasksQcTaskIdSeriesDeleteData
  >({
    mutationFn: async (query) => {
      const response = await deleteSeriesMetadataQcTasksQcTaskIdSeriesDelete({
        path: query.path,
        query: query.query,
        throwOnError: true,
      })
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['QCTask'] })
    },
  })
}
