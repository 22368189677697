import { useQuery } from '@tanstack/react-query'
import {
  CompanyTypeFilter,
  listCompaniesCompaniesGet,
  PaginatedResponse_CompanySchema_,
} from '@common/config/api/client'
import queryKeyFactory from '@features/company_management/queryKeyFactory'
import { CompaniesParams } from '@features/company_management/types'

export interface TransformedCompanies {
  items: {
    id: string
    name: string
    type: string
    usersNbr: number
    createdByUser: string | null
  }[]
  pagination: {
    nextCursor: string | null
    previousCursor: string | null
    limit: number | null
  }
}

function transformResponse(data: PaginatedResponse_CompanySchema_ | undefined): TransformedCompanies {
  return {
    items:
      data?.items.map((item) => ({
        id: item.id,
        name: item.name,
        type: item.type,
        usersNbr: item.number_of_users,
        createdByUser: item.created_by,
      })) ?? [],
    pagination: {
      nextCursor: data?.pagination.next_cursor ?? null,
      previousCursor: data?.pagination.previous_cursor ?? null,
      limit: data?.pagination.limit ?? null,
    },
  }
}

export default function useListCompanies(params: CompaniesParams) {
  return useQuery({
    queryKey: queryKeyFactory.companies(params),
    queryFn: async () => {
      const response = await listCompaniesCompaniesGet({
        query: {
          company_type: params.company_type as CompanyTypeFilter,
          search: params.search,
          limit: params.limit,
          next_cursor: params.next_cursor,
          previous_cursor: params.previous_cursor,
          order_by_field: params.order_by_field,
          asc_order: params.asc_order,
        },
      })
      return response.data
    },
    select: transformResponse,
    enabled: !!params.company_type,
  })
}
