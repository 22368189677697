import { StudyDetails } from '@common/config/api/client'

const statusMap = {
  STUDY_SETUP: { label: 'Study set up', stepNumber: 1 },
  UAT: { label: 'UAT', stepNumber: 2 },
  LIVE: { label: 'Study is live', stepNumber: 3 },
  COMPLETED: { label: 'Completed', stepNumber: 4 },
  ARCHIVED: { label: 'Archive', stepNumber: 5 },
} as const

export interface TimelineStep {
  label: string
  date: string
  status: 'completed' | 'current' | 'upcoming'
  stepNumber: number
}

export default function useTimelineSteps(studyDetails: StudyDetails | undefined): TimelineStep[] {
  if (!studyDetails) {
    return []
  }
  const currentStatus = studyDetails.status
  const timeline = studyDetails.status_timeline || []

  return Object.entries(statusMap).map(([status, { label, stepNumber }]) => {
    const timelineEntry = timeline.find((entry) => entry.status === status)
    const date = timelineEntry
      ? new Date(timelineEntry.created_at).toLocaleDateString('de-DE', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })
      : ''

    let stepStatus: 'completed' | 'current' | 'upcoming' = 'upcoming'
    if (status === currentStatus) {
      stepStatus = 'current'
    } else if (timelineEntry) {
      stepStatus = 'completed'
    }

    return {
      label,
      date,
      status: stepStatus,
      stepNumber,
    }
  })
}
