import { Box, Typography, Collapse, Button, Stack } from '@mui/material'
import { useState } from 'react'
import { styled } from '@mui/material/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddIcon from '@mui/icons-material/AddRounded'
import { useNavigate, useParams } from 'react-router-dom'
import { useCreateTimepointSubmission } from '@features/subject/hooks/useCreateTimepointSubmission.ts'
import { generatePathWithParams } from '@common/utils/urlUtils.ts'
import { ROUTES } from 'src/routes'
import { SPACING } from '@common/theme/spacing.ts'
import TimepointSwitchDialog from '../TimepointSwitchDialog/TimepointSwitchDialog'
import { createUppy } from '@common/utils/createUppy'
import { MINIMUM_ALLOWED_TIMEPOINTS } from '@common/constants/study'

export type TimePointsGroupProps = {
  name: string
  id: string
  children: Array<{
    name: string
    id: string
  }>
  selectedTimePointSubmission?: string
  maximumAllowedTimepoints: number
}
const ExpandMore = styled('div')(({ theme }) => ({
  display: 'flex',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

const getTimepointById = (
  children: Array<{ name: string; id: string }>,
  selectedTimePointSubmission?: string,
): { name: string; id: string } | undefined => {
  const timepoint = children.find((child) => child.id === selectedTimePointSubmission)
  return timepoint ? timepoint : undefined
}

export default function TimePointsGroup({
  name,
  children,
  selectedTimePointSubmission,
  id,
  maximumAllowedTimepoints,
}: TimePointsGroupProps) {
  const navigate = useNavigate()
  const [uppy] = useState(createUppy)
  const timepoint = getTimepointById(children, selectedTimePointSubmission)

  const [isExpanded, setExpanded] = useState(children.some((x) => x.id === selectedTimePointSubmission))
  const { studyId, subjectId } = useParams<{
    studyId: string
    subjectId: string
  }>()
  const { mutateAsync: createTimepointSubmission } = useCreateTimepointSubmission()

  const [selectedItemID, setSelectedItemID] = useState<string | undefined>(timepoint?.id)
  const [showTimepointSwitchDialog, setShowTimepointSwitchDialog] = useState(false)

  const handleAddTimePointSubmission = async () => {
    const result = await createTimepointSubmission({
      body: {
        timepoint_id: id,
      },
      path: {
        study_id: studyId!,
        subject_id: subjectId!,
      },
    })

    const path = generatePathWithParams(ROUTES.SUBJECT_DETAILS.path, {
      studyId: studyId!,
      subjectId: subjectId!,
      timepointSubmissionId: result.timepoint_submission_id,
    })
    navigate(path)
  }

  const handleTimepointClick = (id: string) => {
    if (id === selectedItemID) return

    if (uppy.getFiles().length > 0) {
      setSelectedItemID(id)
      setShowTimepointSwitchDialog(true)
    } else {
      setSelectedItemID(id)
      handleTimePointSubmissionClick(id)
    }
  }

  const onClose = () => {
    setSelectedItemID(id)
    setShowTimepointSwitchDialog(false)
  }
  const onSwitch = () => {
    uppy.cancelAll()
    handleTimePointSubmissionClick(selectedItemID)
    setShowTimepointSwitchDialog(false)
  }

  const handleTimePointSubmissionClick = (id?: string) => {
    const path = generatePathWithParams(ROUTES.SUBJECT_DETAILS.path, {
      studyId: studyId!,
      subjectId: subjectId!,
      timepointSubmissionId: id,
    })
    navigate(path)
  }

  return (
    <Box py={SPACING.spacingMd}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        onClick={() => setExpanded(!isExpanded)}
        sx={{ cursor: 'pointer' }}
      >
        <Box px={SPACING.minSpacing} display="flex" alignItems="center">
          <ExpandMore
            sx={{
              transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          >
            <ExpandMoreIcon />
          </ExpandMore>
          <Typography variant="body1" ml={SPACING.typographySpacing} fontWeight={500}>
            {name}
          </Typography>
        </Box>
        <Typography variant="body1" color="text.secondary">
          {children.length} timepoint{children.length === 0 || children.length > 1 ? 's' : ''}
        </Typography>
      </Box>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <Stack gap={SPACING.minSpacing} mt={SPACING.minSpacing}>
          {children.map((item, index) => (
            <Box
              key={index}
              borderRadius={SPACING.borderRadiusXl}
              bgcolor={selectedTimePointSubmission === item.id ? 'secondary.main' : ''}
              px={SPACING.minSpacing}
              py={SPACING.timePointSpacing}
              sx={{ cursor: 'pointer' }}
              onClick={() => handleTimepointClick(item.id)}
            >
              <Typography variant="body1" ml={4.5} fontWeight={500}>
                {item.name}
              </Typography>
            </Box>
          ))}
        </Stack>

        {maximumAllowedTimepoints > MINIMUM_ALLOWED_TIMEPOINTS ? (
          <Button
            variant="text"
            size="large"
            color="secondary"
            startIcon={<AddIcon />}
            sx={{ mt: SPACING.minSpacing }}
            fullWidth
            onClick={() => handleAddTimePointSubmission()}
          >
            Add timepoint
          </Button>
        ) : null}
      </Collapse>

      {showTimepointSwitchDialog && (
        <TimepointSwitchDialog timepointName={timepoint?.name} onClose={onClose} onSubmit={onSwitch} />
      )}
    </Box>
  )
}
