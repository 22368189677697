/**
 * Table component for displaying and managing study subjects with sorting, filtering,
 * and action menu functionality
 */
import { MouseEvent, useMemo } from 'react'
import { TableBody, TableCell, TableRow, Paper, Box } from '@mui/material'
import EnhancedTable from '@common/components/Table/EnhancedTable'
import { Order, HeadCell } from '@common/components/Table/table.types'

import SubjectsTableToolbar from './SubjectsTableToolbar'
import { TransformedSubject, TransformedSubjects } from '../types'
import useSubjectsTableActionsHandlers from '../hooks/useSubjectsTableActionsHandlers'
import { SubjectData } from '../types'
import EnhancedNavTabs from '@common/components/Tab/EnhancedNavTabs'
import { ROUTES } from 'src/routes'
import { NavTabsVariant } from '@common/components/Tab/tabs.types'
import { generatePathWithParams } from '@common/utils/urlUtils'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetStudyById } from '@study_setup/hooks/useStudyQueries'
import { capitalize } from '@common/utils/stringUtils'
import { useCombinedPermissions } from '@auth/hooks/useCombinedPermissions'
import { SubjectPermission } from '@auth/permissionsEnum'
import { SPACING } from '@common/theme/spacing'
export interface SubjectTableProps {
  page: number
  subjects: TransformedSubjects | undefined
  searchValue?: string
  order: Order
  orderBy: string
  handlePageChange: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onSearch: (value: string) => void
  onRequestSort: (event: MouseEvent<unknown>, property: keyof SubjectData) => void
  onAddClick: () => void
  onEditClick: (subjectId: string) => void
}

const getHeadCells = (
  showSecondaryAssignment: boolean,
  hasFullListPermission: boolean,
): readonly HeadCell<SubjectData>[] => {
  const baseCells: HeadCell<SubjectData>[] = [
    { id: 'pseudo_id', label: 'Subject ID', disablePadding: false },
    { id: 'blind_code', label: 'Blind code', disablePadding: false, disableSort: true },
  ]

  if (hasFullListPermission) {
    baseCells.push({
      id: 'primary_site_assignment_name',
      label: 'Primary Site Assignment',
      disablePadding: false,
      disableSort: true,
    })

    if (showSecondaryAssignment) {
      baseCells.push({
        id: 'secondary_site_assignment_name',
        label: 'Secondary Site Assignment',
        disablePadding: false,
      })
    }
  }

  return [
    ...baseCells,
    { id: 'status', label: 'Status', disablePadding: false },
    { id: 'eligibility_status', label: 'Eligibility Status', disablePadding: false },
    { id: 'created_by_name', label: 'Created By', disablePadding: false },
    { id: 'actions', label: '', disablePadding: false },
  ]
}
export default function SubjectsTable({
  subjects,
  searchValue,
  page,
  order,
  orderBy,
  onSearch,
  onRowsPerPageChange,
  handlePageChange,
  onRequestSort,
  onAddClick,
  onEditClick,
}: Readonly<SubjectTableProps>) {
  const { handleClick } = useSubjectsTableActionsHandlers({
    onEditClick,
    subjects,
  })
  const navigate = useNavigate()
  const { studyId } = useParams<{ studyId: string }>()
  const { hasPermission } = useCombinedPermissions()
  const hasFullListPermission = hasPermission(SubjectPermission.LIST)

  const { data: studyData, isLoading: isStudyDataLoading } = useGetStudyById(studyId!)

  const subjectsRoutes = [
    {
      label: 'Subject timepoint',
      path: generatePathWithParams(ROUTES.SUBJECTS_TIMEPOINTS.path, { studyId: studyId! }),
      id: 'subjects-timepoints',
    },
  ]

  const showSecondaryAssignment = useMemo(
    () => !isStudyDataLoading && studyData?.subjects_required_to_visit_multiple_sites,
    [isStudyDataLoading, studyData],
  )

  const headCells = useMemo(
    () => getHeadCells(showSecondaryAssignment!, hasFullListPermission),
    [showSecondaryAssignment, hasFullListPermission],
  )

  const navigateToSubjectDetails = (subject_id: string) => {
    const path = generatePathWithParams(ROUTES.SUBJECT_DETAILS.path, {
      studyId: studyId!,
      subjectId: subject_id,
    })
    navigate(path)
  }

  return (
    <Box component="section">
      <SubjectsTableToolbar onSearch={onSearch} searchValue={searchValue} onAddClick={onAddClick} />
      <Paper sx={{ p: SPACING.paperInnerPadding }}>
        <EnhancedNavTabs routes={subjectsRoutes} variant={NavTabsVariant.SEGMENTED_CONTROLS} />
        <EnhancedTable<SubjectData>
          rows={subjects?.items ?? []}
          headCells={headCells}
          order={order}
          orderBy={orderBy as keyof SubjectData}
          page={page}
          next_cursor={subjects?.metadata.next_cursor}
          previous_cursor={subjects?.metadata.previous_cursor}
          rowsPerPage={subjects?.metadata.limit ?? 50}
          rowsPerPageOptions={[10, 25, 50]}
          onRequestSort={onRequestSort}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={onRowsPerPageChange}
        >
          <TableBody>
            {subjects?.items.map((row: TransformedSubject) => {
              return (
                <TableRow hover onClick={(event) => handleClick(event, row.id)} tabIndex={-1} key={row.id}>
                  <TableCell onClick={() => navigateToSubjectDetails(row.id)}>{row.pseudo_id}</TableCell>
                  <TableCell>{row.blind_code}</TableCell>
                  {hasFullListPermission && (
                    <>
                      <TableCell>{row.primary_site_assignment_name}</TableCell>
                      {studyData?.subjects_required_to_visit_multiple_sites && (
                        <TableCell>{row.secondary_site_assignment_name}</TableCell>
                      )}
                    </>
                  )}
                  <TableCell>{capitalize(row.status)}</TableCell>
                  <TableCell>{capitalize(row.eligibility_status)}</TableCell>
                  <TableCell>{row.created_by_name ?? '-'}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </EnhancedTable>
      </Paper>
    </Box>
  )
}
