import {
  HTTPValidationError,
  QcTaskEsignQcTasksQcTaskIdEsignPostResponse,
  QcTaskEsignQcTasksQcTaskIdEsignPostData,
  qcTaskEsignQcTasksQcTaskIdEsignPost,
} from '@common/config/api/client'
import { queryClient } from '@common/config/api/queryClient'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export const useSignPayload = (): UseMutationResult<
  QcTaskEsignQcTasksQcTaskIdEsignPostResponse,
  AxiosError<HTTPValidationError>,
  QcTaskEsignQcTasksQcTaskIdEsignPostData
> => {
  return useMutation<
    QcTaskEsignQcTasksQcTaskIdEsignPostResponse,
    AxiosError<HTTPValidationError>,
    QcTaskEsignQcTasksQcTaskIdEsignPostData
  >({
    mutationFn: async (query) => {
      const response = await qcTaskEsignQcTasksQcTaskIdEsignPost({
        body: query.body,
        path: query.path,
        query: query.query,
        throwOnError: true,
      })
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['QCTask'] })
    },
  })
}
