import { Button, TextField, InputAdornment, styled, Box } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import { useEffect, useState } from 'react'
import { SPACING } from '@common/theme/spacing'
import { SIZING } from '@common/theme/sizing'

interface SitesTableToolbarProps {
  onSearch: (value: string) => void
  searchValue?: string
  onAddClick: () => void
}

const ToolbarContainer = styled(Box)({
  padding: 16,
  display: 'flex',
  gap: 24,
  alignItems: 'center',
})

export default function SitesTableToolbar({ onSearch, searchValue, onAddClick }: Readonly<SitesTableToolbarProps>) {
  const [localSearchValue, setLocalSearchValue] = useState(searchValue ?? '')
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalSearchValue(event.target.value)
  }
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSearch(localSearchValue)
    }
  }

  useEffect(() => {
    setLocalSearchValue(searchValue ?? '')
  }, [searchValue])

  const handleAddClick = () => {
    onAddClick()
  }

  return (
    <ToolbarContainer
      sx={{ paddingX: 0, display: 'flex', justifyContent: 'flex-end', gap: SPACING.tableToolbarItemsSpacing }}
    >
      <Button variant="contained" color="secondary" startIcon={<AddIcon />} size="small" onClick={handleAddClick}>
        Add Site
      </Button>
      <TextField
        placeholder="Search"
        variant="outlined"
        size="small"
        sx={{ minWidth: SIZING.textFieldWidth }}
        value={localSearchValue}
        onChange={handleSearchChange}
        onKeyDown={handleKeyDown}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          },
        }}
      />
    </ToolbarContainer>
  )
}
