import { Stack, Typography } from '@mui/material'

interface ReaderLabelProps {
  readerNumber: string
  responseType: string
}

export default function ReaderLabel({ readerNumber, responseType }: ReaderLabelProps) {
  return (
    <Stack>
      <Typography variant="body1" color="text.primary">
        Reader {readerNumber}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {responseType}
      </Typography>
    </Stack>
  )
}
