import { listUserStudyAssignmentsUsersUserIdStudyAssignmentsGet } from '@common/config/api/client'
import { useQuery } from '@tanstack/react-query'
import { StudyAssignmentsListParams } from '../types'

export const useListStudyAssignments = (params: StudyAssignmentsListParams, userId: string) => {
  return useQuery({
    queryKey: ['studyAssignments', userId],
    queryFn: async () => {
      const response = await listUserStudyAssignmentsUsersUserIdStudyAssignmentsGet({
        path: { user_id: userId },
        query: {
          limit: params.limit,
          next_cursor: params.next_cursor,
          previous_cursor: params.previous_cursor,
          order_by_field: params.order_by_field,
          asc_order: params.asc_order,
        },
      })
      return response.data
    },
    enabled: !!userId,
  })
}
