import { Box, Button, Typography } from '@mui/material'
import Uppy from '@uppy/core'
import { useEffect, useState } from 'react'
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress'
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined'
import RestartAltRounded from '@mui/icons-material/RestartAltRounded'
import { useUpdateContainerSubmission } from '@features/subject/hooks/useUpdateContainerSubmission.ts'
import { TimepointContainerSubmissionPartialUpdateSchema } from '@common/config/api/client'
import { useParams } from 'react-router-dom'
import { SIZING } from '@common/theme/sizing'
import { SPACING } from '@common/theme/spacing'

type UploadStepProps = {
  onUploadCompleted: () => void
  uppy: Uppy
}

type UpdatePayload = {
  container_submission_id: string
  comments?: string
  exam_metadata: Array<{
    study_uid: string
    study_description: string
    acquisition_date: string
    file_name: string
    file_url: null
    file_extension: string
    file_size: string
    series: [
      {
        series_uid: string
        description: string
        series_number: number
        acquisition_date: string
        total_instances: number
        file_name: string
      },
    ]
  }>
}

export default function UploadStep({ uppy, onUploadCompleted }: UploadStepProps) {
  const [uploadInfo, setUploadInfo] = useState({
    state: 'PROGRESS',
    progress: 0,
  })
  const { mutateAsync: updateContainerSubmission } = useUpdateContainerSubmission()
  const { studyId, subjectId, timepointSubmissionId } = useParams<{
    studyId: string
    subjectId: string
    timepointSubmissionId: string
  }>()

  // If this hook is triggered twice for you during development - remove StrictMode from main.tsx
  useEffect(() => {
    const state = uppy.getState()

    if (state.currentUploads && Object.keys(state.currentUploads).length) {
      uppy.restore(Object.keys(state.currentUploads)[0])
    } else {
      uppy.upload()
    }

    uppy.on('complete', () => {
      const state = uppy.getState()

      if (state.totalProgress === 100) {
        const files = uppy.getFiles()
        const result: UpdatePayload[] = []

        files.forEach((file) => {
          const existingContainer = result.find((x) => x.container_submission_id === file.meta.container_submission_id)

          if (existingContainer && !existingContainer.comments) {
            // container_submission_id is already in result
            if (file.meta.study_uid) {
              // dicom files processing
              const existingStudy = existingContainer.exam_metadata.find((x) => x.study_uid === file.meta.study_uid)

              if (existingStudy) {
                const existingSeries = existingStudy.series.find((x) => x.series_uid === file.meta.seriesInstanceUID)

                if (existingSeries) {
                  existingSeries.total_instances += file.meta.totalInstances as number
                } else {
                  existingStudy.series.push({
                    series_uid: file.meta.study_uid ? (file.meta.seriesInstanceUID as string) : '',
                    description: file.meta.study_uid
                      ? (file.meta.seriesDescription as string)
                      : (file.meta.studyDescription as string),
                    acquisition_date: file.meta.acquisitionDate as string,
                    series_number: file.meta.study_uid
                      ? (file.meta.seriesId as number)
                      : existingContainer.exam_metadata[0].series.length + 1,
                    total_instances: file.meta.study_uid ? (file.meta.totalInstances as number) : 1,
                    file_name: file.meta.file_name as string,
                  })
                }
              } else {
                existingContainer.exam_metadata.push({
                  study_uid: (file.meta.study_uid as string) || '',
                  study_description: (file.meta.studyDescription as string) || '',
                  acquisition_date: file.meta.acquisitionDate as string,
                  file_name: file.meta.file_name as string,
                  file_url: null,
                  file_extension: file.extension,
                  file_size: file.size?.toString() as string,
                  series: [
                    {
                      series_uid: file.meta.study_uid ? (file.meta.seriesInstanceUID as string) : '',
                      description: file.meta.study_uid
                        ? (file.meta.seriesDescription as string)
                        : (file.meta.studyDescription as string),
                      acquisition_date: file.meta.acquisitionDate as string,
                      series_number: file.meta.study_uid
                        ? (file.meta.seriesId as number)
                        : existingContainer.exam_metadata[0].series.length + 1,
                      total_instances: file.meta.study_uid ? (file.meta.totalInstances as number) : 1,
                      file_name: file.meta.file_name as string,
                    },
                  ],
                })
              }
            } else {
              // non-dicom files processing

              if (existingContainer.exam_metadata.length) {
                existingContainer.exam_metadata[0].series.push({
                  series_uid: file.meta.study_uid ? (file.meta.seriesInstanceUID as string) : '',
                  description: file.meta.study_uid
                    ? (file.meta.seriesDescription as string)
                    : (file.meta.studyDescription as string),
                  acquisition_date: file.meta.acquisitionDate as string,
                  series_number: file.meta.study_uid
                    ? (file.meta.seriesId as number)
                    : existingContainer.exam_metadata[0].series.length + 1,
                  total_instances: file.meta.study_uid ? (file.meta.totalInstances as number) : 1,
                  file_name: file.meta.file_name as string,
                })
              } else {
                existingContainer.exam_metadata.push({
                  study_uid: (file.meta.study_uid as string) || '',
                  study_description: (file.meta.studyDescription as string) || '',
                  acquisition_date: file.meta.acquisitionDate as string,
                  file_name: file.meta.file_name as string,
                  file_url: null,
                  file_extension: file.extension,
                  file_size: file.size?.toString() as string,
                  series: [
                    {
                      series_uid: file.meta.study_uid ? (file.meta.seriesInstanceUID as string) : '',
                      description: file.meta.study_uid
                        ? (file.meta.seriesDescription as string)
                        : (file.meta.studyDescription as string),
                      acquisition_date: file.meta.acquisitionDate as string,
                      series_number: file.meta.study_uid
                        ? (file.meta.seriesId as number)
                        : existingContainer.exam_metadata[0].series.length + 1,
                      total_instances: file.meta.study_uid ? (file.meta.totalInstances as number) : 1,
                      file_name: file.meta.file_name as string,
                    },
                  ],
                })
              }
            }
          } else {
            // container_submission_id is not in result
            if (!result.some((x) => x.container_submission_id === file.meta.container_submission_id)) {
              const item: UpdatePayload = {
                container_submission_id: file.meta.container_submission_id as string,
                comments: file.meta.comment ? (file.meta.comment as string) : undefined,
                exam_metadata: file.meta.comment
                  ? []
                  : [
                      {
                        study_uid: (file.meta.study_uid as string) || '',
                        study_description: (file.meta.studyDescription as string) || '',
                        acquisition_date: file.meta.acquisitionDate as string,
                        file_name: file.meta.file_name as string,
                        file_url: null,
                        file_extension: file.extension,
                        file_size: file.size?.toString() as string,
                        series: [
                          {
                            series_uid: file.meta.study_uid ? (file.meta.seriesInstanceUID as string) : '',
                            description: file.meta.study_uid
                              ? (file.meta.seriesDescription as string)
                              : (file.meta.studyDescription as string),
                            acquisition_date: file.meta.acquisitionDate as string,
                            series_number: file.meta.study_uid ? (file.meta.seriesId as number) : 1,
                            total_instances: file.meta.study_uid ? (file.meta.totalInstances as number) : 1,
                            file_name: file.meta.file_name as string,
                          },
                        ],
                      },
                    ],
              }

              result.push(item)
            }
          }
        })

        updateContainerSubmission({
          body: result.map((x) => ({
            ...x,
            comments: x.comments,
            exam_metadata: x.exam_metadata.length ? x.exam_metadata : undefined,
          })) as unknown as TimepointContainerSubmissionPartialUpdateSchema[],
          path: {
            study_id: studyId!,
            subject_id: subjectId!,
            timepoint_submission_id: timepointSubmissionId!,
          },
        }).then(() => {
          setUploadInfo({ state: uppy.getState().error ? 'ERROR' : 'COMPLETE', progress: 100 })
          onUploadCompleted()
        })
      }
    })
    uppy.on('progress', (progress) => {
      setUploadInfo({ state: 'PROGRESS', progress })
    })
    uppy.on('error', () => {
      setUploadInfo({ state: 'ERROR', progress: 0 })
    })
  }, [])

  const handleRetryClick = () => {
    setUploadInfo({ state: 'PROGRESS', progress: 0 })
    uppy.retryAll()
  }

  const renderProgressBlock = () => {
    return (
      <>
        <Box position="relative" display="flex">
          <CircularProgress
            variant="determinate"
            value={100}
            size={SIZING.uploadStepSize}
            thickness={2}
            sx={() => ({
              color: '#C9E8F2',
            })}
          />
          <CircularProgress
            variant="determinate"
            value={uploadInfo.progress}
            size={SIZING.uploadStepSize}
            thickness={2}
            sx={(theme) => ({
              color: theme.palette.primary.main,
              position: 'absolute',
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: 'round',
              },
            })}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h3" component="div" sx={{ color: 'primary.dark' }}>
              {`${Math.round(uploadInfo.progress)}%`}
            </Typography>
          </Box>
        </Box>

        <Typography mt={SPACING.spacingXl} sx={{ color: 'primary.dark' }}>
          Please wait, we're uploading your files
        </Typography>
      </>
    )
  }

  const renderCompleteBlock = () => {
    return (
      <>
        <Box position="relative" display="flex">
          <CircularProgress
            variant="determinate"
            value={100}
            size={SIZING.uploadStepSize}
            thickness={2}
            sx={() => ({
              color: '#C9E8F2',
            })}
          />
          <CircularProgress
            variant="determinate"
            value={100}
            size={SIZING.uploadStepSize}
            thickness={2}
            sx={(theme) => ({
              color: theme.palette.primary.main,
              position: 'absolute',
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: 'round',
              },
            })}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CloudDoneOutlinedIcon sx={{ color: 'primary.dark', fontSize: 64 }} />
          </Box>
        </Box>

        <Typography mt={4} sx={{ color: 'primary.dark' }}>
          Files uploaded!
        </Typography>
      </>
    )
  }

  const renderErrorBlock = () => {
    return (
      <>
        <Box position="relative" display="flex">
          <CircularProgress
            variant="determinate"
            value={100}
            size={SIZING.uploadStepSize}
            thickness={2}
            sx={(theme) => ({
              color: theme.palette.error.main,
            })}
          />
          <CircularProgress
            variant="determinate"
            value={100}
            size={SIZING.uploadStepSize}
            thickness={2}
            sx={(theme) => ({
              color: theme.palette.error.main,
              position: 'absolute',
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: 'round',
              },
            })}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CloudDoneOutlinedIcon sx={{ color: 'error.main', fontSize: 64 }} />
          </Box>
        </Box>

        <Typography mt={SPACING.spacingXl} sx={{ color: 'error.main' }}>
          Uploading failed
        </Typography>
        <Box display="flex" gap={SPACING.spacingLg} mt={SPACING.uploadStepSpacing}>
          <Button variant="text" size="large" color="secondary">
            Contact support
          </Button>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            startIcon={<RestartAltRounded />}
            onClick={handleRetryClick}
          >
            Retry
          </Button>
        </Box>
      </>
    )
  }

  return (
    <Box
      position="relative"
      display="flex"
      width={SIZING.minWidth}
      height={SIZING.minWidth}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      {uploadInfo.state === 'PROGRESS' && renderProgressBlock()}
      {uploadInfo.state === 'COMPLETE' && renderCompleteBlock()}
      {uploadInfo.state === 'ERROR' && renderErrorBlock()}
    </Box>
  )
}
