import { useCombinedPermissions } from '@auth/hooks/useCombinedPermissions'
import { StudyPermission } from '@auth/permissionsEnum'
import { Status, StudyDetails } from '@common/config/api/client'
import { SIZING } from '@common/theme/sizing'
import { SPACING } from '@common/theme/spacing'
import { InfoOutlined, ArticleOutlined } from '@mui/icons-material'
import {
  Grid2 as Grid,
  Typography,
  Stack,
  Box,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material'
import { useState } from 'react'

interface Props {
  details: StudyDetails
  onPromoteStudy: () => void
}

const getNextStage = (currentStatus: string) => {
  const statusFlow: Record<string, string> = {
    DRAFT: 'UAT',
    UAT: 'LIVE',
    LIVE: 'COMPLETED',
    COMPLETED: 'ARCHIVED',
  }
  return statusFlow[currentStatus]
}

export default function StudyGeneral({ details, onPromoteStudy }: Props) {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
  const { hasAnyPermission } = useCombinedPermissions()
  const hasPromoteStudyPermission = hasAnyPermission([StudyPermission.PROMOTE_STATUS])
  const nextStage = getNextStage(details.status as Status)

  const handlePromote = () => {
    setIsConfirmationOpen(false)
    onPromoteStudy()
  }

  return (
    <>
      <Stack spacing={SPACING.spacingXl}>
        {/* General Information Section */}
        <Box>
          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: SPACING.spacingXl }}>
            <Box component="span" sx={{ display: 'flex' }}>
              <InfoOutlined sx={{ color: 'warning.main' }} />
            </Box>
            General Information
          </Typography>

          <Grid container spacing={SPACING.spacingXl}>
            <Grid size={SIZING.gridSizeMd}>
              <Typography variant="body2" color="text.secondary">
                Compound
              </Typography>
              <Typography>{details.compound ?? 'N/A'}</Typography>
            </Grid>
            <Grid size={SIZING.gridSizeMd}>
              <Typography variant="body2" color="text.secondary">
                Phase
              </Typography>
              <Typography>{details.phases?.[0] ?? 'N/A'}</Typography>
            </Grid>
            <Grid size={SIZING.gridSizeMd}>
              <Typography variant="body2" color="text.secondary">
                No. of subjects
              </Typography>
              <Typography>{details.total_subjects ?? 'N/A'}</Typography>
            </Grid>
            <Grid size={SIZING.gridSizeMd}>
              <Typography variant="body2" color="text.secondary">
                No of sites
              </Typography>
              <Typography>{details.total_sites ?? 'N/A'}</Typography>
            </Grid>
          </Grid>
        </Box>

        <Divider />

        {/* Read and imaging information Section */}
        <Box>
          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: SPACING.spacingXl }}>
            <Box component="span" sx={{ display: 'flex' }}>
              <ArticleOutlined sx={{ color: 'success.main' }} />
            </Box>
            Read and imaging information
          </Typography>

          <Grid container spacing={SPACING.spacingXl}>
            <Grid size={SIZING.gridSizeMd}>
              <Typography variant="body2" color="text.secondary">
                Response criteria
              </Typography>
              <Typography>{details.read_criterias?.map((criteria) => criteria.name).join(', ') ?? 'N/A'}</Typography>
            </Grid>
            <Grid size={SIZING.gridSizeMd}>
              <Typography variant="body2" color="text.secondary">
                Anatomies
              </Typography>
              <Typography>{details.anatomies?.map((anatomy) => anatomy.name).join(', ') ?? 'N/A'}</Typography>
            </Grid>
            <Grid size={SIZING.gridSizeMd}>
              <Typography variant="body2" color="text.secondary">
                Read type
              </Typography>
              <Typography>{details.read_types?.map((type) => type.name).join(', ') ?? 'N/A'}</Typography>
            </Grid>
            <Grid size={SIZING.gridSizeMd}>
              <Typography variant="body2" color="text.secondary">
                Therapeutic area
              </Typography>
              <Typography>{details.therapeutic_area ?? 'N/A'}</Typography>
            </Grid>
            <Grid size={SIZING.gridSizeMd}>
              <Typography variant="body2" color="text.secondary">
                Modalities
              </Typography>
              <Typography>
                {details.imaging_modalities?.map((modality) => modality.name).join(', ') ?? 'N/A'}
              </Typography>
            </Grid>
            <Grid size={SIZING.gridSizeMd}>
              <Typography variant="body2" color="text.secondary">
                Read design
              </Typography>
              <Typography>{details.read_desings?.[0]?.read_design_type ?? 'N/A'}</Typography>
            </Grid>
          </Grid>
        </Box>
        {hasPromoteStudyPermission && nextStage && (
          <Box pt={SPACING.spacingXxl} pb={SPACING.spacingXxl} textAlign="right">
            <Button variant="contained" onClick={() => setIsConfirmationOpen(true)}>
              Promote to next stage
            </Button>
            <Dialog open={isConfirmationOpen} onClose={() => setIsConfirmationOpen(false)}>
              <DialogTitle>
                Update study status
                <Typography variant="caption" component="p" sx={{ mb: SPACING.spacingLg }}>
                  Please confirm that you want to change the status of this study
                </Typography>
              </DialogTitle>
              <DialogContent>
                <Typography>
                  The next stage of this study is {nextStage[0].toUpperCase() + nextStage.slice(1).toLowerCase()}. Would
                  you like to proceed?
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setIsConfirmationOpen(false)}>Cancel</Button>
                <Button onClick={handlePromote} variant="contained">
                  Update Status
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        )}
      </Stack>
    </>
  )
}
