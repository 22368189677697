import { useMutation, UseMutationResult } from '@tanstack/react-query'
import {
  updateQcTaskFormSubmissionQcTasksQcTaskIdFormSubmissionStudyFormIdPatch,
  UpdateQcTaskSubmissionFormSchema,
  HTTPValidationError,
} from '@common/config/api/client'
import { AxiosError } from 'axios'

export type UpdateQcTaskFormSubmissionParams = {
  qc_task_id: string
  study_form_id: string
  study_id: string
  submission_data: UpdateQcTaskSubmissionFormSchema
}

export const useUpdateQcTaskFormSubmission = (): UseMutationResult<
  string | null,
  AxiosError<HTTPValidationError>,
  UpdateQcTaskFormSubmissionParams
> => {
  return useMutation<string | null, AxiosError<HTTPValidationError>, UpdateQcTaskFormSubmissionParams>({
    mutationFn: async ({ qc_task_id, study_form_id, study_id, submission_data }) => {
      const response = await updateQcTaskFormSubmissionQcTasksQcTaskIdFormSubmissionStudyFormIdPatch({
        path: {
          qc_task_id,
          study_form_id,
        },
        query: {
          study_id,
        },
        body: submission_data,
        throwOnError: true,
      })
      return response.data
    },
  })
}
