import { Box, CircularProgress } from '@mui/material'
import { StudyUserRoleAssociationSchema, UserSchema } from '@common/config/api/client'
import StudyAssignmentsTable from './StudyAssignmentsTable'
import useStudyAssignmentsHandlers from '@features/user_management/hooks/useStudyAssignmentsHandlers'
import ConfirmationDialog from '@common/components/ConfirmationDialog/ConfirmationDialog'
import CreateStudyAssignmentsDialog from './CreateStudyAssignmentsDialog'

interface Props {
  user: UserSchema | undefined
}

export default function StudyAssignments({ user }: Props) {
  const {
    isLoading,
    studyAssignmentsList,
    page,
    studyAssignmentsParams,
    roleAnchorEl,
    activeStudyUserRoleId,
    activeStudyAssignment,
    handleRoleClick,
    handleRoleMenuClose,
    handleRoleSelect,
    isUpdatingRole,
    handleDeleteStudyAssignmentClick,
    isDeletingAssignment,
    studyUserRoleIdToDelete,
    handleAddStudyAssignment,
    isAddDialogOpen,
    handleCloseAddDialog,
    availableStudies,
    handlePageChange,
    handleRowsPerPageChange,
    handleRequestSort,
    deleteDialogOpen,
    handleCancelDelete,
    handleConfirmDelete,
  } = useStudyAssignmentsHandlers()

  if (isLoading && !studyAssignmentsList) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <>
      <StudyAssignmentsTable
        studyAssignmentsList={studyAssignmentsList}
        page={page}
        onPageChange={(event, newPage) => handlePageChange(studyAssignmentsList, event, newPage)}
        onRowsPerPageChange={handleRowsPerPageChange}
        onAddStudyAssignment={handleAddStudyAssignment}
        orderBy={studyAssignmentsParams.order_by_field as keyof StudyUserRoleAssociationSchema}
        order={studyAssignmentsParams.asc_order ? 'asc' : 'desc'}
        onRequestSort={handleRequestSort}
        roleAnchorEl={roleAnchorEl}
        activeStudyUserRoleId={activeStudyUserRoleId}
        handleRoleClick={handleRoleClick}
        handleRoleMenuClose={handleRoleMenuClose}
        handleRoleSelect={handleRoleSelect}
        isUpdatingRole={isUpdatingRole}
        handleDeleteStudyAssignmentClick={handleDeleteStudyAssignmentClick}
        isDeletingAssignment={isDeletingAssignment}
        studyUserRoleIdToDelete={studyUserRoleIdToDelete}
      />

      <ConfirmationDialog
        open={deleteDialogOpen}
        title="Unassign user?"
        description={`${user?.user_name} will lose access to the ${activeStudyAssignment?.study_name || 'study'} after unassignment.`}
        confirmLabel="Unassign user"
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
      />

      {user && (
        <CreateStudyAssignmentsDialog
          open={isAddDialogOpen}
          onClose={handleCloseAddDialog}
          userId={user.user_id}
          studies={availableStudies}
        />
      )}
    </>
  )
}
