import { Chip, SxProps, Theme } from '@mui/material'
import { StudyTimepointSubmission, TimepointSubmissionStatus } from '@common/config/api/client'

function getChipStyle(status: TimepointSubmissionStatus): SxProps<Theme> {
  switch (status) {
    case 'NOT_STARTED':
      return {
        backgroundColor: 'status.notStarted',
        border: '1px dashed #546E7A',
        color: 'text.contrast',
      }
    case 'IN_PROGRESS':
      return {
        backgroundColor: 'status.inProgress',
        color: 'text.contrast',
      }
    case 'COMPLETE':
      return {
        backgroundColor: 'status.complete',
        color: 'text.contrast',
      }
    default:
      return {
        backgroundColor: 'status.noStarted',
        color: 'text.contrast',
      }
  }
}

export interface TimepointStatusProps {
  timepoint: StudyTimepointSubmission
}

export default function TimepointSubmissionChip({ timepoint }: TimepointStatusProps) {
  return (
    <Chip
      data-testid="timepoint-status-chip"
      label={timepoint.name}
      size="small"
      sx={{
        ...getChipStyle(timepoint.status),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '8px',
        width: 'auto',
        height: '32px',
      }}
    />
  )
}
