import { Box, Stack, SxProps, Table, TableBody, TableCell, TableRow, Theme, Typography, useTheme } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import ErcfTableRow from './ErcfTableRow'
import DefaultAdjudicationTableHeader from './DefaultAdjudicationTableHeader'
import {
  ReadTaskSummaryData,
  ReadTaskSummaryTimepoints,
  ReadTaskTimepointEcrfDefinition,
} from '@common/config/api/client'
import { TableScrollSize } from '@features/reader_summary/pages/summary.types'
import ExpandableSection from '../summary/ExpandableSection'

function TimepointCell({
  i,
  tableScrollSize,
  stickyStyles,
  theme,
  values,
}: {
  i: number
  tableScrollSize: TableScrollSize
  stickyStyles: SxProps<Theme>
  theme: Theme
  values: string[]
}) {
  const cellRef = useRef<HTMLTableCellElement>(null)
  const [cellHeight, setCellHeight] = useState<number>(0)

  useEffect(() => {
    if (cellRef.current) {
      const { height } = cellRef.current.getBoundingClientRect()
      setCellHeight(height)
    }
  }, [])

  return (
    <TableCell
      key={`timepoint-${i}`}
      ref={cellRef}
      sx={{
        width: tableScrollSize.columnWidth,
        minWidth: tableScrollSize.columnWidth,
        borderBottom: `1px solid ${theme.palette.divider}`,
        borderLeft: `1px solid ${theme.palette.divider}`,
        padding: 0,
        ...stickyStyles,
      }}
    >
      <Stack direction="row" width={tableScrollSize.columnWidth} maxWidth={tableScrollSize.columnWidth}>
        <Box sx={{ height: cellHeight, width: '50%' }}>
          <Typography variant="body2" sx={{ color: 'text.primary' }} align="left" paddingY={1} paddingX={2}>
            {values[0]}
          </Typography>
        </Box>
        <Box
          sx={{
            height: cellHeight,
            width: '50%',
            borderLeft: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Typography variant="body2" sx={{ color: 'text.primary' }} align="left" paddingY={1} paddingX={2}>
            {values[1]}
          </Typography>
        </Box>
      </Stack>
    </TableCell>
  )
}

export default function ExpandableErcfTableRow({
  ecrfDefinition,
  timepoints,
  tableScrollSize,
}: {
  ecrfDefinition: ReadTaskTimepointEcrfDefinition
  timepoints: ReadTaskSummaryData['timepoints']
  tableScrollSize: TableScrollSize
}) {
  const [expanded, setExpanded] = useState(false)
  const theme = useTheme()

  function getAnswerForQuestion(timepoint: ReadTaskSummaryTimepoints, sumbmissionIndex: number, questionName?: string) {
    const formSubmission = timepoint.reader_submissions[sumbmissionIndex].ecrf_form_submission
    if (formSubmission && formSubmission.answers && questionName) {
      try {
        const answers = JSON.parse(formSubmission.answers)
        if (Object.prototype.hasOwnProperty.call(answers, questionName)) {
          return answers[questionName]
        }
      } catch {
        throw new Error(`Error parsing answers for question ${questionName}`)
      }
    }
    return ''
  }

  const rows = ecrfDefinition.pages?.flatMap((page) =>
    page.elements.map((element) => ({
      label: element.title,
      getValue: (timepoint: ReadTaskSummaryTimepoints, sumbmissionIndex: number) => {
        const answer = getAnswerForQuestion(timepoint, sumbmissionIndex, element.name ?? undefined)
        if (element.type === 'boolean') {
          if (typeof answer === 'boolean') {
            return answer ? (element.labelTrue ?? 'Yes') : (element.labelFalse ?? 'No')
          }
        }
        return answer
      },
    })),
  )

  return (
    <>
      <ErcfTableRow
        expanded={expanded}
        setExpanded={setExpanded}
        timepoints={timepoints}
        tableScrollSize={tableScrollSize}
      />
      <TableRow>
        <TableCell sx={{ p: 0 }} colSpan={tableScrollSize.minVisibleTimepoints + 2} data-testid="collapse-cell">
          <ExpandableSection expanded={expanded} disableAnimation>
            <Table
              data-testid="expanded-table"
              sx={{
                borderCollapse: 'separate',
              }}
            >
              <DefaultAdjudicationTableHeader isHidden timepoints={timepoints} tableScrollSize={tableScrollSize} />
              <TableBody>
                {rows?.map((rowDef, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    sx={{
                      bgcolor: rowIndex % 2 === 0 ? 'paper.main' : 'background.paper',
                    }}
                  >
                    <TableCell
                      sx={{
                        width: tableScrollSize.columnWidth,
                        minWidth: tableScrollSize.columnWidth,
                        position: 'sticky',
                        left: 0,
                        background: 'inherit',
                        zIndex: 2,
                        paddingLeft: 3,
                        borderBottom: `1px solid ${theme.palette.divider}`,
                      }}
                    >
                      <Typography variant="body2" sx={{ color: 'text.secondary', paddingLeft: 4 }}>
                        {rowDef.label}
                      </Typography>
                    </TableCell>

                    {Array.from({ length: tableScrollSize.emptyCellsCount }).map((_, i) => (
                      <TableCell
                        key={`empty-${i}`}
                        sx={{
                          width: tableScrollSize.columnWidth,
                          minWidth: tableScrollSize.columnWidth,
                          borderBottom: `1px solid ${theme.palette.divider}`,
                        }}
                      />
                    ))}

                    {timepoints.map((timepoint, tpIndex) => {
                      let stickyStyles = {}
                      if (tpIndex === 0) {
                        stickyStyles = {
                          position: 'sticky',
                          left: tableScrollSize.firstTimepointLeftOffset,
                          background: 'inherit',
                          zIndex: 2,
                        }
                      }
                      if (tpIndex === timepoints.length - 1) {
                        stickyStyles = {
                          position: 'sticky',
                          right: tableScrollSize.checkboxWidth,
                          background: 'inherit',
                          zIndex: 2,
                          borderLeft: `1px solid ${theme.palette.primary.main}`,
                        }
                      }
                      return (
                        <TimepointCell
                          i={tpIndex}
                          tableScrollSize={tableScrollSize}
                          stickyStyles={stickyStyles}
                          theme={theme}
                          values={[rowDef.getValue(timepoint, 0), rowDef.getValue(timepoint, 1)]}
                        />
                      )
                    })}

                    <TableCell
                      padding="checkbox"
                      sx={{
                        width: tableScrollSize.checkboxWidth,
                        minWidth: tableScrollSize.checkboxWidth,
                        position: 'sticky',
                        right: 0,
                        background: 'inherit',
                        zIndex: 3,
                        borderBottom: `1px solid ${theme.palette.divider}`,
                        borderLeft: `1px solid ${theme.palette.primary.main}`,
                      }}
                    />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ExpandableSection>
        </TableCell>
      </TableRow>
    </>
  )
}
