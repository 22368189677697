import { Chip, ChipOwnProps } from '@mui/material'
import { SubjectStatus } from '@common/config/api/client'

export type SubjectStatusChipProps = {
  subjectStatus?: SubjectStatus
  size?: ChipOwnProps['size']
}

const StatusLabelMap: Record<SubjectStatus, { label: string; color: ChipOwnProps['color'] }> = {
  CREATED: {
    label: 'Created',
    color: 'info-alt',
  },
  WITHDREW: {
    label: 'Withdrew',
    color: 'warning-alt',
  },
  SCREENING_NOT_STARTED: {
    label: 'Created',
    color: 'info-alt',
  },
  UNDER_SCREENING: {
    label: 'Created',
    color: 'info-alt',
  },
  SCREENING_FAILED: {
    label: 'Screening Failed',
    color: 'error-alt',
  },
  ACTIVE: {
    label: 'Active',
    color: 'success-alt',
  },
  IN_TREATMENT: {
    label: 'In Treatment',
    color: 'success-alt',
  },
  POST_TREATMENT: {
    label: 'Post Treatment',
    color: 'success-alt',
  },
  VOID: {
    label: 'Removed',
    color: 'error-alt',
  },
  DECEASED: {
    label: 'Deceased',
    color: 'error-alt',
  },
}

export default function SubjectStatusChip({ subjectStatus, size = 'medium' }: SubjectStatusChipProps) {
  if (!subjectStatus) {
    return null
  }

  return (
    <Chip
      data-testid="subject-status-chip"
      label={StatusLabelMap[subjectStatus]?.label}
      color={StatusLabelMap[subjectStatus]?.color}
      size={size}
      sx={{
        padding: 0.25,
        borderRadius: 100,
      }}
    />
  )
}
