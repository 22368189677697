import { Stack, Grid2 as Grid } from '@mui/material'
import { Breadcrumb } from '@common/components/Breadcrumb'
import { useUserGroup } from '@common/hooks/useUserGroup'
import { useEffect } from 'react'
import { COMPANY_MANAGEMENT_BREADCRUMB_ITEMS } from '../constants'
import CompanyManagementTable from './CompanyManagementTable'
import CompanyAddMenu from './CompanyAddMenu'
import {
  useCompanyTypeNavigation,
  useListCompanies,
  useCompanyMetadata,
  useCompanyManagementHandlers,
} from '@features/company_management/hooks'

import CompanyManagementTableSkeleton from './CompanyManagementTableSkeleton'
import { SPACING } from '@common/theme/spacing'
import { SIZING } from '@common/theme/sizing'
import { PageHeader } from '@common/components/PageHeader'
import CreateOrUpdateCompanyDialog from './CreateOrUpdateCompanyDialog'

export default function CompanyManagement() {
  const { setCurrentUserGroup } = useUserGroup()
  const { data: companyMetadata, isPending: isCompanyMetadataPending } = useCompanyMetadata()

  const {
    page,
    addMenuAnchor,
    companiesParams,
    setCompaniesParams,
    currentCompanyType,
    handleCloseCompanyMenu,
    handleCompanyTypeSelect,
    handlePageChange,
    handleRowsPerPageChange,
    handleSearch,
    handleRequestSort,
    handleAddClick,
    handleEditClick,
    handleDeleteCompany,
    handleCompanyTypeChange,
    isCompanyDialogVisible,
    setIsCompanyDialogVisible,
    editingCompanyId,
    setEditingCompanyId,
    handleSaveCompany,
    handleSaveEditedCompany,
    editingCompany,
    isEditingCompanyPending,
    handleBulkDeleteCompanies,
  } = useCompanyManagementHandlers()

  useCompanyTypeNavigation({
    companyMetadata,
    companiesParams,
    setCompaniesParams,
  })

  useEffect(() => {
    setCurrentUserGroup('IAM')
  }, [setCurrentUserGroup])

  const { data: companies, isPending: isCompaniesPending } = useListCompanies(companiesParams)
  const isLoading = isCompanyMetadataPending || isCompaniesPending

  const shouldShowCompanyDialog = isCompanyDialogVisible && (!editingCompanyId || !isEditingCompanyPending)

  return (
    <>
      <Breadcrumb items={COMPANY_MANAGEMENT_BREADCRUMB_ITEMS} sx={{ marginBottom: SPACING.breadcrumbsMarginBottom }} />
      <Grid container>
        <Grid size={SIZING.gridSizeXxl}>
          <Stack spacing={SPACING.sectionsSpacing}>
            <Stack>
              <PageHeader title="Company Management" buttonsVariant="contained" icon="arrow" />
            </Stack>
            {isLoading ? (
              <CompanyManagementTableSkeleton />
            ) : (
              <CompanyManagementTable
                companyMetadata={companyMetadata}
                companies={companies}
                currentCompanyType={currentCompanyType}
                handlePageChange={(event, newPage) => handlePageChange(companies, event, newPage)}
                onRowsPerPageChange={handleRowsPerPageChange}
                onSearch={handleSearch}
                searchValue={companiesParams.search ?? ''}
                page={page}
                orderBy={companiesParams.order_by_field}
                order={companiesParams.asc_order ? 'asc' : 'desc'}
                onRequestSort={handleRequestSort}
                onAddClick={handleAddClick}
                onEditClick={handleEditClick}
                onDeleteCompany={handleDeleteCompany}
                onBulkDeleteCompanies={handleBulkDeleteCompanies}
                onCompanyTypeChange={handleCompanyTypeChange}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
      <CompanyAddMenu
        anchorEl={addMenuAnchor}
        onClose={handleCloseCompanyMenu}
        companyTypes={companyMetadata?.companyTypeFilter}
        onCompanyTypeSelect={handleCompanyTypeSelect}
      />
      {shouldShowCompanyDialog && (
        <CreateOrUpdateCompanyDialog
          title={editingCompanyId ? 'Edit Company' : 'Add new company'}
          data={editingCompany}
          onClose={() => {
            setIsCompanyDialogVisible(false)
            setEditingCompanyId(null)
          }}
          onSave={editingCompanyId ? handleSaveEditedCompany : handleSaveCompany}
        />
      )}
    </>
  )
}
