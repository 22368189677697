import { Box, Typography } from '@mui/material'
import UploadZone from '../UploadZone'
import Uppy from '@uppy/core'
import { useEffect, useState } from 'react'
import { ContainerParam, ContainerType, FileSystemItem } from '@features/subject/subject.types'
import { deleteItemsByIds, getAllChildrenIdsForParents, mapFilesToStructure } from '@features/subject/utils'
import { useParams } from 'react-router-dom'

import MimeTypeSchema from './MimeTypeSchema.json'

interface MimeTypeProps {
  [key: string]: string[]
}

type AddFilesStepProps = {
  uppy: Uppy
  containerParam: ContainerParam[]
  onCheckStepComplete: (bool: boolean) => void
}

const getFileTypeAllowed = (data: Array<ContainerParam>, type: ContainerType): string[] => {
  const container = data.find((item) => item.type === type)
  return container ? container.fileTypesAllowed : []
}

const convertListFileTypes = (data: string[]): string[] => {
  const result = data.flatMap((fileType) => fileType.split('_').map((part) => `.${part.toLowerCase()}`))

  // Add `.dcm` to the result by default
  if (!result.includes('.dcm')) {
    result.push('.dcm')
  }

  return result
}

const filterFileTypes = (data: MimeTypeProps, filter: string[]): MimeTypeProps => {
  const result: MimeTypeProps = {}

  // Normalize the filter by splitting combined filters and converting to lowercase
  const normalizedFilter = new Set(filter.flatMap((f) => f.split('_').map((part) => part.toLowerCase())))

  for (const [mimeType, extensions] of Object.entries(data)) {
    // Always include "application/dicom" without filtering
    if (mimeType === 'application/dicom') {
      result[mimeType] = extensions
      continue
    }

    const matchingExtensions = extensions.filter((ext) => normalizedFilter.has(ext.toLowerCase().replace('.', '')))

    if (matchingExtensions.length > 0) {
      result[mimeType] = matchingExtensions // Include only matching extensions
    }
  }

  return result
}

export default function AddFilesStep({ uppy, containerParam, onCheckStepComplete }: AddFilesStepProps) {
  const [data, setData] = useState<FileSystemItem[]>(mapFilesToStructure(uppy.getFiles()))

  const { studyId, timepointSubmissionId, subjectId } = useParams<{
    studyId: string
    subjectId: string
    timepointSubmissionId: string
  }>()

  const fileTypeAllowed = getFileTypeAllowed(containerParam, 'NON_DICOM')
  const listFileTypes = convertListFileTypes(fileTypeAllowed)
  const acceptedFilesTypes = filterFileTypes(MimeTypeSchema, fileTypeAllowed)

  const onRemove = (filesIds: string[]) => {
    const actualFiles = getAllChildrenIdsForParents(data, filesIds)
    uppy.removeFiles(actualFiles)
    setData(deleteItemsByIds(data, actualFiles))
  }

  const onDrop = (acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      const fileId = uppy.addFile(file)

      uppy.setFileMeta(fileId, {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        path: file.path,
        file_name: file.name,
        file_type: file.type || 'application/octet-stream',
        study_id: studyId,
        subject_id: subjectId,
        timepoint_submission_id: timepointSubmissionId,
      })
    })

    setData(mapFilesToStructure(uppy.getFiles()))
  }

  useEffect(() => {
    onCheckStepComplete(data.length > 0)
  }, [data.length])

  return (
    <Box>
      <Typography color="text.primary" variant="h4">
        Add and review files
      </Typography>
      <Typography color="text.secondary" variant="body2" mb={4}>
        Pick files or folders from your computer and add them here
      </Typography>
      <UploadZone
        onRemove={onRemove}
        data={data}
        onDrop={onDrop}
        onDropRejected={() => {}}
        fileTypesAllowed={listFileTypes}
        accept={acceptedFilesTypes}
      />
    </Box>
  )
}
