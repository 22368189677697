import { useMutation } from '@tanstack/react-query'
import {
  HTTPValidationError,
  UserCreateSchema,
  updateUserUsersUserIdPut,
  UpdateUserUsersUserIdPutResponse,
} from '@common/config/api/client'

import { queryClient } from '@common/config/api/queryClient'
import { AxiosError } from 'axios'
import queryKeyFactory from '../queryKeyFactory'

interface Params {
  userId: string
  userData: UserCreateSchema
}

export default function useUpdateUser() {
  return useMutation<UpdateUserUsersUserIdPutResponse, AxiosError<HTTPValidationError>, Params>({
    mutationFn: async ({ userId, userData }) => {
      const response = await updateUserUsersUserIdPut({
        path: {
          user_id: userId,
        },
        body: {
          role_id: userData.role_id,
          company_id: userData.company_id,
          name: userData.name,
          email: userData.email,
          phone: userData.phone,
          email_verified: userData.email_verified,
        },
        throwOnError: true,
      })
      return response.data
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: queryKeyFactory.allUsers() })
      queryClient.invalidateQueries({ queryKey: queryKeyFactory.userDetails(data.user_id) })
    },
  })
}
