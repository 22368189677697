import {
  getAllSubjectsTimepointsStudiesStudyIdSubjectsTimepointsGet,
  GetAllSubjectsTimepointsStudiesStudyIdSubjectsTimepointsGetData,
} from '@common/config/api/client'
import { useQuery } from '@tanstack/react-query'

export const useGetSubjectsList = (
  queryKey: string[],
  params: GetAllSubjectsTimepointsStudiesStudyIdSubjectsTimepointsGetData['path'],
) => {
  return useQuery({
    queryKey: queryKey,
    queryFn: () =>
      getAllSubjectsTimepointsStudiesStudyIdSubjectsTimepointsGet({
        path: params,
      }),
    enabled: !!params.study_id,
  })
}
