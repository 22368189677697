import { Menu, MenuItem, MenuList } from '@mui/material'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { Fragment } from 'react/jsx-runtime'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import DeleteModal from '../modals/DeleteModal'
import CommentModal from '../modals/CommentModal'
import { User } from '@common/config/api/client'
import MoveProgressModal from '../modals/MoveProgressModal'

export default function PopupMenuList({
  task_id,
  study_id,
  reader,
  comment,
}: {
  task_id: string
  study_id: string
  reader?: User
  comment?: string
}) {
  return (
    <PopupState variant="popover">
      {(popupState) => (
        <Fragment>
          <MoreVertRoundedIcon
            fontSize="small"
            {...bindTrigger(popupState)}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                borderRadius: '50%',
                backgroundColor: 'background.default',
              },
            }}
            data-testid="popup-menu-icon"
          />
          <Menu
            {...bindMenu(popupState)}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            data-testid="popup-menu-list"
          >
            <MenuList
              sx={{
                width: 251,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                paddingY: 0.5,
                outline: 'none',
              }}
            >
              <MenuItem
                sx={{
                  width: '100%',
                }}
                data-testid="popup-menu-list-item-reopen"
              >
                <CommentModal reader={reader} comment={comment} popupState={popupState} />
              </MenuItem>
              <MenuItem
                sx={{
                  width: '100%',
                }}
                data-testid="popup-menu-list-item-reopen"
              >
                <MoveProgressModal task_id={task_id} study_id={study_id} popupState={popupState} />
              </MenuItem>
              <MenuItem
                sx={{
                  width: '100%',
                }}
                data-testid="popup-menu-list-item-reset"
              >
                <DeleteModal taskIds={[task_id]} study_id={study_id} popupState={popupState} />
              </MenuItem>
            </MenuList>
          </Menu>
        </Fragment>
      )}
    </PopupState>
  )
}
