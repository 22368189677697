import { ReadTaskStatus } from '@common/config/api/client'

export const TASK_STATE_COLOR: Record<ReadTaskStatus, string> = {
  NOT_ASSIGNED: '#DCE3E5',
  NOT_STARTED: '#C7EFFC',
  IN_PROGRESS: '#FFEFBF',
  REOPENED: '#FFEFBF',
  REOPENED_PENDING_APPROVAL: '#FFEFBF',
  COMPLETE: '#D7F5DB',
  SKIPPED: '#FEDADD',
  ON_HOLD: '#DCE3E5',
}

export const TASK_STATE_TEXT_COLOR: Record<ReadTaskStatus, string> = {
  NOT_ASSIGNED: '#263238',
  NOT_STARTED: '#006080',
  IN_PROGRESS: '#994300',
  REOPENED: '#994300',
  REOPENED_PENDING_APPROVAL: '#994300',
  COMPLETE: '#1B5E24',
  SKIPPED: '#910410',
  ON_HOLD: '#263238',
}
