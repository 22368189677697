import EnhancedTabs from '@common/components/Tab/EnhancedTabs'
import { CurrentActiveTabProvider } from '@common/context/CurrentActiveTabProvider'
import { TabOptionalComponentProvider } from '@common/context/TabOptionalComponentProvider'
import ReportTable from './ReportTable'
import { StudyReportPermission } from '@auth/permissionsEnum'
export default function Reporting() {
  const tabs = {
    'all-reports': {
      label: 'All reports',
      content: <ReportTable reportType="ALL" />,
    },
    'general-reports': {
      label: 'General reports',
      content: <ReportTable reportType="GENERAL" />,
    },
    'qc-reports': {
      label: 'QC reports',
      content: <ReportTable reportType="QC" />,
      permission: StudyReportPermission.DOWNLOAD_QC_REPORT,
    },
    'timepoint-eform-reports': {
      label: 'Timepoint eForm reports',
      content: <ReportTable reportType="TIMEPOINT_EFORM" />,
    },
    'ecrf-reports': {
      label: 'eCRF Reports',
      content: <ReportTable reportType="ECRF" />,
    },
  }

  return (
    <CurrentActiveTabProvider>
      <TabOptionalComponentProvider>
        <EnhancedTabs tabs={tabs} />
      </TabOptionalComponentProvider>
    </CurrentActiveTabProvider>
  )
}
