import { getSubjectByIdParams, GetSubjectTimepointsParams, SubjectsParams } from './types'

export const queryKeyFactory = {
  studySubjects: (params: SubjectsParams) => ['study', params.study_id, 'subjects', params],
  studySubject: (params: getSubjectByIdParams) => ['study', params.study_id, 'subject', params.subject_id],
  myStudySite: (studyId: string) => ['study', studyId, 'site', 'my'],
  studySubjectsMetadata: () => ['study', 'subjects', 'metadata'],
  subjectTimepoints: (params: GetSubjectTimepointsParams) =>
    ['study', params.study_id, 'site', params.site_id, 'subject', params.subject_id, 'timepoints'] as const,
}
