import { Button, TextField, InputAdornment, styled, Box } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import { CompanyType } from '../types'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { SPACING } from '@common/theme/spacing'
import { SIZING } from '@common/theme/sizing'

interface CompanyManagementTableToolbarProps {
  companyTypes?: CompanyType[]
  onSearch: (value: string) => void
  searchValue?: string
  onAddClick: (companyType: CompanyType) => void
  currentCompanyType: CompanyType | null
  onCompanyTypeChange: (companyType: CompanyType | null) => void
}

const ToolbarContainer = styled(Box)({
  padding: 16,
  display: 'flex',
  gap: 24,
  alignItems: 'center',
})

export default function CompanyManagementTableToolbar({
  companyTypes,
  onSearch,
  searchValue,
  onAddClick,
  currentCompanyType,
  onCompanyTypeChange,
}: Readonly<CompanyManagementTableToolbarProps>) {
  const location = useLocation()

  useEffect(() => {
    if (companyTypes) {
      const hash = location.hash.slice(1)
      const newType = hash ? companyTypes.find((type) => type.name === hash) : companyTypes[0]
      if (newType) {
        onCompanyTypeChange(newType)
      }
    }
  }, [location.hash, companyTypes, onCompanyTypeChange])

  const [localSearchValue, setLocalSearchValue] = useState(searchValue ?? '')
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalSearchValue(event.target.value)
  }
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSearch(localSearchValue)
    }
  }

  useEffect(() => {
    setLocalSearchValue(searchValue ?? '')
  }, [searchValue])

  const handleAddClick = () => {
    if (currentCompanyType) {
      onAddClick(currentCompanyType)
    }
  }

  return (
    <ToolbarContainer
      sx={{ paddingX: 0, display: 'flex', justifyContent: 'flex-end', gap: SPACING.tableToolbarItemsSpacing }}
    >
      <Button variant="contained" color="secondary" startIcon={<AddIcon />} size="small" onClick={handleAddClick}>
        Add company
      </Button>
      <TextField
        placeholder="Search"
        variant="outlined"
        size="small"
        sx={{ minWidth: SIZING.textFieldWidth }}
        value={localSearchValue}
        onChange={handleSearchChange}
        onKeyDown={handleKeyDown}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          },
        }}
      />
    </ToolbarContainer>
  )
}
