import { Badge, Box, Divider, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined'
import ChecklistRoundedIcon from '@mui/icons-material/ChecklistRounded'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import WarningIcon from '@mui/icons-material/Warning'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import { SeriesStatus } from '@features/subject/subject.types.ts'
import { SPACING } from '@common/theme/spacing'
import { SIZING } from '@common/theme/sizing'

type SeriesRowProps = {
  isBorderNeeded: boolean
  description: string
  tooltipText: string
  acquisitionDate: string
  seriesNumber: number
  instances: number
  modality: string
  onDicomTagsDialogOpen: (seriesNumber: number) => void
  onCriteriaDialogOpen: () => void
  status: SeriesStatus
  fileIds: string[]
  modalityAllowed: string[]
  onDelete: (seriesNumber: number, filesIds: string[]) => void
}

export default function SeriesRow({
  isBorderNeeded,
  description,
  seriesNumber,
  acquisitionDate,
  tooltipText,
  instances,
  modality,
  onDicomTagsDialogOpen,
  status,
  onCriteriaDialogOpen,
  fileIds,
  modalityAllowed,
  onDelete,
}: SeriesRowProps) {
  const theme = useTheme()
  const checkModalityAllowed = (allowedTypes: string[], modality: string): boolean => {
    return allowedTypes.includes(modality) ? true : false
  }

  const isModalityAllowed = checkModalityAllowed(modalityAllowed, modality)

  return (
    <Box
      px={SPACING.spacingLg}
      py={SPACING.minSpacing}
      bgcolor="#F0F4F5"
      borderBottom={isBorderNeeded ? `1px solid ${theme.palette.divider}` : undefined}
      display="flex"
      gap={2}
      alignItems="center"
    >
      {isModalityAllowed ? (
        status === 'FAILED' ? (
          <ErrorOutlineRoundedIcon sx={{ color: 'error.main' }} />
        ) : (
          <CheckCircleOutlineRoundedIcon sx={{ color: 'success.main' }} />
        )
      ) : (
        <WarningIcon fontSize="small" color="warning" id="error" />
      )}

      <Box flex={5}>
        <Box display="flex" mb={SPACING.spacingXs} alignItems="center">
          <Typography variant="subtitle2" color="text.secondary" mr={SPACING.spacingMd}>
            Series description
          </Typography>
          <Tooltip title={tooltipText} placement="top" arrow>
            <InfoRoundedIcon sx={{ width: SIZING.iconWidthXs, height: SIZING.iconWidthXs, color: 'text.secondary' }} />
          </Tooltip>
        </Box>
        <Typography variant="body2">{description}</Typography>
      </Box>
      <Box flex={2}>
        <Typography variant="subtitle2" color="text.secondary" mb={SPACING.spacingXs}>
          Acquisition date
        </Typography>
        <Typography variant="body2">{acquisitionDate}</Typography>
      </Box>
      <Box flex={1.5}>
        <Typography variant="subtitle2" color="text.secondary" mb={SPACING.spacingXs}>
          Series №
        </Typography>
        <Typography variant="body2">{seriesNumber}</Typography>
      </Box>
      <Box flex={1.5}>
        <Typography variant="subtitle2" color="text.secondary" mb={SPACING.spacingXs}>
          Instances
        </Typography>
        <Typography variant="body2">{instances}</Typography>
      </Box>
      <Box flex={2}>
        <Typography variant="subtitle2" color="text.secondary" mb={SPACING.spacingXs}>
          Modality
        </Typography>
        <Typography variant="body2">{modality}</Typography>
      </Box>
      <Divider orientation="vertical" flexItem variant="middle" />
      <Box display="flex" gap={1}>
        <IconButton size="small" aria-label="Checklist" onClick={() => onCriteriaDialogOpen()}>
          {status === 'FAILED' ? (
            <Badge color="error" variant="dot">
              <ChecklistRoundedIcon fontSize="small" />
            </Badge>
          ) : (
            <ChecklistRoundedIcon fontSize="small" />
          )}
        </IconButton>
        <IconButton size="small" aria-label="Label" onClick={() => onDicomTagsDialogOpen(seriesNumber)}>
          <LabelOutlinedIcon fontSize="small" />
        </IconButton>
        <IconButton size="small" aria-label="Delete" onClick={() => onDelete(seriesNumber, fileIds)}>
          <DeleteOutlineOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  )
}
