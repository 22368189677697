import { Box, Collapse, Paper, Stack, useTheme } from '@mui/material'
import { useState } from 'react'
import StudyAndSeriesDefinitionForm from './StudyAndSeriesDefinitionForm'
import { QcTaskExamDescriptionValues } from '../../qc_task_details_view.types'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  QcStudyDefinitionCreateFormValues,
  qcStudyDefinitionCreateSchema,
} from '../../schemas/StudyAndSeriesDefinitionValidationSchema'
import { QcTimepointContainerSubmission } from '@common/config/api/client'
import { useCreateQcStudyDefinitionMutation } from '../../hooks/useCreateQcStudyDefinitionMutation'
import { useParams } from 'react-router-dom'
import { useUpdateQcStudyDefinitionMutation } from '../../hooks/useUpdateQcStudyDefinitionMutation'
import { SPACING } from '@common/theme/spacing'
import StudyAndSeriesDefinitionHeader from './StudyAndSeriesDefinitionHeader'
import StudyAndSeriesDefinitionInstance from './StudyAndSeriesDefinitionInstance'

export default function StudyAndSeriesDefinition({
  examDescription,
  qcTaskSubmissionId,
  qcTimepointContainerSubmissions,
  removeStudy,
  editStudy,
}: {
  examDescription: QcTaskExamDescriptionValues
  qcTaskSubmissionId: string
  qcTimepointContainerSubmissions: QcTimepointContainerSubmission[]
  removeStudy: (id: string) => void
  editStudy: (id: string) => void
}) {
  const [open, setOpen] = useState(false)
  const { qcTaskId } = useParams()
  const theme = useTheme()
  const { mutateAsync: createQcStudyDefinition } = useCreateQcStudyDefinitionMutation()
  const { mutateAsync: updateQcStudyDefinition } = useUpdateQcStudyDefinitionMutation()

  const { control, handleSubmit, formState } = useForm<QcStudyDefinitionCreateFormValues>({
    resolver: zodResolver(qcStudyDefinitionCreateSchema),
    mode: 'onSubmit',
    defaultValues: {
      study_description: examDescription.study_description || '',
      exam_metadata_ids: examDescription.source_study_description.map(
        (sourceStudyDescription) => sourceStudyDescription.container_submission_exam_metadata_id,
      ),
      series_definitions:
        examDescription.series_descriptions.map((seriesDescription, index) => ({
          order: index + 1,
          is_selected: false,
          series_label_id: seriesDescription.series_label_id || '',
          submission_series_id: seriesDescription.submission_series_id,
          series_metadata_id: seriesDescription.source_series_description.container_submission_series_metadata_id,
          instance_number_ranges: seriesDescription.instance_number_ranges.map((instanceNumberRange) => ({
            start: instanceNumberRange.start || 1,
            end: instanceNumberRange.end || 1,
          })),
        })) || [],
    },
  })

  const onSubmit = (data: QcStudyDefinitionCreateFormValues) => {
    if (examDescription.isEditing) {
      updateQcStudyDefinition({
        body: data,
        path: {
          qc_task_id: qcTaskId!,
          qc_task_submission_id: qcTaskSubmissionId,
          study_definition_id: examDescription.submission_exam_id,
        },
      })
    } else {
      createQcStudyDefinition({
        body: data,
        path: { qc_task_id: qcTaskId!, qc_task_submission_id: qcTaskSubmissionId },
      })
    }
  }

  return (
    <Paper
      sx={{
        padding: SPACING.spacingLg,
        borderRadius: SPACING.borderRadiusXxl,
        border: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Box
        onSubmit={examDescription.isSubmitted && !examDescription.isEditing ? () => {} : handleSubmit(onSubmit)}
        component={examDescription.isSubmitted && !examDescription.isEditing ? 'div' : 'form'}
      >
        <Stack spacing={SPACING.spacingXl}>
          <StudyAndSeriesDefinitionHeader
            open={open}
            setOpen={setOpen}
            study={examDescription}
            removeStudy={removeStudy}
            editStudy={editStudy}
            saveDisabled={formState.isSubmitting}
          />
          <Collapse in={open} timeout="auto" unmountOnExit>
            {examDescription.isSubmitted && !examDescription.isEditing ? (
              <StudyAndSeriesDefinitionInstance qcTaskExamDescription={examDescription} />
            ) : (
              <StudyAndSeriesDefinitionForm
                control={control}
                saveDisabled={formState.isSubmitting}
                qcTimepointContainerSubmissions={qcTimepointContainerSubmissions}
              />
            )}
          </Collapse>
        </Stack>
      </Box>
    </Paper>
  )
}
