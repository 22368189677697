import { useMutation } from '@tanstack/react-query'
import {
  downloadQcReportStudiesStudyIdReportsQcReportTypeDownloadPost,
  downloadTimepointEformReportStudiesStudyIdReportsTimepointEformReportTypeDownloadPost,
  downloadGeneralReportStudiesStudyIdReportsGeneralReportTypeDownloadPost,
  GeneralReportType,
  QCReportType,
  TimepointEFormReportType,
} from '@common/config/api/client'
import { AxiosError } from 'axios'

export type ReportDownloadType =
  | {
      type: 'QC'
      reportType: QCReportType
    }
  | {
      type: 'TIMEPOINT_EFORM'
      reportType: TimepointEFormReportType
    }
  | {
      type: 'GENERAL'
      reportType: GeneralReportType
    }

interface Params {
  studyId: string
  reportDownloadType: ReportDownloadType
}

interface BlobWithHeaders extends Blob {
  headers?: {
    filename?: string | null
  }
}

const getFilenameFromHeader = (filename: string | null): string => {
  if (!filename) return 'report.csv'

  return filename
}

// Define download handlers with proper typing
const downloadHandlers = {
  QC: (studyId: string, reportType: QCReportType) =>
    downloadQcReportStudiesStudyIdReportsQcReportTypeDownloadPost({
      path: {
        report_type: reportType,
        study_id: studyId,
      },
      throwOnError: true,
      responseType: 'blob',
    }),
  TIMEPOINT_EFORM: (studyId: string, reportType: TimepointEFormReportType) =>
    downloadTimepointEformReportStudiesStudyIdReportsTimepointEformReportTypeDownloadPost({
      path: {
        study_id: studyId,
        report_type: reportType,
      },
      throwOnError: true,
      responseType: 'blob',
    }),
  GENERAL: (studyId: string, reportType: GeneralReportType) =>
    downloadGeneralReportStudiesStudyIdReportsGeneralReportTypeDownloadPost({
      path: {
        study_id: studyId,
        report_type: reportType,
      },
      throwOnError: true,
      responseType: 'blob',
    }),
}

export default function useDownloadReport() {
  return useMutation<BlobWithHeaders, AxiosError, Params>({
    mutationFn: async ({ studyId, reportDownloadType }) => {
      let response
      switch (reportDownloadType.type) {
        case 'QC':
          response = await downloadHandlers['QC'](studyId, reportDownloadType.reportType)
          break
        case 'GENERAL':
          response = await downloadHandlers['GENERAL'](studyId, reportDownloadType.reportType)
          break
        case 'TIMEPOINT_EFORM':
        default:
          response = await downloadHandlers['TIMEPOINT_EFORM'](studyId, reportDownloadType.reportType)
          break
      }

      const filename = response.headers?.['filename']

      const blob = response.data as BlobWithHeaders
      Object.defineProperty(blob, 'headers', {
        value: { filename },
        writable: false,
      })

      return blob
    },
    onSuccess: (data) => {
      const url = window.URL.createObjectURL(data)
      const link = document.createElement('a')
      link.href = url

      const filename = getFilenameFromHeader(data.headers?.filename ?? null)
      link.setAttribute('download', filename)

      document.body.appendChild(link)
      link.click()
      link.remove()
      window.URL.revokeObjectURL(url)
    },
  })
}
