import { StudyDetails } from '@common/config/api/client'
import { SPACING } from '@common/theme/spacing'
import { AssessmentOutlined } from '@mui/icons-material'
import { Card, Divider, Box, CardContent, Stack, Typography } from '@mui/material'

interface Props {
  details: StudyDetails
}

export default function StudyInformation({ details }: Props) {
  return (
    <Card sx={{ bgcolor: 'secondary.light', height: '100%', borderRadius: '8px' }}>
      <CardContent>
        <Stack spacing={SPACING.spacingXl}>
          <Box>
            <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Box component="span" sx={{ display: 'flex' }}>
                <AssessmentOutlined color="primary" />
              </Box>
              Study Information
            </Typography>
          </Box>

          <Stack spacing={SPACING.spacingLg}>
            <Box>
              <Typography variant="body2" color="text.secondary">
                Created by
              </Typography>
              <Typography>{details.created_by_name}</Typography>
            </Box>

            <Divider />

            <Box>
              <Typography variant="body2" color="text.secondary">
                Study ID
              </Typography>
              <Typography>{details.user_facing_study_id}</Typography>
            </Box>

            <Box>
              <Typography variant="body2" color="text.secondary">
                Sponsor
              </Typography>
              <Typography>{details.sponsor?.name}</Typography>
            </Box>

            <Box>
              <Typography variant="body2" color="text.secondary">
                Location
              </Typography>
              <Typography>{details.locations?.map((location) => location.name).join(', ') || 'N/A'}</Typography>
            </Box>

            <Box>
              <Typography variant="body2" color="text.secondary">
                Description
              </Typography>
              <Typography>{details.description}</Typography>
            </Box>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}
