import { MouseEvent } from 'react'
import {
  Box,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Chip,
  Popover,
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemIcon,
  Stack,
  CircularProgress,
} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import { PersonRemoveOutlined } from '@mui/icons-material'
import EnhancedTable from '@common/components/Table/EnhancedTable'
import { Order, HeadCell } from '@common/components/Table/table.types'
import { SIZING } from '@common/theme/sizing'
import { SPACING } from '@common/theme/spacing'
import {
  PaginatedResponse_StudyUserRoleAssociationSchema_,
  StudyUserRoleAssociationSchema,
} from '@common/config/api/client'
import { Role } from '@features/user_management/hooks/useRoles'
import StudyAssignmentsTableToolbar from './StudyAssignmentsTableToolbar'
import { useGetStudyAssignmentsMetadata } from '@features/user_management/hooks/useGetStudyAssignmentsMetadata'

interface Props {
  studyAssignmentsList: PaginatedResponse_StudyUserRoleAssociationSchema_ | undefined
  page: number
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  orderBy: keyof StudyUserRoleAssociationSchema
  order: Order
  onRequestSort: (event: MouseEvent<unknown>, property: string) => void
  onPageChange: (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => void
  roleAnchorEl: HTMLElement | null
  activeStudyUserRoleId: string | null
  handleRoleClick: (event: React.MouseEvent<HTMLElement>, studyId: string) => void
  handleRoleMenuClose: () => void
  handleRoleSelect: (roleId: string) => void
  onAddStudyAssignment: () => void
  isUpdatingRole: boolean
  handleDeleteStudyAssignmentClick: (event: React.MouseEvent, studyUserRoleId: string) => void
  isDeletingAssignment: boolean
  studyUserRoleIdToDelete: string
}

type StudyAssignmentsTableSchema = StudyUserRoleAssociationSchema & {
  actions: never
}

const getHeadCells = (): readonly HeadCell<StudyAssignmentsTableSchema>[] => {
  return [
    { id: 'study_name', label: 'Study name', disablePadding: false },
    { id: 'status', label: 'Study status', disablePadding: false },
    { id: 'role_name', label: 'Assigned Role in study', disablePadding: false },
    { id: 'actions', label: '', disablePadding: false },
  ]
}

const EmptyStudyAssignmentsTable = () => {
  return (
    <Stack spacing={SPACING.minSpacing} alignItems="center" sx={{ py: SPACING.spacing2xl }}>
      <Typography variant="h6">No assigned studies</Typography>
      <Typography variant="body2" color="text.secondary">
        When user will be assigned to a study, it will show up here
      </Typography>
    </Stack>
  )
}

const StudyAssignmentsTable = ({
  studyAssignmentsList,
  onRowsPerPageChange,
  onPageChange,
  onRequestSort,
  onAddStudyAssignment,
  page,
  order,
  orderBy,
  roleAnchorEl,
  activeStudyUserRoleId,
  handleRoleClick,
  handleRoleMenuClose,
  handleRoleSelect,
  isUpdatingRole,
  handleDeleteStudyAssignmentClick,
  isDeletingAssignment,
  studyUserRoleIdToDelete,
}: Props) => {
  const studyAssignments = studyAssignmentsList?.items ?? []

  const { data: metadata, isLoading: isLoadingMetadata } = useGetStudyAssignmentsMetadata()

  const headCells = getHeadCells()

  const open = Boolean(roleAnchorEl)

  return (
    <Box component="section">
      <StudyAssignmentsTableToolbar onAddStudyAssignment={onAddStudyAssignment} />
      <Paper
        sx={{
          p: SPACING.paperInnerPadding,
          borderRadius: SPACING.borderRadiusXl,
        }}
      >
        <EnhancedTable<StudyAssignmentsTableSchema>
          rows={studyAssignments}
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          page={page}
          rowsPerPageOptions={[10, 25, 50]}
          onRequestSort={onRequestSort}
          onChangePage={onPageChange}
          next_cursor={studyAssignmentsList?.pagination.next_cursor}
          previous_cursor={studyAssignmentsList?.pagination.previous_cursor}
          rowsPerPage={studyAssignmentsList?.pagination.limit ?? 50}
          onChangeRowsPerPage={onRowsPerPageChange}
        >
          <TableBody>
            {studyAssignments.length > 0 ? (
              studyAssignments.map((row) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.study_user_role_id}>
                    <TableCell>{row.study_name}</TableCell>
                    <TableCell>
                      <Chip
                        label={row.status === 'ACTIVE' ? 'Active' : 'Deactivated'}
                        size="small"
                        color={row.status === 'ACTIVE' ? 'success-alt' : 'error-alt'}
                        sx={{ borderRadius: 100 }}
                      />
                    </TableCell>
                    <TableCell>
                      <Box
                        component="span"
                        onClick={(e) => handleRoleClick(e, row.study_user_role_id)}
                        sx={{
                          cursor: 'pointer',
                          color: 'primary.main',
                          textDecoration: 'underline',
                          '&:hover': {
                            textDecoration: 'none',
                          },
                        }}
                      >
                        {row.role_name}
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        size="small"
                        onClick={(event) => handleDeleteStudyAssignmentClick(event, row.study_user_role_id)}
                        disabled={isDeletingAssignment && studyUserRoleIdToDelete === row.study_user_role_id}
                      >
                        {isDeletingAssignment && studyUserRoleIdToDelete === row.study_user_role_id ? (
                          <CircularProgress size={SIZING.iconWidth} />
                        ) : (
                          <PersonRemoveOutlined sx={{ width: SIZING.popupMenuIconWidth, color: 'text.secondary' }} />
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })
            ) : (
              <TableRow>
                <TableCell colSpan={headCells.length}>
                  <EmptyStudyAssignmentsTable />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </EnhancedTable>
        <Popover
          open={open}
          anchorEl={roleAnchorEl}
          onClose={handleRoleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Box sx={{ width: SIZING.TableMenuWidth }}>
            <Typography sx={{ px: SPACING.spacingLg, pt: SPACING.minSpacing, color: 'text.secondary' }}>
              Select a new role
            </Typography>
            {isLoadingMetadata ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                <CircularProgress size={24} />
              </Box>
            ) : (
              <List>
                {metadata?.roles.map((role: Role) => {
                  const activeStudyAssignment = studyAssignments.find(
                    (s) => s.study_user_role_id === activeStudyUserRoleId,
                  )
                  const isCurrentRole = activeStudyAssignment?.role_name === role.name

                  return (
                    <ListItem
                      key={role.id}
                      onClick={() => handleRoleSelect(role.id)}
                      sx={{
                        cursor: isUpdatingRole ? 'default' : 'pointer',
                        '&:hover': {
                          backgroundColor: isUpdatingRole ? 'transparent' : 'action.hover',
                        },
                        py: SPACING.spacingXs,
                        opacity: isUpdatingRole ? 0.5 : 1,
                        pointerEvents: isUpdatingRole ? 'none' : 'auto',
                      }}
                    >
                      <ListItemText primary={role.name} />
                      {isCurrentRole && (
                        <ListItemIcon sx={{ minWidth: 'auto' }}>
                          <CheckIcon color="primary" />
                        </ListItemIcon>
                      )}
                    </ListItem>
                  )
                })}
              </List>
            )}
            {isUpdatingRole && (
              <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                <CircularProgress size={24} />
              </Box>
            )}
          </Box>
        </Popover>
      </Paper>
    </Box>
  )
}

export default StudyAssignmentsTable
