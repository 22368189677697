import {
  respondToReopenRequestReadTasksReopenResponsePost,
  RespondToReopenRequestReadTasksReopenResponsePostResponse,
  RespondToReopenRequestReadTasksReopenResponsePostData,
  HTTPValidationError,
} from '@common/config/api/client'
import { queryClient } from '@common/config/api/queryClient'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export const useReopenTaskMutation = (): UseMutationResult<
  RespondToReopenRequestReadTasksReopenResponsePostResponse,
  AxiosError<HTTPValidationError>,
  RespondToReopenRequestReadTasksReopenResponsePostData
> => {
  return useMutation<
    RespondToReopenRequestReadTasksReopenResponsePostResponse,
    AxiosError<HTTPValidationError>,
    RespondToReopenRequestReadTasksReopenResponsePostData
  >({
    mutationFn: async ({ body, query }) => {
      const response = await respondToReopenRequestReadTasksReopenResponsePost({
        body: body,
        query: query,
        throwOnError: true,
      })
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['ReadTasksList'] })
    },
  })
}
