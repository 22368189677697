import {
  getAdjudicationJustificationsReadTasksAdjudicationJustificationsGet,
  AdjudicationJustificationSchema,
} from '@common/config/api/client'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export function useGetAdjudicationJustifications(queryKey: [string]) {
  return useQuery<{ data: AdjudicationJustificationSchema[] }, AxiosError>({
    queryKey,
    queryFn: async () => {
      const response = await getAdjudicationJustificationsReadTasksAdjudicationJustificationsGet({ throwOnError: true })
      return { data: response.data }
    },
  })
}
