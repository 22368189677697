/* eslint-disable @typescript-eslint/no-unused-vars */
import { Stack, Typography, Divider, IconButton, Button } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { Dropdown } from '@common/components/Form/Dropdown/Dropdown'
import { DeleteOutlineRounded, EditOutlined } from '@mui/icons-material'
import { SyntheticEvent, useCallback } from 'react'
import { CompaniesMetadataSchema, CompanyType, User, UserSchema } from '@common/config/api/client'
import { SPACING } from '@common/theme/spacing'

export interface AssignUserCardProps {
  index: number
  companiesMetadata?: CompaniesMetadataSchema
  usersList?: { items: Array<UserSchema> }
  onRemove: (index: number) => void
  isLastField: boolean
}

export const AssignUserCard = ({ index, companiesMetadata, usersList, onRemove, isLastField }: AssignUserCardProps) => {
  const { control, watch, setValue, getValues, trigger } = useFormContext()

  const isCollapsed = watch(`users.${index}.isCollapsed`)

  const handleEditUser = () => {
    setValue(`users.${index}.isCollapsed`, false)
  }

  const handleSaveChanges = async () => {
    const isValid = await trigger(`users.${index}`)

    if (isValid) {
      const currentUser = getValues(`users.${index}`)

      const selectedUser = usersList?.items.find((user) => user.user_id === currentUser.user_id)
      const selectedCompany = companiesMetadata?.companies.find(
        (company) => company.company_id === currentUser.company_id,
      )
      const selectedRole = selectedCompany?.roles.find((role) => role.role_id === currentUser.role_id)
      const selectedCompanyType = companiesMetadata?.company_types.find(
        ([value, _]) => value === currentUser.company_type,
      )

      setValue(`users.${index}.isCollapsed`, true)
      setValue(`users.${index}.userName`, selectedUser?.user_name || '')
      setValue(`users.${index}.roleName`, selectedRole?.role_name || '')
      setValue(`users.${index}.companyName`, selectedCompany?.company_name || '')
      setValue(`users.${index}.companyTypeName`, selectedCompanyType ? selectedCompanyType[1] : '')
    }
  }

  const getCompanyOptions = useCallback(() => {
    if (!companiesMetadata?.companies) return []

    const selectedCompanyType = watch(`users.${index}.company_type`) as CompanyType | null

    if (!selectedCompanyType) {
      return companiesMetadata.companies.map((company) => ({
        label: company.company_name,
        value: company.company_id,
        type: company.company_type,
      }))
    }

    return companiesMetadata.companies
      .filter((company) => company.company_type === selectedCompanyType)
      .map((company) => ({
        label: company.company_name,
        value: company.company_id,
        type: company.company_type,
      }))
  }, [companiesMetadata, watch, index])

  const getUserOptions = useCallback(() => {
    if (!usersList?.items) return []

    const companyId = watch(`users.${index}.company_id`)
    const allSelectedUserIds = getValues()
      .users.map((user: User) => user.user_id)
      .filter((id: string) => id)

    let filteredUsers = usersList.items

    if (companyId) {
      filteredUsers = filteredUsers.filter((user) => user.company_id === companyId)
    }

    return filteredUsers
      .filter(
        (user) => !allSelectedUserIds.includes(user.user_id) || user.user_id === getValues(`users.${index}.user_id`),
      )
      .map((user) => ({
        label: user.user_name,
        value: user.user_id,
        company_id: user.company_id,
      }))
  }, [usersList, watch, getValues, index])

  const getRoleOptions = useCallback(() => {
    if (!companiesMetadata?.companies) return []

    const companyId = watch(`users.${index}.company_id`)

    if (!companyId) return []

    const selectedCompany = companiesMetadata.companies.find((company) => company.company_id === companyId)

    return (
      selectedCompany?.roles.map((role) => ({
        label: role.role_name,
        value: role.role_id,
      })) ?? []
    )
  }, [companiesMetadata, watch, index])

  if (isCollapsed) {
    const userName = watch(`users.${index}.userName`)
    const roleName = watch(`users.${index}.roleName`)
    const companyName = watch(`users.${index}.companyName`)
    const companyTypeName = watch(`users.${index}.companyTypeName`)

    return (
      <Stack
        spacing={SPACING.spacingLg}
        sx={{
          backgroundColor: 'paper.main',
          p: SPACING.spacingLg,
          borderRadius: SPACING.borderRadiusLg,
          mb: SPACING.spacingLg,
        }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack>
            <Typography variant="subtitle1" color="text.primary">
              {userName}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {roleName}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <IconButton onClick={() => onRemove(index)} size="small">
              <DeleteOutlineRounded fontSize="small" />
            </IconButton>
            <IconButton onClick={handleEditUser} size="small">
              <EditOutlined fontSize="small" />
            </IconButton>
          </Stack>
        </Stack>

        <Divider />

        <Stack direction="row" spacing={SPACING.spacingXxl}>
          <Stack flex={1}>
            <Typography variant="subtitle2" color="text.secondary">
              Company
            </Typography>
            <Typography variant="body2">{companyName}</Typography>
          </Stack>
          <Stack flex={1}>
            <Typography variant="subtitle2" color="text.secondary">
              Type
            </Typography>
            <Typography variant="body2">{companyTypeName}</Typography>
          </Stack>
        </Stack>
      </Stack>
    )
  }

  const selectedCompanyId = watch(`users.${index}.company_id`)

  const handleCompanyTypeChange = (
    _: SyntheticEvent<Element, Event>,
    value:
      | NonNullable<string | { label: string; value: string }>
      | (string | { label: string; value: string })[]
      | null,
  ) => {
    let newCompanyType = null
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      newCompanyType = value.value as CompanyType
    }
    setValue(`users.${index}.company_type`, newCompanyType)
    setValue(`users.${index}.company_id`, '')
    setValue(`users.${index}.user_id`, '')
    setValue(`users.${index}.role_id`, '')
  }

  const handleCompanyChange = (
    _: SyntheticEvent<Element, Event>,
    value:
      | NonNullable<string | { label: string; value: string }>
      | (string | { label: string; value: string })[]
      | null,
  ) => {
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      const companyId = value.value as string
      setValue(`users.${index}.company_id`, companyId)

      setValue(`users.${index}.role_id`, '')

      if (companiesMetadata?.companies) {
        const selectedCompany = companiesMetadata.companies.find((company) => company.company_id === companyId)
        if (selectedCompany) {
          setValue(`users.${index}.company_type`, selectedCompany.company_type)
        }
      }
    } else if (value === null) {
      setValue(`users.${index}.company_id`, '')
      setValue(`users.${index}.role_id`, '')
    }
  }

  const handleUserChange = (
    _: SyntheticEvent<Element, Event>,
    value:
      | NonNullable<string | { label: string; value: string }>
      | (string | { label: string; value: string })[]
      | null,
  ) => {
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      const userId = value.value as string
      setValue(`users.${index}.user_id`, userId)

      if (!selectedCompanyId && usersList?.items) {
        const selectedUser = usersList.items.find((user) => user.user_id === userId)
        if (selectedUser && selectedUser.company_id) {
          setValue(`users.${index}.company_id`, selectedUser.company_id)

          if (companiesMetadata?.companies) {
            const userCompany = companiesMetadata.companies.find(
              (company) => company.company_id === selectedUser.company_id,
            )
            if (userCompany) {
              setValue(`users.${index}.company_type`, userCompany.company_type)
            }
          }
        }
      }
    } else if (value === null) {
      setValue(`users.${index}.user_id`, '')
    }
  }

  return (
    <Stack
      spacing={SPACING.spacingLg}
      sx={{
        backgroundColor: 'paper.main',
        p: SPACING.spacingLg,
        borderRadius: SPACING.borderRadiusLg,
        mb: SPACING.spacingLg,
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle1">User {index + 1}</Typography>

        <Stack direction="row" spacing={1} alignItems="center">
          {index > 0 && (
            <IconButton onClick={() => onRemove(index)} size="small">
              <DeleteOutlineRounded fontSize="small" />
            </IconButton>
          )}

          {!isLastField && (
            <Button variant="outlined" size="small" onClick={handleSaveChanges}>
              Save changes
            </Button>
          )}
        </Stack>
      </Stack>

      <Dropdown
        name={`users.${index}.company_type`}
        label="Company type"
        options={
          companiesMetadata?.company_types.map(([value, label]) => ({
            label,
            value,
          })) ?? []
        }
        control={control}
        size="small"
        onChange={handleCompanyTypeChange}
      />

      <Dropdown
        name={`users.${index}.company_id`}
        label="Company name"
        options={getCompanyOptions()}
        control={control}
        size="small"
        onChange={handleCompanyChange}
      />

      <Dropdown
        name={`users.${index}.user_id`}
        label="User name"
        options={getUserOptions()}
        control={control}
        size="small"
        onChange={handleUserChange}
      />

      <Dropdown
        name={`users.${index}.role_id`}
        label="Role"
        options={getRoleOptions()}
        control={control}
        size="small"
        disabled={!selectedCompanyId}
      />
    </Stack>
  )
}

export default AssignUserCard
