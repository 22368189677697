import { Avatar, Stack, Typography, useTheme } from '@mui/material'
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded'
import React from 'react'

interface BlockContentProps {
  fileTypesAllowed: string[]
}

const BlockContent = ({ fileTypesAllowed }: BlockContentProps) => {
  const theme = useTheme()

  return (
    <Stack px={2} py={4} alignItems="center" justifyContent="center" sx={{ width: 1, textAlign: 'center' }}>
      <Avatar sx={{ bgcolor: '#C9E8F2' }}>
        <CloudUploadRoundedIcon sx={{ color: theme.palette.primary.light }} />
      </Avatar>
      <Typography variant="body2" mb={0.5} mt={1}>
        <Typography variant="subtitle2" component="span" sx={{ color: 'primary.main', textDecoration: 'underline' }}>
          Click to upload
        </Typography>
        &nbsp;or drag and drop
      </Typography>
      <Typography gutterBottom variant="body2" color="text.secondary">
        Only these file extensions are allowed:{' '}
        {fileTypesAllowed.map((fileType: string, index: number) => (
          <React.Fragment key={index}>
            {fileType}
            {index < fileTypesAllowed.length - 1 && ', '}
          </React.Fragment>
        ))}
      </Typography>
    </Stack>
  )
}

export default BlockContent
