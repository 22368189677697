import {
  HTTPValidationError,
  updateSeriesMetadataDicomTagsQcTasksQcTaskIdExamSeriesSeriesMetadataIdPut,
  UpdateSeriesMetadataDicomTagsQcTasksQcTaskIdExamSeriesSeriesMetadataIdPutData,
  UpdateSeriesMetadataDicomTagsQcTasksQcTaskIdExamSeriesSeriesMetadataIdPutResponse,
} from '@common/config/api/client'
import { queryClient } from '@common/config/api/queryClient'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export const useUpdateExamMetadataMutation = (): UseMutationResult<
  UpdateSeriesMetadataDicomTagsQcTasksQcTaskIdExamSeriesSeriesMetadataIdPutResponse,
  AxiosError<HTTPValidationError>,
  UpdateSeriesMetadataDicomTagsQcTasksQcTaskIdExamSeriesSeriesMetadataIdPutData
> => {
  return useMutation<
    UpdateSeriesMetadataDicomTagsQcTasksQcTaskIdExamSeriesSeriesMetadataIdPutResponse,
    AxiosError<HTTPValidationError>,
    UpdateSeriesMetadataDicomTagsQcTasksQcTaskIdExamSeriesSeriesMetadataIdPutData
  >({
    mutationFn: async (query) => {
      const response = await updateSeriesMetadataDicomTagsQcTasksQcTaskIdExamSeriesSeriesMetadataIdPut({
        path: query.path,
        query: query.query,
        body: query.body,
        throwOnError: true,
      })
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['QCTask'] })
    },
  })
}
