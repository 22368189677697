import { Box, Chip, Stack, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import BaseDialog from '@common/components/BaseDialog'
import { CheckItem } from '@features/subject/subject.types.ts'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import EnhancedTable from '@common/components/Table/EnhancedTable.tsx'
import { HeadCell, Order } from '@common/components/Table/table.types.ts'
import { MouseEvent, useMemo, useState } from 'react'
import { getGenericComparator } from '@features/subject/utils.ts'

export interface CriteriaCheckDialogProps {
  checks: CheckItem[]
  onClose: () => void
}

const headCells: readonly HeadCell<CheckItem>[] = [
  { id: 'tagId', label: 'ID and Tag name', disableSort: false, disablePadding: true },
  { id: 'ruleLabel', label: 'Rule name', disableSort: false, disablePadding: true },
  { id: 'targetValue', label: 'Target value', disableSort: false, disablePadding: true },
  { id: 'actualValue', label: 'Actual value', disableSort: false, disablePadding: true },
  { id: 'status', label: 'Status', disableSort: false, disablePadding: true },
]

const CriteriaCheckDialog = ({ onClose, checks }: CriteriaCheckDialogProps) => {
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof CheckItem>('tagId')
  const failedCount = checks.filter((item) => item.status === 'failed').length
  const passedCount = checks.filter((item) => item.status === 'passed').length

  const sortedRows = useMemo(() => {
    return checks.sort(getGenericComparator<CheckItem>(order, orderBy))
  }, [checks, order, orderBy])

  const handleRequestSort = (_event: MouseEvent<unknown>, property: keyof CheckItem) => {
    const isAsc = orderBy.split('.')[0] === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  return (
    <BaseDialog width={1200} title="Criteria check results" open={true} cancelLabel="Close" onClose={onClose}>
      <Stack direction="row" gap={2}>
        <Box borderRadius={3} border="1px solid #DCE3E5" p={1} flexGrow={1}>
          <Stack direction="row" gap={0.5}>
            <CheckCircleRoundedIcon sx={{ color: 'success-alt.dark', pt: 0.5 }} />
            <Box lineHeight={1}>
              <Typography variant="subtitle1" data-testid="passed-count">
                {passedCount}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                Passed
              </Typography>
            </Box>
          </Stack>
        </Box>
        <Box borderRadius={3} border="1px solid #DCE3E5" p={1} flexGrow={1}>
          <Stack direction="row" gap={0.5}>
            <CancelRoundedIcon sx={{ color: 'error.main', pt: 0.5 }} />
            <Box lineHeight={1}>
              <Typography variant="subtitle1" data-testid="failed-count">
                {failedCount}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                Failed
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Stack>

      {checks && (
        <EnhancedTable<CheckItem>
          rows={sortedRows}
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          sx={{
            color: 'text.primary',
            bgcolor: 'paper.main',
            fontWeight: '700',
            fontSize: '14px',
            p: 1,
            '&:first-of-type': {
              borderTopLeftRadius: '6px',
            },
            '&:last-of-type': {
              borderTopRightRadius: '6px',
            },
          }}
        >
          <TableBody data-testid="table-body">
            {sortedRows.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  borderBottom: '1px solid ${paper.main}',
                  cursor: 'pointer',
                }}
                hover
                data-testid="table-row"
              >
                <TableCell align="left" data-testid="cell-status">
                  {row.tagId} {row.tagName}
                </TableCell>
                <TableCell align="left" data-testid="cell-tag">
                  {row.ruleLabel}
                </TableCell>
                <TableCell align="left" data-testid="cell-originalValue">
                  {row.targetValue}
                </TableCell>
                <TableCell align="left" data-testid="cell-newValue">
                  {row.actualValue}
                </TableCell>
                <TableCell align="left" data-testid="cell-status">
                  {row.status === 'passed' ? (
                    <Chip label="Passed" variant="filled" color="success-alt" sx={{ borderRadius: 100 }} />
                  ) : (
                    <Chip label="Failed" variant="filled" color="error-alt" sx={{ borderRadius: 100 }} />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </EnhancedTable>
      )}
    </BaseDialog>
  )
}

export default CriteriaCheckDialog
