import { Stack, TableCell, TableHead, TableRow, Typography, useTheme } from '@mui/material'
import { ReadTaskSummaryData } from '@common/config/api/client'
import { ScheduleRounded } from '@mui/icons-material'
import { TableScrollSize } from '@features/reader_summary/pages/summary.types'

interface DefaultSummaryTableHeaderProps {
  timepoints: ReadTaskSummaryData['timepoints']
  tableScrollSize: TableScrollSize
  isHidden: boolean
}

export default function DefaultSummaryTableHeader({
  timepoints,
  tableScrollSize,
  isHidden,
}: DefaultSummaryTableHeaderProps) {
  const theme = useTheme()

  return (
    <TableHead sx={{ visibility: isHidden ? 'collapse' : 'visible' }} data-testid="default-subject-table-header">
      <TableRow
        data-testid="header-row"
        sx={{
          bgcolor: 'background.paper',
        }}
      >
        <TableCell
          key="pseudo_id"
          sx={{
            width: tableScrollSize.columnWidth,
            minWidth: tableScrollSize.columnWidth,
            position: 'sticky',
            left: 0,
            background: 'inherit',
            zIndex: 3,
            paddingLeft: 3,
            borderBottom: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Stack direction="row" spacing={1} sx={{ color: 'text.primary' }}>
            <ScheduleRounded />
            <Typography noWrap variant="button" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              Timepoint
            </Typography>
          </Stack>
        </TableCell>

        {Array.from({ length: tableScrollSize.emptyCellsCount }).map((_, idx) => (
          <TableCell
            key={`empty-${idx}`}
            sx={{
              width: tableScrollSize.columnWidth,
              minWidth: tableScrollSize.columnWidth,
              borderBottom: `1px solid ${theme.palette.divider}`,
            }}
          />
        ))}

        {timepoints.map((timepoint, index) => {
          let stickyStyles = {}

          if (index === 0) {
            stickyStyles = {
              position: 'sticky',
              left: tableScrollSize.firstTimepointLeftOffset,
              background: 'inherit',
              zIndex: 2,
            }
          }

          if (index === timepoints.length - 1) {
            stickyStyles = {
              position: 'sticky',
              right: tableScrollSize.checkboxWidth,
              background: 'inherit',
              zIndex: 2,
              borderLeft: `1px solid ${theme.palette.primary.main}`,
            }
          }

          return (
            <TableCell
              key={`timepoint-${index}`}
              data-testid={`head-table-cell-${index}`}
              sx={{
                width: tableScrollSize.columnWidth,
                minWidth: tableScrollSize.columnWidth,
                borderBottom: `1px solid ${theme.palette.divider}`,
                borderLeft: `1px solid ${theme.palette.divider}`,

                ...stickyStyles,
              }}
            >
              <Typography
                noWrap
                variant="button"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: 'text.primary',
                }}
              >
                {timepoint.timepoint_name}
              </Typography>
            </TableCell>
          )
        })}

        <TableCell
          padding="checkbox"
          sx={{
            width: tableScrollSize.checkboxWidth,
            minWidth: tableScrollSize.checkboxWidth,
            position: 'sticky',
            right: 0,
            background: 'inherit',
            zIndex: 3,
            borderBottom: `1px solid ${theme.palette.divider}`,
            borderLeft: `1px solid ${theme.palette.primary.main}`,
          }}
        />
      </TableRow>
    </TableHead>
  )
}
