import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { TextField } from '@common/components/Form/TextField'
import BaseDialog from '@common/components/BaseDialog'
import { Typography } from '@mui/material'
import { SPACING } from '@common/theme/spacing'

const ImportStudySchema = z.object({
  nickname: z.string().optional(),
  userFacingId: z.string().optional(),
})

export type ImportStudyFormValues = z.infer<typeof ImportStudySchema>

interface ImportStudyDialogProps {
  open: boolean
  onClose: () => void
  onImport: (nickname: string | undefined, userFacingId: string | undefined) => void
  fileName: string
  isImporting: boolean
}

export default function ImportStudyDialog({ open, onClose, onImport, fileName, isImporting }: ImportStudyDialogProps) {
  const form = useForm<ImportStudyFormValues>({
    resolver: zodResolver(ImportStudySchema),
    mode: 'onBlur',
    defaultValues: {
      nickname: '',
      userFacingId: '',
    },
  })

  const handleImport = () => {
    const formData = form.getValues()
    const { nickname, userFacingId } = formData

    onImport(nickname?.trim() ? nickname.trim() : undefined, userFacingId?.trim() ? userFacingId.trim() : undefined)
  }

  return (
    <BaseDialog
      title="Import Study"
      open={open}
      onClose={onClose}
      onSubmit={handleImport}
      form={form}
      submitLabel="Import Study"
      cancelLabel="Cancel"
      isSubmitting={isImporting}
      width={600}
    >
      <Typography variant="body1" sx={{ mb: 1 }}>
        You are about to import a study from the file: <strong>{fileName}</strong>
      </Typography>

      <Typography variant="body2" sx={{ mb: SPACING.spacingLg, color: 'text.secondary' }}>
        Optionally, you can specify a custom nickname and ID for the imported study. If left blank, values from the
        import file will be used.
      </Typography>

      <TextField
        name="nickname"
        control={form.control}
        label="Study Nickname"
        placeholder="Enter custom study nickname (optional)"
        disabled={isImporting}
      />

      <TextField
        name="userFacingId"
        control={form.control}
        label="Study ID"
        placeholder="Enter custom study ID (optional)"
        disabled={isImporting}
      />
    </BaseDialog>
  )
}
