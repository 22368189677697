import { useEffect, useRef, useState } from 'react'
import { Box, Stack, SxProps, Table, TableBody, TableCell, TableRow, Theme, Typography, useTheme } from '@mui/material'
import DefaultAdjudicationTableHeader from './DefaultAdjudicationTableHeader'
import ResponseTableRow from './ResponseTableRow'
import { ReadTaskSummaryData } from '@common/config/api/client'
import { TableScrollSize } from '@features/reader_summary/pages/summary.types'
import ExpandableSection from '../summary/ExpandableSection'

interface RowDef {
  label: string
  getValue: (tp: ReadTaskSummaryData['timepoints'][number], index: number) => string
  getComment?: (tp: ReadTaskSummaryData['timepoints'][number], index: number) => string
}

function TimepointCell({
  i,
  tableScrollSize,
  stickyStyles,
  theme,
  values,
  isBold,
  comments,
  isDifferentAdjudication,
}: {
  i: number
  tableScrollSize: TableScrollSize
  stickyStyles?: SxProps<Theme>
  theme: Theme
  values: string[]
  isBold: boolean
  comments: string[]
  isDifferentAdjudication: boolean
}) {
  const cellRef = useRef<HTMLTableCellElement>(null)
  const [cellHeight, setCellHeight] = useState<number>(0)

  useEffect(() => {
    if (cellRef.current) {
      const { height } = cellRef.current.getBoundingClientRect()
      setCellHeight(height)
    }
  }, [])

  return (
    <TableCell
      key={`timepoint-${i}`}
      ref={cellRef}
      sx={{
        width: tableScrollSize.columnWidth,
        minWidth: tableScrollSize.columnWidth,
        borderBottom: isDifferentAdjudication
          ? `2px solid ${theme.palette.error.main}`
          : `1px solid ${theme.palette.divider}`,
        borderLeft: isDifferentAdjudication
          ? `2px solid ${theme.palette.error.main}`
          : `1px solid ${theme.palette.divider}`,
        borderTop: isDifferentAdjudication ? `2px solid ${theme.palette.error.main}` : 'none',
        borderRight: isDifferentAdjudication ? `2px solid ${theme.palette.error.main}` : 'none',
        padding: 0,
        ...stickyStyles,
      }}
    >
      <Stack direction="row" width={tableScrollSize.columnWidth} maxWidth={tableScrollSize.columnWidth}>
        <Box sx={{ height: { cellHeight }, width: '50%' }}>
          <Stack>
            <Typography
              variant={isBold ? 'button' : 'body2'}
              sx={{
                color: 'text.primary',
              }}
              align="left"
              paddingTop={1}
              paddingX={2}
            >
              {values[0]}
            </Typography>
            <Typography
              variant="caption"
              sx={{
                color: 'text.secondary',
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
              }}
              paddingBottom={1}
              paddingX={2}
              align="left"
            >
              {comments[0]}
            </Typography>
          </Stack>
        </Box>
        <Box
          sx={{
            height: cellHeight,
            width: '50%',
            borderLeft: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Stack>
            <Typography
              variant={isBold ? 'button' : 'body2'}
              sx={{
                color: 'text.primary',
              }}
              align="left"
              paddingY={1}
              paddingX={2}
            >
              {values[1]}
            </Typography>
            <Typography
              variant="caption"
              sx={{
                color: 'text.secondary',

                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
              }}
              align="left"
            >
              {comments[1]}
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </TableCell>
  )
}

export default function ExpandableResponseTableRow({
  timepoints,
  tableScrollSize,
}: {
  timepoints: ReadTaskSummaryData['timepoints']
  tableScrollSize: TableScrollSize
}) {
  const [expanded, setExpanded] = useState(false)
  const theme = useTheme()

  const rows: RowDef[] = [
    {
      label: 'SOD',
      getValue: (tp, index) => tp.reader_submissions[index].ecrf_form_submission?.overall_response?.sod ?? '',
    },
    {
      label: 'From baseline',
      getValue: (tp, index) => tp.reader_submissions[index].ecrf_form_submission?.overall_response?.from_baseline ?? '',
    },
    {
      label: 'From nadir',
      getValue: (tp, index) => tp.reader_submissions[index].ecrf_form_submission?.overall_response?.from_nadir ?? '',
    },
    {
      label: 'Target lesion response',
      getValue: (tp, index) =>
        tp.reader_submissions[index].ecrf_form_submission?.overall_response?.target_lesion_response ?? '',
    },
    {
      label: 'Non-target lesion response',
      getValue: (tp, index) =>
        tp.reader_submissions[index].ecrf_form_submission?.overall_response?.non_target_lesion_response ?? '',
    },
    {
      label: 'New lesions',
      getValue: (tp, index) => tp.reader_submissions[index].ecrf_form_submission?.overall_response?.new_lesions ?? '',
    },
    {
      label: 'Adjudication',
      getValue: (tp, index) =>
        tp.reader_submissions[index].ecrf_form_submission?.overall_response?.adjudication_justification ?? '',
      getComment: (tp, index) =>
        tp.reader_submissions[index].ecrf_form_submission?.overall_response?.adjudication_comment ?? '',
    },
  ]

  return (
    <>
      <ResponseTableRow
        expanded={expanded}
        setExpanded={setExpanded}
        timepoints={timepoints}
        tableScrollSize={tableScrollSize}
      />
      <TableRow>
        <TableCell sx={{ p: 0 }} colSpan={tableScrollSize.minVisibleTimepoints + 2} data-testid="collapse-cell">
          <ExpandableSection expanded={expanded} disableAnimation>
            <Table
              data-testid="expanded-table"
              sx={{
                borderCollapse: 'separate',
              }}
            >
              <DefaultAdjudicationTableHeader isHidden timepoints={timepoints} tableScrollSize={tableScrollSize} />
              <TableBody>
                {rows.map((rowDef, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    sx={{
                      bgcolor: rowIndex % 2 === 0 ? 'paper.main' : 'background.paper',
                    }}
                  >
                    <TableCell
                      sx={{
                        width: tableScrollSize.columnWidth,
                        minWidth: tableScrollSize.columnWidth,
                        position: 'sticky',
                        left: 0,
                        background: 'inherit',
                        zIndex: 2,
                        paddingLeft: 3,
                        paddingY: 1,
                        borderBottom: `1px solid ${theme.palette.divider}`,
                      }}
                    >
                      <Typography variant="body2" sx={{ color: 'text.secondary', paddingLeft: 4 }}>
                        {rowDef.label}
                      </Typography>
                    </TableCell>

                    {Array.from({ length: tableScrollSize.emptyCellsCount }).map((_, i) => (
                      <TableCell
                        key={`empty-${i}`}
                        sx={{
                          width: tableScrollSize.columnWidth,
                          minWidth: tableScrollSize.columnWidth,
                          borderBottom: `1px solid ${theme.palette.divider}`,
                        }}
                      />
                    ))}

                    {timepoints.map((timepoint, tpIndex) => {
                      let stickyStyles = {}
                      if (tpIndex === 0) {
                        stickyStyles = {
                          // border: 'none',
                          position: 'sticky',
                          left: tableScrollSize.firstTimepointLeftOffset,
                          background: 'inherit',
                          zIndex: 2,
                        }
                      }

                      if (tpIndex === timepoints.length - 1) {
                        stickyStyles = {
                          // border: 'none',
                          position: 'sticky',
                          right: tableScrollSize.checkboxWidth,
                          background: 'inherit',
                          zIndex: 2,
                          borderLeft: `1px solid ${theme.palette.primary.main}`,
                        }
                      }
                      const isNadir =
                        timepoint.reader_submissions[0].ecrf_form_submission?.overall_response?.from_nadir === 'Nadir'
                      const isFirstTimepoint = tpIndex === 0
                      const isFirstThreeColumns = rowIndex < 3
                      const isDifferentAdjudication =
                        (rowDef.label === 'Target lesion response' ||
                          rowDef.label === 'Non-target lesion response' ||
                          rowDef.label === 'New lesions') &&
                        rowDef.getValue(timepoint, 0) !== rowDef.getValue(timepoint, 1)
                      return (
                        <TimepointCell
                          i={tpIndex}
                          tableScrollSize={tableScrollSize}
                          stickyStyles={stickyStyles}
                          theme={theme}
                          values={[rowDef.getValue(timepoint, 0), rowDef.getValue(timepoint, 1)]}
                          isBold={(isNadir || isFirstTimepoint) && isFirstThreeColumns}
                          comments={
                            rowDef.getComment ? [rowDef.getComment(timepoint, 0), rowDef.getComment(timepoint, 1)] : []
                          }
                          isDifferentAdjudication={isDifferentAdjudication}
                        />
                      )
                    })}

                    <TableCell
                      padding="checkbox"
                      sx={{
                        width: tableScrollSize.checkboxWidth,
                        minWidth: tableScrollSize.checkboxWidth,
                        position: 'sticky',
                        right: 0,
                        background: 'inherit',
                        zIndex: 3,
                        borderBottom: `1px solid ${theme.palette.divider}`,
                        borderLeft: `1px solid ${theme.palette.primary.main}`,
                      }}
                    />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ExpandableSection>
        </TableCell>
      </TableRow>
    </>
  )
}
