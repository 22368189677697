import { TableBody, TableCell, TableRow, Paper, Box, Link, Stack, Typography } from '@mui/material'
import EnhancedTable from '@common/components/Table/EnhancedTable'
import { FilterReportTypeSchema, ReportSchema } from '@common/config/api/client'
import { HeadCell } from '@common/components/Table/table.types'
import { useReportActions } from './hooks'
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined'
import { SPACING } from '@common/theme/spacing'

interface Props {
  reportType: FilterReportTypeSchema
}

type ReportTableSchema = ReportSchema & {
  actions: never
}

const headCells: readonly HeadCell<ReportTableSchema>[] = [
  { id: 'name', label: 'Report name', disablePadding: false },
  { id: 'last_downloaded', label: 'Last download date', disablePadding: false },
  { id: 'last_downloaded', label: 'Last download time', disablePadding: false },
  { id: 'actions', label: 'Actions', disablePadding: false },
] as const

const formatDate = (isoString: string | null | undefined): string => {
  if (!isoString) return '-'
  const date = new Date(isoString)
  return date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  })
}

const formatTime = (isoString: string | null | undefined): string => {
  if (!isoString) return '-'
  const date = new Date(isoString)
  return date.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
  })
}

export default function ReportTable({ reportType }: Props) {
  const { reports, isLoading, handleDownload, isDownloading } = useReportActions(reportType)

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <Box component="section">
      <Paper sx={{ p: SPACING.paperInnerPadding }}>
        <EnhancedTable<ReportTableSchema>
          rows={reports}
          headCells={headCells}
          order="asc"
          orderBy="name"
          selected={[]}
          page={0}
          rowsPerPage={50}
          onRequestSort={() => {}}
          onChangePage={() => {}}
          onChangeRowsPerPage={() => {}}
        >
          <TableBody>
            {reports.map((report) => (
              <TableRow hover key={report.name}>
                <TableCell>{report.name}</TableCell>
                <TableCell>
                  {report.last_downloaded ? (
                    formatDate(report.last_downloaded)
                  ) : (
                    <Stack direction="row" spacing={1} alignItems="center">
                      <DownloadForOfflineOutlinedIcon color="disabled" fontSize="small" />
                      <Typography variant="body2" color="text.secondary">
                        Never
                      </Typography>
                    </Stack>
                  )}
                </TableCell>
                <TableCell>{report.last_downloaded ? formatTime(report.last_downloaded) : '-'}</TableCell>
                <TableCell>
                  <Link
                    component="button"
                    onClick={() => handleDownload(report.report_subtype, report.report_type)}
                    disabled={isDownloading}
                    sx={{ textDecoration: 'none', cursor: 'pointer' }}
                  >
                    Download
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </EnhancedTable>
      </Paper>
    </Box>
  )
}
