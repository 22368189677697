import { MouseEvent, useState } from 'react'
import { TableBody, TableCell, TableRow, Paper, IconButton, Box, Stack, Typography, Chip } from '@mui/material'
import EnhancedTable from '@common/components/Table/EnhancedTable'
import TableToolbar from './TableToolbar'
import EnhancedNavTabs from '@common/components/Tab/EnhancedNavTabs'
import { CompaniesMetadataSchema, UserSchema, PaginatedResponse_UserSchema_ } from '@common/config/api/client'
import { HeadCell, Order } from '@common/components/Table/table.types'
import { useUserTable } from '@features/user_management/hooks'
import { SPACING } from '@common/theme/spacing'
import { NavTabsVariant } from '@common/components/Tab/tabs.types'
import { DeleteOutlineRounded } from '@mui/icons-material'
import { SIZING } from '@common/theme/sizing'
import { useNavigate } from 'react-router-dom'
import { generatePathWithParams } from '@common/utils/urlUtils'
import { ROUTES } from 'src/routes'
import ConfirmationDialog from '@common/components/ConfirmationDialog/ConfirmationDialog'

interface Props {
  companiesMetadata: CompaniesMetadataSchema | undefined
  usersList: PaginatedResponse_UserSchema_ | undefined
  page: number
  onDeleteUser: (userId: string) => void
  onAddUser: () => void
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onSearch: (value: string) => void
  searchValue?: string
  orderBy: keyof UserSchema
  order: Order
  onRequestSort: (event: MouseEvent<unknown>, property: string) => void
  onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void
}

type UserTableSchema = UserSchema & {
  actions: never
}

const headCells: readonly HeadCell<UserTableSchema>[] = [
  { id: 'user_name', label: 'User name', disablePadding: false },
  { id: 'user_email', label: 'Email address', disablePadding: false },
  { id: 'company_type', label: 'Company type', disablePadding: false },
  { id: 'is_verified', label: 'Verified', disablePadding: false },
  { id: 'user_status', label: 'Status', disablePadding: false },
  { id: 'company_name', label: 'Company', disablePadding: false },
  { id: 'created_by', label: 'Created by', disablePadding: false },
  { id: 'actions', label: '', disablePadding: false },
] as const

function EmptyUserTable() {
  return (
    <Stack spacing={SPACING.minSpacing} alignItems="center" sx={{ py: SPACING.spacing2xl }}>
      <Typography variant="h6">No matching user found</Typography>
      <Typography variant="body2" color="text.secondary">
        Please ensure the user's name is correct, or try a different one
      </Typography>
    </Stack>
  )
}

export default function UserTable({
  companiesMetadata,
  usersList,
  onDeleteUser,
  onAddUser,
  onRowsPerPageChange,
  onPageChange,
  onRequestSort,
  onSearch,
  searchValue,
  page,
  order,
  orderBy,
}: Props) {
  const users = usersList?.items ?? []
  const { handleDeleteUser, userTypeRoutes } = useUserTable(companiesMetadata, users, onDeleteUser)
  const navigate = useNavigate()

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [userIdToDelete, setUserIdToDelete] = useState<string>('')

  const handleDeleteUserClick = (event: React.MouseEvent, userId: string) => {
    event.stopPropagation()
    setUserIdToDelete(userId)
    setConfirmDialogOpen(true)
  }

  const handleConfirmDelete = () => {
    handleDeleteUser(userIdToDelete)
    setConfirmDialogOpen(false)
  }

  const handleCancelDelete = () => {
    setConfirmDialogOpen(false)
  }

  return (
    <Box component="section">
      <TableToolbar searchValue={searchValue} onSearch={onSearch} onAddUser={onAddUser} />
      <Paper sx={{ p: SPACING.paperInnerPadding }}>
        <EnhancedNavTabs routes={userTypeRoutes} variant={NavTabsVariant.SEGMENTED_CONTROLS} />
        <EnhancedTable<UserTableSchema>
          rows={users}
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          selected={[]}
          page={page}
          rowsPerPageOptions={[10, 25, 50]}
          onRequestSort={onRequestSort}
          onChangeRowsPerPage={onRowsPerPageChange}
          onChangePage={onPageChange}
          next_cursor={usersList?.pagination.next_cursor}
          previous_cursor={usersList?.pagination.previous_cursor}
          rowsPerPage={usersList?.pagination.limit ?? 50}
        >
          <TableBody>
            {users.length > 0 ? (
              users.map((user) => (
                <TableRow
                  hover
                  key={user.user_id}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate(
                      generatePathWithParams(ROUTES.USER_PROFILE.path, {
                        userId: user.user_id,
                      }),
                    )
                  }}
                >
                  <TableCell>{user.user_name}</TableCell>
                  <TableCell>{user.user_email}</TableCell>
                  <TableCell>{user.company_type}</TableCell>
                  <TableCell>{user.is_verified ? 'Yes' : 'No'}</TableCell>
                  <TableCell>
                    <Chip
                      label={user.user_status == 'ACTIVE' ? 'Active' : 'Deactivated'}
                      size="small"
                      color={user.user_status == 'ACTIVE' ? 'success-alt' : 'error-alt'}
                      sx={{ borderRadius: 100 }}
                    />
                  </TableCell>
                  <TableCell>{user.company_name}</TableCell>
                  <TableCell>{user.created_by}</TableCell>
                  <TableCell align="right">
                    <IconButton size="small" onClick={(event) => handleDeleteUserClick(event, user.user_id)}>
                      <DeleteOutlineRounded
                        sx={{ w: SIZING.iconWidth, mr: SPACING.buttonSpacing, color: 'text.secondary' }}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={headCells.length}>
                  <EmptyUserTable />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </EnhancedTable>
      </Paper>

      <ConfirmationDialog
        open={confirmDialogOpen}
        title="Delete user?"
        description="This will permanently delete all user data and cannot be undone."
        confirmLabel="Delete user"
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
      />
    </Box>
  )
}
