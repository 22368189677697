import { StepType } from '@common/context/StepperContext'
import { Box, Stepper, Step, StepLabel, Typography, useTheme, Tooltip, useMediaQuery } from '@mui/material'
import { useLocation } from 'react-router-dom'

type HorizontalLinearStepperProps = {
  steps: StepType[]
  activeStep: number
  showStepLabels?: boolean
  onStepClick?: (stepIndex: number) => void
}

export function HorizontalLinearStepper({
  steps,
  activeStep,
  showStepLabels = false,
  onStepClick,
}: HorizontalLinearStepperProps) {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xl'))
  const location = useLocation()
  const isStepperInteractive = location.pathname.includes('/studies/edit')

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map(({ label, optional = false, completed = false }, index) => {
          const isActiveStep = index === activeStep
          const stepProps: { completed: boolean } = { completed }
          const labelProps: {
            optional?: React.ReactNode
            slotProps?: {
              label?: {
                sx?: object
              }
              stepIcon?: {
                sx?: object
              }
            }
          } = {}

          if (optional) {
            labelProps.optional = <Typography variant="caption">Optional</Typography>
          }

          labelProps.slotProps = {
            label: {
              sx: {
                display: 'block',
                [theme.breakpoints.down('xl')]: {
                  display: isActiveStep || showStepLabels ? 'block' : 'none',
                },
              },
            },
            stepIcon:
              isStepperInteractive && !isActiveStep
                ? {
                    sx: {
                      transition: 'all 0.3s ease-in-out',
                      '&:hover': {
                        transform: 'scale(1.2)',
                        color: 'primary.light',
                        borderRadius: '50%',
                      },
                    },
                  }
                : undefined,
          }

          const stepLabel = <StepLabel {...labelProps}>{label}</StepLabel>

          return (
            <Step
              key={label}
              {...stepProps}
              onClick={
                isStepperInteractive && onStepClick
                  ? () => {
                      onStepClick(index)
                    }
                  : undefined
              }
            >
              {!isActiveStep && isSmallScreen && !showStepLabels ? (
                <Tooltip title={label} arrow>
                  <span>{stepLabel}</span>
                </Tooltip>
              ) : (
                stepLabel
              )}
            </Step>
          )
        })}
      </Stepper>
    </Box>
  )
}
