import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function NonTargetIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_4290_271" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
          <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_4290_271)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.80844 2.80484L1.39453 4.21887L3.6582 6.48279C2.61035 8.06421 2 9.96082 2 12C2 17.5228 6.47705 22 12 22C14.0391 22 15.9355 21.3895 17.5171 20.3416L19.7793 22.6036L21.1932 21.1896L2.80844 2.80484ZM12 20C13.4849 20 14.875 19.5956 16.0664 18.8909L14.5894 17.4139C13.8052 17.7897 12.9272 18 12 18C8.68652 18 6 15.3137 6 12C6 11.0726 6.21045 10.1943 6.58594 9.4104L5.10889 7.93347C4.4043 9.12512 4 10.5153 4 12C4 16.4183 7.58154 20 12 20ZM8 12C8 11.6405 8.04736 11.2921 8.13623 10.9607L10.5117 13.3361C10.5596 13.3895 10.6108 13.4403 10.6641 13.4883L13.0391 15.8636C12.7075 15.9526 12.3594 16 12 16C9.79102 16 8 14.2091 8 12Z"
            fill="#263238"
          />
          <path
            d="M20.3448 17.5127C21.3909 15.9323 22 14.0373 22 12C22 6.47717 17.5229 2 12 2C9.96294 2 8.06788 2.6092 6.48736 3.65533L7.93841 5.10638C9.12896 4.40339 10.5174 4 12 4C16.4185 4 20 7.58167 20 12C20 13.4827 19.5967 14.8712 18.8937 16.0617L20.3448 17.5127Z"
            fill="#263238"
          />
          <path
            d="M17.4164 14.5844C17.7905 13.8017 18 12.9253 18 12C18 8.68628 15.3135 6 12 6C11.0747 6 10.1983 6.20946 9.4156 6.58357L10.9668 8.13472C11.2964 8.04684 11.6427 8 12 8C14.209 8 16 9.79089 16 12C16 12.3573 15.9532 12.7037 15.8653 13.0333L17.4164 14.5844Z"
            fill="#263238"
          />
        </g>
      </svg>
    </SvgIcon>
  )
}
