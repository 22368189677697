import * as React from 'react'
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import CardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box } from '@mui/material'

const ExpandMore = styled('div')(({ theme }) => ({
  display: 'flex',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

export type ExpandableSectionProps = {
  title: string
  isFinished: boolean
  children: React.ReactNode
  unControllable?: boolean
  isExpanded: boolean
  setIsExpanded?: (value: boolean) => void
}

export default function ExpandableSection({
  title,
  children,
  isFinished,
  isExpanded,
  setIsExpanded,
  unControllable = false,
}: ExpandableSectionProps) {
  const handleExpandClick = () => {
    if (setIsExpanded && !unControllable) {
      setIsExpanded(!isExpanded)
    }
  }

  return (
    <Card sx={{ p: 3, borderRadius: 4 }} elevation={1}>
      <CardActions
        disableSpacing
        sx={{ p: 0, cursor: 'pointer', justifyContent: 'space-between' }}
        onClick={handleExpandClick}
      >
        <Box display="flex" alignItems="center">
          <CheckCircleOutlineRoundedIcon sx={{ color: isFinished ? 'success.main' : 'text.disabled' }} />
          <Typography variant="h6" ml={2}>
            {title}
          </Typography>
        </Box>
        {!unControllable && (
          <ExpandMore
            sx={{
              transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          >
            <ExpandMoreIcon />
          </ExpandMore>
        )}
      </CardActions>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </Card>
  )
}
