import { ReactNode, useState } from 'react'
import { Collapse, CollapseProps } from '@mui/material'

type ExpandableSectionProps = {
  children: ReactNode
  expanded: boolean
  collapseProps?: Omit<CollapseProps, 'in'>
  disableAnimation?: boolean
}

export default function ExpandableSection({
  children,
  expanded,
  collapseProps,
  disableAnimation,
}: ExpandableSectionProps) {
  const [isAnimating, setIsAnimating] = useState(false)

  return (
    <Collapse
      in={expanded}
      timeout="auto"
      data-testid="collapse-section"
      onEnter={() => setIsAnimating(true)}
      onEntered={() => setIsAnimating(false)}
      onExit={() => setIsAnimating(true)}
      onExited={() => setIsAnimating(false)}
      {...collapseProps}
    >
      {isAnimating && disableAnimation ? null : children}
    </Collapse>
  )
}
