import { UserSchema } from '@common/config/api/client'
import { SPACING } from '@common/theme/spacing'
import { Button, Divider, Paper, Stack, Typography } from '@mui/material'
import { toast } from 'sonner'
import Toast from '@common/components/Toast'
import { handleMutationError } from '@common/hooks/handleMutationError'
import useSendAccountActionEmail from '@features/user_management/hooks/useSendAccountActionEmail'
import useToggleAccountStatus from '@features/user_management/hooks/useToggleAccountStatus'
import { queryClient } from '@common/config/api/queryClient'
import queryKeyFactory from '@features/user_management/queryKeyFactory'
import { useMemo } from 'react'

export type SettingsProps = {
  user: UserSchema | undefined
}

export enum AccountActionEnum {
  UPDATE_PASSWORD = 'UPDATE_PASSWORD',
  VERIFY_EMAIL = 'VERIFY_EMAIL',
}

export default function Settings({ user }: SettingsProps) {
  const sendAccountAction = useSendAccountActionEmail()
  const toggleStatus = useToggleAccountStatus()
  const userStatus = useMemo(() => user?.user_status, [user])
  const isSubmitting = sendAccountAction.isPending

  const sendRequiredActionEmail = async (action: AccountActionEnum) => {
    if (!user?.user_id) return

    sendAccountAction.mutate(
      {
        userId: user.user_id,
        action,
      },
      {
        onSuccess: () => {
          toast(<Toast variant="success" message={`Email sent successfully`} />)
        },
        onError: handleMutationError,
      },
    )
  }
  const handleAccountStatusChange = async () => {
    if (!user?.user_id) return
    toggleStatus.mutate(
      { userId: user?.user_id },
      {
        onSuccess: () => {
          toast(
            <Toast
              variant="success"
              message={`User ${userStatus == 'ACTIVE' ? 'deactivated' : 'reactivated'} successfully`}
            />,
          )

          queryClient.setQueryData(queryKeyFactory.userDetails(user?.user_id), (oldData: UserSchema) => ({
            ...oldData,
            user_status: userStatus === 'ACTIVE' ? 'DEACTIVATED' : 'ACTIVE',
          }))
        },
        onError: handleMutationError,
      },
    )
  }

  return (
    <>
      <Paper
        sx={{
          padding: SPACING.paperInnerPadding,
          borderRadius: SPACING.borderRadiusXl,
        }}
      >
        <Typography variant="h6" sx={{ mb: SPACING.paperHeadingMarginY }}>
          Access settings
        </Typography>

        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="column" flex={1}>
            <Typography variant="subtitle1">Reset this user's password</Typography>
            <Typography variant="body1" color="textSecondary">
              This action will send a reset email to the users verified email address
            </Typography>
          </Stack>
          <Button
            size="medium"
            variant="outlined"
            color="secondary"
            onClick={() => sendRequiredActionEmail(AccountActionEnum.UPDATE_PASSWORD)}
            disabled={isSubmitting || userStatus === 'DEACTIVATED'}
          >
            Reset email
          </Button>
        </Stack>

        <Divider sx={{ my: SPACING.dividerMarginY }} />

        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="column" flex={1}>
            <Typography variant="subtitle1">Verify this user</Typography>
            <Typography variant="body1" color="textSecondary">
              This action will send a verification email to the user's registered email address
            </Typography>
          </Stack>
          <Button
            size="medium"
            variant="outlined"
            color="secondary"
            onClick={() => sendRequiredActionEmail(AccountActionEnum.VERIFY_EMAIL)}
            disabled={isSubmitting || userStatus === 'DEACTIVATED'}
          >
            Verify email
          </Button>
        </Stack>

        <Divider sx={{ my: SPACING.dividerMarginY }} />

        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="column" flex={1}>
            <Typography variant="subtitle1">
              {userStatus == 'ACTIVE' ? 'Deactivate' : 'Reactivate'} this user
            </Typography>
            <Typography variant="body1" color="textSecondary">
              This action will {userStatus == 'ACTIVE' ? 'deactivate' : 'reactivate'} the user's account
            </Typography>
          </Stack>
          <Button
            size="medium"
            variant="outlined"
            color="secondary"
            onClick={() => handleAccountStatusChange()}
            disabled={isSubmitting}
          >
            {userStatus == 'ACTIVE' ? 'Deactivate' : 'Reactivate'} account
          </Button>
        </Stack>

        <Divider sx={{ my: SPACING.dividerMarginY }} />
      </Paper>
    </>
  )
}
