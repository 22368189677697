import React from 'react'
import {
  Button,
  Dialog,
  DialogProps as MUIDialogProps,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material'
import { FieldValues, UseFormReturn } from 'react-hook-form'
import { SPACING } from '@common/theme/spacing'

export interface BaseDialogProps<T extends FieldValues> {
  title: string
  description?: string
  open: boolean
  onClose: () => void
  onSubmit?: () => void
  form?: UseFormReturn<T>
  children?: React.ReactNode
  submitLabel?: string
  cancelLabel?: string
  width?: number
  bgcolor?: string
  contentSpacing?: number
  isSubmitting?: boolean
}

const BaseDialog = <T extends Record<string, unknown>>({
  title,
  description,
  open,
  onClose,
  onSubmit,
  form,
  children = null,
  submitLabel = 'Save',
  cancelLabel = 'Cancel',
  width = 500,
  bgcolor = 'white',
  isSubmitting = false,
  ...rest
}: BaseDialogProps<T> & MUIDialogProps) => {
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (onSubmit) {
      onSubmit()
    }
  }

  const BaseDialogContent = (
    <>
      <DialogTitle sx={{ pt: SPACING.ModalInnerSpacing, pb: 0 }} id="dialog-title">
        {title}
      </DialogTitle>
      <DialogContent
        role="dialog"
        aria-labelledby="dialog-title"
        aria-describedby={description ? 'dialog-description' : undefined}
        tabIndex={-1}
        sx={{
          paddingX: SPACING.spacingXl,
          paddingY: SPACING.spacingLg,
          width,
          mx: 'auto',
          overflow: 'auto',
          maxHeight: '80vh',
        }}
      >
        {description && (
          <Typography
            id="dialog-description"
            variant="body1"
            color="text.secondary"
            sx={{ marginBottom: SPACING.spacingXxl }}
          >
            {description}
          </Typography>
        )}
        {children && (
          <Stack direction="column" spacing={SPACING.contentSpacing}>
            {children}
          </Stack>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: SPACING.spacingXl }}>
        <Button
          onClick={onClose}
          size="large"
          color="secondary"
          variant="contained"
          disabled={isSubmitting}
          aria-label={cancelLabel}
        >
          {cancelLabel}
        </Button>
        {onSubmit && (
          <Button
            onClick={handleSubmit}
            size="large"
            color="primary"
            variant="contained"
            disabled={isSubmitting}
            aria-label={submitLabel}
          >
            {submitLabel}
          </Button>
        )}
      </DialogActions>
    </>
  )

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="xl"
      sx={{
        bgcolor: bgcolor === 'transparent' ? 'rgba(0, 0, 0, 0.7)' : bgcolor,
        maxHeight: '100%',

        '& .MuiPaper-root': {
          borderRadius: 3,
          maxHeight: '100%',
        },
      }}
      {...rest}
    >
      {form ? (
        <form role="form" onSubmit={handleSubmit}>
          {BaseDialogContent}
        </form>
      ) : (
        BaseDialogContent
      )}
    </Dialog>
  )
}

export default BaseDialog
