export const DEFAULT_ENV = 'DEV'
export const DEV_WEB_URL = import.meta.env.VITE_DEV_WEB_URL
export const STAGING_WEB_URL = import.meta.env.VITE_STAGING_WEB_URL
export const PROD_WEB_URL = import.meta.env.VITE_PROD_WEB_URL
export const CURRENT_ENV = (import.meta.env.VITE_CURRENT_ENV || DEFAULT_ENV) as Environment

export type Environment = 'DEV' | 'UAT' | 'PRODUCTION'

export const ENVIRONMENT_URLS: Record<Environment, string> = {
  DEV: DEV_WEB_URL as string,
  UAT: STAGING_WEB_URL as string,
  PRODUCTION: PROD_WEB_URL as string,
}

export const ENVIRONMENT_CHANGE_ALLOWED_ROLES = ['IAM', 'IQM', 'IQS', 'IPM']
