import { SPACING } from '@common/theme/spacing'
import { Box, Paper, Skeleton, Stack } from '@mui/material'

export default function SubjectsTableSkeleton() {
  return (
    <Box component="section">
      {/* Toolbar Skeleton */}
      <Stack direction="row" spacing={3} sx={{ p: 2 }} justifyContent="flex-end">
        <Skeleton variant="rectangular" width={120} height={32} sx={{ borderRadius: 1 }} />
        <Skeleton variant="rectangular" width={300} height={32} sx={{ borderRadius: 1 }} />
      </Stack>

      {/* Table Container */}
      <Paper sx={{ p: SPACING.paperInnerPadding }}>
        {/* Tab Navigation Skeleton */}
        <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
          {[1, 2].map((i) => (
            <Skeleton key={i} variant="rectangular" width={140} height={36} sx={{ borderRadius: 1 }} />
          ))}
        </Stack>

        {/* Table Header Skeleton */}
        <Skeleton variant="rectangular" width="100%" height={56} sx={{ mb: 1 }} />

        {/* Table Rows Skeleton */}
        {[1, 2, 3, 4, 5].map((i) => (
          <Skeleton key={i} variant="rectangular" width="100%" height={52} sx={{ mb: 1, borderRadius: 1 }} />
        ))}

        {/* Pagination Skeleton */}
        <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
          <Skeleton variant="rectangular" width={300} height={32} sx={{ borderRadius: 1 }} />
        </Stack>
      </Paper>
    </Box>
  )
}
